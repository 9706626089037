import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { insuranceActions } from "../../../core/actions";

import {
    ListGroup,
    Row,
    Col,
    Form,
    FormInput,
    Alert,
    Button
  
    
  } from "shards-react";



function InsuranceMasteradd(props) {
  const dispatch = useDispatch();

  const [submitted, setSubmitted] = useState([]);

  const [formErrors, setFormErrors] = useState(
    {
      email:'',
      phone1:'',
     
    }, 
  )
  const [addFlag, setTextFlag] =useState(false);

  const [insuranceDetail, setInsuranceDetail] = useState(
    {
      id: "",
      companyName:"",
    
    
      address: "",
  
      email: "",
      phone1:"",
      phone2:"",
      description:""
     
     

    }
  );
  const [ServerRes, setServerRes] = useState(
    {success:'',error:'',}
    );
    //let errormsg="Email is Invalid";
    const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
 
    const handleInputChange = (name, value) => {
        console.log("In handleinput change",name, value);
        var pDetail = insuranceDetail;
        formErrors[name]="";
        console.log('pDetail["basicinfo"][name]',pDetail[name])
        pDetail[name] = value;
        setInsuranceDetail({...pDetail});

      }

      const handleSubmit = (event) => {
        event.preventDefault();
      // if(props.location.state&&props.location.state.rowData){
      //   console.log("edit case");
      //   console.log("insuranceDetail",insuranceDetail);

      //   dispatch(
      //     insuranceActions.modifyInsurance(insuranceDetail, (resCb) => {
      //       if (resCb) {
      //         console.log("modifyDoctor", resCb);
      //         props.history.push("/insuremasterlist");

      //       }
      //     })
      //   );
      // }
      // else{
      //   console.log("save case");

      // dispatch(
      //   insuranceActions.addInsuranceMaster(insuranceDetail, (resCb) => {
      //     if (resCb) {
      //       console.log("saveDoctor", resCb);
      //       props.history.push("/insuremasterlist");

      //     }
      //   })
      // );
      // }
      setSubmitted(true);
     
      Object.keys(insuranceDetail).forEach((key)=>{
        console.log("key",key)
        console.log("key",insuranceDetail[key])
        validateField(key,insuranceDetail[key])
      })
      validateForm(event); 
    }
    const validateField = (fieldName, value) => {
      let fieldValidationErrors = formErrors;
      let basicInfo = insuranceDetail;
   
      var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
      var currentDate = new Date().getDate;    
      switch(fieldName){

          case "phone1":
            if (fieldValidationErrors.phone1 = value.length == 0) {
              fieldValidationErrors.phone1 = value.length == 0 ? 'Please Enter Phone Number' : '';
              basicInfo.phone1 = value !== '' ? value : '';
              break;
            } else {
              fieldValidationErrors.phone1 = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
              basicInfo.phone1 = value !== '' ? value : '';
              break;
            };
            case "email":
               if (!isEmail(value)) {
                    fieldValidationErrors.email = value == isEmail ? '' : 'Please Enter Email';
                    basicInfo.email = value !== '' ? value : '';
                    break;
               }
      
        default:
            break
      }
      setFormErrors({...fieldValidationErrors});
    }

    const validateForm = (event) => {
      event.preventDefault();
  
      // var Detail = doctorDetail;
      // var age = getAge(doctorDetail.dob)
      // setdoctorDetail(Detail);
      console.log("formErrors.::",formErrors);
      let formValid =
    
        formErrors.email === ""&&
        formErrors.phone1 === "" ;
        console.log("formValid",formValid);
  
      if(formValid){
        if(props.location.state&&props.location.state.rowData){
        console.log("edit case");
        console.log("insuranceDetail",insuranceDetail);

        dispatch(
          insuranceActions.modifyInsurance(insuranceDetail, (resCb) => {
            if (resCb) {
              console.log("modifyDoctor", resCb);
              props.history.push("/insuremasterlist");

            }
          })
        );
      }
      else{
        console.log("save case");

      dispatch(
        insuranceActions.addInsuranceMaster(insuranceDetail, (resCb) => {
          if (resCb) {
            console.log("saveDoctor", resCb);
            props.history.push("/insuremasterlist");

          }
        })
      );
      }
       
     }
        else{
          console.log("invalid");
        }
      }





  useEffect(() => {

    console.log("propsprops",props);
    // console.log("props.location.state.rowData",props.location.state.rowData);

    if(props.location.state&&props.location.state.rowData){
      let insuranceData = props.location.state.rowData
      setTextFlag(!addFlag);

      setInsuranceDetail({...insuranceData})
    }else{
      setTextFlag(addFlag);

      setInsuranceDetail(insuranceDetail)
    }

  
  }, []);

  const [onmodal, setOnModal] =useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  }
  const toggle =(event)=>{
    // props.onModalClose();
    props.history.push("/insuremasterlist");

   }
  return (
    <>
          <div class="mainsection">
           <div ><h5>{addFlag ? "Edit" :"Add"} Insurance</h5> </div>
         

            <main className="mainsection">
        <ListGroup flush className="mb-3 ">
            <div className="p-2 bg-none">
              <Row>
                <Col>
                  <Form>

                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="gender">Company Name</label>
                        <FormInput
                          id="companyName"
                          value={insuranceDetail.companyName}
                          placeholder="Company Name"
                          autoComplete="off"
                          required
                          onChange={(event) => {
                            handleInputChange(
                              "companyName",
                              event.target.value
                            );
                          }}
                        />

                      </Col>
                      <Col md="6" className="form-group">

                         <label htmlFor="email">Email Id</label>
                        <FormInput 
                          id="email"
                          value={insuranceDetail.email}
                          placeholder="Email Id"
                        autoComplete="off"
                        required
                            onChange={(event) => {
                        handleInputChange("email", event.target.value);
                             }}
                          invalid={formErrors.email === "" ? false : true}
                            />
                          <div style={{ color: "#EC4147" }}>
                        <span>{formErrors.email}</span>
                      </div>
                         </Col>

                    </Row>


                    <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="feInputAddress">Phone Number 1</label>
                      <FormInput
                        id="phoneno"
                        type="number"
                        placeholder="Phone Number 1"
                        value={insuranceDetail.phone1}
                        onChange={(event) => {
                          handleInputChange("phone1", event.target.value);
                        }}
                        invalid={formErrors.phone1 === "" ? false : true}

                      />
                      <div style={{ color: "#EC4147" }}>
                        <span>{formErrors.phone1}</span>
                      </div>
                  </Col>

                  

                    <Col md="6" className="form-group">
                        <label htmlFor="phoneno">Phone Number 2</label>
                        <FormInput
                          id="phoneno"
                          type="number"
                          value={insuranceDetail.phone2}
                          placeholder="Phone Number 2"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phone2", event.target.value);
                          }}
                        />
                      </Col>

                    </Row>

                    <Row form>
                  
                      <Col md="6" className="form-group">
                        <label htmlFor="feInputDescription">Description</label>
                        <FormInput
                          id="feInputDescription"
                          placeholder="Description"
                          autoComplete="off"
                          value={insuranceDetail.description}
                          onChange={(event) => {
                            handleInputChange("description", event.target.value);
                          }}
                        />
                      </Col>
                    </Row>

                    {ServerRes.success &&
                      <Alert theme="success">{ServerRes.success}</Alert>
                    }
                    {ServerRes.error &&
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    }
                  </Form>

                  <div className="buttonsetconfirm">
                    <Button
                          className="btn-danger"
                          type="submit"
                          onClick={(event) => toggle(event)}
                        >
                          close
                        </Button>
                      <Button
                        type="submit"
                        onClick={(event) => handleSubmit(event)}
                      >
                        Save
                      </Button>
                    </div>
                    <br/>
                </Col>
              </Row>
            </div>
          </ListGroup>
          
        
          </main>
     
          
          </div>
    
     
    </>
  );
}
export default InsuranceMasteradd;
