import React, { Component } from "react";
import { connect } from "react-redux";
import { formActions } from "../../core/actions";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormTextarea,
  Button,
} from "shards-react";

class SectionAdd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      msectioninfo: {
        slno: "",
        sheader: "",
        contents: "",
      },
      submitted: "",
    };
  }

  handleInputChange(name, value) {
    console.log("In handleinput change");
    var sectionDetail = this.state.msectioninfo;
    sectionDetail[name] = value;
    this.setState({ formDetail: sectionDetail });
  }

  handleSubmit(event) {
    console.log(
      "Length of section header : ",
      this.state.msectioninfo.sheader.length
    );
    this.props.onAddModalClose({ sectioninfo: this.state.msectioninfo });
  }

  closeModal(event) {
    this.props.onAddModalClose({ close: "true" });
  }

  render() {
    return (
      <div className="modalmn">
        <div className="mb-4 col-sm-12 col-md-6 modal-main">
          <div style={{ display: `flex`, justifyItems: `flex-end` }}>
            <span
              onClick={(event) => this.closeModal(event)}
              className="topright"
            >
              X
            </span>
          </div>
          <div className="blog-comments card pcard">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <label htmlFor="rate">Section Header</label>
                        <FormInput
                          id="sheader"
                          value={this.state.msectioninfo.sheader}
                          autoComplete="off"
                          maxlength="256"
                          onChange={(event) => {
                            this.handleInputChange(
                              "sheader",
                              event.target.value
                            );
                          }}
                        />
                      </Row>
                      <Row form>
                        <label htmlFor="ref">Content</label>
                        <FormTextarea
                          maxlength="4096"
                          value={this.state.msectioninfo.contents}
                          onChange={(event) => {
                            this.handleInputChange(
                              "contents",
                              event.target.value
                            );
                          }}
                        ></FormTextarea>
                      </Row>

                      <div className="bbtn">
                        <div className="btnspc">
                          <Button onClick={(event) => this.handleSubmit(event)}>
                            Save
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formDetail: state.formDetailsState.formDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFormDetails: (pDetail, resCb) =>
      dispatch(formActions.getFormDetails(pDetail, resCb)),
  };
}

const HomeWithProps = connect(mapStateToProps, mapDispatchToProps)(SectionAdd);

export default HomeWithProps;
