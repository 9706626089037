import history from "../../shared/utils/history";

const basePath = "/api";
let token = "eyJhbGciOiJSUzI1NiIsImtpZCI6IjNkZDZjYTJhODFkYzJmZWE4YzM2NDI0MzFlN2UyOTZkMmQ3NWI0NDYiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJodHRwczovL2FjY291bnRzLmdvb2dsZS5jb20iLCJuYmYiOjE2NDYzODEzMDIsImF1ZCI6IjQ5OTkzODA3OTMwMC1jaHZzYmxtcGo4bTA4bTVwZWRjZ2U3aHI5bDVrYnFvbi5hcHBzLmdvb2dsZXVzZXJjb250ZW50LmNvbSIsInN1YiI6IjEwMzA5ODM3NTUxMTE4Mjc1NTYxOCIsImVtYWlsIjoicHJvbGFrZWluZGlhQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhenAiOiI0OTk5MzgwNzkzMDAtY2h2c2JsbXBqOG0wOG01cGVkY2dlN2hyOWw1a2Jxb24uYXBwcy5nb29nbGV1c2VyY29udGVudC5jb20iLCJuYW1lIjoiUHJvbGFrZSBUZWNobm9sb2dpZXMiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKemFBSDRCSUtyN0hMOTVsb1dTTEJodFd1SVBvbVRLQXN1emhHQXo9czk2LWMiLCJnaXZlbl9uYW1lIjoiUHJvbGFrZSIsImZhbWlseV9uYW1lIjoiVGVjaG5vbG9naWVzIiwiaWF0IjoxNjQ2MzgxNjAyLCJleHAiOjE2NDYzODUyMDIsImp0aSI6IjMxNmY1YWM0MjliZjk5MDIwNmU2ZTRjZGU5OGY4NDk5MWVlZGM4ZDkifQ.spaJwibajMnO4NN9dkK3tEAniXfKVwTKdw7LEN7uCSGPEcMyq-tC24bHesYcZVCGHn2B1qSUmcHjF_zr-Fy8sgaivkkTmEg5gK3fDccbcFCXo_LvVMM18_i8HCNssLycYNnQDlgWwxkGCwPKB0rRUP4fFGdqOy3jzpz4rQ1SG3HaAoYPQos0J5LafvxaZhOGMmBroYoZR3bcDOx78Z5kfKNZ0QmRqqKjanUt7-ZUIDeXzKMKq6DKHRlbFZ1pub-nN1ncYFsqao7Il7xbcC-qx3f3tXb2HytYC2xW-naVwwKNI7hXYW1bWsPEPkSK4wumNXvHyIeK4gEacURTKvdplA";

function login(
  code, resCb
  ) {
    // code = props.match.params.code;
    // code=(code.split("code")[1]);
    console.log("login from session : ", code)
    //  if (!code){
    //   //  console.log("getcode is null");
    //  code="null";
    //    console.log("getcode value null is set", code);
    //  }
    // };
    let heraderValue
    if(!code){
      heraderValue = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*' }
    }
    else{
      heraderValue = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin':'*', 'signin-token':code }
    }
  const requestOptions = {

      method: 'Get',
      headers: heraderValue     
    //  headers:{'signin-token': code}
     
      // Param:
      // 'sign-in-token': token 
      // body: JSON.stringify(userData)
  };
  const url = basePath + `/auth/login`;
  return fetch(url, requestOptions)
  .then(handleResponse)
  .then(data => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //localStorage.setItem('user', JSON.stringify(data));
//function to send data to 4200 port
    // window.location.href = "http://localhost:3000/patient";
   
console.log("reap data value : ", data);
      //  let loadPage = helperServices.getStartUpPage(user.serverResponse.authData.role)
        // window.location.href = props.history.push(loadPage);

        // if (data.role) {
        //   // const roles = role.split(",");
        //   let roles = data.role;
        //   console.log("role value : ", roles)
        //   if (roles && roles.length > 0) {
        //     if (roles.indexOf("Admin") !== -1) {
        //       // props.history.push("/Admin");
        //       let  loadPage = "http://localhost:3000/Admin";
        //       window.location.href = "http://madmin.prolake.in/admin";
        //       // window.location.href = props.history.push(loadPage);
        //       // props.history.push("/Bed");
        //     } else if (roles.indexOf("Doctor") !== -1) {
        //       // props.history.push("/patient");
        //       let  loadPage = "http://madmin.prolake.in/patient";
        //       window.location.href = "http://madmin.prolake.in/patient";
        //       // window.location.href = props.history.push(loadPage);
        //       //props.history.push("/Dashboard");
        //     } else if (roles.indexOf("serviceDesk") !== -1) {
        //       // props.history.push("/patient");
        //       let  loadPage = "http://madmin.prolake.in/patient";
        //       window.location.href = "http://madmin.prolake.in/dashboard";
        //       // window.location.href = props.history.push(loadPage);
        //       //props.history.push("/Home");
        //     } else if (roles.indexOf("Nurse") !== -1) {
        //       // props.history.push("/patient");
        //       let  loadPage = "http://localhost:3000/ListDoctor";
        //       window.location.href = "http://madmin.prolake.in/patient";
        //       // window.location.href = props.history.push(loadPage);
        //       //props.history.push("/Home");
        //     }
        //   }
        // }

    //props.history.push("/Home");
      return data;
  },
  error => {
      console.log(error);
      // if(resCb=="UNAUTHORIZED"){
          //  window.location.href = "http://testaccounts.prolake.in/accountslogin";
            // window.location.href = "http://91.203.132.60:7081/accountslogin";
            // window.location.href = "http://91.203.132.60:8085/jyvjy/";
          //  window.location.href = "http://jyvjy.prolake.in/accountslogin";
           console.log("The Login is ")
           console.log("The Login is ")
           window.location.href=process.env.REACT_APP_ACCOUNTS_URL
            // if(process.env.REACT_APP_ENV ==="dev"){
            //   console.log("The Login is ")
            //   window.location.href="http://jyvjy.prolake.in/accountslogin";
            //   // window.location.href="http://localhost:3001/accountslogin";
      
            // }else if(process.env.REACT_APP_ENV ==="prod") {
            //   console.log("The Login is  another")
            //   // window.location.href="http://jyvjy.prolake.in/accountslogin";
            //   window.location.href="http://accounts/accountslogin";
            // }
            // window.location.href="http://accounts/accountslogin";
	// window.location.href="http://localhost:3001/accountslogin";
        // }
      // if(error=="UNAUTHORIZED"){
     // window.location.href = "http://localhost:3001/accountslogin";
      // }
      //return error;
  }
      );
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

const loginServices = {
  login,
  logout
};
export default loginServices;
