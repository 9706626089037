import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { bedActions } from "../../core/actions";
import { helperServices } from "../../shared/helper";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Button,
  Alert
} from "shards-react";

function BedForm(props) {
  const[disableSubmit, setDisableSubmit] = useState(false)
  const[bedDetail, setBedDetail] = useState({basicinfo: {
    id: "",
    code: "",
    type: "",
    rate: "",
    effectiveFrom: "",
  }})
  const[bedDetailFinal, setBedDetailFinal] = useState({basicinfo: {
    id: "",
    code: "",
    type: "",
    rate: "",
    effectiveFrom: "",
    effectiveFromDates: {
      date: "",
      ratenew: "",
    },
  }})
  const[formErrors, setFormErrors] = useState({
    rate: "",
    effFromDate:''
  })
  const[submitted, setSubmitted] = useState('')
  const[effFromDate, setEffFromDate] = useState('')
  const[existingRate, setExistingRate] = useState('')
  const[existingDate, setExistingDate] = useState('')
  const[ServerRes, setServerRes] = useState({success : '', error : ''})
  const dispatch = useDispatch();

  useEffect(() => {
    setDisableSubmit(true)
    console.log("Bed detail obtained in form : ",props.bedDetailList)
    setBedDetail(props.bedDetailList)
    populateEffectiveFromDate();
  },[])

  const populateEffectiveFromDate = () => {
    console.log("Modified effective from : ", props.bedDetailList);
    var effFrom = helperServices.getDateFromTimestamp(
      props.bedDetailList.basicinfo.effectiveFrom
    );
    console.log("Modified effective from : ", effFrom);
    setEffFromDate(effFrom);
    
    setExistingRate(props.bedDetailList.basicinfo.rate);
    setExistingDate(helperServices.getDateFromTimestamp(
        props.bedDetailList.basicinfo.effectiveFrom
      ));
  }

  const handleInputChange = (name, value) => {
    if (name !== "effectiveFrom") {
      var pDetail = bedDetail;
      pDetail["basicinfo"][name] = value;
      setBedDetail({...pDetail});
    } else {
      setEffFromDate(value);
    }

    if (name === "effectiveFrom") {
      if (value !== existingDate) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }

    if (name === "rate") {
      if (value !== existingRate) {
        setDisableSubmit(false);
      } else {
        setDisableSubmit(true);
      }
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    let fieldValidationErrors = formErrors;
    console.log("Final effective from : ",effFromDate,existingRate);

    if(effFromDate <= helperServices.getDateFromTimestamp(new Date())){
      fieldValidationErrors.effFromDate = 'Effective date cannot be less than current date'
    }
    else{
      fieldValidationErrors.effFromDate = ''
    }

    // check if the changed rate and date are different from previous values, if yes, 
    // then form effectiveFromdates object and set it to basicinfo
    var existingRate = existingRate;
    var existingDate = existingDate;
    var changedDate = effFromDate;
    var changedRate = bedDetail.basicinfo.rate;

    console.log("existingRate",existingRate,"existingDate",existingDate,
      "changedDate",changedDate,"changedRate",changedRate)
    
    var bdDetail = bedDetailFinal;
    bdDetail["basicinfo"]["id"] = bedDetail.basicinfo.id;
    bdDetail["basicinfo"]["code"] = bedDetail.basicinfo.code;
    bdDetail["basicinfo"]["type"] = bedDetail.basicinfo.type;

    // When effectivefrom not changed only rate changed
    if (changedRate !== existingRate) {
      bdDetail["basicinfo"].effectiveFromDates.ratenew = bedDetail.basicinfo.rate;
      bdDetail["basicinfo"]["rate"] = bedDetail.basicinfo.rate;
      bdDetail["basicinfo"]["effectiveFrom"] = existingDate;
      bdDetail["basicinfo"]["effectiveFromDates"]["date"] = existingDate;
      setBedDetailFinal(bdDetail);
    }
    
    // When rate not changed only effectivefrom changed
    if (changedDate !== existingDate) {
      bdDetail["basicinfo"]["effectiveFromDates"]["date"] = effFromDate;
      bdDetail["basicinfo"]["effectiveFrom"] = effFromDate;
      bdDetail["basicinfo"].effectiveFromDates.ratenew = existingRate;
      bdDetail["basicinfo"]["rate"] = existingRate;
      setBedDetailFinal(bdDetail);
    }

    //when both rate and effectivefrom changed
    if (changedRate !== existingRate && changedDate !== existingDate){
      bdDetail["basicinfo"].effectiveFromDates.ratenew = bedDetail.basicinfo.rate;
      bdDetail["basicinfo"]["rate"] = bedDetail.basicinfo.rate;
      bdDetail["basicinfo"]["effectiveFromDates"]["date"] = effFromDate;
      bdDetail["basicinfo"]["effectiveFrom"] = effFromDate;
      setBedDetailFinal(bdDetail);
    }
    
    setBedDetailFinal(bdDetail);

    console.log("bedDetailFinal : ", bedDetailFinal);

    var ServerResVal = ServerRes;
    dispatch(bedActions.updateBed(bedDetailFinal, resCb => {
      if (resCb) {
        console.log("Update Bed - resCb", resCb,resCb.state);
        if(resCb.state === 'success'){
          ServerResVal.success = resCb.message
        }
        else if(resCb.state === 'error'){
          ServerResVal.error = resCb.messages.message
        }
        setServerRes({...ServerResVal});
      }
    }));
  
  }

  const closeModal = (event) => {
    props.onModalClose({ close: true });
  }

    return (
      <div className="modalmn">
        <div className="mb-4 col-sm-12 col-md-6 modal-main">
          <div className="blog-comments card pcard">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col><h5>Edit Bed Details</h5></Col>
                </Row>
                <Row>
                  <Col>
                    <strong className="text-muted d-block mb-2">
                      Bed : {bedDetail.basicinfo.type}[
                      {bedDetail.basicinfo.code}]
                    </strong>
                    <Form>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="fname">Code</label>
                          <FormInput
                            id="code"
                            value={bedDetail.basicinfo.code}
                            placeholder="Code"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange(
                                "code",
                                event.target.value
                              );
                            }}
                            disabled="true"
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="rate">Rate</label>
                          <FormInput
                            id="rate"
                            value={bedDetail.basicinfo.rate}
                            placeholder="Rate"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange(
                                "rate",
                                event.target.value
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="ref">Rate Effective From</label>
                          <FormInput
                            id="ref"
                            type="date"
                            value={effFromDate}
                            placeholder="Rate Effective From"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange(
                                "effectiveFrom",
                                event.target.value
                              );
                            }}
                            invalid={formErrors.effFromDate === "" ? false :true }
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.effFromDate}</span>
                          </div>
                        </Col>
                      </Row>

                      <div className="btns-wrap">
                          <Button
                            type="submit"
                            onClick={(event) => closeModal(event)}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            disabled={disableSubmit}
                            onClick={(event) => handleSubmit(event)}
                          >
                            Save
                          </Button>
                      </div>
                      <br/>
                      {ServerRes.success &&
                        <Alert theme="success">{ServerRes.success}</Alert>
                      }
                      {ServerRes.error &&
                        <Alert theme="danger">{ServerRes.error}</Alert>
                      }
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </div>
    )
}

/* function mapStateToProps(state) {
  return {
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updateBed: (bedData, resCb) => dispatch(bedActions.updateBed(bedData, resCb)),
  };
}

const BedFormWithProps = connect(mapStateToProps, mapDispatchToProps)(BedForm); */

export default BedForm;
