import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Container, Navbar } from "shards-react";

import NavbarSearch from "./NavbarSearch";
import NavbarNav from "./NavbarNav/NavbarNav";
import NavbarToggle from "./NavbarToggle";

const MainNavbar = ({ layout, stickyTop }) => {
  const classes = classNames(
    "main-navbar",
    "bg-white",
    stickyTop && "sticky-top"
  );
  const toggleSidebar=()=> {
  //  document.body.classList.toggle("sidebar-hidden");
   document.getElementById('sidebar').classList.toggle('sidebar-hidden')
   document.getElementById('mainpage').classList.toggle('sidebar-hidden')
  }

  return (
    <div className={classes}>
      <Container fluid className="pl-3 d-flex justify-content-between align-items-center">
      <span class='material-icons menu-icon' onClick={toggleSidebar}>menu</span>

        <Navbar type="light" className="align-items-stretch flex-md-nowrap p-0 jce">
          {/* <NavbarSearch /> */}
          <NavbarNav />
          <NavbarToggle />
        </Navbar>
      </Container>
    </div>
  );
};

MainNavbar.propTypes = {
  /**
   * The layout type where the MainNavbar is used.
   */
  layout: PropTypes.string,
  /**
   * Whether the main navbar is sticky to the top, or not.
   */
  stickyTop: PropTypes.bool
};

MainNavbar.defaultProps = {
  stickyTop: true
};

export default MainNavbar;
