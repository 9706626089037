import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form,Row,Col, FormTextarea,Button,Alert,Card,CardBody} from "shards-react";
import { patientActions } from "../../core/actions";
import { connect } from "react-redux";

function References(props) {
    const [referenceText, setReferenceText] = useState('');
    const [ServerRes, setServerRes] = useState(
    {success:'',error:'',}
    );
    const dispatch = useDispatch();

    const patient = useSelector(state => state.patientDetailsState.patient)

    const patientProceduresList = useSelector(state => state.doctorDetailsState.patientprocedurelist)
    console.log("patientProceduresList in  complaints :", patientProceduresList);
    let patientDetail;
    let procedureList;
    var refernceList = '';
    const [viewReference, setviewReference] = useState(false);

    useEffect(() => {
        //  = () => {
            console.log("patientProceduresList.procedureslist :",patientProceduresList.procedureslist);
            if(patientProceduresList && patientProceduresList.procedureslist ){
                procedureList = patientProceduresList.procedureslist
                console.log("copy procedurelist :" ,procedureList);
            }
            // console.log("procedures complaint props 11:", patientProceduresList)
              let referenceList = '';
              console.log(" :", procedureList);
              if(procedureList){
              for(let proc of procedureList){
                console.log("procedures complaint 45:", proc);
                  if (proc.className=="reference") {
                      console.log("procedures complaint 55:", proc.complaint);
                      if(patientProceduresList.visit.id && patientProceduresList.visit.id == proc.visitId){
                          console.log("procedures 1: ", patientProceduresList.visit.id, "procedures complaint 1: ",proc.visitId,"visit id in patient and procedure :",proc.class," complaint in loop proc : ",proc.complaint);
                          // props.patientInfo.
                          referenceList = proc.ref;
                          
                        }
                    }
                    }
              }
        setReferenceText(referenceList)
    console.log("complaintlist : ",referenceText);
    },[])

    const handleChange = (e) => {
        setReferenceText(e.target.value)
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        var ServerResVal = ServerRes;
        console.log("Patient",patient)
        let tempArray = {
            claSS : "reference",
            references   : referenceText,
            patientId : patient.id
            // visitId : patient.visit.id
        }
        console.log("complaint on submit : ",tempArray);
        
        dispatch(patientActions.addReference(tempArray,resCb => {
            if (resCb) {
                console.log("ResCb-File", resCb.state, resCb.message);
                if (resCb.state === "success") {
                  ServerResVal.success = resCb.message;
                } else if (resCb.state === "error") {
                  ServerResVal.error = resCb.messages.message;
                }
                setServerRes({...ServerResVal});
            }
        }))
    }

    const toggleReference = () => {
        setviewReference(!viewReference);
      };
    
        return (
            <main className="">
            <h5 className="">References
            <span className="ml-3" onClick={toggleReference} style={{cursor:'pointer'}} ><i className="material-icons" title={viewReference ? 'Edit' : 'View'}>{viewReference ? 'edit' : 'visibility'}</i></span>
            </h5>
            {(viewReference && referenceText) &&
            <div>
                <Card className="border">
                    <CardBody className="p-3">
                        {referenceText}
                    </CardBody>
                </Card>
            </div>
            }
            {!viewReference && 
            <Form className="mb-2 card px-4 py-3">
                <Row form>
                    <Col className="mb-2">
                        <label htmlFor="references">References</label>
                        <FormTextarea onChange={handleChange} value={referenceText}/>
                    </Col>
                </Row>
                
                <div className="fbtn mb-2">
                     <Button type="submit" onClick={handleSubmit}>Save</Button>  
                </div>
                {ServerRes.success && (
                  <Alert theme="success">{ServerRes.success}</Alert>
                )}
                {ServerRes.error && (
                  <Alert theme="danger">{ServerRes.error}</Alert>
                )}
            </Form>
            }
            </main>
        )
    
}
/* function mapStateToProps(state) {
    return {
      patient: state.patientDetailsState.patient,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        addReference: (data, resCb) => dispatch(patientActions.addReference(data, resCb)),
    };
}
  
const ReferencesWithProps = connect(
    mapStateToProps,
    mapDispatchToProps
)(References);
   */
export default References;
