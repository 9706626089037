import { generateJsonSchema } from "@jsonforms/core"
import healtheduschema from './healthedu.json';
import {useDispatch, useSelector} from "react-redux";
import { ehrActions } from '../../core/actions';
import {getschemafromBloodSugarProfile} from "./BloodSugarProfile.js";

import { VITAL_SIGNS,EPRESCRIPTION,DIAGNOSIS,KESSLERTEST,LAB_RESULT, HEMATOLOGYREPORTCBCDLC,BLOODGLUCOSEPROFILE, HEMATOLOGYREPORTESR, HEMATOLOGYREPORTAWC} from "../constants";
import { result, template } from "lodash";
import { contextType } from "react-datetime";
import LabReportsSchema from "./LabReportsSchema";
import { format } from "date-fns";
//import { result, template } from "lodash";
//import { contextType } from "react-datetime";
//import LabReportsSchema from "/LabReportsSchema";

function getElementName(children){

  for(let child of children){
    return child.name
    
    //elementName = getElementName(tree.children)
  }
}

function camelize(str) {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}



function getSchemaFromJson(schema){
    console.log('schema : ',schema)
    let jsonSchema = {}
    let elementName
    var obj1 ;
    var obj2;
    var sampleobj2,sampleobj1;
    jsonSchema['properties'] = {}
    //let observationSection = getEvent(schema)
    //getSchema(observationSection)
    for(let tree of schema.tree.children){
      if(tree.children){
        for(let child of tree.children){
          elementName = child.name
          jsonSchema['properties'][elementName] = {}
          jsonSchema['properties'][elementName]['type'] = "object"
          jsonSchema['title'] = elementName
          jsonSchema['type'] = 'object'
          console.log('child :',child)
          if(child.inputs){
           /*  for(let ele of child.inputs){
              jsonSchema['properties'][elementName] = {}
              let type = getElementType(ele)
              let format = getFormat(ele)
              jsonSchema['properties'][elementName]['type'] = type
              if (format != ''){
                jsonSchema['properties'][elementName]['format'] = format
              }
            } */
          }    
          else{
            if(child.children){
              for(let observation of child.children){
                console.log('.......eveobservationnt......', observation)
                if(observation.children){
                  jsonSchema['properties'][elementName]['properties'] = {}
                  for(let event of observation.children){
                    console.log('.......event......', event)
                    console.log('.......event......', event.name)
                    jsonSchema['properties'][elementName]['properties'][event.name]= {}
                    jsonSchema['properties'][elementName]['properties'][event.name]['properties'] = {}
                    if(event.name != 'Time'){
                      
                      let labelName = event.name
                      if(event.inputs){
                        console.log('labelName',labelName)                 
                        console.log('event.inputs ',event.inputs)

                        for(let inputs of event.inputs){
                         // let labelName = inputs.name
                         console.log('labelName ',inputs)
                         let suffix = inputs.suffix
                          if(inputs.list){
                            console.log('input list : ',inputs.list)
                            
                            for(let list of inputs.list){
                               let typeName = getElementType(inputs)
                               console.log('type ',typeName)
                              
                            obj1 = {
                                [suffix]: {enum:[list.value],enumNames:[list.label]},
                              };}
                              sampleobj1 = {
                                Diastolic: {type: "string", title: "sampl1..", default: "A new task"},
                             };
                          }

                          else{
                            console.log('only input ')
                            let typeName = getElementType(inputs)
                            console.log('type ',typeName)
                            let format = getFormat(inputs)
                           obj2 = {
                              [suffix]: {type: typeName, title: labelName},
                            };
                            sampleobj2 = {
                              Sistolic: {type: "string", title: "sampl2...", default: "A new task"},
                            };
                            if (format != ''){
                              obj2 = {
                                 [suffix]: {type: typeName, title: labelName,format:format},
                              };
                            }
                          }
                        }
                        console.log(' obj1 : ',obj1)
                        console.log(' objobj21' , obj2)
                        console.log(' objobj21 properties: ',jsonSchema['properties'][elementName]['properties'])
                        let result = Object.assign(jsonSchema['properties'][elementName]['properties'][event.name]['properties'],obj1,obj2);
                        console.log(' finl : ',result,jsonSchema['properties'][elementName]['properties'])
                      }

                    }
                  }
                }
                if( observation.inputs){
                  for(let eleinput of observation.inputs){
                      let format = getFormat(eleinput)
                      if (format != ''){
                      }
                  }
                }
              }
            }
          }  
        }
    }    
    }
    return jsonSchema
    // return schema
}

// schema.archetype_details.template_id.value
function getSchemaFromCanonicalJson(schema,templateId,flags,compositionPreviewValue,icd10){
  let data;
  // let compositionPreview= {
  //   brandName: ""
  // };
  // icd10Name.icdName="hi how are you ";
  if(templateId === KESSLERTEST){
    console.log("templateId:",templateId);
    data = getSchemaFromKesslersJson(templateId,schema,flags);
  }else if(templateId === EPRESCRIPTION){
    console.log("templateId:",templateId,flags);
    console.log("products is:", icd10);
    data = getSchemaFromPrescJson(templateId,schema,flags,icd10,compositionPreviewValue);
  }else if(templateId === DIAGNOSIS){
    console.log("templateId:",templateId);
    console.log("icd10",icd10);
    data = getSchemaFromDiagnoJson(templateId,schema,flags,icd10,compositionPreviewValue);
  }else if(templateId === VITAL_SIGNS){
    console.log("templateId:",templateId);
    data = getSchemaFromVitalsJson(templateId,schema,flags);
      console.log("data:" , data);
    // data = getSchemaFromTestJson(schema,flags);
  }else if(templateId === "Test.v1"){
    console.log("templateId:",templateId);
    data = getSchemaFromTestJson(templateId,schema,flags);
  }else if(templateId === LAB_RESULT){
    console.log("templateId:",templateId);
    data = getSchemaFromLabResultJson(templateId,schema,flags);
  }else if(templateId === "k_problem"){
    console.log("templateId:",templateId);
    data = getSchemaFromK_problemJson(templateId,schema,flags);
  }else if(templateId === BLOODGLUCOSEPROFILE){
    console.log("templateId:",templateId);
    data = getschemafromBloodSugarProfile(templateId,schema,flags);
  }
  else if(templateId === HEMATOLOGYREPORTCBCDLC || templateId === HEMATOLOGYREPORTESR || templateId === HEMATOLOGYREPORTAWC){
    console.log("templateid: ", templateId);
    data = LabReportsSchema.getschemafromLabReportsDefault(templateId,schema,flags);
  }

  return data
}

function getSchemaFromKesslersJson(templateId,schema,flags){
  console.log('schema : ',schema);
    let jsonSchema = {}
    let elementName
    let elementType
    var obj1 ;
    var obj2;
    var sampleobj2,sampleobj1;
    jsonSchema['properties'] = {}
    jsonSchema['type'] = 'object';
    for(let content of schema.content){
      console.log('content : ',content.data);
      console.log('content.data.events : ',content.data.events);
      for(let events of content.data.events){
        console.log('events :',events);
        // let que1 = "During the last 30 days, about how often did you feel tired out for no good reason?";
        // let que2 = "During the last 30 days, about how often did you feel nervous?";
        // let que3 = "During the last 30 days, about how often did you feel so nervous that nothing could calm you down?";
        // let que4 = "During the last 30 days, about how often did you feel hopeless?";
        // let que5 = "During the last 30 days, about how often did you feel restless or fidgety?";
        // let que6 = "During the last 30 days, about how often did you feel so restless you could not sit still?";
        // let que7 = "During the last 30 days, about how often did you feel depressed?";
        // let que8 = "During the last 30 days, about how often did you feel that everything was an effort?";
        // let que9 = "During the last 30 days, about how often did you feel so sad that nothing could cheer you up?";
        // let que10 = "During the last 30 days, about how often did you feel worthless?";
        // let que = [que1,que2,que3,que4,que5,que6,que7,que8,que9,que10]
        for(let items of events.data.items){
          console.log("items :",items);
          console.log('name : ',items.name.value);
          // console.log('symbol : ',items.value.symbol.value);
          console.log("value :",items.value.value);
          elementName = items.name.value
          jsonSchema['properties'][elementName] = {}
          // jsonSchema['properties'][que1,que2,que3,que4,que5,que6,que7,que8,que9,que10] = {}
         // jsonSchema['properties'][elementName]['type'] = "object"
          // jsonSchema['title'] = elementName
         // jsonSchema['type'] = 'object'
          //jsonSchema['properties'][elementName]['properties'] = {}
          elementType = "DV_ORDINAL";
          // jsonSchema['properties'][elementName]['properties'][elementType]= {}
          let suffix = elementType
          let numValue = [1,2,3,4,5];
          let symValue = ["None of the time","A little of the time","Some of the time","Most of the time","All of the time"];
          let num1 = 1;
          let num2 = 2;
          let num3 = 3;
          let num4 = 4;
          let num5 = 5;
          let sym1 = "None of the time";
          let sym2 = "A little of the time";
          let sym3 = "Some of the time";
          let sym4 = "Most of the time";
          let sym5 = "All of the time";
         obj1 = {
          type: "string",
           enum:[num1+'-'+sym1,num2+'-'+sym2,num3+'-'+sym3,num4+'-'+sym4,num5+'-'+sym5],enumNames:[num1+'-'+sym1,num2+'-'+sym2,num3+'-'+sym3,num4+'-'+sym4,num5+'-'+sym5]
          };

          let result = Object.assign(jsonSchema['properties'][elementName],obj1);
         }
      }
    }
    return jsonSchema
}


function getSchemaFromPrescJson(templateId,schema,flags,products,compositionPreviewValue){
  console.log("products in getschema presc", products);
  console.log("schema content", schema.content);
  // const Ajv = require('ajv');
  // const ajv = new Ajv();

  let jsonSchema = {
    // "type": "object",
    // "properties": {
    //   "Instructions to patient": {
    //     "type": "string",
    //     "title": "Instructions to patient"
    //   },
    //   "Advice to dispenser": {
    //     "type": "string",
    //     "title": "Advice to dispenser"
    //   },
    //   "Comment": {
    //     "type": "string",
    //     "title": "Comment"
    //   }
    // },
     "required": [ "Dose unit", "Advice to dispenser", "Comment"]    
  } 
  console.log("JsonSchema: ", jsonSchema);
  jsonSchema['properties'] = {}
  console.log("propertiespresc",jsonSchema['properties']);

  jsonSchema['type'] = 'object'
  console.log("typepresc",jsonSchema['type']);
	for(let content of schema.content){
		if(content._type == "INSTRUCTION"){
			getschemafromInstruction(templateId,jsonSchema,content,flags,products,compositionPreviewValue);
		}
	}
  console.log("jsonSchema1",jsonSchema);
  return jsonSchema
}


function getSchemaFromK_problemJson(templateId,schema,flags){
  let jsonSchema = {}
  jsonSchema['properties'] = {}
  jsonSchema['type'] = 'object'

	for(let content of schema.content){
		if(content._type == "OBSERVATION"){
			getschemafromObservation(templateId,jsonSchema,content,flags);
		}
	}
   return jsonSchema
}

 function getschemafromInstruction(templateId,jsonSchema,content,flags,products,compositionPreviewValue){
  for(let events of content.activities){
    for(let items of events.description.items){
      if(items._type == "CLUSTER"){
        getschemafromCluster(templateId,jsonSchema,items,flags);
      }
      if(items._type == "ELEMENT"){
        getschemafromElement(templateId,jsonSchema,items,flags,products,compositionPreviewValue);
      }
    }
  }
  return jsonSchema
}


function getschemafromElement(templateId,jsonSchema,items,flags,products,compositionPreviewValue){
  console.log("items :",items);
  let elementName1
  let elementType
  var obj1 ;
  var obj2 ;
  let Obj=[]
  let ele1;
	elementName1 = items.name.value
  // console.log("karthik",items.name.value)
  jsonSchema['properties'][elementName1] = {}

	
  elementType = "Enter";
  console.log("entry",elementType)
  let suffix = elementType;
  console.log("flags: ", flags)


  if(flags=="editComposition"){
      console.log("edit composition")
        if(templateId === VITAL_SIGNS){
          if(items.archetype_node_id == "at0006"){
            console.log(items.archetype_node_id)
            let percent = "%"
            obj1 = {type: "string", default:items.value.numerator + " " + percent}
            console.log(obj1)
          }
          else{
            obj1 = {type:"string", default: items.value.magnitude+ " " +items.value.units}  
          }
        
      }else{
        if(items.name.value == "Pattern sequence"){
          obj1 = 
            {type:"string",textarea:[items.value.value], default: items.value.magnitude}
        }else if(items.name.value == "Local medicinal product brand"){
          if(compositionPreviewValue){
          compositionPreviewValue.brandName= items.value.value;
         // console.log("productBrand.brandName",compositionPreviewValue.brandName);
          }
        }
        else{
          obj1 = 
            {type:"string",textarea:[items.value.value], default: items.value.value}
        }
      }
	}else {
      console.log("entered else getschemafromelement");
    let productField = ['Local medicinal product brand'];
                let productsList=[]
                let productname= products;
      console.log("typing value:",items.name.value);
      
	  obj1 = 
		{type:"string"}
    if(productField.includes(items.name.value)){
            // for(let i of products){
                          
            //   //  productsList.push(i.productName);
            //               //icd.push(i.description)
            //  }
             productsList.push(products);
             
              console.log("icd after push",productname);
              obj1 = {enum:productsList,enumNames:productsList}  
        }

	}
  
  // jsonSchema['properties']["newproductname"]={}
    console.log("obj1: ", obj1)
	let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
  
  // if(jsonSchema['properties'][elementName1] == "Local medicinal product brand")
  // {
  
  // }
  // let result2 = Object.assign(jsonSchema['properties']["newproductname"],obj1);
	console.log("jsonSchema4 :",jsonSchema);
  
  console.log("result :",result);
  // console.log("result2",result2);
  return jsonSchema
}


function getschemafromCluster(templateId,jsonSchema,items,flags){
  console.log("items :",items);
  let elementName1
  let elementType
  var obj1 ;
  var obj2 ;
  let Obj=[]
  let ele1;

  for(let item of items.items){
    console.log("item of items.item: ", item)
    if(item.items){
      
    for(let it of item.items){
      console.log("it",it);
      getschemafromElement(templateId,jsonSchema,it,flags);
    }
    }else{
      console.log("itrm",item);
      elementName1 = item.name.value
      jsonSchema['properties'][elementName1] = {}
      jsonSchema['properties'][elementName1]['type'] = "object"
      elementType = "Enter";
      let suffix = elementType;
      if(flags=="editComposition"){
        if(templateId === "Vital signs"){
          obj1 = {type:"string", default: item.value.magnitude+ " " +item.value.units}
        }else{
          if(item.name.value == "Strength of medicinal product"){
            obj1 = 
              {type:"string",textarea:[item.value.value], default: item.value.magnitude}
          }else{
            obj1 = 
              {type:"string",textarea:[item.value.value], default: item.value.value}
          }
        }
      }else {
        obj1 = 
        {type:"string",textarea:[item.value.value]}
      }
      let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
      console.log("jsonSchema3 :",jsonSchema);
      console.log("result :",result);
		} 
  }     
  return jsonSchema
}
// function getSchemaFromPrescJson(templateId,schema,flags,products){
  
//   console.log('schema in presc: ',schema);
//   let jsonSchema = {
//     "required": [
//       "Local medicinal product brand",
//     ],
//   }
//     let elementName = schema.name.value;
//     let elementName1
//     let elementType
//     var obj1 ;
//     var obj2 ;
//     let Obj=[]
//     let ele1;
//     jsonSchema['properties'] = {}
//     jsonSchema['type'] = 'object'
//     for(let content of schema.content){
//       console.log('content : ',content);
//       for(let events of content.activities){
//         console.log('events :',events);
//         for(let items of events.description.items){
//           console.log("items1 :",items);
//           if(items._type == "CLUSTER"){
//             for(let item of items.items){
//               if(item.items){
//                 for(let it of item.items){
//                   console.log("it :",it);
//                   elementName1 = it.name.value
//                   console.log("elementName1 :",elementName1);
//                   jsonSchema['properties'][elementName1] = {}
//                   elementType = "Enter";
//                   let suffix = elementType;
//                   if(flags=="editComposition"){
//                     obj1 = 
//                       {type:"string",textarea:[it.value.value], default: it.value.value}
//                   }else {
//                     obj1 =
//                       {type:"string",textarea:[it.value.value]}
//                   }
//                   let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
//                   console.log("ele1 :",ele1);
//                   console.log("jsonSchema1 :",jsonSchema);
//                 }
//               }else{
//                 console.log("items :",item);
//                 console.log("items :",items);
//                 console.log('name : ',item.name.value);
//                 console.log("value :",item.value.value);
//               //  console.log("elementName :",elementName);
//                 elementName1 = item.name.value
//                 console.log("elementName1 :",elementName1);
//                 jsonSchema['properties'][elementName1] = {}
//                 elementType = "Enter";
//                 let suffix = elementType;
//                 if(flags=="editComposition"){
//                   obj1 = 
//                     {type:"string",textarea:[item.value.value], default: item.value.value}
//                 }else {
                  
//                   obj1 = 
//                    {type:"string",textarea:[item.value.value]}

                  
//                 }
//                 let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
//                 }
//               console.log("jsonSchema3 :",jsonSchema);
//             }
//           }
//           if(items._type == "ELEMENT"){
//               elementName1 = items.name.value
//               console.log("elementName1 :",elementName1);
//               jsonSchema['properties'][elementName1] = {}
//             //  jsonSchema['properties'][elementName1]['type'] = "object"
//               elementType = "Enter";
//               let suffix = elementType;
//               if(flags=="editComposition"){
//                 obj1 = 
//                   {type:"string",textarea:[items.value.value], default: items.value.value}
//               }else {
//                 let datefields2 = ['Local medicinal product brand'];
//                 let productsList=[]

//                 obj1 = 
//                 {type:"string"}
//                 if(datefields2.includes(items.name.value)){
//                   for(let i of products){
                    
//                     productsList.push(i.productId+'-'+i.name);
//                     //icd.push(i.description)
//                   }
//                   console.log("icd after push",productsList);
//                   obj1 = {enum:productsList,enumNames:productsList}
//                   //obj1 = {enum:icd}
      
//                 }
//               }
//               let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
//               console.log("jsonSchema4 :",jsonSchema);
//           }
//         }
//       }
//     }
//     return jsonSchema
// }

/* For reference */
/* https://react-jsonschema-form.readthedocs.io/en/latest/api-reference/uiSchema/ */
/** https://rjsf-team.github.io/react-jsonschema-form/  **/

function getSchemaFromDiagnoJson(templateId,schema,flags,icd10,compositionPreviewValue){

  console.log("icd10 is",icd10);
  console.log('schema : ',schema);
    let jsonSchema = {
         "required":[
          "Clinical description","Comment","Course description","Cause"
         ]
    }
    let elementName = schema.name.value;
    let elementName1
    let elementType
    var obj1 ;
    var obj2 ;
    let Obj=[]
    let ele1;
    const time=new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })).toISOString();
    jsonSchema['properties'] = {}
    jsonSchema['type'] = 'object'
    for(let content of schema.content){
      console.log('content : ',content);
      for(let items of content.data.items){
        console.log('items : ',items);
        elementName1 = items.name.value
        console.log("elementName1 :",elementName1);
        jsonSchema['properties'][elementName1] = {}
        elementType = [items.name.value];
        let suffix = elementType;
        if(flags=="editComposition"){
          obj1 = {type:"string",textarea:[items.value.value], default: items.value.value}
          if(items.name.value == 'Problem/Diagnosis name' )
          {
            if (!compositionPreviewValue) {
              compositionPreviewValue = {}; // Initialize icd10Name if it doesn't exist
             }
             compositionPreviewValue.icdName=items.value.value;
            console.log("compositionPreviewValue.icdName",items.value.value);
          }
        }
        else 
        {
          let datefields1 = ['Date/time clinically recognised','Date/time of onset','Date/time of resolution'];
          let datefields2 = ['Problem/Diagnosis name'];

          let icd = []
          let icdName = icd10;

          obj1 = 
          {type:"string"}
          if(datefields2.includes(items.name.value)){
            // for(let i of icd){
            //   icd.push(i.code+'-'+i.description);
            //   //icd.push(i.description)
            //  }
            // console.log("icd after push",icd);
            icd.push(icd10);
            obj1 = {enum:icd,enumNames:icd}
            //obj1 = {enum:icd}

          }
          if(datefields1.includes(items.name.value)){
            obj1 = 
               {type:"string",format:"date-time", default:time} 
          }
        }
        console.log("object1 in log", obj1);
        let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
        console.log("jsonSchema4 :",jsonSchema);
      }
    }
  
  return jsonSchema
}


function getSchemaFromVitalsJson(templateId,schema,flags){
  console.log('schema : ',schema);
  console.log(flags)
  let jsonSchema = {
  "required":[
    "Temperature","Pulse Rate","Systolic","Diastolic","Weight","Height/Length","Oxygen Saturation Level (SpO₂)","Respiratory Rate"
   ]}
  jsonSchema['properties'] = {}
  jsonSchema['type'] = 'object'

  for(let content of schema.content){
    if(content._type == "OBSERVATION"){
        console.log("jsonSchema", jsonSchema)
      getschemafromObservation(templateId,jsonSchema,content,flags);
    }
  }
  return jsonSchema
}


function getSchemaFromLabResultJson(templateId,schema,flags){
  console.log('schema : ',schema);
  let jsonSchema = {}
  jsonSchema['properties'] = {}
  jsonSchema['type'] = 'object'
  for(let content of schema.content){
    if(content._type == "OBSERVATION"){
      getschemafromObservation(templateId,jsonSchema,content,flags);
    }
  }
  return jsonSchema
}


function getSchemaFromFastingSugarTest(templateId,schema,flags){
  console.log('schema : ',schema);
  let jsonSchema = {}
  jsonSchema['properties'] = {}
  jsonSchema['type'] = 'object'
  for(let content of schema.content){
    if(content._type == "OBSERVATION"){
      console.log("enteredobservation")
      getschemafromObservation(templateId,jsonSchema,content,flags);
      
    }
  }
  return jsonSchema
} 


function getschemafromObservation(templateId,jsonSchema,content,flags){
  if(templateId === "LabResult" ){
    for(let items of content.protocol.items){
        if(items._type == "CLUSTER"){
          getschemafromCluster(templateId,jsonSchema,items,flags);
        }
        if(items._type == "ELEMENT"){
            getschemafromElement(templateId,jsonSchema,items,flags);
        }
    }
  }else{
    for(let event of content.data.events){
      for(let items of event.data.items){
          console.log("element: ", items)
        if(items._type == "CLUSTER"){
          getschemafromCluster(templateId,jsonSchema,items,flags);
        }
        if(items._type == "ELEMENT"){
            getschemafromElement(templateId,jsonSchema,items,flags);
        }
      }
    }
  }
  return jsonSchema
}


// function getSchemaFromVitalsJson(schema,flags){
//   console.log('schema : ',schema);
//   let jsonSchema = {}
//   let elementName = schema.name.value;
//   let elementName1
//   let elementType
//   var obj1 ;
//   var obj2 ;
//   let Obj=[]
//   let ele1;
//   jsonSchema['properties'] = {}
//   jsonSchema['type'] = 'object'
//   for(let items of schema.content){
//       console.log('items : ',items);
//       elementName1 = items.name.value
//       console.log("elementName1 :",elementName1);
//       jsonSchema['properties'][elementName1] = {}
//       elementType = "Enter";
//       let suffix = elementType;
//       if(flags=="editComposition"){
//         for(let item of items.data.events){
//           for(let it of item.data.items){
//             obj1 = 
//             {type:"string", default: it.value.magnitude+ " " +it.value.units}
//           }
//         }
//       }else {
//         obj1 = {type:"string"}
//       }
//       let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
//       console.log("jsonSchema4 :",jsonSchema);
//   }
//   return jsonSchema
// }

function getSchemaFromTestJson(templateId,schema,flags){
  console.log('schema : ',schema);
  let jsonSchema = {}
  let elementName = schema.name.value;
  let elementName1
  var obj1 ;
  var obj2 ;
  let Obj=[]
  let ele1;
  jsonSchema['properties'] = {}
  jsonSchema['type'] = 'object'
  for(let content of schema.content){
    for(let event of content.data.events){
      for(let items of event.data.items){
        if(items._type == "CLUSTER"){
          for(let item of items.items){
            if(item.items){
              for(let it of item.items){
                console.log("it :",it);
                elementName1 = it.name.value

                console.log("elementName1 :",elementName1);
                jsonSchema['properties'][elementName1] = {}

               if(flags=="editComposition"){
                  obj1 = 
                    {type:"string",textarea:[it.value.value], default: it.value.value}
                 }

		else{
                  obj1 =
                    {type:"string",textarea:[it.value.value]}
                }

                let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
                console.log("ele1 :",ele1);
                console.log("jsonSchema1 :",jsonSchema);
              }
            }else{
              console.log("items :",item);
              console.log("items :",items);
              console.log('name : ',item.name.value);
              console.log("value :",item.value.value);
              console.log("elementName :",elementName);
              elementName1 = item.name.value

              console.log("elementName1 :",elementName1);
              jsonSchema['properties'][elementName1] = {}

              if(flags=="editComposition"){
                obj1 = 
                  {type:"string",textarea:[item.value.value], default: item.value.value}
              }

	      else {
                obj1 = 
                {type:"string",textarea:[item.value.value]}
              }

              let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
              }

            console.log("jsonSchema3 :",jsonSchema);
          }
        }

        if(items._type == "ELEMENT"){
            elementName1 = items.name.value
            console.log("elementName1 :",elementName1);
            jsonSchema['properties'][elementName1] = {}
            jsonSchema['properties'][elementName1]['type'] = "object"
            if(flags=="editComposition"){
              obj1 = 
                {type:"string",textarea:[items.value.value], default: items.value.value}
            }else {
              obj1 = 
              {type:"string"}
            }
            let result = Object.assign(jsonSchema['properties'][elementName1],obj1);
            console.log("jsonSchema4 :",jsonSchema);
        }
      }
    }
  }
  return jsonSchema
}

function getSchema(observationSection){
  console.log('child : ',observationSection)
  /* for(let observation of observationSection.children){    
    if(observation.children){
      console.log('subHeading',observation.name)
      let subHeading = observation.name
      for(let event of observation.children){
        if(event.children){
          for(let eventInputs of event.children){
            console.log('label',eventInputs.name)
            let label = eventInputs.name
          }
        }
      }
    }
  } */
}

function getEvent(schema){
  console.log('schema : ',schema)
  let jsonSchema = {}
  for(let tree of schema.tree.children){
    if(tree.children){
      console.log('tree.children : ',tree.children)
      for(let observation of tree.children){    
        if(observation.children){
          console.log('elementName',observation.name)
          
          for(let event of observation.children){
            if(event.children){
              for(let eventInputs of event.children){
                console.log('eventInputs',eventInputs)
                console.log('label',eventInputs.name)
                let label = eventInputs.name
                if(eventInputs.inputs){
                  //console.log('elementName...',elementName)
                  //jsonSchema['properties'][elementName] = {}
                  for(let inputField of eventInputs.inputs){
                    let elementName = observation.name
                    if(inputField.list){
                      for(let list of inputField.list){
                        let type = getElementType(inputField)
                        jsonSchema['properties'][elementName] = {}
                        console.log('.............',  jsonSchema['properties'][elementName]['type'] )
                        jsonSchema['properties'][elementName]['type'] = type
                        jsonSchema['properties'][elementName]['enum'] = [list.value]
                        jsonSchema['properties'][elementName]['enumNames'] = [list.label]
                      }
                    }
                    else{
                      //let property = {}
                      //property[label] = 
                      jsonSchema['properties'][elementName] = {}
                      console.log('elementName...',inputField)
                      let type = getElementType(inputField)
                      let format = getFormat(inputField)
                      jsonSchema['properties'][elementName]['type'] = type
                      if (format != ''){
                        jsonSchema['properties'][elementName]['format'] = format
                      }
                    }      
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  console.log('jsonSchema........',jsonSchema)
}

function getElementType(element){
  console.log('element : ',element.type)
  //eleProperties = {}
  if(element.type == 'DATETIME'){
    return 'string'
  }
  if(element.type == 'TEXT'){
    return 'string'
  }
  if(element.type == 'CODED_TEXT'){
    return 'integer'
  }
  if(element.type == 'DECIMAL'){
    return 'integer'
  }
  else{
    return 'string'
  }
}

function getFormat(element){
  //console.log('get format : ',element)
  if(element.format == 'date-time'){
    return 'date'
  }
  return ''
}



const jsonSchema = {
    getSchemaFromJson,
    getSchemaFromCanonicalJson,
    getSchemaFromKesslersJson,
    getSchemaFromPrescJson,
    getSchemaFromDiagnoJson,
    getSchemaFromLabResultJson,
    getSchemaFromK_problemJson
}

export default jsonSchema;


