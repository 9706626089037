import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  FormInput,
  Form,
  Card,
  CardBody,
  CardHeader,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormSelect,
  Alert
} from "shards-react";
import { diagnosisActions } from "../../core/actions";
import Components from './index';
import {helperServices} from '../../shared/helper';
import { connect } from "react-redux";
import PageTitle from "../common/PageTitle";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';

function DiagnosisMaster(props) {

  const [patientInfo, setPatientInfo] = useState({
    id:'',
    patientId: "",
    name: "",
    age: "",
    phno: "",
  });
    const [diadnosisMaster, setDiadnosisMaster] = useState([]);
    const [addNewDiagnosisModalOpenClose, setAddNewDiagnosisModalOpenClose] = useState(false);
    const [showDiagnosis, setShowDiagnosis] = useState(false);
    const [diagnosisSelected, setDiagnosisSelected] = useState(null);
    const [selectedDate, setSelectedDate] = useState('');
    const [newDiagnosis, setNewDiagnosis] = useState({
      id:'',
      code:'',
      name:'',
      udt:''
    });
    const [formErrors, setFormErrors] = useState({
      code:'',
      name:''
    });
    const [diagnosis, setDiagnosis] = useState({
      diagnosisId:'',
      patientId:'',
      visitId:'',
      diagnosisDate:'',
      udt:''
    }); 
    const [selectedPatentInfo,setSelectedPatentInfo] = useState();
    
    const [pagination,setPagination] = useState(true)
    const [paginationPageSize,setPaginationPageSize] = useState(10)
    const [ServerRes, setServerRes] = useState({success : '', error : ''});
    const dispatch = useDispatch();
    
    const dPatient = useSelector(state => state.diagnosisDetailsState.dPatient)

  useEffect(() => {
    console.log("In Diagnosis Master", dPatient);
    let patientState = patientInfo;
    if (dPatient && dPatient.basicinfo) {
        console.log("In Diagnosis Master", dPatient.basicinfo.fname);
      // if (this.props.dPatient.basicinfo.patientId === this.props.location.state.patientId) {
        patientState['id'] = dPatient.basicinfo.id
        patientState["patientId"] = dPatient.basicinfo.patientId;
        patientState["name"] = dPatient.basicinfo.fname + " " + dPatient.basicinfo.mname + " " +
        dPatient.basicinfo.lname;
        patientState["age"] = dPatient.basicinfo.age;
        patientState["phno"] = dPatient.basicinfo.phno;
        setPatientInfo(patientState);
      // }
    }
    //Callling diagnosis Master
    dispatch(diagnosisActions.getDiagnosisMaster(resCb=>{
      if(resCb){
        console.log("Diagnosis Master API Response:",resCb)
        if(diagnosisMasterDetail){
          let dmInfo = diagnosisMasterDetail;
          Object.keys(dmInfo).forEach((key)=>{
            if(!dmInfo[key]){
              dmInfo[key] = "";
            }
          })
          setDiadnosisMaster(dmInfo)
        }
      }
    }))
  },[])
  const diagnosisMasterDetail = useSelector(state => state.diagnosisDetailsState.diagnosisMasterDetail)

  const handleVisitedDateChange = (selectedDate) => {
    setShowDiagnosis(true);
    setSelectedDate(selectedDate);
    let patientVisitInfo = []
    console.log('dPatient.visitinfo',dPatient.visitinfo)
    for(let visitinfo of dPatient.visitinfo){
      console.log('visitinfo.',visitinfo)
      if(helperServices.getDate(visitinfo.visitDate) === selectedDate ){
        console.log('visitinfo.diagnosisList',visitinfo.diagnosisList)
        for(let diagno of visitinfo.diagnosisList){
          diagno['diagnodate'] = helperServices.getDate(diagno.date)
        }
        //patientVisitInfo.push(visitinfo.diagnosisList)helperServices.getDate(diagnosis.date)
        //visitinfo.diagnosisList.date =helperServices.getDate(diagnosis.date)
        console.log('visitinfo.diagnosisList',visitinfo.diagnosisList)
        setSelectedPatentInfo(visitinfo.diagnosisList);
      }
    }
    //console.log('patientVisitInfo',patientVisitInfo)
    
  }

  // For Adding New Diagnosis
  const handleNewDiagnosis = () => {
    setAddNewDiagnosisModalOpenClose(!addNewDiagnosisModalOpenClose);
  }

  const handleInputChange = (name, value) => {
    console.log("In handleinput change");
    var dDetail = newDiagnosis;
    dDetail[name] = value;
    setNewDiagnosis({...dDetail});
  }

  const handleSaveNewDiagnosis = (event) => {
    // Save the new diagnosis
    event.preventDefault()
    Object.keys(newDiagnosis).forEach((key)=>{
      console.log("key",key)
      console.log("key",newDiagnosis[key])
      validateField(key,newDiagnosis[key])
    })
    validateForm(event);
  }

  const validateField = (fieldName,value) => {
    let fieldValidationErrors = formErrors;
    let diagnosisInfo = newDiagnosis;
    console.log("Diagnosis Info", diagnosisInfo)
    console.log("FieldName",fieldName,"Value",value)
    switch(fieldName){
      case "code":
        fieldValidationErrors.code = value !== '' ? '' : 'Please Enter Diagnosis Code';
        diagnosisInfo.code = value !== '' ? value : '';
        break;
      case "name":
        fieldValidationErrors.name = value !== '' ? '' : 'Please Enter Diagnosis Name';
        diagnosisInfo.name = value !== '' ? value : '';
        break;
      default:
          break
    }
    setFormErrors(fieldValidationErrors);
  }

  const validateForm = (event) =>{
    event.preventDefault();
    var ServerResVal = ServerRes;
    let formValid =
    formErrors.code === "" &&
    formErrors.name === "";

    if(formValid){
      dispatch(diagnosisActions.addDiagnosisMaster(newDiagnosis, resCb => {
        if(resCb){
          console.log("Rescb",resCb.state,resCb.message)
          if(resCb.state === 'success'){
            ServerResVal.success = resCb.message 
            // resCb.data.id
            
          }
          else if(resCb.state === 'error'){
            ServerResVal.error = resCb.messages.message
          }
          setServerRes({...ServerResVal});
        }
      }));
    }
  }

  const handleSearch = (childSelectedOption, childDiagnosisArray) => {
    if (childSelectedOption != null) {
      setDiagnosisSelected(childSelectedOption);
      console.log("childDiagnosisArray",childDiagnosisArray)

      let diagnosisState = diagnosis
      diagnosisState['diagnosisId'] = childDiagnosisArray.id
      diagnosisState['patientId'] = dPatient.basicinfo.id
      dPatient.visitinfo.map((visit,index)=>{
        visit.diagnosisList.map((diagnosis,idx)=>{
          if(childDiagnosisArray.id === diagnosis.id){
            diagnosisState['visitId'] = visit.id
          }
        })
      })
      setDiagnosis(diagnosisState)

      //Adding to diagnosis table (calling api)
      dispatch(diagnosisActions.addDiagnosisMaster(diagnosis, resCb => {
        if(resCb){
          console.log("Res:State",resCb.state,"Res:Msg",resCb.message)
          dPatient.visitinfo.map((data,index)=>{
            if(helperServices.getDate(data.visitDate) === selectedDate){
              data.diagnosisList.push(childDiagnosisArray)
              console.log(data.diagnosisList)
              handleVisitedDateChange(selectedDate) 
            }
          })
        } 
      }));

      // //Adding to the diagnosis History
      // this.setState({
      //   diagnosisHistory: [...this.state.diagnosisHistory, childDiagnosisArray],
      // });
    } else {
      //Custom diagnosis (Not in dropdown list)
      handleNewDiagnosis();
    }
  }


    return (
      <main className="mainsection">
        {/* Page Header */}
        <Row noGutters className="page-header">
          <PageTitle title="Patient Information" 
            // subtitle="Dashboard" 
            className="text-sm-left mb-3" />
        </Row>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            {/* Patient Information */}
            <Form>
              <Row form>
                <Col className="form-group" md="6">
                  <label htmlFor="#patientid">Patient Id : {patientInfo.patientId}</label>  
                </Col>
                <Col className="form-group" md="6">
                  <label htmlFor="#name">Name : {patientInfo.name}</label>
                </Col>
              </Row>
              <Row form>
                <Col className="form-group" md="6">
                  <label htmlFor="#age">Age : {patientInfo.age}</label>
                </Col>
                <Col className="form-group" md="6">
                  <label htmlFor="#mobile">Mobile : {patientInfo.phno}</label>
                </Col>
              </Row>
              <Row>
                <Col className="form-group" md="6">
                  <label htmlFor="#">Visited Dates </label>
                  <FormSelect
                    onChange={(event) => {
                      handleVisitedDateChange(event.target.value);
                    }}
                  >
                    <option defaultValue="Select last visited date">
                      Select last visited date
                    </option>
                    {dPatient && dPatient.visitinfo && dPatient.visitinfo.map((data,index)=>(
                      <option
                        key={index}
                        id={data.id}
                        value={helperServices.getDate(data.visitDate)}
                      >
                        {helperServices.getDate(data.visitDate)}
                      </option>
                    ))}
                  </FormSelect>
                </Col>
              </Row>
            </Form>

            {/* Search Diagnosis */}
            {showDiagnosis === true ? (
              <>
                <Row>
                  <Col>
                    <h5>Diagnosis History</h5>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" className="mb-3">
                    <Components.SearchDiagnosis parentCallBack={handleSearch} />
                  </Col>
                  <Col md="6">
                    <Button onClick={handleNewDiagnosis} style={{ float: `right` }}>
                      Add New Diagnosis
                    </Button>
                  </Col>
                </Row>
                
                {/* Table showing diagnosis list based on selected date from visited date dropdown  */}
                <Row>
                  <Col>
                    <Card small className="mb-4">
                      <CardHeader className="border-bottom">
                        <h6 className="m-0">Diagnosis</h6>
                      </CardHeader>
                      <CardBody className="p-0 pb-3">
                      <div className="ag-theme-balham" style={{height: 400, width: `100%`}}>
                  <AgGridReact 
                  
                  pagination={pagination} paginationPageSize={paginationPageSize}
                    rowData={selectedPatentInfo}
                     
                  >
                     <AgGridColumn headerName='Id' field="id" sortable={ true } filter={ true } resizable={ true }
                     width={100} floatingFilter={true} ></AgGridColumn>
                    <AgGridColumn headerName='Code' field="code" sortable={ true } filter={ true } flex={1} resizable={ true }
                     floatingFilter={true} ></AgGridColumn> 
                    <AgGridColumn headerName='Name' field="name" sortable={ true } filter={ true }  flex={1} resizable={ true }
                     floatingFilter={true} ></AgGridColumn>
                    <AgGridColumn headerName='Diagno Date' field="diagnodate" sortable={ true } filter={ true }  flex={1} resizable={ true }
                     width={150}
                >
                     
                    </AgGridColumn>
                  </AgGridReact>
                </div>
                      {/*   <table className="table mb-0">
                          <thead className="bg-light">
                            <tr>
                              <th scope="col" className="border-0">#</th>
                              <th scope="col" className="border-0">Diagnosis Code</th>
                              <th scope="col" className="border-0">Diagnosis Name</th>
                              <th scope="col" className="border-0">Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dPatient.visitinfo.map((data,index)=>(
                              helperServices.getDate(data.visitDate) === selectedDate 
                              ? data.diagnosisList.map((diagnosis, idx) => (
                                <tr key={idx}>
                                  <td>{idx+1}</td>
                                  <td>{diagnosis.code}</td>
                                  <td>{diagnosis.name}</td>
                                  <td>{helperServices.getDate(diagnosis.date)}</td>
                                </tr>
                              ) )
                              :''
                            ))}  
                          </tbody>
                        </table> */}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
             ) : (
              ""
            )}

            {/* Code For adding new diagnosis */}
            <Modal
              open={addNewDiagnosisModalOpenClose}
              toggle={handleNewDiagnosis}
            >
              <ModalHeader>Add New Diagnosis</ModalHeader>
              <ModalBody>
                <Row>
                  <Col>
                    <FormInput 
                      id="code"
                      placeholder="Diagnosis Code" 
                      value={newDiagnosis.code}
                      onChange={(event)=>{handleInputChange("code",event.target.value)}}
                      className="mb-2"
                      invalid={formErrors.code === "" ? "":true } />
                    <div style={{ color: '#EC4147' }} >
                      <span>{formErrors.code}</span>
                    </div>   
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormInput 
                      id="name"
                      placeholder="Diagnosis Name" 
                      value={newDiagnosis.name}
                      onChange={(event)=>{handleInputChange("name",event.target.value)}}
                      className="mb-2" 
                      invalid={formErrors.name === "" ? "":true }/>
                    <div style={{ color: '#EC4147' }} >
                      <span>{formErrors.name}</span>
                    </div> 
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {ServerRes.success &&
                      <Alert theme="success">{ServerRes.success}</Alert>
                    }
                    {ServerRes.error &&
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    }
                  </Col>
                </Row>
                   
              </ModalBody>
              <ModalFooter style={{justifyContent:`space-between`}}>
                <Button onClick={handleNewDiagnosis}>Close</Button>
                <Button
                  onClick={handleSaveNewDiagnosis}
                  style={{ float: `left` }}
                >
                  Save
                </Button>
              </ModalFooter>
            </Modal>
          </ListGroupItem>
        </ListGroup>
      </main>
    );
}
/* function mapStateToProps(state) {
    return {
      dPatient:state.diagnosisDetailsState.dPatient,
      diagnosisMasterDetail:state.diagnosisDetailsState.diagnosisMasterDetail,
      diagnosisDetail:state.diagnosisDetailsState.diagnosisDetail,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      addDiagnosisMaster: (data, resCb) => dispatch(diagnosisActions.addDiagnosisMaster(data, resCb)),
      getDiagnosisMaster: (resCb) => dispatch(diagnosisActions.getDiagnosisMaster(resCb)),
      addDiagnosis:(data,resCb) => dispatch(diagnosisActions.addDiagnosis(data,resCb))
    };
  }
  
  const DiagnosisMasterWithProps = connect(mapStateToProps, mapDispatchToProps)(diagnosisMaster); */
  
  export default DiagnosisMaster;
