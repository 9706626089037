import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Row, Col, Card, CardBody, Button } from "shards-react";

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';

  function FormList(props) {
    const[formsList, setFormsList] = useState({
        formsList : [
            {
                basicinfo : {
                    id:'1',
                    createdOn:'12-01-2018',
                    formName:'ADMISSION FORM'
                }
            },
            {
                basicinfo : {
                    id:'2',
                    createdOn:'12-01-2019',
                    formName:'SAMPLE FORM 1'
                }
            },
            {
                basicinfo : {
                    id:'3',
                    createdOn:'12-01-2020',
                    formName:'SAMPLE FORM 2'
                }
            },
            {
                basicinfo : {
                    id:'4',
                    createdOn:'12-01-2021',
                    formName:'SAMPLE FORM 3'
                }
            }
        ],
      })
      const[showFormDetail, setShowFormDetail] = useState({})
      const[showCreateBed, setShowCreateBed] = useState({})
      const [pagination,setPagination] = useState(true)
      const [paginationPageSize,setPaginationPageSize] = useState(10)

    useEffect(() => {
      
    },[])

    const handleEditClick = (formId) => {
        props.history.push(`/FormDetail/${formId}`);
    }

    const handleAddNewFormClick = () => { 
        props.history.push('/AddForm');
    }

    const toggleModal = (event) => {
        setShowFormDetail('')
    }

    const toggleAddModal = (event) => {
        setShowCreateBed('')
    }
    const context = {componentParent: (props) => handleRendererCall(props)};


    
    const handleRendererCall = (props) =>{
        console.log('props',props.id)
        handleEditClick(props.id)
    }
        return (
            <main className="mainsection">
                <Row>
                    <Col>
                        <Card small className="mb-4">
                            <CardBody className="p-0 pb-3">
                            <div className="ag-theme-balham" style={{height: 400, width: `100%`}}>
                  <AgGridReact 
                  frameworkComponents={{
                    BtnCellRenderer: BtnCellRenderer,
                  }}
                  
                  pagination={pagination} paginationPageSize={paginationPageSize}
                    rowData={formsList.formsList}
                     context = {context}
                  >
                     <AgGridColumn field="basicinfo.formName" sortable={ true } filter={ true } resizable={ true }
                     width={250} floatingFilter={true} ></AgGridColumn>
                    <AgGridColumn field="basicinfo.createdOn" sortable={ true } filter={ true } flex={1} resizable={ true }
                     floatingFilter={true} ></AgGridColumn> 
                    <AgGridColumn field="Actions" sortable={ true } filter={ true }  flex={1} resizable={ true }
                     width={150}
                    cellRenderer="BtnCellRenderer"
                    
                >
                     
                    </AgGridColumn>
                  </AgGridReact>
                </div>
                                {/* <table className="table mb-0 tcol">
                                    <thead className="bg-light">
                                        <tr>
                                            <th scope="col" className="border-0">Name</th>
                                            <th scope="col" className="border-0">CreatedOn</th>
                                            <th scope="col" className="border-0">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.formsList.map((formDetail, idx) => (
                                        <tr key = {idx} >
                                           <td>{formDetail.basicinfo.formName}</td>
                                            <td>{formDetail.basicinfo.createdOn}</td>
                                            <td>
                                                <div className="d-inline-block item-icon-wrapper cpointer">
                                                    <i class="material-icons" title="Edit form" onClick={(event) => this.handleEditClick(formDetail.basicinfo.id)}>edit</i>
                                                </div>
                                                &nbsp;&nbsp;
                                                <div className="d-inline-block item-icon-wrapper cpointer">
                                                    <i class="material-icons" title="Delete form">delete</i>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> */}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div>
                <Button type="submit" className = "floatingbtn" title="Add new form" onClick={(event) => this.handleAddNewFormClick()}>Add New</Button>
            </div>
            </main>
            
        );


  }
/* 
  function mapStateToProps(state) {
    return {
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
    };
}

const HomeWithProps = connect(mapStateToProps, mapDispatchToProps)(FormList); */

export default FormList;
