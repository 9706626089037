import { helperServices } from "../../shared/helper";
import history from "../../shared/utils/history";

const basePath = "/api";

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem("user");
  history.push("/");
  window.location.reload();
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    let token = response.headers.get("Authorization");
    if (token != null && token != undefined) {
      localStorage.setItem("token", JSON.stringify(token));
    }
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        logout();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function getAllPatientInfo(searchText) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(searchText),
  };
  const url = basePath + `/master/patients`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addPatient(data, flag,admissionDateTime) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/ipd/patient?flag=${flag}&admissionDate=${admissionDateTime}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function demoCon() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //body: JSON.stringify(data),
  };
  const url = basePath + `/v1/Democontroller`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updatePatient(data, convert,admissionDateTime) {
  var accessToken;
  let image;
  if (data && data.upload && data.upload.file) {
    image = data.upload.file;
  }
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    // "PUT"
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  // /v1/master/patient
  const url = basePath + `/v1/ipd/patient?file =${image}&convert=${convert}&admissionDate=${admissionDateTime}`;
  // let url = `${basePath}/v1/ipd/patient?file=${image}&convert=${convert}`;
// Check if admissionDateTime has a value before appending it to the URL
  // if (admissionDateTime) {
  // url += `&admissionDate=${admissionDateTime}`;
  // }
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addVisit(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/visit`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addProcedure(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/master/procedure`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getVisitInfo() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(),
  };
  const url = basePath + `/master/visit`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getInsuranceList(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url =
    basePath +
    `/v1/insurance?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addInsuranceProcedure(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/insurance`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function updateInsuranceProcedure(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/insurance`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function ListInsuranceSettlements(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/insurance/tobesettled`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function EditInsuranceSettlements(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/insurance/settled?visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function uploadAttachments(fileData) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }

  const formData = new FormData();
  formData.append("file", fileData, fileData.name);
  formData.append("sample", JSON.stringify({ name: "dummy" }));
  console.log("FormData-", formData);
  // let formData = new FormData();
  // formData.append('file', fileData);

  const requestOptions = {
    method: "POST",
    headers: {
      Authorization: accessToken,
      "Content-Type": "x-www-form-urlencoded;charset=UTF-8",
    },
    body: formData,
  };
  // /master/attachmentUpload
  const url = basePath + `/v1/fileupload`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((httpResponse) => {
      return httpResponse.response;
    })
    .catch((error) => {
      console.log(error);
    });
}

function addComplaint(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/addProcedure`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addReference(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/ipd/reference`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function ListPatients(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(data),
  };
  const url =
    basePath +
    `/v1/patients?targetId=${data.targetId}&floorId=${data.floorId}&department=${data.department}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function ListVisits(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/visits`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getInsuranceStatus(visitIdList) {
  console.log("visit id in service:", visitIdList);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(visitIdList),
  };
  const url = basePath + `/v1/insurance/getStatus`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // console.log("data in service", data);
      return data;
    });
}

function getActionList(visitIdList) {
  console.log("visit id in service:", visitIdList);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(visitIdList),
  };
  const url = basePath + `/v1/visitaction`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // console.log("data in service", data);
      return data;
    });
}

function ListRevisits(date1, date2, searchFollowUps) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //  body: JSON.stringify(data),
  };
  const url = basePath + `/v1/revisits?Date1=${date1}&Date2=${date2}&SearchedFolUp=${searchFollowUps}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addRevisit(data, resCb) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/revisitupdate`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function reAddVisit(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/readdvisit`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function addAppointment(data, type, resCb) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/appointment?type=${type}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getPatientProfile(patientId) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //  body: JSON.stringify(patientId),
  };
  const url = basePath + `/v1/patientProfile?patientId=${patientId}`;
  //  const url = basePath + `v1/patientProfile`
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}


// function fileUpload(fileUploadData) {
//   console.log("fileupload api call ")
//   var accessToken;
//   if (helperServices.getAuthorization()) {
//     accessToken = helperServices.getAuthorization();
//   }
// let formData = new FormData();
//   var fileName ;
//   var fileType;
//    for (let i = 0; i < fileUploadData['fileData'].length; i++) {
//       console.log("CService for loop : ",fileUploadData['fileData'][i].name)
//       fileName = fileUploadData['fileData'][i].name
//       // fileType = fileUploadData['filedata'][i].type
//       formData.append('file' + i, fileUploadData['fileData'][i],fileUploadData['fileData'][i].name)
//   }
//   const requestOptions = {
//     method: "POST",
//     headers: {
//       Accept: "multipart/form-data",
//       "Content-Type": "multipart/form-data",
//       // Authorization: accessToken,
//     },
//   //  body: JSON.stringify(patientId,fileUploadData),
//   body: formData,
//   };
//   console.log("requestOptions ",requestOptions)
//   const url = basePath + `/v1/fileupload`;

//   return fetch(url, requestOptions)
//     .then(handleResponse)
//     .then((data) => {
//       return data;
//     });
// }

function fileUpload(fileUploadData, percentageProgressHandler) {
  console.log("fileupload api call ");
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  let formData = new FormData();
  var fileName;
  var fileType;
  var patientId = fileUploadData["id"];
  var visitId = fileUploadData["visitId"];
  var typeOf = fileUploadData["typeOf"];
  console.log("patientId : ", patientId);
  for (let i = 0; i < fileUploadData["fileData"].length; i++) {
    console.log("CService for loop : ", fileUploadData["fileData"][i].name);
    fileName = fileUploadData["fileData"][i].name;
    // fileType = fileUploadData['filedata'][i].type
    //  formData.append('file' + i, fileUploadData['fileData'][i],fileUploadData['fileData'][i].name)
    formData.append(
      "file",
      fileUploadData["fileData"][i],
      fileUploadData["fileData"][i].name
    );
    // formData.append( 'files',fileUploadData['fileData'][i].name)
  }
  // formData.append('file', fileUploadData['fileData']);
  console.log("FormData - ", formData);

  // const requestOptions = {
  //     method: 'POST',
  //     headers: {
  // Accept: "multipart/form-data",
  // "Content-Type": "multipart/form-data",
  //        'Authorization': accessToken
  //     },

  //     body: formData,
  // };
  // const url = basePath + `/v1/fileupload?${formData}`;
  const url =
    basePath +
    `/v1/fileupload?patientId=${patientId}&visitId=${visitId}&typeOf=${typeOf}`;
  // console.log("requestt options  ", requestOptions)
  return new Promise(function (resolve, reject) {
    // Do some Async stuff
    // call resolve if it succeeded
    // reject if it failed
    let progressHandler = function (event) {
      var percent = Math.round((event.loaded / event.total) * 100);
      console.log("#progressHandler percent", percent);
      // percentageProgressHandler({'name':fileName,'progress':percent})
    };
    let completeHandler = function (event) {
      console.log("event.target.responseText;", event.target.responseText);
      console.log(event);
      if (event.target.status >= 200 && event.target.status < 300) {
        resolve(JSON.parse(event.target.response));
      } else {
        reject({
          status: event.target.status,
          statusText: event.target.statusText,
        });
      }
    };
    let errorHandler = function (event) {
      reject({
        status: event.target.status,
        statusText: event.target.statusText,
      });
    };

    var ajax = new XMLHttpRequest();

    ajax.upload.addEventListener("progress", progressHandler, false);
    ajax.addEventListener("load", completeHandler, false);
    ajax.addEventListener("error", errorHandler, false);
    ajax.addEventListener("abort", errorHandler, false);
    ajax.open("POST", url); // http://www.developphp.com/video/JavaScript/File-Upload-Progress-Bar-Meter-Tutorial-Ajax-PHP
    ajax.setRequestHeader("Authorization", accessToken);
    // ajax.setRequestHeader("Content-Type","multipart/form-data");
    //use file_upload_parser.php from above url
    ajax.send(formData);
  });
}

// getPatientProcedureInfo(patientId)
function getPatientProcedureInfo(patientId, visitId, type) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: patientId,
    //  JSON.stringify(patientId),
    // Params : patientId,
  };
  console.log("patient services in procedures");
  const url =
    basePath +
    `/v1/procedure?patientId=${patientId}&visitId=${visitId}&type=${type}`;

  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("patient services in procedures", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function patientSearch(postObj) {
  console.log("data", postObj)
  let data;
  let dateFrom
  let dateTo
  if(postObj.dateFrom && postObj.dateTo && postObj.text){
   dateFrom = postObj.dateFrom;
   dateTo = postObj.dateTo;
   data = postObj.text;
   }else{
      data = postObj
      dateFrom = "";
      dateTo = ""
    }
   
  
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: data,
    //  JSON.stringify(patientId),
    // Params : patientId,
  };
  console.log("patient services in patientsearch");
  const url = basePath + `/v1/search?dateFrom=${dateFrom}&dateTo=${dateTo}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (patients) => {
        console.log("patient services in patientsearch", patients);
        return patients;
      },
      (error) => {
        console.log(error);
      }
    );

}
function followUpByDoctor(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
    // Params : patientId,
  };
  console.log("patient services in patientsearch", data);
  const url = basePath + `/v1/followUpByDoctor`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("patient services in patientsearch", data);
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}
function  getrevisitbyId(patientId, visitId) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: patientId,
    //  JSON.stringify(patientId),
    // Params : patientId,
  };
  console.log("patient services in procedures");
  const url =
    basePath +
    `/v1/getrevisitbyId?patientId=${patientId}&visitId=${visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("patient services in procedures", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}
function updateVAStatus(data, resCb) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/closeAssignment`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function saveDischargeSummary(data, resCb) {
  var accessToken;
  console.log("data : ", data);
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },

    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/saveDischargeSummary`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}
function saveDischargeDate(data, resCb) {
  var accessToken;
  console.log("data : ", data);
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },

    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/saveDischargeDate`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}
function saveVisitAssignment(data, resCb) {
  var accessToken;
  console.log("data : ", data);
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },

    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/saveVisitAssignment`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}
function updateDischargeSummary(data, resCb) {
  var accessToken;
  console.log("data : ", data);
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },

    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/procedureUpdate`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function markForDel(data, resCb) {
  var accessToken;
  console.log("data : ", data);
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },

    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/markForDel`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getDischargeSummary(data, details) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  ///patientProfile?patientId=${patientId}
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //body: JSON.stringify(data),
  };
  const url =
    basePath +
    `/v1/procedure/group?patientId=${details.patientId}&visitId=${details.visitId}&groupname=${details.groupname}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getVisitbyId(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  ///patientProfile?patientId=${patientId}
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/visit/groupId`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function updateGroupId(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  ///patientProfile?patientId=${patientId}
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/visit/groupId`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getPatientProcedureByVisitDate(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: patientId,
    //  JSON.stringify(patientId),
    // Params : patientId,
  };
  console.log("patient services in procedures");
  const url =
    basePath +
    `/v1/procedure/visitDate?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("patient services in procedures", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getVisitListById(visitId, type) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  const url = basePath + `/v1/visit?visitId=${visitId}&type=${type}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("visit assignmnet list obtained", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}
function getDoctorCalenderByDate(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  console.log("Doctor Calender By Date");
  const url =
    basePath + `/v1/calenderForDoc?gvid=${data.gvid}&apptdate=${data.apptdate}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("Doctor Calender By Date", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getDoctorCalenderForPatient(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  console.log("Doctor Calender for Patient");
  const url =
    basePath + `/v1/calenderForPatient?gvid=${data.gvid}&patientId=${data.patientId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("Doctor Calender for Patient", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function onboardPatient(data, type,admissionDateTime) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url = basePath + `/v1/onboard?type=${type}&admissionDate=${admissionDateTime}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function cancelDoctorCalenderByPatientId(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  console.log("Cancel Doctor Calender By patientId");
  const url =
    basePath +
    `/v1/cancelDoctorAppointment?gvid=${data.gvid}&apptdate=${data.date}&patientId=${data.patientId}&vaId=${data.vaid}&type=${data.type}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("Doctor Calender By Date", data);

        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function ListPrescriptions(data, patientEhrid) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  console.log("Doctor Calender By Date");
  const url =
    basePath +
    `/v1/ehr/ListComposition?compositionIdList=${data}&patientEhrid=${patientEhrid}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("Doctor Calender By Date", data);
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function prescriptions(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
  };
  console.log("Doctor Calender By Date");
  const url = basePath + `/v1/prescriptions`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        console.log("prescriptions", data);

        return [
          { serialNo: 1, medicineName: "dolo", units: "650", dosage: "1+0+1" },
          {
            serialNo: 2,
            medicineName: "dolodf gdfdfdf",
            units: "650",
            dosage: "1+0+1",
          },
          {
            serialNo: 2,
            medicineName: "dolodf",
            units: "650",
            dosage: "1+0+1",
          },
        ];
      },
      (error) => {
        console.log(error);
      }
    );
}

function moveToBilling(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url =
    basePath +
    `/v1/moveSOtoBilling?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function soIdCheck(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url =
    basePath +
    `/v1/soIdCheck?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function billclearstatus(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url =
    basePath +
    `/v1/billclearstatus?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}



function roomDealocate(data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url =
    basePath +
    `/v1/roomDealocate?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function registrationFees(customerId, totalfees, visitId, patientId, type, data) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(data),
  };
  const url =
    basePath +
    `/v1/registrationFees?customerId=${customerId}&totalFees=${totalfees}&visitId=${visitId}&patientId=${patientId}&type=${type}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function totalFees() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //body: JSON.stringify(data),
  };
  const url = basePath + `/v1/registration/amount`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getVouchers(erpCustId) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(erpCustId),
  };
  const url = basePath + `/v1/getVoucher?erpCustId=${erpCustId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function getRedeemedVouchers(erpCustId) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(erpCustId),
  };
  const url = basePath + `/v1/redeemedVoucher?erpCustId=${erpCustId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function redeemVoucher(visitId, pateintId, voucherInfo) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    body: JSON.stringify(voucherInfo),
  };
  const url = basePath + `/v1/redeemVoucher?visitId=${visitId}&pateintId=${pateintId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getRedeemeedVoucher(pateintId) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    }
  };
  const url = basePath + `/v1/redeemedVoucher?pateintId=${pateintId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}
function addToProcedure(data, resCb) {
  var accessToken;
  console.log("data : ", data);
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },

    body: JSON.stringify(data),
    //  JSON.stringify(data),
  };
  const url = basePath + `/v1/procedure`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then(
      (data) => {
        return data;
      },
      (error) => {
        console.log(error);
      }
    );
}

function getAppointedPatientDetails(pateintIds) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //body: JSON.stringify(pateintIds),
  };
  const url = basePath + `/v1/getAppointedPatientDetails?patientIds=${pateintIds}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function getRegistrationPDF(pateintIds) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //body: JSON.stringify(pateintIds),
  };
  const url = basePath + `/v1/getRegistrationPDF
  patientIds=${pateintIds}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function checkScanner() {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    //body: JSON.stringify(pateintIds),
  };
  const url = `http://127.0.0.1:5000/api/checkScanner`;
  //  const url = 'http://localhost:3000/api/checkScanner';
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function uploadToken(data) {
  console.log("data in service method", data);
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = basePath + `/v1/uploadToken?patientId=${data.patientId}&visitId=${data.visitId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function scanDocument(authToken) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(),
  };
  const url = `http://127.0.0.1:5000/api/scanDocument?authToken=${authToken}&&accessToken=${accessToken}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

function deletePackageService(packageServiceId) {
  var accessToken;
  if (helperServices.getAuthorization()) {
    accessToken = helperServices.getAuthorization();
  }
  const requestOptions = {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: accessToken,
    },
    // body: JSON.stringify(data),
  };
  const url = basePath + `/v1/deletePackageService?id=${packageServiceId}`;
  return fetch(url, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}




const patientServices = {
  handleResponse,
  logout,
  getAllPatientInfo,
  addPatient,
  updatePatient,
  addVisit,
  addProcedure,
  getVisitInfo,
  uploadAttachments,
  addComplaint,
  addReference,
  ListPatients,
  getPatientProfile,
  fileUpload,
  ListVisits,
  ListRevisits,
  addRevisit,
  reAddVisit,
  addAppointment,
  getPatientProcedureInfo,
  patientSearch,
  updateVAStatus,
  saveDischargeSummary,
  updateDischargeSummary,
  getDischargeSummary,
  getVisitbyId,
  updateGroupId,
  markForDel,
  getPatientProcedureByVisitDate,
  getInsuranceList,
  addInsuranceProcedure,
  updateInsuranceProcedure,
  getVisitListById,
  getDoctorCalenderByDate,
  onboardPatient,
  cancelDoctorCalenderByPatientId,
  EditInsuranceSettlements,
  ListInsuranceSettlements,
  prescriptions,
  ListPrescriptions,
  moveToBilling,
  roomDealocate,
  getInsuranceStatus,
  getActionList,
  demoCon,
  registrationFees,
  totalFees,
  getVouchers,
  getrevisitbyId,
  redeemVoucher,
  getAppointedPatientDetails,
  getRedeemeedVoucher,
  getRegistrationPDF,
  getRedeemedVouchers,
  checkScanner,
  uploadToken,
  followUpByDoctor,
  scanDocument,
  deletePackageService,
  soIdCheck,
  billclearstatus, 
  saveVisitAssignment,
  getDoctorCalenderForPatient,
  saveDischargeDate
};
export default patientServices;
