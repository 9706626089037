import React, { useState, useEffect } from 'react';
import { Card , CardBody,Modal,
    ModalBody,
    ModalFooter,
    ModalHeader, Button } from "shards-react";
import {useDispatch, useSelector} from "react-redux";
import {ZOOMNOTESLABEL} from "../../shared/config";
import {ZOOMNOTESLABELMAP} from "../../shared/config";
import Components from '..';
import { cleanup } from '@testing-library/react';
import { circularProgressClasses } from '@mui/material';
import { doctorActions, patientActions } from '../../core/actions';
import { format } from "date-fns";

function ZoomMeetingReviewModel(props) {
    
    //const[showEditCardModel, setShowEditCardModel] = useState(false);
    //const[selectedCardInfo, setSelectedCardInfo] = useState({});
    const[draggedLabel, setDragedLabel] = useState({});
    const[isDragged, setIsDragged] = useState(false);
    const[selectedtags, setTags] = useState([]);
    const[selectedLable, setSelectedLable] = useState({selectedLable:true});
    const[showSelectedLable, setShowSelectedLable] = useState(false);
    const [tagnotes, setTagNotes] = useState();
    const [notes, setNotes] = useState();
    const [status, setStatus] = useState(undefined);
    const [lablemapobj] = useState([]);
    const dispatch = useDispatch();
    const[isTrue, setIsTrue] = useState(false);
    const [color, setcolor] =useState("red");
    const [zoomMeetingReview, setZoomMeetingReview] =useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    // const zoomMeetingReview = useSelector(state => state.doctorDetailsState.zoomReview)
    const selectedNote = useSelector(state => state.patientDetailsState.selectedNotes)
   

    const[procedureDetail, setProcedureDetail] = useState({
        patientId: '',
        visitId: '',
        groupid: "",
        notes: "",
        tags:"",
      })

      const [procedure, setProcedure] = useState({
        patientId: '',
        visitId: '',
        groupid: "",
      });

    useEffect(() => {
        console.log("selectedNote:",selectedNote);
        console.log("props in zoomMeetingModel:",props);
       
        // let notes = selectedNote;
        // setTagNotes(notes);
        console.log("tagnotes:",notes);
        setZoomMeetingReview([...props.procedureNotes]);
        console.log("props in meeting model:props in meeting model:",props.selectedPatient);
        if(props.match && props.match.params){
            let patientid = props.match.params.patientid;
            let visitid = props.match.params.visitid;
            let groupid = props.match.params.groupid;
          
           
            console.log("procedure1",props.match.params.patientid,props.match.params.visitid,props.match.params.groupid);
            if(patientid === ':patientid' || visitid === ':visitid' || groupid === ':groupid'){
                groupid='';
                visitid='';
                patientid='';
               
            }else{
                // procedure = {patientId:patientid,groupId:groupid,visitId:visitid}
                procedure.patientId = patientid;
                procedure.groupid = groupid;
                procedure.visitId = visitid;
                setProcedureDetail(procedure);
                console.log("procedure",procedure);
                reviewNotes(procedure)
                procedureDetail.patientId = patientid;
                procedureDetail.visitId = visitid;
                procedureDetail.groupid = groupid;
                console.log("procedureDetail :",procedureDetail);
                prepareLabelFromTags();
                dispatch(doctorActions.updateMeetingStatus(procedureDetail,resCb=>{                   
                    if(resCb){                                           
                      console.log("API Response:",resCb)          
                    }
                }))
            }
        }else{
            console.log("setLabelasPerTheTagsReceived is getting called 1");
            console.log('zoomMeetingReview 1',zoomMeetingReview)
            console.log("patientprops",props);
            console.log("Initial selectedLable value ", selectedNote)
            
            prepareLabelFromTags();
            //setTagNotes(selectedNote);            

           // console.log("tagnotes:",tagnotes);
            let procedure = procedureDetail;
            procedure.patientId = props.selectedPatient.id;
            procedure.visitId = props.selectedPatient.visit.id;
            procedure.groupid = zoomMeetingReview.map((item)=>{return item.groupid})[0];
            setProcedureDetail({...procedure});
          //  procedureDetail.tags=props.selectedPatient.
            console.log("procedureDetail :",procedureDetail);
            // setTimeout(() => {
            //     console.log("tagnotes: ...",tagnotes);
            //     console.log('zoomMeetingReview',zoomMeetingReview)
            // }, 1000);
        }
    },[props])

    useEffect(() => {

        setShowSelectedLable(true);
        var element = document.getElementById('review-notes');
        element.scrollTop = element.scrollHeight;
        console.log("element:",element.scrollHeight);

    },[tagnotes])
  
    const prepareLabelFromTags = () => {
        // etZoomMeetingReviesw([...props.procedureNotes]);
        let zoomMeetingReview = props.procedureNotes;
        console.log("setLabelasPerTheTagsReceived is getting called 2");
        let tagnotesloc = ({...zoomMeetingReview})
        console.log("local tag notes :", zoomMeetingReview)
        console.log("procedureNotes in prepareLabelFromTags :", props.procedureNotes)
        if(!zoomMeetingReview){
            console.log("tagnotesloc not recevied from api", zoomMeetingReview);
        }
        if(zoomMeetingReview && zoomMeetingReview.length!=0){
            console.log("tagnotesloc recevied from api", zoomMeetingReview.length);
            for (let procFromAPIRes of zoomMeetingReview){
                console.log("Procedure recevied from api", procFromAPIRes)
                let tagsOfProc =procFromAPIRes.tags;
                if (!procFromAPIRes.label){
                    procFromAPIRes['label']=[];
                }
                let eachTag = []
                if(tagsOfProc){   
                    eachTag = tagsOfProc.split(",");
                    for (let tag of eachTag){
                        console.log("each tag of the procedure ",tag)
                        if( procFromAPIRes['label'].length != eachTag.length){
                            procFromAPIRes['label'].push(ZOOMNOTESLABELMAP[tag]);
                            console.log("ZOOMNOTESLABELMAP",ZOOMNOTESLABELMAP[tag]);
                        }
                       
                    }
                }
                console.log("procFromAPIRes after assign :", procFromAPIRes);
            
            }  
        }  
            
        console.log("review notes after label edit:", zoomMeetingReview);
        setTagNotes([...zoomMeetingReview]);
       
    }


    const reviewNotes = (data) => {
        // let data = {}
        console.log("data0",data);
        setTagNotes(zoomMeetingReview);
        dispatch(doctorActions.reviewNotes(data,resCb=>{
          if(resCb){
            console.log("reviewZoomNotes API Response:",resCb)          
        //    setTagNotes(resCb.procedureList);

           
        }}))
      }



    const closeModal = () => {
        props.onReviewModalClose()
    }

    const onDragStart = (ev, value) => {
        console.log('on drag of section')
        console.log('dragstart:',ev.target,value);
    }

    const onDragOver = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        console.log('onDragOver:',ev);
    }

    const onLabelDragStart = () => {
        console.log('onLabelDragStart:');
    }

    function getKeyByValue(object, value) {
        return Object.keys(object).find(key => object[key] === value);
    }

    const[selectionmap, setselectionmap]=useState([{
        key:'',value:''
    }]);

    const [alldata, setAllData] = useState([{}]);

    function mapToObject(event,value){
        const map = getKeyByValue(alldata,event);
        if(!map){
        setselectionmap({
            key:event,
            value:value
        })
        setAllData.add(selectionmap)
        }else{
            map.add(selectionmap)
            setAllData(map)
        }
    }

    const onSelect = (reviewLabel,review) => {    
        console.log("handle drag reviewLabel value : ",reviewLabel,review)
        console.log("selectedLable : ", selectedLable)
        console.log("onSelect  :",review);
        let procedure =[];
        for (let note of review.label){
            if(note.label === reviewLabel.label){
                // note ='';
            }else{
                procedure.push(note)
            }
        }
        review.label ='';
        review.label = procedure;
        setIsTrue(!isTrue);
        console.log(" review.label :" , review.label);
        console.log(" tagnotes :" , tagnotes);
        // return review;

        review.tags = null;
        for(let tags of review.label){
            if(!review.tags){
                if(tags.label){
                    review.tags = tags.label;
                }
            }else{
                console.log("tags1:" ,tags.label);
                review.tags = review.tags + ',' + tags.label;
                console.log("tags2:" ,tags.label);
            }
        }
        let lab = {...reviewLabel};
        lab.label = "";
        addTag(review,lab);
        // return review;
    }
    
    const clearLabelSelection = (event) => {
        setTagNotes(zoomMeetingReview)
        console.log("clearLabelSelection tagnotes :",tagnotes);
    }

    const onSelectedLable = (event) => {
        console.log("onSelectedLable :",event);
        console.log("tagnotes on select:", tagnotes)
        setSelectedLable(event)
        let filtredList = [];
        console.log("onselected label zoomMeetingReview :", zoomMeetingReview)
        console.log("onselected label lablemapobj :", lablemapobj)

            for (let element of zoomMeetingReview){
                console.log("element:",element);
            let tags = element.tags.split(',');
            console.log("tags:",tags);
            if(tags.includes(event.label)){
                console.log("filtered by label:",element);
                filtredList.push(element)
            }
        };
       
         setTagNotes(filtredList);
        console.log("tagnotes on select:", tagnotes)
    }

    const handleLabelDragLeave = (event,review) => {
        console.log('handleLabelDragLeave:',review,draggedLabel);
        console.log('draggedLabel:',draggedLabel)
        if(!review.label){
            review['label'] = []
            
        }
        if(review.label && !review.label.includes(draggedLabel)){
            review['label'].push(draggedLabel)
            let selectedTags =selectedtags
            console.log("selectedTags:selectedTags:",selectedTags);
            console.log('!draggedLabel:',draggedLabel);
            selectedTags.push(draggedLabel.label)
            console.log('!selectedTags:',selectedTags.includes(draggedLabel.label));
            console.log("copy ",(lablemapobj[draggedLabel.label]));
            if((draggedLabel.label && lablemapobj && !(lablemapobj[draggedLabel.label]))){
                console.log("lablemapobj 1 :", lablemapobj);
                lablemapobj[draggedLabel.label] = [{...review}];
                console.log("lablemapobj 2 :", lablemapobj);
                addTag(review,draggedLabel);
            }else{
                addTag(review,draggedLabel);
                console.log("copy = lablemapobj [draggedLabel] copy :",lablemapobj[draggedLabel.label]);
                lablemapobj[draggedLabel.label].push({...review});
                console.log(" lable mapobj value : ",lablemapobj);
             }
        }else{
            console.log('review:',);
        }
        setIsDragged(!isDragged)
        console.log('handleLabelDragLeave review:',review);
        // setTagNotes(review)
        //api call 
        //review includes the notes and the lable value
    }

    const addTag = (review,label) => {   
        if(review.tags){
            console.log("review.tags 1:",review.tags);
            if(label.label){
                review.tags = review.tags +','+ label.label;
                console.log("review.tags 2:",review.tags);
            }
        }else{
            console.log("review.tags 3:",review.tags);
            if(label.label){
                review.tags = label.label;
                console.log("review.tags 4:",review.tags);
            } 
        }
        console.log("review1:",review);
        updateProcedure(review);
    }

    const updateProcedure = (data) => {
        dispatch(doctorActions.addtags(data,resCb=>{
            if(resCb){
              console.log("API Response:",resCb)          
            }
        }))
    }

    const handleLabelDrag = (event) => {
        console.log("handle drag leave e:value : ",event)
        setDragedLabel(event)
    }
    
    const onLabelDrop = () => {
        console.log('onLabelDrop:');
    }

    const handleInputChange = (name, value) => {
        console.log("In handleinput change",name, value);
        console.log("procedureDetail on input change:",procedureDetail);
          var pDetail = procedureDetail;
          pDetail[name] = value;
          console.log("pDetail[name] change",pDetail[name], value);
          setProcedureDetail({...pDetail});
          console.log("procedure:",procedureDetail);
      }

    const submit = () => {
        console.log("procedureDetail submit:",procedureDetail);
        // if (!groupid) {
        //     groupid = Date.now() + "_" + props.selectedPatient.patientId + "_" +props.selectedPatient.visitId;
        //     console.log("groupid created:", groupid);
        //     let visit;
        //     if (props.selectedPatient.visit && !props.selectedPatient.visit.groupId) props.selectedPatient.visit["groupId"] = groupid;
        //   } else {
        //     props.selectedPatient.visit.groupId = groupid;
        //     dispatch(
        //       patientActions.updateGroupId(props.selectedPatient.visit, (resCb) => {
        //         if (resCb) {
        //           if (resCb.visit && resCb.visit.groupId) {
        //             groupid = resCb.visit.groupId;
        //           }
        //           console.log("reviewZoomNotes API Response:", resCb);
        //           // setSelectedPatientInfo(resCb)
        //         }
        //       })
        //     );
        //   }
        if(!procedureDetail.groupid){

            procedureDetail.groupid = Date.now() + "_" + procedureDetail.patientId + "_" + procedureDetail.visitId;
        }

        dispatch(doctorActions.addProcedure(procedureDetail,(resCb) => {
            console.log("procedureDetail:",procedureDetail);
            if(resCb.successMessages){
                setStatus({ type: 'success' });
                props.onReviewModalClose({'success':"Notes added successfully"});
            } else {
                props.onReviewModalClose({'error':"Failed to add notes"});
            }
        }));
    }

    const editNote = (name, value, review) => {
        console.log("In handleinput change",name, value, review);
        console.log("value", value);
        review.notes = value;
        console.log("tsgnotes",tagnotes);
        const reqdate = new Date(review.udt); 
        // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min; 
        // review.udt = format(reqdate, "MMM dd yyyy");
        let upadtedVal = review;
        let date = new Date();
        const hour = date.getHours();
        const minute = date.getMinutes(); 
        upadtedVal.udt = date;
        // return formatedate;
        updateProcedure(review);
    }

    const confirmDelete = (val,e,procedure)=> {
        //e.preventDefault();
       
        console.log("procedure conform delete:",procedure);
        console.log("conform delete:",val);
        console.log("e:",e);
        // props.delete({val,e,procedure});
        // setDeleteProcedure(procedure);
        setOpenDeleteModal(val);
        // e.preventDefault();
      } 
      const handleopenDeleteModal = () =>{
        setOpenDeleteModal(!openDeleteModal);
      }
      const markForDel=(presc,e)=>{
        console.log("presc selected for delete:",presc);
        props.delete({presc,e});
        setOpenDeleteModal(false);
        // props.onReviewModalClose()
      }

    console.log('zoomMeetingReview,tagnotes,showSelectedLable:',zoomMeetingReview,tagnotes,showSelectedLable);
    // props.match && props.match.params
    const formatUdt = (cdt) =>{
        let date = new Date(cdt);
        const hour = date.getHours();
        const minute = date.getMinutes(); 
        let udt = `${hour}:${minute < 10 ? '0' + minute : minute}`;
        return udt;
    }
    return (  
        <>   
        <div className='bg-white nav-wrapper h-100 d-flex flex-column'  key={isTrue}>            
            <div className="d-flex border-bottom">
                <span className='p-3'>
                {props.match && props.match.params ? (
                        <h5 className="mb-0" >Meeting Discussion for </h5>
                    ) : (
                        <>
                            <h5 className="mb-0" >Meeting Discussion for {props.selectedPatient.fname} {props.selectedPatient.lname}</h5>
 
                            <span className='text-muted'>Phone No:&nbsp;{props.selectedPatient.phno}</span>
                        </>
                    
                    )}
                </span>
 
                <span className='topright d-flex justify-content-end pl-4 pt-3 mr-4' onClick={(event) => closeModal(event)}>X</span> 
            </div>

            <div className='px-3 pt-3 pb-2' style={{overflowY:'auto'}}>
                <div className="zoom-noteslabel d-flex justify-content-between mt-2">
                    <div className="review-model-notes border" id="review-notes" key={isDragged}>
                        <>
                        
                            {showSelectedLable ?
                                <div className="py-3">
                                    {tagnotes && tagnotes &&
                                        tagnotes.map((review, idx) => (
                                            <CardBody className="notes-card-body model-notes-body pt-0" key={idx}  >
                                                <Card small className="mb-3 h-100 note-card" key={idx} 
                                                    onDragLeave={e => handleLabelDragLeave(e,review)}
                                                    onDrop={(e)=>{onLabelDrop(e, review)}}>
                                                    <div className="d-flex justify-content-between">
                                                        <span className='d-flex tag-label flex-wrap'>
                                                        {review.label && review.label.map((reviewLabel, index) => (
                                                            <Card small className="mb-2  note-label" key={index}
                                                        
                                                                onClick={e=>onSelect(reviewLabel,review)}
                                                                //   style={reviewLabel.color === color ? { 'backgroundColor': `${color}` }:{ 'backgroundColor': `${reviewLabel.color}` }}>
                                                                style={{ 'backgroundColor': `${reviewLabel.color}` }}
                                                                >
                                                                
                                                            </Card>
                                                        
                                                        )
                                                    
                                                        )}
                                                        </span>
                                                     
                                                        <span onClick={(event)=>confirmDelete(true,event,review)}>
                                                        <i class="material-icons">delete</i>
                                                        </span>
                                                        {/* <Modal
                                                        open={openDeleteModal}
                                                        toggle={handleopenDeleteModal}
                                                    >
                                                        <ModalHeader>Delete</ModalHeader>
                                                        <ModalBody>This cannot be undone. Are you sure you want to delete?</ModalBody>
                                                        <ModalFooter style={{justifyContent:`space-between`}}>
                                                        <Button onClick={handleopenDeleteModal}>Cancel</Button>
                                                        <Button
                                                        onClick={(event)=>markForDel(review,event)} 
                                                        >
                                                            Delete
                                                        </Button>
                                                        </ModalFooter>
                                                    </Modal> */}
                                                    </div>
                                                 
                                                    
                                                    <div name="notes" id="notes" contentEditable="true" className='d-flex justify-content-between align-items-baseline'
                                                        onBlur={(event) => {editNote("notes",event.target.innerHTML,review);}}
                                                        >{review.notes}
                                                    <span style={{float: "right",fontSize: '10px',fontWeight:400}} className=''>{formatUdt(review.cdt)}</span>
                                                    </div>
                                                </Card>
                                                
                                            </CardBody>
                                        ))
                                    }
                                </div>
                            : <div>false</div>}
                        </>
                    </div>
                        {props.match && props.match.params ? (
                            <div className=" review-model-label p-3 h-100">
                                <Components.NotesLabel
                                draggedLabel = {(event) => handleLabelDrag(event)}
                                selectedLable = {(event) => onSelectedLable(event)}
                                clearSelect = { (event) => clearLabelSelection(event)}
                            //  selectedPatient = {props.selectedPatient}
                                />
                            </div>
                        ) : (
                            <div className="review-model-label p-3 h-100">
                                <Components.NotesLabel
                                draggedLabel = {(event) => handleLabelDrag(event)}
                                selectedLable = {(event) => onSelectedLable(event)}
                                clearSelect = { (event) => clearLabelSelection(event)}
                                selectedPatient = {props.selectedPatient}
                                />
                            </div>
                        )}  
                </div>
                <div className='appdate d-flex justify-content-between'>
                    <input name="notes"
                        id="notes" className="form-control filterin add-note"
                        placeholder="Enter notes"
                        onChange={(event) => {handleInputChange("notes",event.target.value);}}/>
                    <button disabled={!procedureDetail.notes || procedureDetail.notes == ''} type="button" className="btn btn-primary notes-submit" onClick={(event) => submit(event)}>Save</button>
                </div>
            </div>
        </div>

    

        

        </>
           
       

    );
}

export default ZoomMeetingReviewModel;
