import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Navbar, NavbarBrand } from "shards-react";
import {  ADMIN_PAGE_HEADER } from '../../../shared/constants';
import { Dispatcher, Constants } from "../../../flux";

function SidebarMainNavbar(props) {
  const [heading, setHeading] = useState('');
   
  const handleToggleSidebar=()=> {
    Dispatcher.dispatch({
      actionType: Constants.TOGGLE_SIDEBAR
    });
  }

  useEffect(()=>{
    var role = JSON.parse(localStorage.getItem("role"));
      console.log("Role from session : ", role);
      if (role) {
        const roles = role; 
        // role.split(",");
        if (roles && roles.length > 0) {
          if (roles.indexOf("Admin") !== -1) {
            setHeading('Admin');
          }
          else if (roles.indexOf("Doctor") !== -1) {
            setHeading('Doctor');
          }
          else if (roles.indexOf("serviceDesk") !== -1){
            setHeading('Service Desk');
          }
        }
      }
  },[]);
 
    const { hideLogoText } = props;
    return (
      <div className="main-navbar">
        <Navbar
          className="align-items-stretch bg-white flex-md-nowrap border-bottom p-0"
          type="light"
        >
          <NavbarBrand
            className="w-100 mr-0"
           
            style={{ lineHeight: "25px" }}

          >
            <div className="d-table m-auto">
              
              {!hideLogoText && (
                <span className="d-md-inline ml-1 navlabel">
                  {ADMIN_PAGE_HEADER}
                  {/* {this.state.heading} */}
                  {/* OPD */}
                </span>
              )}
              
            </div>
          </NavbarBrand>
          {/* eslint-disable-next-line */}
          <img src="Manaswini_Logo.jpg" className="px-2 d-none d-md-block"></img>

          {/* <a
            className="toggle-sidebar d-sm-inline d-md-none d-lg-none"
            onClick={handleToggleSidebar}
          >
            <span class='material-icons'>menu</span>
          </a> */}
        </Navbar>
      </div>
    );
  }


SidebarMainNavbar.propTypes = {
  /**
   * Whether to hide the logo text, or not.
   */
  hideLogoText: PropTypes.bool
};

SidebarMainNavbar.defaultProps = {
  hideLogoText: false
};

export default SidebarMainNavbar;
