import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import doctorActions from "../../../core/actions/doctorActions";
import Components from "../insurance/index";
import { patientActions } from "../../../core/actions";
import {
  ListGroup,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from "shards-react";
import { insuranceActions } from '../../../core/actions';

function InsuranceMasterGrid(props) {
  let ids;
  const history = useHistory();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);

  const [insuranceForm, setInsuranceData] = useState(false);
  const [insuranceRowData, setinsuranceRowData] = useState({});
  //   const [setinsuranceRow, setinsuranceRowData] = useState({});

  const [insuranceRowlist, setRowData] = useState();

  const dispatch = useDispatch();

  const [doctorList, setdoctorList] = useState({
    doctors: "",
  });

  const [confirm, setConfirm] = useState(false);

  const [columnDefs] = useState([
    {
      headerName: "Company Name ",
      field: "companyName",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Contact 1",
      field: "phone1",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    {
      headerName: "Contact 2",
      field: "phone2",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Description.",
      field: "description",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },

    {
      headerName: "Actions",
      field: "Actions",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      width: 150,
      cellRenderer: "BtnCellRenderer",
    },
  ]);

  const closeModal = (event) => {
    setInsuranceData(!insuranceForm);
  };

  const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    return formatteddate;
  };

  const getFormatedReqtDate = (date) => {
    const reqtdate = new Date(date);
    // const apphours=new Date(date);
    //  const appminites= new Date(date);
    let formatedreqtdate = format(reqtdate, "MMM dd yyyy");
    //  let formatedappdate= format(appdate, "MMM dd yyyy",apphours,"HH",appminites,"MM" );
    return formatedreqtdate;
  };
  const getFormatedApptDate = (date) => {
    const apptdate = new Date(date);
    let formatedapptdate = format(apptdate, "MMM dd yyyy");
    return formatedapptdate;
  };

  const visitsList = useSelector(
    (state) => state.patientDetailsState.visitsList
  );
  console.log("visitsList", visitsList);

  const doctorlist = (data) => {
    dispatch(
      doctorActions.getDoctorsBasednId(data, (resCb) => {
        if (resCb) {
          setdoctorList(resCb);
          doctorList.doctors = resCb;
          console.log("list doctor Response:", doctorList);
        }
      })
    );
  };
  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  };
  const getAggriddata = (e) => {
    console.log("props", props);
    console.log("getAggriddata", e.data);

    if (e.colDef.field == "Actions") {
      setConfirm(!confirm);
      console.log("delete rowdata:", e.data);
      setTimeout(() => {
        setinsuranceRowData(e.data);

      }, 1000);
    } else {
      history.push("/insurancemaster", {
        rowData: e.data,
      });
    }

  
  };

  useEffect(() => {
    dispatch(patientActions.setPageHeader("insurance"));

    let data = {};
    console.log("props are:", props.insurancemaster);

    if (props && props.insurancemaster && props.insurancemaster.length > 0) {
      let pslist = props.insurancemaster;
      for (let item of pslist) {
        setRowData(pslist);
      }
    }
  }, [props]);

  const handleSubmit = (event) => {
    console.log("event", event);
    dispatch(
      insuranceActions.deleteInsurance(insuranceRowData, (resCb) => {
        if (resCb) {
          console.log("deleteTpa", resCb);
          // props.history.push("/tpamasterlist");
          props.onGridAction();
          setConfirm(!confirm);
        }
      })
    );
  };
  
  const toggle = (event) => {
    setConfirm(!confirm);
  };

  const rowStyle = { background: "white" };

  return (
    <>
      {/* <h5 className="page-title my-2 mx-3">Doctors</h5> */}
      <div>
        <div className="p-0 preview">
          <div
            className="ag-theme-balham ag-style card rounded table mb-0 tcol"
            style={{ height: 500, width: `98%` }}
          >
            <AgGridReact
              frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              //   context={context}
              rowData={insuranceRowlist}
              columnDefs={columnDefs}
              // onRowClicked={(e) => getAggriddata(e)}
              rowStyle={rowStyle}
              //   getRowStyle={getRowStyle}
              onCellClicked={(e) => {
                console.log("event onCellclicked::", e);
                const field = e.colDef.field;
                const colIndex = e.columnApi
                  .getAllColumns()
                  ?.findIndex((col) => col.getColDef().field === field);

                console.log("cell clicked ", field, colIndex);
                getAggriddata(e);
              }}
            ></AgGridReact>
          </div>

          {confirm && (
            <div>
              {/* <Components.Confirmation onModalClose={(event) => closeModal(event)} /> */}
              <Modal
                className="d-flex w-95 h-90 flex-column-reverse "
                id="doctor-modal"
                open={onmodal}
                toggle={toggle}
              >
                <div>
                  <ModalHeader>
                   Confirm Operation
                    <span
                      className="close-model"
                      onClick={(event) => toggle(event)}
                    >
                      X
                    </span>
                  </ModalHeader>
                  <ModalBody>
                  <div className="confirmationheader">
              <h6>This operation cannot be undo! Do you want to continue? </h6>
            </div>
                    <main className="mainsection">
                      <div className="buttonsetconfirm">
                        <Button
                          className="btn-danger"
                          type="submit"
                          onClick={(event) => toggle(event)}
                        >
                          close
                        </Button>
                        <Button
                          type="submit"
                          onClick={(event) => handleSubmit(event)}
                        >
                          delete
                        </Button>
                      </div>
                    </main>
                  </ModalBody>
                </div>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default InsuranceMasterGrid;
