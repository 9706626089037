import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import BtnCellRenderer from '../common/BtnCellRenderer';
import { doctorActions, patientActions } from '../../core/actions';
import PatientListing from './PatientListing';
import Select from 'react-select';
import Components from "./index";
import { format } from "date-fns";
import { DatePicker } from 'shards-react';
import { DateTimePicker } from 'react-datetime-picker';
import "./"
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Alert,
    Button, Card, CardBody
} from "shards-react";
import {DateTimePickerComponent} from '@syncfusion/ej2-react-calendars';
import * as ReactDOM from 'react-dom';
var showReqAppoinment = "";

function GiveAppointment(props){
    
    const [requestedtime, setRequestedtime] = useState({
        time:""
    });

    const [appointeddoc, setAppointeddoc]= useState({
      counselor: ""
    })
    const[appointmentDetail, setappointmentDetail] = useState({
        reqtime: "",
        reqtdate:"",
        reqthours:"",
        reqtminutes:"",
        apptdate: "",
        appthours:"",
        apptminutes:"",
        type: "Doctor",
        counselor: "",
        patientId: "",
        visitId: "",
        vaId:"",
        v:"",
        va:"",
      })

    const[visitdetail, setVisitdetail] = useState({
// patientId, udt, visitDate, code, URL, status, start_time, end_time, response, mid, mpassword, reqtime, type, cdt, appointment
      patientId:"",
      reqtime: "",
      reqtdate:"",
      reqthours:"",
      reqtminutes:"",
      counselor: "",
      apptdate: "",
      appthours:"",
      apptminutes:"",
      type: "Doctor",
      vaId:"",
      status:"",
      reId:"",
    })

    let selectedDate = new Date();
    const dispatch = useDispatch();
    const[doctorList, setdoctorList] = useState({
        doctors:""
    });

    const [status, setStatus] = useState(undefined);

    const [docselect, setDocList] = useState('')
  
    const doctorlist = (data) =>{
      dispatch(doctorActions.getDoctorList(data,resCb=>{
        if(resCb){
          setdoctorList(resCb)
          doctorList.doctors=resCb;
            console.log("list doctor Response:", doctorList)
          }
      }))
    }

    const handleDeptChange = (event) =>{  
      if(props.patientData.typeFlag == 'bookvisit'){
        var docState = visitdetail;
        if(event!=null){
        docState['counselor'] = event.id;
        visitdetail.counselor=event.id;
        }
        console.log("D-appointmentdetails",visitdetail)
      }else{
        var docState = appointmentDetail;
        if(event!=null){
        docState['counselor'] = event.id;
        appointmentDetail.counselor=event.id;
        }
        console.log("D-appointmentdetails",appointmentDetail)
      }    
    }

    const[pDetails, setPDetails] = useState({
      reqtime: "",
      reqtdate:"",
      reqthours:"",
      reqtminutes:"",
      type: "Doctor",
      counselor: "",
      patientId: "",
      visitId: "",
      vaId:"",
      v:"",
      va:{},
      Patient: {}
    })

    const formatdatentime = (date) => {
      const reqdate = new Date(date);
      let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
        setRequestedtime({
            time : formatteddate
        })
        console.log("requestedtime :",requestedtime.time);
        return formatteddate;
    }

    const formatdatetime = (date) => {
      const apptdate = new Date(date);
      let formatteddate = format(apptdate, "MMM dd yyyy");
       
        return formatteddate;
    }
    const[flag, setFlag] = useState({ })

    useEffect(() => {
        let check = "";
        let patientVisit= props.patientData;
        // setFlag(props.patientData.typeFlag);
        console.log("give appointment ",patientVisit);
        if(props.patientData.typeFlag == 'bookvisit'){
          console.log('patientVisit',patientVisit)
          setFlag(props.patientData.typeFlag);
          visitdetail.patientId = patientVisit.id;
          visitdetail.reqtime = patientVisit.revisit.scheduledTime;
          visitdetail.status = "booked";
          visitdetail.reId = patientVisit.revisit.id;
          formatdatetime(patientVisit.revisit.scheduledTime);
        }else{
          console.log("props.patientData",props.patientData.typeFlag);
          console.log('patientVisit',patientVisit)
          pDetails.visitId = patientVisit.visit.id;
          pDetails.v = patientVisit.visit;

          
          console.log('appointeddoctor',appointmentDetail.counselor)

          appointmentDetail.patientId = patientVisit.id;
          console.log('pid',appointmentDetail.patientId)
          appointmentDetail.visitId = patientVisit.visit.id;
          appointmentDetail.v = patientVisit.visit;
          // appointmentDetail.v.appointment()
          console.log('vid',appointmentDetail.visitId)
          for (let va of patientVisit.visitAssignmentList){
              if("Doctor" === va.type) {
                  pDetails.vaId = va.id;
                  pDetails.va = va;
                  appointmentDetail.va = va;
                  appointmentDetail.vaId=va.id;
                  appointmentDetail.counselor=va.targetid;
                  appointmentDetail.type = "";
                  check=false;
                  break
              }   
          }  
          if(check != 'false'){
              appointmentDetail.va = patientVisit.visitAssignment;
          }
          console.log('vaid',appointmentDetail.vaId)
          formatdatetime(patientVisit.visit.apptdate);
          console.log("appointmentDetail n : ",appointmentDetail);
          console.log("utc date requestedtime.time : ", requestedtime.time)
        }
    },[])

    const submit = () => {  
      if(props.patientData.typeFlag == 'bookvisit'){
        dispatch(patientActions.reAddVisit(visitdetail,(resCb) => {
          if(resCb){
              setStatus({ type: 'success' });
              props.onModalClose()
          }
        })); 
      }else{
        let type = '';
        dispatch(patientActions.addAppointment(appointmentDetail,type,(resCb) => {
          if(resCb){
              setStatus({ type: 'success' });
              props.onModalClose()
          }
        }));
      }  
    }

    const closeModal = () => {
        props.onModalClose()
    }
      
    const handleInputChange = (name, value) => {
     // console.log("In handleinput change",name, value);
      if(props.patientData.typeFlag == 'bookvisit'){
        console.log("In handleinput change",name, value);
        var pDetail = visitdetail;
        pDetail[name] = value;
        console.log("pDetail[name] change",pDetail[name], value);
        setVisitdetail({...pDetail});
      }else{
        console.log("In handleinput change",name, value);
        var pDetail = appointmentDetail;
        pDetail[name] = value;
        console.log("pDetail[name] change",pDetail[name], value);
        setappointmentDetail({...pDetail});
      }  
    }
    console.log("slected date value : ", selectedDate)  


    function sort(doctorList){
      {doctorList.map((post, index) => {
        <div key={index}>
          <p>{post}</p>
        </div>
      })}
    }

    return( <>
        <div className="nav-wrapper modalmn preview" >
          <div className='col d-flex ag-style'>
            <div className="nav mb-12 col-sm-12 col-md-6 col-lg-6 pres-modal-main modal-main border-rad-bottom schedule-appnt">
              <Col className='py-3'>
                <div className='col-md-12 border-bottom'>
                  <div className = 'w-100 d-flex justify-content-between align-items-start'>
                    {flag=='bookvisit' ? (
                      <span className='my-2 h6'>Re-Book appointment to {props.patientData.fname + ' '+ props.patientData.mname + ' ' + props.patientData.lname} </span>
                    ) : (
                      <span className='my-2 h6'>Schedule appointment to {props.patientData.fname + ' '+ props.patientData.mname + ' ' + props.patientData.lname} </span>
                    )}     
                    <span className="topright d-flex justify-content-end"  onClick={(event) => closeModal(event)}>X</span>
                  </div>
                </div>
                  <div className='d-flex align-items-center form-group col-md-12 my-2'>
                    <Form className='w-100'>
                      <Row form className='d-block'>
                        <div className=' flex-wrap: wrap justify-content: center'>
                          <span className='col-md-5' ><label>Requested Time : {props.patientData.reqtdt} {props.patientData.scheduleddate}</label><br></br></span>                                                
                          <div className = 'col-md-12'> 
                            <div className='appdate d-flex w-100 align-items-center justify-content-start'>
                              <label>Counselor: </label>
                              <div className='px-4 py-3 w-100'>

                              <Components.SearchDocList docInfo={appointmentDetail} from="patientList" parentCallBack={(event) => handleDeptChange(event)}   className="mb-2"/>
                              </div>

                            </div>
                            {flag=='bookvisit' ? (
                            <div className='mt-2rem appdate d-flex align-items-center'>
                                <label>Re-Appointment </label>
                                <div className='px-3 py-3 w-60'>
                                  <input name="Re_Appointment"
                                    id="Re_Appointment" className="form-control filterin Quantity"
                                    placeholder="Enter Re-Appointment" 
                                    required
                                    type="date"
                                  //  min={'00:00'}
                                  //  step="900"
                                    onChange={(event) => {handleInputChange("apptdate",event.target.value);}}
                                  />  
                                </div>
                                <div className='px-3 py-3 w-40'>
                                  <select name="Reappointmenthours" className="form-control filterin Quantity" onChange={(event) => {handleInputChange("appthours",event.target.value);}} >
                                    <option value= " " > </option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  </select>
                                </div>
                                <div className='px-3 py-3 w-40'>
                                  <select  className="form-control filterin Quantity" onChange={(event) => {handleInputChange("apptminutes",event.target.value);}}>
                                    <option value= "" > </option>
                                  <option value="00">00</option>
                                  <option value="15">15</option>
                                  <option value="30">30</option>
                                  <option value="45">45</option>
                                  </select>
                          </div> 
                                
                            </div> 
                            ) : (
                              <div className='mt-2rem appdate d-flex align-items-center'>
                                <label>Appointment </label>
                                <div className='px-3 py-3 w-60'>
                                  <input name="Appointment"
                                    id="Appointment" className="form-control filterin Quantity"
                                    placeholder="Enter Appointment" 
                                    required
                                    type="date"
                                   // defaultValue={props.patientData.appointment}
                                   // min={'00:00'}
                                   // step="900"
                                   defaultValue={props.patientData.apptdate}
                                  // defaultValue={appointmentDetail.appdate}
                                    onChange={(event) => {handleInputChange("apptdate",event.target.value);}} 
                                  /> 
                                </div>
                  
                                <div className='px-3 py-3 w-40'>
                                  <select name="appointmenthours" className="form-control filterin Quantity"  onChange={(event) => {handleInputChange("appthours",event.target.value);}} >
                                  <option value={props.patientData.appthours} selected>{props.patientData.appthours} </option>
                                  <option value="8">8</option>
                                  <option value="9">9</option>
                                  <option value="10">10</option>
                                  <option value="11">11</option>
                                  <option value="12">12</option>
                                  <option value="13">13</option>
                                  <option value="14">14</option>
                                  <option value="15">15</option>
                                  <option value="16">16</option>
                                  <option value="17">17</option>
                                  <option value="18">18</option>
                                  <option value="19">19</option>
                                  <option value="20">20</option>
                                  </select>
                                </div>
                            
                          
                          <div className='px-3 py-3 w-40'>
                                  <select className="form-control filterin Quantity"  onChange={(event) => {handleInputChange("apptminutes",event.target.value);}}>
                                    <option value={props.patientData.apptminutes} selected>{props.patientData.apptminutes} </option>
                                  <option value="00">00</option>
                                  <option value="15">15</option>
                                  <option value="30">30</option>
                                  <option value="45">45</option>
                                  </select>
                          </div> 
                              </div>
                            )}
                          </div>
                          <div className="modal-footer border-top-0 ng9-footer bordernull">
                            <button type="button" className="btn btn-primary " onClick={(event) => submit(event)}>Save</button>
                          </div>
                        </div>
                      </Row>   
                    </Form>
                  </div>
              </Col>
            </div>
          </div>
        </div>

      </>
    );
}

export default GiveAppointment;
