import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import Components from "./index";
import { bedActions } from "../../core/actions";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormInput,CardHeader
} from "shards-react";
import { helperServices } from "../../shared/helper";
import PageTitle from "../common/PageTitle";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';

function BedList() {
  const[bedsList, setBedsList] = useState({})
  const[bedDetail, setBedDetail] = useState({basicinfo: {
    category: "",
    code: "",
    effectiveFrom: "",
    id: "",
    rate: "",
    type: "",
    udt: "",
  }})
  const[showBedDetail, setShowBedDetail] = useState(false)
  const[showCreateBed, setShowCreateBed] = useState(false)
  const[searchError, setSearchError] = useState('')
  const[searchText, setSearchText] = useState('')
  const[bed, setBed] = useState({
    page:1,
    pageSize:50,
    searchText: '',
  })
  const [pagination,setPagination] = useState(true)
  const [paginationPageSize,setPaginationPageSize] = useState(10)
  const dispatch = useDispatch();
  

  useEffect(() => {
    listAllBeds()
  },[])
  
  const listAllBeds = () => {
      //Calling Api for listing all beds
    dispatch(bedActions.getAllBedInfo(bed,(resCb) => {
        console.log("All Bed Details - Response", resCb);
        if (resCb) {
          console.log("All Bed Details - Response", bedDetails);
           if (resCb) {
            let bedDetailList = resCb;
            setBedsList(resCb);
            setTimeout(() =>{
              setBedInfo()
            },500)
            /* Object.keys(bedDetailList).forEach((key) => {
                if (!bedDetailList[key]) {
                    bedDetailList[key] = "";
                }
            });
             console.log("#bedDetailList", bedDetailList);
            setBedsList(bedDetailList); */
          } 
        }
        console.log("#bedsList", bedsList);
    }));
    console.log("#Props", bedDetails);
  }

  const setBedInfo = () => {
    for(let info of bedDetails){
      info['type'] = 'beddetails'
    }
    console.log("#bedsList", bedDetails);
  }

  const bedDetails = useSelector(state => state.bedDetailsState.bedDetails)
  
  const handleEditClick = (bDetail) => {
    setBedDetail(bDetail);
    setShowBedDetail(true);
  }

  const toggleModal = (event) => {
    setShowBedDetail(false);
    listAllBeds()
  }

  const toggleAddModal = (event) => {
    event.preventDefault()
    setShowCreateBed(false);
    listAllBeds()
    event.preventDefault();
  }

  const handleAddNewBedClick = () => {
    setShowCreateBed(true);
  }

  const handleInputChange = (value) => {
    if (value.length < 3) {
      setSearchText(value);
      setSearchError("Search Text should contain atleast 3 letters");
      
    } else {
      setSearchError("");
      setSearchText(value);
      handleSearch(value);
    }
  }

  const handleSearch = (value) => {
    let bedState = bed;
    bedState['searchText'] = value;
    setBed(bedState)
    listAllBeds()
  }

  const handleRendererCall = (props) =>{
    console.log('props',props.bedDetailList)
    handleEditClick(props.bedDetailList)
  }

  const context = {componentParent: (props) => handleRendererCall(props)};

    return (
      <main className="mainsection">
        {/* Page Header */}
        
        <Row noGutters className="page-header">
          <PageTitle title="List of Beds" 
            // subtitle="Dashboard" 
            className="text-sm-left mb-3" />
            <Button
              type="submit"
              className="floatingbtn"
              onClick={(event) => handleAddNewBedClick()}
            >
              Add New Bed
            </Button>
        </Row>

        <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend  mb-2">
              <span className="input-group-text">
                <i style={{color:`#111b21`}} className="material-icons">search</i>
              </span>
            </div>
            <FormInput
              className="navbar-search mb-2"
              placeholder="Search Beds..."
              value={searchText}
              type="search"
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </div>
        </form>
        
        {searchError && (
          <div style={{ color: "#EC4147" }}>
            <p>
              <span>{searchError}</span>
            </p>
          </div>
        )}
        
        {/* Default Light Table */}
        <Row>
        
          <Col>
            <Card small className="mb-4">
              <CardHeader className="border-bottom">
                <h6 className="m-0">Beds</h6>
              </CardHeader>
              <div className="ag-theme-balham" style={{height: 400, width: `100%`}}>
                  <AgGridReact 
                  frameworkComponents={{
                    BtnCellRenderer: BtnCellRenderer,
                  }}
                  
                  pagination={pagination} paginationPageSize={paginationPageSize}
                    rowData={bedDetails}
                     context = {context}
                  >
                     <AgGridColumn headerName='Code' field="basicinfo.code" sortable={ true } filter={ true } resizable={ true }
                     width={100} floatingFilter={true} ></AgGridColumn>
                    <AgGridColumn headerName='Type' field="basicinfo.type" sortable={ true } filter={ true } flex={1} resizable={ true }
                     floatingFilter={true} ></AgGridColumn> 
                    <AgGridColumn headerName='Rate' field="basicinfo.rate" sortable={ true } filter={ true }  flex={1} resizable={ true }
                     floatingFilter={true} ></AgGridColumn>
                    <AgGridColumn headerName='Effective From' field="basicinfo.effectiveFrom" sortable={ true } filter={ true }  flex={1} resizable={ true }
                     floatingFilter={true} ></AgGridColumn>
                    <AgGridColumn field="Actions" sortable={ true } filter={ true }  flex={1} resizable={ true }
                     width={150}
                    cellRenderer="BtnCellRenderer"
                    
                >
                     
                    </AgGridColumn>
                  </AgGridReact>
                </div>
             
           {/*    <CardBody className="p-0 pb-3"> */}
              
                {/* <table className="table mb-0">
                  <thead className="bg-light">
                    <tr>
                      <th scope="col" className="border-0">
                        #
                      </th>
                      <th scope="col" className="border-0">
                        Code
                      </th>
                      <th scope="col" className="border-0">
                        Type
                      </th>
                      <th scope="col" className="border-0">
                        Rate
                      </th>
                      <th scope="col" className="border-0">
                        Effective From
                      </th>
                      <th scope="col" className="border-0">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {bedDetails && bedDetails.length > 0 &&
                      bedDetails.map((bedDetail, idx) => (
                      <tr key={idx}>
                        <td>{idx+1}</td>
                        <td>{bedDetail.basicinfo.code}</td>
                        <td>{bedDetail.basicinfo.type}</td>
                        <td>{bedDetail.basicinfo.rate}</td>
                        <td>{helperServices.getDateFromTimestamp(bedDetail.basicinfo.effectiveFrom )}</td>
                        <td className="cpointer">
                          <i className="material-icons"
                             onClick={(event) => handleEditClick(bedDetail)}
                          >edit</i>&nbsp;&nbsp;
                          <i className="material-icons">delete</i>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table> */}
             {/*  </CardBody> */}
            </Card>
          </Col>
        </Row>
       
        {showBedDetail === true ? (
          <Components.BedForm
            bedDetailList={bedDetail}
            onModalClose={(event) => toggleModal(event)}
          />
        ) : (
          ""
        )}

        {showCreateBed === true ? (
          <Components.BedAddForm
            onAddModalClose={(event) => toggleAddModal(event)}
          />
        ) : (
          ""
        )}
      </main>
    );
}

/* function mapStateToProps(state) {
  return {
    bedDetails: state.bedDetailsState.bedDetails,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAllBedInfo:(searchText, resCb) => dispatch(bedActions.getAllBedInfo(searchText, resCb)),
  };
}

const BedListWithProps = connect(mapStateToProps, mapDispatchToProps)(BedList); */

export default BedList;
