import React, { useState, useEffect, Component } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { loginActions } from "../../core/actions";
import {sideBarNavItemsServices} from "../../data/index";
import { Row, Col, Form, FormInput, Button } from "shards-react";

function Login(props) {

  const [loginId, setLoginId] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [user, setUser] = useState({
    loginId: "",
    loginPassword: "",
  });
  const [formErrors, setFormErrors] = useState({
    username: "",
    password: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [response, setResponse] = useState('');
  const [responseCodeMsgHashMap, setResponseCodeMsgHashMap] = useState([]);
  const [sidebarNavItems, setSidebarNavItems] = useState(sideBarNavItemsServices.getSideBarNavItems());
  const dispatch = useDispatch();


  const handleInputChange = (name, value) => {
   /*  if(name == 'loginPassword'){

    }
    if(name == 'loginId'){

    } */
    let data = user
    data[name] = value
    setUser({...data})
    //this.setState({ [name]: value });
  }

  const handleSubmit = (event) => {
    console.log("In handle submit of login page");
    setSubmitted(true);
    Object.keys(user).forEach((key) => {
      validateField(key, user[key]);
    });

    validateForm(event);
  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let userInfo = user;
    // var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

    switch (fieldName) {
      case "loginId":
        fieldValidationErrors.username =
          value !== "" ? "" : "Please enter valid value";
        userInfo.loginId = value !== "" ? value : "";

        break;
      case "loginPassword":
        fieldValidationErrors.password =
          value !== "" ? "" : "Please enter valid value";
        userInfo.loginPassword = value !== "" ? value : "";
        break;
      default:
        break;
    }

    setFormErrors(fieldValidationErrors);
  }

  const validateForm = (event) => {
    setResponse("");
    let isServerError = true;
    let formValid =
      formErrors.username === "" &&
      formErrors.password === "";
    console.log("Is login form valid : ", formValid);
    if (formValid) {
      dispatch(loginActions.login(user, (resCb) => {
        event.preventDefault();

        //this.setState({response :resCb.ERROR_CODE})
        if (resCb.type === "Success") {
          isServerError = false;
          sessionStorage.setItem("token", resCb.token);
          sessionStorage.setItem("loginId", resCb.loginId);
          //Put roles in session
          if (resCb.role && resCb.role.length > 0) {
            processRole(resCb.role);
          }
          var role = JSON.parse(sessionStorage.getItem("role"));
          console.log("Role from session : ", role);
          if (role) {
            const roles = role;
            // role.split(",");
            console.log("roles =",roles );
            if (roles && roles.length > 0) {
              if (roles.indexOf("Admin") !== -1) {
                props.history.push("/Home");
                //props.history.push("/Bed");
              } else if (roles.indexOf("doctor") !== -1) {
                props.history.push("/patient");
                //props.history.push("/Dashboard");
              } else if (roles.indexOf("serviceDesk") !== -1) {
                props.history.push("/PatientSections");
                //props.history.push("/Home");
              } else if (roles.indexOf("floornurse") !== -1) {
                props.history.push("/patient");
                //props.history.push("/Home");
              } else if (roles.indexOf("InsuranceDesk") !== -1) {
                props.history.push("/Home");  
              }
              else if (roles.indexOf("TokenManager") !== -1) {
                props.history.push("/TokenManagement");  
              }
              else if (roles.indexOf("Admin2") !== -1) {
                props.history.push("/Home");  
              }
              else if (roles.indexOf("FrontDesk") !== -1) {
                props.history.push("/Home");  
              }
              else if (roles.indexOf("RegistrationDesk") !== -1) {
                props.history.push("/Home");  
              }
              
            }
          }
        } else {
          console.log("Error occurred while login : ", resCb);
        }
      }));
      //    }
      if (isServerError) {
        setLoginPassword("");
        event.preventDefault();
      }
    } else {
      setLoginPassword("");
      event.preventDefault();
    }
  }

  const processRole = (roles) => {
    var userRoles = [];
    roles.forEach((role) => {
      console.log("Role : ", role.role);
      userRoles.push(role.role);
    });

    if (userRoles && userRoles.length > 0) {
      var roleSaved = userRoles.join(",");
      sessionStorage.setItem("role", JSON.stringify(roleSaved));
      console.log("Role to be saved in session : ", roleSaved);
    }
  }
  
  useEffect(() => {
    
    // setResponse("");
    // let isServerError = true;
    // let formValid =
    //   formErrors.username === "" &&
    //   formErrors.password === "";
   // console.log("login from session : ", props)
    console.log("login from session :", props.match.params)
    let code = props.match.params.code;
    
    if(code === ':code'){
      code=''
    }
    dispatch(loginActions.login( 
      code,resCb  => {
      // () 
        // resCb.preventDefault();
        // let codeData = code;
        // console.log("login from session : ", props) 
          // this.setState({response :resCb.ERROR_CODE})
        
          if (resCb && resCb.successmsg === "Success") {  
  
          //  let loadPage = helperServices.getStartUpPage(user.serverResponse.authData.role)
            // window.location.href = props.history.push(loadPage);
  
            if (resCb.role) {
              // const roles = role.split(",");
              let roles = resCb.role;
              if (roles && roles.length > 0) {
                if (roles.indexOf("Admin") !== -1) {
                  props.history.push("/Home");
                 
                } else if (roles.indexOf("Doctor") !== -1) {
                  props.history.push("/patient");
                 
                } else if (roles.indexOf("serviceDesk") !== -1) {
                  props.history.push("/Home");
                  
                } else if (roles.indexOf("Nurse") !== -1) {
                  props.history.push("/patient");
                  
                } else if (roles.indexOf("dataentry") !== -1) {
                  props.history.push("/doctorlist");
                  
                }else if (roles.indexOf("InsuranceDesk") !== -1) {
                  props.history.push("/Home"); 
                }
                else if (roles.indexOf("TokenManager") !== -1) {
                  props.history.push("/TokenDashBoard");  
                }
                else if (roles.indexOf("Admin2") !== -1)  {
                  props.history.push("/Home"); 
                }
                else if (roles.indexOf("FrontDesk") !== -1) {
                  props.history.push("/Home");  
                }
                else if (roles.indexOf("RegistrationDesk") !== -1) {
                  props.history.push("/Home");  
                }

                
              }
            }
          } else {
            console.log("Error occurred while login : ", resCb);
          }
        }
      ));


  },[])

    return (
      <>
      </>
      // <div>
      //   <div className="welcome d-flex justify-content-center flex-column">
      //     <div className="inner-wrapper mt-auto mb-auto">
      //       <div className="slide-in visible">
      //         <div className="loginDiv">
      //           <div className="Row verticalCenter">
                 
      //             <Col md="6" lg="6" sm="12" xs="12">
      //               <div className="x_panel">
      //                 <div className="x_title">
      //                   <h3>Login</h3>
      //                   <div className="clearfix"></div>
      //                 </div>
      //                 <div className="x_content">
      //                   <Form>
      //                     <Row form>
      //                       <Col className="form-group">
      //                         <label htmlFor="uname">User Name</label>
      //                         <FormInput
      //                           value={user.loginId}
      //                           placeholder="User Name"
      //                           required
      //                           autoComplete="off"
      //                           onChange={(event) => {
      //                             handleInputChange(
      //                               "loginId",
      //                               event.target.value
      //                             );
      //                           }}
      //                         />
      //                         <div style={{ color: "#EC4147" }}>
      //                           <span>{formErrors.username}</span>
      //                         </div>
      //                       </Col>
      //                     </Row>
      //                     <Row form>
      //                       <Col className="form-group">
      //                         <label htmlFor="password">Password</label>
      //                         <FormInput
      //                           type="password"
      //                           value={user.loginPassword}
      //                           placeholder="Password"
      //                           required
      //                           autoComplete="off"
      //                           onChange={(event) => {
      //                             handleInputChange(
      //                               "loginPassword",
      //                               event.target.value
      //                             );
      //                           }}
      //                         />

      //                         <div style={{ color: "#EC4147" }}>
      //                           <span>{formErrors.password}</span>
      //                         </div>
      //                       </Col>
      //                     </Row>
      //                     <div className="actionBar">
      //                     </div>
      //                   </Form>
      //                 </div>
      //               </div>
      //             </Col>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
}

/* function mapStateToProps(state) {
  return {
    // bedDetail : state.bedDetailsState.bedDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    login: (userData, resCb) => dispatch(loginActions.login(userData, resCb)),
  };
}
const HomeWithProps = connect(mapStateToProps, mapDispatchToProps)(Login);
 */
export default Login;
