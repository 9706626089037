import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { doctorActions, patientActions } from "../../../core/actions";
import Select, { createFilter } from "react-select";
import Components from "../../doctor/index";
import { format } from "date-fns";
import { DatePicker } from "shards-react";
import { DateTimePicker } from "react-datetime-picker";
import "../../doctor";
import {
  ListGroup,
  ListGroupItem,
  Row,
  FormTextarea,
  Col,
  Form,
  FormInput,
  Alert,
  Button,
  Card,
  CardBody,
} from "shards-react";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import * as ReactDOM from "react-dom";
import { filter } from "lodash";
import { addService } from "../../../core/actions/categoryActions";
import { Checkbox } from "@mui/material";

// import SearchPackageServiceList from './';
var showReqAppoinment = "";

function DoctorList(props) {
  const dispatch = useDispatch();


  const [search, SetSearch] = useState();

  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [searchText, setSearchText] = useState("");

  const [searchError, setSearchError] = useState("");
  const [showList, setShowList] = useState(false);
  const [patient, setPatient] = useState({ page: 1, pageSize: 50 });
  const [doctorSearchList, setDoctorSearchList] = useState({});
  const [doctorSearchFilterList, setDoctorSearchfilterList] = useState({});
  const [addDoctor, setDoctor] = useState(false);

  const handleServices = (service) => {
    console.log("service selected :", service);
  };
  let proc = {};

  useEffect(() => {
    let data = {};
    console.log("dr action");
    console.log("props :");
    let type = "doctor";
    dispatch(
      doctorActions.getDoctorListByType(type, (resCb) => {
        if (resCb) {
          console.log(
            "doctor search resCb name in patient list : ",
            resCb.accresp.personList
          );
          setDoctorSearchList(resCb.accresp.personList);
          setDoctorSearchfilterList(resCb.accresp.personList);
          console.log("response from api : ", resCb);
        }
      })
    );

    //setting opening modal
  }, []);
  const closeServiceModal = (event) => {
    props.onModalClose(event);
  };

  // const handleClick = (pDetail, isAddNew) => {
  //   setDoctor(!addDoctor);
  // }
  const handleClick = (pDetail, isAddNew) => {
    // setTpaMaster(!addTpaMaster);
    console.log("props.history", props);
    props.history.push("/adddoctor");
  };

  const handleInputChange = (event) => {
    let emptymsg = {};
    // setMessageDetails({message: null});
    let value = event.target.value;
    validateAndChange(value);
    // sessionStorage.setItem("searchvalue", JSON.stringify(value));
    console.log("value...", value);
  };

  const validateAndChange = (value) => {
    let nullarray = [];

    if (value.length < 3) {
      // setDoctorSearchList(nullarray);
      setSearchText(value);
      setSearchError("Search Text should contain atleast 3 letters");
      // messageDetails.messageStrip='';
      setShowMsgStrip(false);
      setDoctorSearchfilterList(doctorSearchList);
    }
    if (!value) {
      // messageDetails.messageStrip='';
      // setDoctorSearchList(nullarray);
      console.log(
        "doctorSearchList null array !value : ",
        value,
        doctorSearchList
      );
      setShowMsgStrip(false);
      setDoctorSearchfilterList(doctorSearchList);
    }
    //  else {
    if (value.length >= 3) {
      setSearchError("");
      setSearchText(value);
      // handleSearch(value);
      setShowList(true);
      let search = { patient: value };
      console.log("search input : ", value);
      searchDoctorResult(value);
    }
    // }
  };
  // const getFullName = (fname, mname, lname) => {
  //   var fullName = "";
  //   console.log("fname : ", fname);
  //   console.log("mname : ", mname);
  //   console.log("lname : ", lname);
  //   if (!fname || fname == null) {
  //     fname = "";
  //   }
  //   if (!mname || mname == null) {
  //     mname = "";
  //   }
  //   if (!lname || lname == null) {
  //     lname = "";
  //   }
  //   var pfname = fname;
  //   console.log("pfname : ", pfname);
  //   //   .toLowerCase();
  //   var pmname = mname;
  //   console.log("pmname : ", pmname);
  //   //   .toLowerCase();
  //   var plname = lname;
  //   console.log("plname : ", plname);
  //   //   .toLowerCase();
  //   if (pfname == null) {
  //     pfname = "";
  //   }
  //   if (pmname == null) {
  //     pmname = "";
  //   }
  //   if (plname == null) {
  //     plname = "";
  //   }
  //   pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1);
  //   pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1);
  //   plname = plname.charAt(0).toUpperCase() + plname.slice(1);
  //   if (fname && lname) {
  //     fullName = pfname + " " + plname;
  //     console.log("fullName : ", fullName);
  //   } else if (fname && mname) {
  //     fullName = pfname + " " + pmname;
  //     console.log("fullName : ", fullName);
  //   } else if (fname && mname && lname) {
  //     fullName = pfname + " " + pmname + " " + plname;
  //     console.log("fullName : ", fullName);
  //   } else {
  //     fullName = pfname + "      ";
  //     console.log("fullName : ", fullName);
  //   }
  //   return fullName;
  // };
  const searchDoctorResult = (data) => {
    console.log("data searched ", data);
    setMessageDetails({ message: "" });
    let filteredData = doctorSearchList.filter(e => e.first_name.toLowerCase().indexOf(data.toLowerCase()) >= 0);

    setDoctorSearchfilterList(filteredData);

    console.log("filteredData", doctorSearchFilterList);

    return doctorSearchFilterList;
    // doctorSearchFilterList=filteredData;
  };
  const closeModal = (data) => {
    //saving the form doctor data and closing the modal
    console.log("data ::", data);
    setDoctor(!addDoctor);
  };

  const callBackGrid = (data) => {
    console.log("datadata", data);
    let type="doctor";
    dispatch(
      doctorActions.getDoctorListByType(type, (resCb) => {
        if (resCb) {
          console.log("response from api : ", resCb);
          console.log(
            "doctor search resCb name in patient list : ",
            resCb.accresp.personList
          );
          setDoctorSearchList(resCb.accresp.personList);
          setDoctorSearchfilterList(resCb.accresp.personList);
        }
      })
    );
  };

  return (
    <>
      <div className="p-3"> 
       
        <div className="listTitles">

        <Row noGutters className="page-header mb-3">
          <h5
            // subtitle="Dashboard"
            className="text-sm-left mb-0"
          >
            Doctors
          </h5>
          <Button
            type="submit"
            className="floatingbtn"
            onClick={(event) => handleClick("", true)}
          >
            Add New
          </Button>
        </Row>
        </div>
        <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{ color: `#111b21` }} className="material-icons">
                  search
                </i>
              </span>
            </div>
            <FormInput
              className="navbar-search mb-2 menu-search bg-white"
              placeholder="Search Doctor..."
              value={searchText}
              type="search"
              onChange={handleInputChange}
            />
          </div>
        </form>
      {doctorSearchFilterList && doctorSearchFilterList.length>0 && 
        <Components.DoctorListGrid
        doctorSearchList={doctorSearchFilterList}
        onGridAction={(event) => callBackGrid(event)}
      />
      }
      
        {addDoctor && (
          <div>
            {/* <Components.addDoctor onModalClose={(event) => closeModal(event)} /> */}
          </div>
        )}
      </div>
    </>
  );
}
export default DoctorList;
