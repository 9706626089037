import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Form,Row,Col, FormSelect,Button,Alert } from "shards-react";
// import {DoctorDepartment} from './docDept';
import { connect } from "react-redux";
import { patientActions,doctorActions } from "../../core/actions";

function DoctorsList()  {

    const [selectedDept, setSelectedDept] = useState(null);
    const [showDoctor, setShowDoctor] = useState(false);
    const [doctors, setDoctors] = useState([]);
    const [selectedDoctor, setSelectedDoctor] = useState(null);
    const [visitinfo, setVisitinfo] = useState({patientId:''});
    const [procedure, setProcedure] = useState({patientId:'',
    visitId:'',
    customId1:'',
    categoryId:''});
    const [doctorByDeptData, setDoctorByDeptData] = useState([]);
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [selectedDoctorId, setSelectedDoctorId] = useState('');
    const [ServerRes, setServerRes] = useState(
    {success:'',error:'',}
    );
    const [count, setCount] = useState(0);
    const dispatch = useDispatch();

    const patient = useSelector(state => state.patientDetailsState.patient)


    useEffect(() =>{
        // Get All Doctors by Dept
            dispatch(doctorActions.getDoctorsByDept(resCb=>{
                console.log("Doctor By Dept",resCb)
                if(resCb){
                    if(doctorsDetail){
                        let doctorsInfo = doctorsDetail;
                        Object.keys(doctorsInfo).forEach((key)=>{
                            if(!doctorsInfo[key]){
                                doctorsInfo[key] = ""
                            }
                        })
                        setDoctorByDeptData(doctorsInfo)
                    }
                }
            }))
            console.log("Doctors Props",doctorsDetail)
        
        
    },[])

    const doctorsDetail = useSelector(state => state.doctorDetailsState.doctorsDetail)
    
    const handleDeptChange = (selectedDept) => {
        setSelectedDept(selectedDept)
        setShowDoctor(true)
        doctorsDetail && doctorsDetail.map((dept,index)=>{
            console.log("Doctors",dept.doctors)
            if(dept.departments.name === selectedDept){
                setDoctors(dept.doctors)
            }
        })
    }
    const handleDoctorChange = (selectedDoctor) => {
        setSelectedDoctor(selectedDoctor)
        setDisableSubmit(false)
        doctors.map((doctor,index) => {
            var name = doctor.fname + " " + doctor.mname + " " + doctor.lname
            if(name === selectedDoctor){
                setSelectedDoctorId(doctor.id)
            }
        })
    }
    const handleSubmit = (event) => {
        event.preventDefault()
        var ServerResVal = ServerRes;

        console.log("#@",patient)
        if(patient){
            var visitState = visitinfo
            visitState['patientId'] = patient.basicinfo.id
            setVisitinfo(visitState)
            dispatch(patientActions.addVisit(visitinfo,resCb=>{
                if(resCb){
                    console.log("Visit Rescb",resCb)
                    if(resCb.state === 'success'){
                        dispatch(patientActions.setVisitId(resCb.data.id))
                        var procedureState = procedure
                        procedureState['patientId'] = patient.basicinfo.id
                        procedureState['visitId'] = resCb.data.id
                        procedureState['categoryId'] = 7;
                        procedureState['customId1'] = selectedDoctorId;
                        setProcedure(procedureState)
                        dispatch(patientActions.addProcedure(procedure,resCb=>{
                            if(resCb){
                                console.log("Procedure Rescb",resCb)
                                if(resCb.state === 'success'){
                                    dispatch(patientActions.setProcedureId(resCb.data.id))
                                    ServerResVal.success = "Doctor Assignment Successful"
                                }
                                else if(resCb.state === 'error'){
                                    ServerResVal.error = resCb.messages.message
                                }
                                setServerRes({...ServerResVal})
                            }
                        }))
                    }
                }
            }))
        }
    }


        return (
            <Form className="mb-2">
                
                <Row form>
                    <Col md="6">
                        <label htmlFor="departments">Departments </label>
                        <FormSelect onChange={event => { handleDeptChange(event.target.value) }}>
                            {/* {DoctorDepartment.map((dept,index)=>( */}
                            {doctorsDetail && doctorsDetail.map((dept,index)=>(
                                <option value={dept.departments.name} key={index} id={dept.departments.id}>{dept.departments.name}</option>
                            ))}
                        </FormSelect>
                    </Col>
                    {showDoctor === true ? 
                    <Col md="6">
                        <label htmlFor="doctors">Doctors of {selectedDept} </label>
                        <FormSelect onChange={event => { handleDoctorChange(event.target.value,event.target.id) }}>
                            {doctors.map((doctor,index)=>(
                                <option 
                                    value={doctor.fname + " " + doctor.mname + " " + doctor.lname} 
                                    key={index} 
                                    id={doctor.id}>{doctor.fname + " " + doctor.mname + " " + doctor.lname}</option>
                            ))}
                        </FormSelect>
                    </Col>
                    :""}
                </Row>
                <br/>
                <Row form>
                    <Col>
                        <div className="fbtn mb-2">
                            <Button type="submit" 
                                onClick={event => {handleSubmit(event)}}
                                disabled={disableSubmit}>Assign Doctor</Button>  
                        </div>
                    </Col>
                </Row>
                {ServerRes.success &&
                      <Alert theme="success">{ServerRes.success}</Alert>
                }
                {ServerRes.error &&
                      <Alert theme="danger">{ServerRes.error}</Alert>
                }
            </Form>
        )
    
}

/* function mapStateToProps(state) {
    return {
        patient:state.patientDetailsState.patient,
        doctorsDetail:state.doctorDetailsState.doctorsDetail
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
        addVisit: (data, resCb) => dispatch(patientActions.addVisit(data, resCb)),
        addProcedure: (data, resCb) => dispatch(patientActions.addProcedure(data, resCb)),
        getDoctorsByDept : (resCb) => dispatch(doctorActions.getDoctorsByDept(resCb)),
        setVisitId: (val) => dispatch(patientActions.setVisitId(val)),
        setProcedureId: (val) => dispatch(patientActions.setProcedureId(val)),
    };
  }
  
  const DoctorsListWithProps = connect(mapStateToProps, mapDispatchToProps)(DoctorsList); */
  
  export default DoctorsList;
