import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from '../../core/actions';
import { GET_UPLOAD_PATH } from '../../shared/constants';
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Alert,
    Button, Card, CardBody, FormGroup, Modal,
    ModalHeader,
    ModalBody
} from "shards-react";
import Select, { createFilter } from "react-select";
import Components from './index';
//import { modalview } from 'react-ga';
import { format } from "date-fns";
import { setValidationMode } from '@jsonforms/core';
import { helperServices } from "../../shared/helper";

function InsuranceProcedureListing(props) {
    const [mode, setMode] = useState("new")
    const [selectedOption, setSelectedOption] = useState('')
    const [selectedType, setSelectedType] = useState()
    const dispatch = useDispatch();
    const [insuranceLists, setinsuranceLists] = useState()
    const [insuranceImages, setinsuranceImages] = useState([])
    const [patientInfo, setpatientInfo] = useState(
        {
            visitId: "",
            patientId: "",
            name: "",
            age: "",
            phno: "",
            id: "",
            address: "",
            adhar: "",
            age: "",
            area: "",
            bloodgroup: "",
            city: "Arkula",
            state: "Karnataka",
            lname: "",
            mname: "",
            fname: "",
            occupation: "",
            patientcode: "",
            pincode: "",
            admsnDate: "",
            type: "OutPatient",
            fileNo: "",
            srcRef: "",
            language: "",
            education: "",
            sociostatus: "",
            tokenNumber: "",
            deskNumber: "",
            comments: "",
            status: "",
            ehrid: "",
            erpCustId: "",
            altPhno: "",
            visitDate: "",
            registeredOn: "",
            regFeesPdfUrl: "",
            emrPhono: ""
        }
    )
    const [showClaimButton, setShowClaimButton] = useState(false)
    const [data, setData] = useState();
    const [showModal, setShowModal] = useState(false);

    // const[option, setOption] = useState([
    //     'Basic','UploadDoc','PriorRequest','PriorResponse','InvoiceGen','ClaimApproval','BillClear'
    // ]);


    const [viewInsurance, setviewInsurance] = useState(true);
    const [openInsuranceModal, setOpenInsuranceModal] = useState(props.addInsurance);
    const [selectedDate, setSelectedDate] = useState();
    let resCb = '';

    useEffect(() => {
        console.log("INSURANCE SECTION", props);
        console.log("INSURANCE SECTION", props.patientInfo);
        let patientInfolocal = props.patientInfo;
        let patientId = props.patientInfo.patientId;
        let visitId = props.patientInfo.visitId;
        console.log("ALL Id:", patientId, visitId);
        const keys = Object.keys(patientInfo);
        const item = Object.keys(props.patientInfo);
        var itmData = { ...patientInfolocal };

        for (let itm of item) {
            for (let key of keys) {
                if (itm === key) {
                    console.log("patientDetail : ", itmData[itm]);
                    patientInfo[key] = itmData[itm];
                }
            }
        }
        // setpatientInfo(patientInfolocal);
        setSelectedDate(props.selectedDate);

    }, [props])

    useEffect(() => {
        console.log("props in insurance procedure list : ", props)
        let patientInfolocal = props.patientInfo;
        const keys = Object.keys(patientInfo);
        const item = Object.keys(props.patientInfo);
        var itmData = { ...patientInfolocal };

        for (let itm of item) {
            for (let key of keys) {
                if (itm === key) {
                    console.log("patientDetail : ", itmData[itm]);
                    patientInfo[key] = itmData[itm];
                }
            }
        }

        setTimeout(() => {
            getInsuranceList();

        }, 1000);
        console.log("infoinsurance", patientInfo);
    }, [])

    const getInsuranceList = () => {
        console.log("props in insurance procedure list : ", patientInfo)
        dispatch(
            patientActions.getInsuranceList(patientInfo, (resCb) => {
                if (resCb) {
                    console.log("Insurance listss : ", resCb.insuranceList);
                }

                if (resCb.insuranceList) {
                    let insure = resCb.insuranceList;
                    const filteredData = insure.filter((item) => {
                        return item.type == "InvoiceGen"

                    })
                    if (filteredData && filteredData.length > 0) {
                        setShowClaimButton(true);
                    }
                    setinsuranceLists(insure);
                    console.log("lists:", insure);
                }

            })
        );
    }

    const handleChange = (event) => {
        setSelectedOption(event.target.value)
        console.log("selectedOption", event.target.value);
        console.log("SELECTEDOPTION:", selectedOption);

    }


    const handleChangeType = (event) => {
        setSelectedType(event.target.value)
        console.log("EVnn", event.target.value);
        console.log("selected", selectedType);


    }

    const insuranceButtonClick = (value, event) => {

        console.log("EVnn", value);
        setSelectedType(value)
        console.log("selected", selectedType);
        if (event) {
            setMode("edit");
        }

    }


    const closeModal = (event) => {
        setSelectedType(false)
        // props.onClose(event);
        getInsuranceList();
        setShowModal(false)

    }

    const closeInsuranceModal = (event) => {
        props.onClose(event);
    }

    const getFormattedDate = (date) => {
        if (date == '') return;
        const reqdate = new Date(date);
        let formatteddate = format(reqdate, "MMM dd yyyy");
        return formatteddate;
    }
    const toggleInsurance = () => {
        setviewInsurance(!viewInsurance);
    }

    const showImage = (data) => {
        console.log('showImage', data)
        console.log('insurancelist: ', insuranceLists)
        let lst = [];
        for (let index = 0; index < insuranceLists.length; index++) {
            if (insuranceLists[index].type == "UploadDoc") {
                console.log("index is : ", index, insuranceLists[index])
                lst.push(insuranceLists[index])

            }
            
        }
        setinsuranceImages(lst);
        for (let i = 0; i < lst.length; i++) {
            if (lst[i].uploadId == data) {
                        console.log("element : ", lst[i].uploadId, data)
                        setData(i)
                    }
            
        }
        console.log("Images to view is : ", insuranceImages, lst, data)
        setShowModal(true)
        //setpreview(true)
    }

    const handlevisitSelect = (e) => {
        // setSelectedDate(e.target.value);
        // selectedDate = e.target.value
        console.log("handleVisitSelect", e);
        props.handlevisitChange(e);
    }

    const getFormateDate = (date) => {
        console.log("date passed:", date);
        if (date == '') return;
        const reqdate = new Date(date);
        let formatedate = format(reqdate, "MMM dd yyyy");
        return formatedate;
    };


    return (<>
        <main className="">
            {/* <h5 className="">Insurance
            <span className="ml-3" onClick={toggleInsurance} style={{cursor:'pointer'}} >
                {!viewInsurance && helperServices.CheckAccessLevel("InsurancePatients") &&
                <i className="material-icons" title='Edit'>edit</i>
                }
                {viewInsurance &&
                <i className="material-icons" title='View'>visibility</i>
                }
            </span>
            </h5> */}

            {/* <Modal
                className="d-flex flex-column-reverse "
                id="closemodalopen"
                open={openInsuranceModal}
                size="lg"
            
            >
                <div  style={{minHeight:'500px'}}>
                <ModalHeader>
                Insurance
                    <span
                    className="close-model"
                    onClick={(e) => closeInsuranceModal(e)}
                    >
                    X
                    </span>
                </ModalHeader>
                <ModalBody>
                    
                </ModalBody>
                </div>
            </Modal> */}
            <Card className='p-3 my-4 mx-3'>
                <div className='position-relative insurance'>
                    <span className="d-flex align-items-baseline justify-content-between">
                        <span className="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex align-items-baseline px-0">
                            <h4 className='mb-1  pl-0'>Insurance&nbsp;</h4>
                            <p style={{ fontSize: '12px' }}> ({props.patientInfo.type}, &nbsp;{getFormattedDate(props.patientInfo.visitDate)})</p>

                        </span>
                        <span className="mb-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                            {/* <div> */}
                            {/* <span className="col-4">Select Date :</span>
                            <select
                                name="Select Date"
                                value={selectedDate}
                                className="form-control"
                                onChange={(e) => handlevisitSelect(e)}
                                >
                                {props.allVisitdates &&
                                    props.allVisitdates.map((lastVisited, index) => (
                                    <option key={index} value={lastVisited.id}>
                                        {getFormateDate(lastVisited.visitDate)}
                                    </option>
                                    ))}
                            </select> */}

                            <Components.VisitList
                                allVisitdates={props.allVisitdates}
                                selectedDate={props.selectedDate}
                                handlevisitChange={(e) => handlevisitSelect(e)}

                            />
                            {/* </div> */}
                        </span>
                    </span>

                    {insuranceLists && insuranceLists.length > 0 ?
                        <div className='d-flex m-3 align-items-baseline filter-options'>
                            <i title="Filter" class="material-icons mx-3">filter_list</i>

                            <div className=' mx-3'>
                                <select value={selectedOption} name="selectType" className='w-100 form-control' onChange={(event) => { handleChange(event) }}>
                                    <option defaultValue={'selected Type'}>Select Type</option>
                                    <option value="Basic">Basic</option>
                                    <option value="UploadDoc">UploadDoc</option>
                                    <option value="Pre Auth Request">Pre Auth Request</option>
                                    <option value="Pre Auth Response">Pre Auth Response</option>
                                    <option value="InvoiceGen">Invoice</option>
                                    <option value="Claim Request">Claim Request</option>
                                    <option value="Claim Approval">Claim Approve</option>
                                    <option value="BillClear">Bill Clear</option>
                                    {/* {option.map((options,index) => (
                <option key={index} value={options}>{options}</option>
                ))} */}

                                </select>
                            </div>
                            <div className='mx-3'>
                                <input type="date" className="form-control" placeholder="select date" aria-label="Recipient's username" aria-describedby="basic-addon2"></input>
                            </div>
                        </div>
                        : ''}

                    {insuranceLists && insuranceLists.length > 0 ?
                        <div className="form-group pt-3 pb-3 mb-0">

                            {insuranceLists && insuranceLists.map((insurance, index) => (
                                <>

                                    <div className='d-flex flex-column px-4 insurance-section mb-3' >

                                        {insurance.iName && insurance.policyNum && selectedOption === '' || insurance.iName && insurance.policyNum && selectedOption === 'Basic' ?
                                            <div className='d-flex justify-content-between' onClick={(event) => insuranceButtonClick('Basic', insurance)}>

                                                <div>
                                                    <p>Policy held by {insurance.iName} with Policy No:{insurance.policyNum}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>

                                            </div>
                                            : ''}



                                        {insurance.uploadId && selectedOption === '' || insurance.uploadId && selectedOption === 'UploadDoc' ?
                                            <div className='d-flex justify-content-between'>

                                                <div>
                                                    <img src={GET_UPLOAD_PATH + insurance.imgurl} className='pres-img' style={{ cursor: `pointer` }} onClick={(event) => showImage(insurance.uploadId)}></img>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>

                                            </div>
                                            : ''}



                                        {insurance.type === 'Pre Auth Request' && selectedOption === '' || insurance.type === 'Pre Auth Request' && selectedOption === 'Pre Auth Request' ?
                                            <div className='d-flex justify-content-between' onClick={(event) => insuranceButtonClick('Pre Auth Request', insurance)}>

                                                <div>
                                                    <p>Pre Auth Request : {insurance.description} for amount {insurance.amount}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>
                                            </div>
                                            : ''}


                                        {insurance.type === 'Pre Auth Response' && selectedOption === '' || insurance.type === 'Pre Auth Response' && selectedOption === 'Pre Auth Response' ?
                                            <div className='d-flex justify-content-between' onClick={(event) => insuranceButtonClick('Pre Auth Response', insurance)}>
                                                <div>
                                                    <p>Pre Auth Response : {insurance.description} for amount {insurance.amount} {insurance.status}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>
                                            </div>
                                            : ''}


                                        {insurance.type === 'Claim Approval' && selectedOption === '' || insurance.type === 'Claim Approval' && selectedOption === 'Claim Approval' ?
                                            <div className='d-flex justify-content-between' onClick={(event) => insuranceButtonClick('Claim Approval', insurance)}>
                                                <div>
                                                    <p>{insurance.type} : {insurance.description} for amount {insurance.amount} with {insurance.status}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>
                                            </div>
                                            : ''}

                                        {insurance.type === 'Claim Request' && selectedOption === '' || insurance.type === 'Claim Request' && selectedOption === 'Claim Request' ?
                                            <div className='d-flex justify-content-between' onClick={(event) => insuranceButtonClick('Claim Request', insurance)}>
                                                <div>
                                                    <p>{insurance.type} : {insurance.description} for amount {insurance.amount} with InvoiceNumber : {insurance.invoiceNum}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>
                                            </div>
                                            : ''}


                                        {insurance.type === 'BillClear' && selectedOption === '' || insurance.type === 'BillClear' && selectedOption === 'BillClear' ?
                                            <div className='d-flex justify-content-between' >

                                                <div>
                                                    <p>{insurance.type} {insurance.status}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>
                                            </div>
                                            : ''}


                                        {insurance.type === 'InvoiceGen' && selectedOption === '' || insurance.type === 'InvoiceGen' && selectedOption === 'InvoiceGen' ?
                                            <div className='d-flex justify-content-between'>
                                                <div>
                                                    <p>Invoice Number {insurance.invoiceNum} generated on {insurance.invoiceDate}</p>
                                                </div>
                                                <div>
                                                    <span className='text-muted' style={{ fontWeight: '400', fontSize: '12px' }}>{getFormattedDate(insurance.cdt)}</span>
                                                </div>
                                            </div>
                                            : ''}


                                    </div>

                                </>
                            ))}

                        </div>
                        : ''}
                    {viewInsurance &&
                        <div className="type-fields p-3 d-flex justify-content-between">
                            <div className='btn btn-primary' onClick={(event) => insuranceButtonClick('Basic')}>Basic</div>
                            <div className='btn btn-primary' onClick={(event) => insuranceButtonClick('Upload Document')}>Upload Document</div>
                            <div className='btn btn-primary' onClick={(event) => insuranceButtonClick('Pre Auth Request')}>Pre Auth Request</div>
                            <div className='btn btn-primary' onClick={(event) => insuranceButtonClick('Pre Auth Response')}>Pre Auth Response</div>
                            {showClaimButton ?
                                <>
                                    <div className='btn btn-primary' onClick={(event) => insuranceButtonClick('Claim Request')}>Claim Request</div>
                                    <div className='btn btn-primary' onClick={(event) => insuranceButtonClick('Claim Approval')}>Claim Approval</div>
                                </>
                                : ''}
                        </div>
                    }
                    {showModal ?
                        <Components.PrescriptionLayoutModel
                            data={data}
                            uploadedImages={insuranceImages}
                            onModalClose={(event) => closeModal(event)} />
                        : ''
                    }
                    {selectedType ?
                        // <div className='modalmn'>  

                        <Components.InsuranceProcedureInput
                            insurancetype={selectedType}
                            insurancelists={insuranceLists}
                            patientInfo={patientInfo}
                            mode={mode}
                            //patientProcedureList={patientProcedureList}
                            onModalClose={(event) => closeModal(event)}
                        />
                        // </div>

                        : ''}

                </div>
            </Card>


        </main>

    </>
    );

}

export default InsuranceProcedureListing;
