import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "shards-react";

import MainNavbar from "../components/layout/MainNavbar/MainNavbar";
import MainSidebar from "../components/layout/MainSidebar/MainSidebar";
import MainFooter from "../components/layout/MainFooter";
import { helperServices } from "../shared/helper";
// style={{display:`none`}}
// const [showNav, setShowNav] = useState(false)

let show = '';
var roles =''

function getrole() {
  roles= helperServices.getRole();
  console.log("roles in ");
  if(roles =="Admin"){
    // setShowNav(true);
    show = 'display:`none`';
  }
  
}

const DefaultLayout = ({ children, noNavbar, noFooter }) => (
  // getrole()
  <Container fluid className="px-0"
  
  style= {{show}}
  
  //  style={{display:`none`}}
  >
    
    <div className="d-flex">
      
      <MainSidebar/>
      <div
        className="main-content" id="mainpage" style={{cursor:'pointer'}}
        // lg={{ size: 10, offset: 2 }}
        // md={{ size: 9, offset: 3 }}
        // sm="12"
        tag="main"
      >
        {!noNavbar && <MainNavbar />}
        {children}
      </div>
    </div>
  </Container>
);

DefaultLayout.propTypes = {
  /**
   * Whether to display the navbar, or not.
   */
  noNavbar: PropTypes.bool,
  /**
   * Whether to display the footer, or not.
   */
  noFooter: PropTypes.bool
};

DefaultLayout.defaultProps = {
  noNavbar: false,
  noFooter: false
};

export default DefaultLayout;
