import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  Card,
  CardHeader,
  CardBody,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormInput,
  Alert
} from "shards-react";
import Components from './index';
import { connect } from "react-redux";
import { categoryActions } from "../../core/actions";
import PageTitle from "../common/PageTitle";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';

function ServiceMaster() {
    const[addNewServiceModalOpenClose, setAddNewServiceModalOpenClose] = useState(false)
    const[selectedOption, setSelectedOption] = useState(null)
    const[catList,setSerCatList] = useState();
    //const[services, setServices] = useState([])
    const [opdEdit, setOpdEdit] = useState(false);
    const[ipdEdit, setIpdEdit] = useState(false)
    const[OPDCharge, setOPDCharge] = useState('')
    const[NewselectedOption, setNewselectedOption] = useState(null)
    const [showTable, setShowTable] = useState(false);
    const[categories, setCategories] = useState([])
    const[service, setServices] = useState({
        categoryId:'',
        header:'',
        code :'',
        serviceName:''
    })
    const [pagination,setPagination] = useState(true)
    const [paginationPageSize,setPaginationPageSize] = useState(10)
    const[ServerRes, setServerRes] = useState({success : '', error : ''})
    const dispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => {
            console.log('....')
            getAllServiceCategory()
        }, 500);
    },[])

    const getAllServiceCategory = () => {
        dispatch(categoryActions.getAllServiceCategory((resCb)=>{
            console.log("# - Response",resCb,
            "Props",categoriesList)
            if(resCb){
                if(categoriesList){
                  let categoryInfo = categoriesList;
                  Object.keys(categoryInfo).forEach((key)=>{
                    if(!categoryInfo[key]){
                        categoryInfo[key] = ""
                    }
                  })
                  setCategories(categoryInfo)
                }
            }
        }))
    }

    const categoriesList = useSelector(state => state.categoryDetailsState.categories)
    
    const handleChange = (childSelectedOption, childServices ) =>{
        console.log("event-parent", childSelectedOption );
        setSelectedOption(childSelectedOption);
        setServices(childServices) ; 
        console.log("event-catList", categoriesList );
        for(let catList of categoriesList){
            if(catList.name === childSelectedOption){
                console.log("event-catList", catList.services );
                setSerCatList(catList.services) ; 
            }
        }
        console.log("event-catList", catList );
        setShowTable(true)
    }
    
    // This method is used to open/close the Modal for creating new service 
    const handleNewService = () => {
        setAddNewServiceModalOpenClose(!addNewServiceModalOpenClose)
    }

    const handleInputChange = (name,value) => {
        var serviceState = service;
        serviceState[name] = value;
        setServices({...serviceState}) ;
    }
    
    const handleSaveNewService = () => {
        var ServerResVal = ServerRes;
        var serviceState = service;
        categoriesList.map((category,index)=>{
            if(category.name === NewselectedOption){
                serviceState['categoryId'] = category.id
            }
        })
        setServices(serviceState) ;
        dispatch(categoryActions.addService(service,resCb=>{
            if(resCb){
                console.log("ResCb",resCb.state,resCb.message)
                if (resCb.state === "success") {
                  ServerResVal.success = resCb.message;
                } else if (resCb.state === "error") {
                  ServerResVal.error = resCb.messages.message;
                }
                setServerRes(ServerResVal);
            }
        }))
    }

    const handleOPDEdit = (opdCharge) => {
        console.log("handleOPDEdit")
        setOpdEdit(!opdEdit)
        setOPDCharge(opdCharge)
    }
    const handleEditOpd = () => {
        setOpdEdit(!opdEdit)
    }
   
    const handleOpDCharge = (value) =>{
        console.log('In handle opd charge change',value);
        setOPDCharge(value)
    }
    
    const handleSaveOPDCharge = () => {

    }
    
    const handleIPDEdit = () => {
        console.log("handleIPDEdit")
        setIpdEdit(!ipdEdit)
    }
    const handleEditIpd = () => {
        setIpdEdit(!ipdEdit)
    }
    
    const handleServiceCategory = (childSelectedOption, childServices) => {
        console.log("event-parent", childSelectedOption);
        setNewselectedOption(childSelectedOption);
        // this.setState({services:childServices}) ; 
    }

    const handleSaveIPDCharge = () => {
        
    }
    
    return (
            <main className="mainsection">
                {/* Page Header */}
                <Row noGutters className="page-header">
                <PageTitle title="Categories and Services" 
                    // subtitle="Dashboard" 
                    className="text-sm-left mb-3" />
                    <Col md="8">
                        <Button onClick={handleNewService} style={{float:`right`}}>Add New Service</Button> 
                    </Col>
                </Row>

                        {/* Code for Search based on the service category */}
                        <ListGroup flush>
                            <ListGroupItem className="p-3">
                                <Row>
                                    <Col>
                                        <Form>
                                            <Row form>
                                                <Col md="4">
                                                    <Components.Search parentCallBack={handleChange} className="mb-2"/>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </ListGroupItem>
                        </ListGroup>

                        {/* Based on service category various services listed here */}
                        {showTable === true ? 
                            <Row>
                                <Col>
                                    <Card small className="mb-4">
                                        <CardHeader className="border-bottom">
                                            <h6 className="m-0">Services</h6>
                                        </CardHeader>
                                        <CardBody className="p-0 pb-3">
                                        <div className="ag-theme-balham" style={{height: 400, width: `100%`}}>
                                            <AgGridReact 
                                            frameworkComponents={{
                                                BtnCellRenderer: BtnCellRenderer,
                                              }}
                                            pagination={pagination} paginationPageSize={paginationPageSize}
                                                rowData={catList}
                                                
                                            >
                                                <AgGridColumn field="header" sortable={ true } filter={ true } resizable={ true }
                                                width={150} floatingFilter={true} ></AgGridColumn>
                                                <AgGridColumn field="code" sortable={ true } filter={ true } flex={1} resizable={ true }
                                                floatingFilter={true} ></AgGridColumn> 
                                                <AgGridColumn field="serviceName" sortable={ true } filter={ true }  flex={1} resizable={ true }
                                                floatingFilter={true} ></AgGridColumn>
                                                <AgGridColumn field="Actions" sortable={ true } filter={ true }  flex={1} resizable={ true }
                                                width={150}  cellRenderer="BtnCellRenderer"
                                            >
                                                
                                                </AgGridColumn>
                                            </AgGridReact>
                                        </div>
                                            {/* <table className="table mb-0">
                                                <thead className="bg-light">
                                                    <tr>
                                                        <th scope="col" className="border-0">
                                                        Header
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                        Code
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                        Service Name
                                                        </th>
                                                        <th scope="col" className="border-0">
                                                        Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {categoriesList && categoriesList.length > 0 && 
                                                    categoriesList.map((serviceCategory, index) => (    
                                                        serviceCategory.name === selectedOption ? 
                                                        serviceCategory.services.map((service,idx)=>(    
                                                            <tr key={idx}>
                                                                {console.log("#",serviceCategory.services,"##",service)}
                                                                <td>{service.header}</td>
                                                                <td>{service.code}</td>
                                                                <td>{service.serviceName}</td>
                                                                <td className="cpointer">
                                                                    <i className="material-icons" title="Delete Service">delete</i>
                                                                </td>
                                                            </tr>   
                                                        ))
                                                    :''))}
                                                </tbody>
                                            </table> */}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                       
                        : ""}

                        {/* Code for Adding New Service*/}
                        <Modal 
                            open={addNewServiceModalOpenClose}
                            toggle={handleNewService}
                        >
                            <ModalHeader>Add New Service</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col className="mb-2">
                                        <Components.Search parentCallBack={handleServiceCategory} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormInput id="header"
                                        placeholder="Header" 
                                        value={service.header}
                                        onChange={(event)=>{handleInputChange("header",event.target.value)}}
                                        className="mb-2"  />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormInput id="code"
                                        placeholder="Code" 
                                        value={service.code}
                                        onChange={(event)=>{handleInputChange("code",event.target.value)}}
                                        className="mb-2" />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormInput id="servicename"
                                        placeholder="Service Name" 
                                        value={service.serviceName}
                                        onChange={(event)=>{handleInputChange("serviceName",event.target.value)}}
                                        className="mb-2" />
                                    </Col>
                                </Row>
                                {ServerRes.success &&
                                    <Alert theme="success">{ServerRes.success}</Alert>
                                }
                                {ServerRes.error &&
                                    <Alert theme="danger">{ServerRes.error}</Alert>
                                }
                            </ModalBody>
                            <ModalFooter style={{justifyContent:`space-between`}}>
                                <Button onClick={handleNewService}>Close</Button>
                                <Button onClick={handleSaveNewService}>Save</Button>
                            </ModalFooter>
                        </Modal>        

                        {/* OPD Charges Edit */}
                        <Modal
                            open={opdEdit}
                            toggle={handleEditOpd}
                        >
                            <ModalHeader>Edit OPD Charges </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col>
                                        <FormInput
                                            id="gender"
                                            value={OPDCharge}
                                            placeholder="OPD Charges"
                                            autoComplete="off"
                                            onChange={event => { handleOpDCharge(event.target.value) }}
                                        />
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                onClick={handleSaveOPDCharge}
                                style={{ float: `left` }}
                                >
                                Save
                                </Button>
                                <Button onClick={handleEditOpd}>Close</Button>
                            </ModalFooter>
                        </Modal>

                        {/* IPD Charge Edit */}
                        <Modal
                            open={ipdEdit}
                            toggle={handleEditIpd}
                        >
                            <ModalHeader>Edit IPD Charges </ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col>
                                        <FormInput placeholder="Regular" className="mb-2" required
                                            autoComplete="off"/>
                                        <FormInput placeholder="Semi Delux" className="mb-2" required
                                            autoComplete="off"/>
                                        <FormInput placeholder="Delux" className="mb-2" required
                                            autoComplete="off"/>
                                        <FormInput placeholder="ICU" className="mb-2" required
                                            autoComplete="off"/>
                                    </Col>
                                </Row>
                            </ModalBody>
                            <ModalFooter>
                                <Button
                                onClick={handleSaveIPDCharge}
                                style={{ float: `left` }}
                                >
                                Save
                                </Button>
                                <Button onClick={handleEditIpd}>Close</Button>
                            </ModalFooter>
                        </Modal>
            </main>
        )
}

/* function mapStateToProps(state) {
    return {
        categories: state.categoryDetailsState.categories
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        getAllServiceCategory : (resCb) => dispatch(categoryActions.getAllServiceCategory(resCb)),
        addService: (data, resCb) => dispatch(categoryActions.addService(data, resCb))
    };
}
  
const serviceMasterWithProps = connect(mapStateToProps, mapDispatchToProps)(serviceMaster); */
  
export default ServiceMaster;
  
