import { Key } from "@mui/icons-material";
import { range } from "lodash";

export const PATIENT_DETAILS = 'PATIENT_DETAILS';
export const PATIENT_SEARCH_FAILURE = 'PATIENT_SEARCH_FAILURE';
export const SET_PATIENT_DETAILS = 'SET_PATIENT_DETAILS';
export const PATIENT_SAVE_FAILURE = 'PATIENT_SAVE_FAILURE';
export const PATIENT_SAVE_SUCCESS = 'PATIENT_SAVE_SUCCESS';
export const PATIENT_UPDATE_FAILURE = 'PATIENT_UPDATE_FAILURE';
export const PATIENT_UPDATE_SUCCESS = 'PATIENT_UPDATE_SUCCESS';
export const PATIENT_VISIT_SAVE_SUCCESS = 'PATIENT_VISIT_SAVE_SUCCESS';
export const PATIENT_VISIT_SAVE_FAILURE = 'PATIENT_VISIT_SAVE_FAILURE';
export const VISIT_DETAILS = 'VISIT_DETAILS';
export const VISIT_DETAIL_FAILURE = 'VISIT_DETAIL_FAILURE';
export const PROCEDURE_SAVE_SUCCESS = 'PROCEDURE_SAVE_SUCCESS';
export const PROCEDURE_SAVE_FAILURE = 'PROCEDURE_SAVE_FAILURE';
export const SET_PROCEDURE_ID = 'SET_PROCEDURE_ID';
export const SET_VISIT_ID = 'SET_VISIT_ID';
export const FILE_UPLOAD_FAILURE = 'FILE_UPLOAD_FAILURE';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const COMPLAINT_SAVE_SUCCESS = 'COMPLAINT_SAVE_SUCCESS';
export const COMPLAINT_SAVE_FAILURE = 'COMPLAINT_SAVE_FAILURE';
export const REFERENCE_SAVE_SUCCESS = 'REFERENCE_SAVE_SUCCESS';
export const REFERENCE_SAVE_FAILURE = 'REFERENCE_SAVE_FAILURE';
export const EHRID = 'EHRID';
export const E_PRISCIPTION = 'E_PRISCIPTION';;
export const ADMIN_PAGE_HEADER = 'MANASWINI';
export const BED_DETAILS = 'BED_DETAILS';
export const BED_SEARCH_FAILURE = 'BED_SEARCH_FAILURE';
export const BED_SAVE_SUCCESS = 'BED_SAVE_SUCCESS';
export const BED_SAVE_FAILURE = 'BED_SAVE_FAILURE';
export const BED_UPDATE_SUCCESS = 'BED_UPDATE_SUCCESS';
export const BED_UPDATE_FAILURE = 'BED_UPDATE_FAILURE';
export const SERVICE_SAVE_FAILURE = 'SERVICE_SAVE_FAILURE';
export const SERVICE_SAVE_SUCCESS = 'SERVICE_SAVE_SUCCESS';
export const CATEGORY_DETAILS = 'CATEGORY_DETAILS';
export const SET_DOCTOR_DETAILS = 'SET_DOCTOR_DETAILS';
export const DEPT_DOCTOR_DETAILS = 'DEPT_DOCTOR_DETAILS';
export const DEPT_ROLE_DETAIL = 'DEPT_ROLE_DETAIL';
export const DEPT_DOCTOR_DETAILS_FAILURE = 'DEPT_DOCTOR_DETAILS_FAILURE';
export const SALTYPE_LIST_DETAILS = 'DEPT_DOCTOR_DETAILS_FAILURE';
export const SALTYPE_LIST_DETAILS_FAILURE = 'DEPT_DOCTOR_DETAILS_FAILURE';
export const DOCTOR_SAVE_SUCCESS = 'DOCTOR_SAVE_SUCCESS';
export const DOCTOR_SAVE_FAILURE = 'DOCTOR_SAVE_FAILURE';
export const DOCTOR_UPDATE_SUCCESS = 'DOCTOR_UPDATE_SUCCESS';
export const DOCTOR_UPDATE_FAILURE = 'DOCTOR_UPDATE_FAILURE';
export const SET_PATIENT_PROCEDURELIST = 'SET_PATIENT_PROCEDURELIST';
export const DIAGNOSIS_SAVE_FAILURE = 'DIAGNOSIS_SAVE_FAILURE';
export const DIAGNOSIS_SAVE_SUCCESS = 'DIAGNOSIS_SAVE_SUCCESS';
export const DIAGNOSIS_MASTER_SAVE_FAILURE = 'DIAGNOSIS_MASTER_SAVE_FAILURE';
export const DIAGNOSIS_MASTER_SAVE_SUCCESS = 'DIAGNOSIS_MASTER_SAVE_SUCCESS';
export const DIAGNOSIS_DETAILS_FAILURE = 'DIAGNOSIS_DETAILS_FAILURE';
export const DIAGNOSIS_DETAILS = 'DIAGNOSIS_DETAILS';
export const SET_DIAGNOSIS_DETAILS = 'SET_DIAGNOSIS_DETAILS';
export const DIAGNOSIS_MASTER_DETAILS_FAILURE = 'DIAGNOSIS_MASTER_DETAILS_FAILURE';
export const DIAGNOSIS_MASTER_DETAILS = 'DIAGNOSIS_MASTER_DETAILS';
export const FORM_DETAILS = 'FORM_DETAILS';
export const DOC_CATEGORIES = [{id:1, value:'Full Time'},{id:2, value:'Part Time'}];
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LIST_PATIENTS = 'LIST_PATIENTS';
export const LIST_PATIENTS_FAILURE = 'LIST_PATIENTS_FAILURE';
export const LIST_PATIENTS_FOR_DOCTORS = 'LIST_PATIENTS_FOR_DOCTORS';
export const LIST_PATIENTS_FOR_DOCTORS_FAILURE = 'LIST_PATIENTS_FOR_DOCTORS_FAILURE';
export const CARD_PAYMENT = 'CARD_PAYMENT';
export const UPI_PAYMENT = 'UPI_PAYMENT';
export const CASH_PAYMENT = 'CASH_PAYMENT';
export const TOKENLIST='TOKENLIST';
export const PATIENTS_INFO = 'PATIENTS_INFO';
export const PATIENTS_INFO_FAILURE = 'PATIENTS_INFO_FAILURE';
export const LIST_FLOORS = 'LIST_FLOORS';
export const PATIENTS_PROCEDURE_INFO = "PATIENTS_PROCEDURE_INFO";
export const LIST_VISTS = 'LIST_VISTS';
export const LIST_VISTS_FAILURE = 'LIST_VISTS_FAILURE';
export const LIST_REVISTS = 'LIST_REVISTS';
export const LIST_REVISTS_FAILURE = 'LIST_REVISTS_FAILURE';
export const SAVE_REVISIT = 'SAVE_REVISIT';
export const SAVE_REVISIT_FAILURE = 'SAVE_REVISIT_FAILURE';
export const UPDATE_VA = 'UPDATE_VA';
export const UPDATE_VA_FAILURE = 'UPDATE_VA_FAILURE';
export const SAVE_VISIT = 'SAVE_VISIT';
export const SAVE_VISIT_FAILURE = 'SAVE_VISIT_FAILURE';
export const SAVE_APPIONTMENT = 'SAVE_APPIONTMENT';
export const SAVE_APPIONTMENT_FAILURE = 'SAVE_APPIONTMENT_FAILURE';
export const FLOORNURSE = 'floornurse'
export const NURSE_ROLE = 'Nurse'
export const DOCTOR_ROLE = 'Doctor'
export const COUNSELLOR_ROLE = 'Counsellor'
export const PATIENTS_LAST_VISITED_INFO = 'PATIENTS_LAST_VISITED_INFO'
export const SET_UPLOAD = 'SET_UPLOAD'
export const APIKEY = 'SuhhYe61dVU0eXgIwTOuRmpwxh8xwEQLGcAc'
export const ZOOM_REVIEW_NOTES = 'ZOOM_REVIEW_NOTES';
export const ZOOM_NOTES_SAVE_SUCCESS = "ZOOM_NOTES_SAVE_SUCCESS";
export const ZOOM_NOTES_SAVE_FAILURE = "ZOOM_NOTES_SAVE_FAILURE";
export const SET_PATIENT_MEETING_INFO = "SET_PATIENT_MEETING_INFO";
export const SET_PAGE_HEADER = "SET_PAGE_HEADER";
export const DEPT_LIST_DETAILS = "DEPT_LIST_DETAILS";
export const DEPT_LIST_DETAILS_FAILURE = "DEPT_LIST_DETAILS_FAILURE";
export const FLOOR_LIST = "FLOOR_LIST";
export const FLOOR_LIST_FAILURE = "FLOOR_LIST_FAILURE";
export const REG_INVOICE = "/regInvoice/Registraion_Fees_Invoice";
//Added on 02-03-2023
export const DESK_LIST_DETAILS = "DESK_LIST_DETAILS";
export const DESK_LIST_DETAILS_FAILURE = "DESK_LIST_DETAILS_FAILURE";
//Added Ends

export const ZOOM_NOTES_UPDATE_SUCCESS = "ZOOM_NOTES_UPDATE_SUCCESS";
export const ZOOM_NOTES_UPDATE_FAILURE = "ZOOM_NOTES_UPDATE_FAILURE";
export const ZOOM_NOTES_LIST = "ZOOM_NOTES_LIST";

export const NEWPROCEDURE_SAVE_SUCCESS = 'NEWPROCEDURE_SAVE_SUCCESS';
export const NEWPROCEDURE_SAVE_FAILURE = 'NEWPROCEDURE_SAVE_FAILURE';

export const COMPOSITION_SAVE = "COMPOSITION_SAVE";
export const COMPOSITION_SAVE_FAILURE = "COMPOSITION_SAVE_FAILURE";

export const SAVE_DISPATCH = "SAVE_DISPATCH";
export const SAVE_DISPATCH_FAILURE = "SAVE_DISPATCH_FAILURE";
export const DISCHARGE_SUMMARY = "DISCHARGE_SUMMARY";

export const DISCHARGESUMMARY = "Discharge Summary";
export const TEMPLATE_DETAILS = 'TEMPLATE_DETAILS';
export const TEMPLATE_DETAILS_FAILURE = 'TEMPLATE_DETAILS_FAILURE';

export const VISIT_STATUS_OPEN = 'open';
export const VISIT_STATUS_CLOSED = 'close';
export const MEETING_STATUS_UPDATE = 'MEETING_STATUS_UPDATE';
export const MEETING_STATUS_UPDATE_FAILURE = 'MEETING_STATUS_UPDATE_FAILURE';
export const JOIN_Meeting_BUTTON_AVAILABLE_TIME_DIFF_HRS = 3;
export const GET_UPLOAD_PATH = '/uploads/Hospital/';
export const RESET = 'reset'; 
export const ROOM = 'ROOM';
export const ROOM_STATUS_AVAILABLE = 'available';
export const ROOM_STATUS_BOOKED = 'booked';

export const DASHBOARD = 'DASHBOARD';
export const PATIENT_SECTION = "PATIENT_SECTION";

export const LIST_PRESCRIPTION = 'LIST_PRESCRIPTION';
export const LIST_PRESCRIPTION_FAILURE = 'LIST_PRESCRIPTION_FAILURE';

export const GET_ICD10_DETAILS = "GET_ICD10_DETAILS";
export const GET_ICD10_DETAILS_FAILURE = "GET_ICD10_DETAILS_FAILURE";
export const GET_TEMPLATEID_DETAILS = "GET_TEMPLATEID_DETAILS";
export const GET_TEMPLATEID_DETAILS_FAILURE = "GET_TEMPLATEID_DETAILS_FAILURE";
export const GET_COMPOSITIONID_DETAILS = "GET_COMPOSITIONID_DETAILS";
export const GET_COMPOSITIONID_DETAILS_FAILURE = "GET_COMPOSITIONID_DETAILS_FAILURE";
export const MARK_FOR_DEL = "MARK_FOR_DEL";
export const MARK_FOR_DEL_FAILURE = "MARK_FOR_DEL_FAILURE";
export const TRUE = "true";
export const FALSE = "false";
export const GET_DOCTOR_CALENDER = "GET_DOCTOR_CALENDER";
export const GET_DOCTOR_CALENDER_FAILURE = "GET_DOCTOR_CALENDER_FAILURE";
export const CANCEL_DOCTOR_CALENDER = "CANCEL_DOCTOR_CALENDER";
export const CANCEL_DOCTOR_CALENDER_FAILURE = "CANCEL_DOCTOR_CALENDER_FAILURE";


export const TOKEN_DETAILS = "TOKEN_DETAILS";
export const TOKEN_GENERATE_SUCCESS="TOKEN_GENERATE_SUCCESS";
export const TOKEN_GENERATE_FAILURE="TOKEN_GENERATE_FAILURE";

export const RUNNING_TOKEN_SUCCESS="RUNNING_TOKEN_SUCCESS";
export const RUNNING_TOKEN_FAILURE="RUNNING_TOKEN_FAILURE";

export const TOKEN_RECORRECT_SUCCESS="TOKEN_RECORRECT_SUCCESS";
export const TOKEN_RECORRECT_FAILURE="TOKEN_RECORRECT_FAILURE";

export const GET_DESKDETAILS_SUCCESS="GET_DESKDETAILS_SUCCESS";
export const GET_DESKDETAILS_FAILURE="GET_DESKDETAILS_FAILURE";

export const COMPLETE_TOKEN_SUCCESS="COMPLETE_TOKEN_SUCCESS";
export const COMPLETE_TOKEN_FAILURE="COMPLETE_TOKEN_FAILURE";

export const INSURANCE_STATUS = "INSURANCE_STATUS";
export const INSURANCE_STATUS_FAILURE = "INSURANCE_STATUS_FAILURE";

export const BASIC = "Basic Updated";
export const PRE_AUTH_REQUEST = "Pre Auth Requested";
export const PRE_AUTH_RESPONSE = "Pre Auth Responsed";
export const CLAIM_REQUEST = "Claim Requested";
export const CLAIM_APPROVAL = "Claim Approved";
export const INVOICE = "Invoice Generated";
export const BILLCLEAR = "Bill Clear";
export const HOSPITAL_ADDRESS = "Arkula Campus, Kuchoor, Farangipete[P.O] Mangalore 574143, Karnataka India";
export const EMAIL_ID = "tmanaswiniarkula@gmail.com";
export const PHONE_NO = "+91 8123935190";
export const HOSPITAL_NAME = "DR. THUNGA'S MANASWINI";
export const HOSPITAL_DESC = "Center of Excellence for Mental Health Care,Training and Research";


export const APPT_MORNING_TIMINGS = [
    {
        time1:"8:00", time2:"8:15",time3:"8:30", time4:"8:45",type1:"eight_00_am",type2:"eight_15_am",type3:"eight_30_am",type4:"eight_45_am"
    },
    {
        time1:"9:00", time2:"9:15",time3:"9:30", time4:"9:45", type1:"nine_00_am",type2:"nine_15_am",type3:"nine_30_am",type4:"nine_45_am"
    },
    {
        time1:"10:00", time2:"10:15",time3:"10:30", time4:"10:45",type1:"ten_00_am",type2:"ten_15_am",type3:"ten_30_am",type4:"ten_45_am"
    },
    {
        time1:"11:00", time2:"11:15",time3:"11:30", time4:"11:45",type1:"eleven_00_am",type2:"eleven_15_am",type3:"eleven_30_am",type4:"eleven_45_am"
    },
    {
        time1:"12:00", time2:"12:15",time3:"12:30", time4:"12:45",type1:"twelve_00_pm",type2:"twelve_15_pm",type3:"twelve_30_pm",type4:"twelve_45_pm"
    },
    // {
    //     time1:"13:00", time2:"13:15",time3:"13:30", time4:"13:45"
    // },
    // {
    //     time1:"14:00", time2:"14:15",time3:"14:30", time4:"14:45"
    // },
    // {
    //     time1:"15:00", time2:"15:15",time3:"15:30", time4:"15:45"
    // },
    // {
    //     time1:"16:00", time2:"16:15",time3:"16:30", time4:"16:45"
    // },
    // {
    //     time1:"17:00", time2:"17:15",time3:"17:30", time4:"17:45"
    // },
    // {
    //     time1:"18:00", time2:"18:15",time3:"18:30", time4:"18:45"
    // },
    // {
    //     time1:"19:00", time2:"19:15",time3:"19:30", time4:"19:45"
    // },
    // {
    //     time1:"20:00", time2:"20:15",time3:"20:30", time4:"20:45"
    // },
];

export const APPT_EVENING_TIMINGS = [
    {
        time1:"13:00", time2:"13:15",time3:"13:30", time4:"13:45",type1:"thirteen_00_pm",type2:"thirteen_15_pm",type3:"thirteen_30_pm",type4:"thirteen_45_pm"
    },
    {
        time1:"14:00", time2:"14:15",time3:"14:30", time4:"14:45", type1:"fourteen_00_pm",type2:"fourteen_15_pm",type3:"fourteen_30_pm",type4:"fourteen_45_pm"
    },
    {
        time1:"15:00", time2:"15:15",time3:"15:30", time4:"15:45",type1:"fifteen_00_pm",type2:"fifteen_15_pm",type3:"fifteen_30_pm",type4:"fifteen_45_pm"
    },
    {
        time1:"16:00", time2:"16:15",time3:"16:30", time4:"16:45",type1:"sixteen_00_pm",type2:"sixteen_15_pm",type3:"sixteen_30_pm",type4:"sixteen_45_pm"
    },
    {
        time1:"17:00", time2:"17:15",time3:"17:30", time4:"17:45",type1:"seventeen_00_pm",type2:"seventeen_15_pm",type3:"seventeen_30_pm",type4:"seventeen_45_pm"
    },
    {
        time1:"18:00", time2:"18:15",time3:"18:30", time4:"18:45",type1:"eightteen_00_pm",type2:"eightteen_15_pm",type3:"eightteen_30_pm",type4:"eightteen_45_pm"
    },
    {
        time1:"19:00", time2:"19:15",time3:"19:30", time4:"19:45",type1:"nineteen_00_pm",type2:"nineteen_15_pm",type3:"nineteen_30_pm",type4:"nineteen_45_pm"
    },
    {
        time1:"20:00", time2:"20:15",time3:"20:30", time4:"20:45",type1:"twenty_00_pm",type2:"twenty_15_pm",type3:"twenty_30_pm",type4:"twenty_45_pm"
    },
]

export const DOCTOR_CALENDER_DETAILS_FAILURE = "DOCTOR_CALENDER_DETAILS_FAILURE";
export const DOCTOR_CALENDER_DETAILS = "DOCTOR_CALENDER_DETAILS";


export const INSURANCE_LIST = "INSURANCE_LIST";
export const INSURANCE_PROCEDURE_SAVE_SUCCESS = "INSURANCE_PROCEDURE_SAVE_SUCCESS";
export const INSURANCE_PROCEDURE_SAVE_FAILURE = "INSURANCE_PROCEDURE_SAVE_FAILURE";
export const INSURANCE_PROCEDURE_UPDATE_SUCCESS = "INSURANCE_PROCEDURE_UPDATE_SUCCESS";
export const INSURANCE_PROCEDURE_UPDATE_FAILURE = "INSURANCE_PROCEDURE_UPDATE_FAILURE";
export const LIST_INSURANCE_SETTLEMENTS = "LIST_INSURANCE_SETTLEMENTS";
export const LIST_INSURANCE_SETTLEMENTS_FAILURE = "LIST_INSURANCE_SETTLEMENTS_FAILURE";

export const DOC_INFO = "DOC_INFO";
export const PATIENT_VISIT_LISTS ="PATIENT_VISIT_LISTS";
export const testNames = ["Fasting Blood Sugar (FBS)", "Random Blood Sugar (RBS)", "Postprandial Blood Sugar (PPBS)", "Hb1Ac"];


export const ONBOARD_SUCCESS = "ONBOARD_SUCCESS";
export const ONBOARD_FAILURE = "ONBOARD_SUCCESS";

export const PATIENT_ID = "PATIENT_ID";
export const SERVICE_LIST ="SERVICE_LIST";

export const PRODUCT_HMS_LOCATION = 0;
export const PRODUCT_PHARMACY_LOCATION = 1;

export const VITAL_SIGNS= "Vital signs";
export const EPRESCRIPTION="ePrescription (epSoS/Contsys)";
export const DIAGNOSIS="Diagnosis";
export const DFollowupNote="docFollowup";
export const CFollowupNote="cFollowup";
export const KESSLERTEST= "kessler_test";
export const LAB_RESULT="LabResult"
export const FASTING_SUGAR_TEST = "Fasting Blood Sugar"
export const BLOODSUGARTESTS = "Blood Sugar Tests"
export const BLOODGLUCOSEPROFILE = "Blood Glucose Profile"
export const HEMATOLOGYREPORTCBCDLC = "Hematology Report-CBC-DLC"
export const HEMATOLOGYREPORTESR = "Hematology Report-ESR"
export const HEMATOLOGYREPORTAWC = "Hematology Report-Ab. Leukocyte Count"
export const CASERECORD = "caseRecord";


export const SCHEMA_OBJ = {
    
    "Diagnosis" : {
        "Problem/Diagnosis name":{
            //"ui:placeholder" :  "Identification of the problem or diagnosis, by name.",
            //"ui:placeholder" :  "isSearchable",

            //  "ui:widget":"textarea",
             "ui:widget":"hidden",
             ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
       
        "Clinical description":{
            "ui:placeholder" :  "Narrative description about the problem or diagnosis.",
            "ui:widget":"textarea",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },

        // "Body site":{
        //     "ui:placeholder" : "Identification of a simple body site for the location of the problem or diagnosis.",
        //     "ui:widget":"textarea",
        //     ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        // },
        "Cause":{
            "ui:placeholder" : "A cause, set of causes, or manner of causation of the problem or diagnosis.",
            "ui:widget":"textarea",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Date/time of onset":{
            // "ui:widget":"hidden",
            "ui:placeholder" :"Estimated or actual date/time that signs or symptoms of the problem/diagnosis were first observed.",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Date/time clinically recognised": {
            "ui:placeholder" : "Estimated or actual date/time the diagnosis or problem was recognised by a healthcare professional.",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Course description":{
            "ui:placeholder" : "Narrative description about the course of the problem or diagnosis since onset.",
            "ui:widget":"textarea",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Date/time of resolution":{
            "ui:placeholder" :"Estimated or actual date/time of resolution or remission for this problem or diagnosis, as determined by a healthcare professional.",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Comment":{
            "ui:placeholder" : "Additional narrative about the problem or diagnosis not captured in other fields.",
            "ui:widget":"textarea",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
    },

     
    "Vital signs" : {
        'ui:order':["Temperature","Pulse Rate","Systolic","Diastolic","Weight","Height/Length","Oxygen Saturation Level (SpO₂)","Respiratory Rate","Body mass index"],
        "Temperature":{
            // "ui:widget":"hidden",
            "ui:placeholder" :  "The measured temperature in Fahrenheit",
             ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
       
        "Systolic":{
            "ui:placeholder" :  "0.0..<1000.0 mm[Hg]",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Diastolic":{
            "ui:placeholder" :  "0.0..<1000.0 mm[Hg]",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },

       
        "Weight":{
            "ui:placeholder" :"0.0..<1000.0 kg",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Height/Length":{
            "ui:placeholder" : "0.0..1000.0 cm.",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        
        "Body mass index":{
            "ui:placeholder" : "0.0..<1000.0 kg/m2",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        
        "Pulse Rate": {
            "ui:placeholder" : " Pulse/Heart beat.. Units: 0.0..<1000.0 /min",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Oxygen Saturation Level (SpO₂)":{
            "ui:placeholder" : "SpO₂.. Units: 30 - 100%",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Respiratory Rate":{
            "ui:placeholder" : "Respiratory Rate.. Units: 0 - 100/min",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        
    },


    "ePrescription (epSoS/Contsys)":{
        // "Active ingredient":{
        //     "ui:placeholder" :  "Name of the medication substance.",
        //      ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        // },
       
        "Local medicinal product brand":{
              "ui:placeholder" :  "Identification of the medication, vaccine or other therapeutic item being ordered",
            //   "ui:widget":"textarea",
            "ui:required": true,
            "ui:widget":"hidden",
             ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'

    
     },
        // "Dose":{
        //     "ui:placeholder" : "Enter dosage",
        //     "ui:required": true,
        //     ["classNames"] : 'col-md-3 col-lg-3 col-sm-12 col-xs-12',
        // },
        "Dose unit":{
            "ui:title":"Dose",
            "ui:placeholder" : "Enter dosage: 20mg/ 5mL",
            "ui:required": true,
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12',
        },

        "Instructions to patient":{
            "ui:placeholder" : "M-A-E-N eg:'1-0-1-1',",
            "ui:widget":"textarea",
             "ui:required": true,
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Advice to dispenser":{
            "ui:placeholder" : "x 10 Days",
            "ui:widget":"textarea",
            "ui:required": true,
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },

        "Comment":{
            "ui:placeholder" : "For example: 'take tablet after food', B/F, AF",
            "ui:required": true,
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        }

    },

    "kessler_test":{
       "During the last 30 days, about how often did you feel tired out for no good reason?":{
          "ui:placeholder" : "Select Option",
            ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },

        "During the last 30 days, about how often did you feel nervous?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          "During the last 30 days, about how often did you feel so nervous that nothing could calm you down?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          "During the last 30 days, about how often did you feel hopeless?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          "During the last 30 days, about how often did you feel restless or fidgety?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

        
          "During the last 30 days, about how often did you feel so restless you could not sit still?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          
          "During the last 30 days, about how often did you feel depressed?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          "During the last 30 days, about how often did you feel that everything was an effort?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          "During the last 30 days, about how often did you feel so sad that nothing could cheer you up?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },

          "During the last 30 days, about how often did you feel worthless?":{
            "ui:placeholder" : "Select Option",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
          },
    },
    "k_problem":{
        "Screening purpose":{
           "ui:placeholder" :  "enter the text only",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Any problems or diagnoses?":{
            "ui:placeholder" :  "enter the problems  ",
              ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
        },
        "Description":{
            "ui:placeholder" :  "description here  ",
               ["classNames"] : 'col-md-6 col-lg-6 col-sm-12 col-xs-12'
         }
   },
   "Blood Glucose Profile" : {},

   "Hematology Report-CBC-DLC" :{},

   "Hematology Report-ESR":{},

   "Hematology Report-Ab. Leukocyte Count": {},
   
}

const testNamesForESR = ["Erythrocyte Sedimentation Rate", "ESR"]
const testNamesForCBCDLC =  [ "Complete Blood Count","Haemoglobin (Hb %)", "Red Blood Cell Count", "Packed Cell Volume (Hematocrit)","MCV", "MCH", "MCHC", "Platelet Count", "Total Count (TC)", 
   "Differential Leukocyte Count", "Neutrophils", "Monophils", "Basophils", "Eosinophils", "Lymphocytes",
]
const testNamesForALC = ["Absolute Leukocyte/WBC Count", "Neutrophils", "Monophils", "Basophils", "Eosinophils", "Lymphocytes", "Mean Platelet Volume"]

for (const testName of testNames) {
    SCHEMA_OBJ["Blood Glucose Profile"][testName] = {
      "Result": {
        "ui:placeholder":"Result Value ",
        "classNames": "col-md-3 col-lg-4 col-sm-12 col-xs-12"
      },
    //   "unit":{
    //     "ui:placeholder":"hehe",
    //     "classNames": "col-md-3 col-lg-4 col-sm-12 col-xs-12",
    //     "ui:disabled": true
    //   },

      "Comment": {
        //"ui:widget": "select",
        "ui:placeholder":"hehe",
        "classNames": "col-md-3 col-lg-4 col-sm-12 col-xs-12"
      },
      "Ref. Range": {
        "ui:placeholder":"hehe",
        "classNames": "col-md-3 col-lg-4 col-sm-12 col-xs-12",
        "ui:disabled": true
      }
    };
  }
export function generatePropertiesforLabTests(){
    return { 
        "Result": {
          "ui:placeholder": "Result Value",
          "classNames": "col-md-3 col-lg-3 col-sm-12 col-xs-12",
          "ui:help": <small>(-1: Test not performed)</small>
        },
        "Units": {
          "ui:placeholder": "",
          "classNames": "col-md-2 col-lg-2 col-sm-3 col-xs-3",
          "ui:disabled": true
        },
        "Comment": {
          "ui:placeholder": "",
          "classNames": "col-md-3 col-lg-3 col-sm-12 col-xs-12"
        },
        "Ref. Range": {
          "ui:widget": "textarea",
          "classNames": "col-md-3 col-lg-3 col-sm-12 col-xs-12",
          "ui:disabled": true
        }
      };
}

for(const test of testNamesForCBCDLC)
    SCHEMA_OBJ["Hematology Report-CBC-DLC"][test] = generatePropertiesforLabTests()
for (const test of testNamesForESR)
    SCHEMA_OBJ["Hematology Report-ESR"][test] = generatePropertiesforLabTests()
for (const test of testNamesForALC)
    SCHEMA_OBJ["Hematology Report-Ab. Leukocyte Count"][test] = generatePropertiesforLabTests()


  
export const CURRENT_NOTES = "CURRENT_NOTES";

