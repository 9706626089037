import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Button ,Modal, ModalHeader, ModalBody} from "shards-react";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../core/actions";
import { helperServices } from "../../shared/helper";
import Components from "./index";

function Vouchers(props) {
  const dispatch = useDispatch();
  const patient = useSelector((state) => state.patientDetailsState.patient);
  const [voucherList, setVoucherList] = useState([]);
  const [patientInfo, setPatientInfo] = useState();
  const [nonRedeemed, setNonRedeemed] = useState(true);
  const [loading, setloading] = useState(true);
  const [disabled, setDisabled]=  useState(false);
  const [openVoucherModal, setOpenVoucherModal]=  useState(props.addVouchers);
  
  
  useEffect(() => {
    console.log("Vouchers Props",patient, props.pateintInfo);
    if( props.pateintInfo){
    setPatientInfo(props.pateintInfo.visit);
    }
    dispatch(
      patientActions.getVouchers(patient.erpCustId, (resCb) => {
        if (resCb) {
          console.log("response from addPatient api", resCb.voucherList);
          setVoucherList(resCb.voucherList);
          setloading(false);
        }
      })
    );
  }, []);

  const handleRedeemClick = (voucher,index)=>{
    console.log("voucherredeemed",index);
    voucherList[index]["redeemed"] = true;
    console.log("voucherredeemednjj",voucherList);
    dispatch(
        patientActions.redeemVoucher(patientInfo.id, patientInfo.patientId, voucher, (resCb) => {
          if (resCb) {
            console.log("response from voucherredeemed api",resCb);
              if(resCb.successMessages){
                  setNonRedeemed(false);
                  setDisabled(true);
                  props.redeemVoucher({success:resCb.successMessages});
              }
              if(resCb.errorMessages){
                  props.redeemVoucher({success:"Failed to redeemed voucher."});
              }
          }
        })
      );
  }

  const closeVoucherModal = () =>{
    props.onCloseVoucher();
  }
  console.log("voucherList", voucherList);
  return (
    <>
      {/* <main className="">
      {(!helperServices.CheckAccessLevel("Assistant")) && (!helperServices.CheckAccessLevel("Patient"))  && (voucherList && voucherList.length >0) && (
          <h5 className="">Vouchers</h5>
        )}
      </main> */}
      <Modal
                className="d-flex flex-column-reverse "
                id="closemodalopen"
                open={openVoucherModal}
                size="lg"
            
            >
                <div>
                <ModalHeader>
                Vouchers
                    <span
                    className="close-model"
                    onClick={(e) => closeVoucherModal(e)}
                    >
                    X
                    </span>
                </ModalHeader>
                <ModalBody>
                {voucherList && voucherList.length >0 ? 
      <div style={{ fontSize: "14px" }}>
        <Card className="border ">
          <CardBody className="p-3 vocherdisplay">
            {loading && 
             <main className="mainsection">
             <div className="voucherpreview">Loading....</div>
           </main>}
            {voucherList === undefined ? (
              <main className="mainsection">
                <div className="voucherpreview">No Voucher Available</div>
              </main>
            ) : (
              <div>
                {voucherList &&
                  voucherList.map((voucher, index) => (
                    <div key={index}>
                      {(helperServices.CheckAccessLevel("Assistant")) && (helperServices.CheckAccessLevel("Patient")) && (
                        <Button theme="danger" onClick={(event)=>handleRedeemClick(voucher)}className="redeem">
                          Redeem{" "}
                        </Button>
                      )}

                      <Row className="vochersList">
                        <Col sm={12} md={12}>
                          <label className="service-lable">Voucher Code</label>
                          <span>
                            <span className="mr-2">:</span>
                            {voucher.voucherId}
                          </span>
                        </Col>
                        <Col sm={12} md={12}>
                          <label className="service-lable">Narration</label>{" "}
                          <span>
                            <span className="mr-2">:</span>
                            {voucher.narration}
                          </span>
                        </Col>
                        <div className="vochersList"></div>
                      </Row>
                    </div>
                  ))}
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      :''}
                  </ModalBody>
                </div>
                </Modal>
     
   
    </>
  );
}

export default Vouchers;
