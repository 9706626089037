import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageTitle from "../common/PageTitle";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {GET_UPLOAD_PATH} from '../../shared/constants';
import BtnCellRenderer from '../common/BtnCellRenderer';
import { helperServices } from '../../shared/helper';
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button, Card, CardBody
} from "shards-react";

  function PrescriptionLayoutModel(props) {
    let formattedName = "";

    //let count = 0;
    const [imageToDisplay, setImageToDisplay]=useState();
    const [count,setCount] = useState();
    const [reload,setReload] = useState(false);

    useEffect(() => {
      console.log("props in prescriptionlayout:",props);
      let name = localStorage.getItem('name');
      formattedName = 'Dr.'+ helperServices.getFullName(name,null,null)
      if(props.uploadedImages){
        showImageToDisplay(props.uploadedImages,props.data);

      }
    },[formattedName,props])


    const showImageToDisplay = (images,index) => {
      console.log("index obtained:",index);
      console.log("images:",images);
      // let img = images.filter((item)=> item.imgurl == url);
      // console.log("filtered image:",img);
      // setImageToDisplay(img);
      setCount(index);
      setReload(!reload)

    }
    const readImage = (imgSrc) => {
      var file = imgSrc;
    var reader = new FileReader();
    var url = null;
    // reader.readAsDataURL(file);
    // var objurl = URL.createObjectURL(file);
      const blob = fetch(imgSrc).then((res) => res.blob());
      console.log("selfie blob : ",blob,blob.Blob);
      const formData = new FormData();
      formData.append('image',blob);
      // formData.append('imageLoad',objurl);
      return formData.blob;
    }
    const closeModal = () => {
        props.onModalClose()
    }

    const nextImage = () =>{
      let c = count;
      if(c <= props.uploadedImages.length)
       {
        c++;
        setCount(c);
        setReload(!reload)
        console.log("count",count);


       }
      // console.log("count",count);
      // let img =[];
      // img.push(props.uploadedImages[index]);

      // console.log("filtered image:",img);
      // setImageToDisplay(img);
    }

    const prevImage = () => {
      let c = count;
      if(c >=0)
      {
        c--;
       setCount(c);
      setReload(!reload)
      console.log("count",count);

      }
      // console.log("count",index);
      // let img =[];
      // img.push(props.uploadedImages[index]);
      // console.log("filtered image:",img);
      // setImageToDisplay(img);
    }


        return (  <>
        <div className="pres-layout" key={reload}>
            <div> 
                   <div className="main-content-container container-fluid">
                        <div className="">
                          <div className="mb-12">
                            <div className="blog-comments__item d-flex cpointer  pt-3 menu-content bg-none">
                              <div className="d-flex justify-content-between align-items-center px-3 mb-3">
                                  <div className=''>
                                    <span className='form-group mb-0'> {formattedName}</span>
                                  </div>
                                  <div className='modal-actions form-group mb-0'>
                                   {/*  <span className='form-group col-md-11 mb-0'> <i className="fas fa-ellipsis-v"></i></span> */}
                                    <span className="" style={{cursor:`pointer`}} onClick={(event) => closeModal(event)}>X</span>
                                  </div>
                              </div>
                            </div>
                            
                          </div>
                        </div>
                    </div>
            </div>

            <div>
            
              <div className={(count > 0) ? 'p-3 col-md-3' : 'p-3 col-md-3 hideIcon'}>
                <span className="material-icons arrow-prev" style={{cursor:'pointer'}} onClick={(event) => prevImage()}>arrow_back_ios</span>
              </div>
                <div className="mb-12 col-sm-12 col-md-6 col-lg-6 pres-modal-main modal-main">
                {props.uploadedImages != null && props.uploadedImages.map((item, idx) => (

                        <Col key={idx}>
                            <Form>  
                                {(count === idx) &&
                                  <Row form>
                                  <img alt="Not Available" src={GET_UPLOAD_PATH + item.imgurl} className='w-100'
                                  ></img>
                                  </Row>   
                                 }
                             
                            </Form>
                        </Col>
                ))}
              
                </div>
                <div className={(count < (props.uploadedImages.length)-1) ? 'p-3 col-md-3 d-flex justify-content-end' : 'p-3 col-md-3 d-flex justify-content-end hideIcon'}>
                  <span className="material-icons arrow-next" style={{cursor:'pointer'}} onClick={(event) => nextImage()}>arrow_forward_ios</span>
                </div>
              
            </div>
          
            <div className=''>
           
                <div className="d-flex">
                  {/*  <div></div>  show selected files using for loop in a div*/}
                  {props.uploadedImages && props.uploadedImages.map((item, idx) => ( 
                    <>
                  
                   
                      <div key={idx} className='media-gallery-thumb p-2 m-2 border'>
                          <img alt="Not Available" src={GET_UPLOAD_PATH + item.imgurl} className="join-meeting-icon" style={{width:`52`,height:`52px`}}></img>
                      </div>
                   
                    </>         
                    ))}
                  
                </div>
          

            </div>
      </div>
                    </>
            
        );


  }


export default PrescriptionLayoutModel;
