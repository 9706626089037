import React,{useState, useEffect} from 'react'
import { connect } from 'react-redux';
import { formActions } from '../../core/actions';
import {TOP_SECTIONS,BOTTOM_SECTIONS} from "../../shared/config";
import Components from './index';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';
import InputRenderer from '../common/InputRenderer';
import TextAreaRenderer from '../common/TextAreaRenderer';
import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Button,
    Card, CardBody} from "shards-react";

 function FormDetail(props) {

    const[formDetail, setFormDetail] = useState({
        formId:'',
        formName:'',
        basicinfo: {
            formTitle:'',
            topSections:[
                
            ],
            middleSections : [
                {
                    msectioninfo : {
                        slno:1,
                        sheader:'Terms & Conditions',
                        contents:'A Limit What Users Can Do clause can inform users that by agreeing to use your service, they\'re also agreeing to not do certain things. This can be part of a very long and thorough list in your Terms and Conditions agreements so as to encompass the most amount of negative uses.'
                    }
                },
                {
                    msectioninfo : {
                        slno:2,
                        sheader:'Terms & Conditions',
                        contents:'A Limit What Users Can Do clause can inform users that by agreeing to use your service, they\'re also agreeing to not do certain things. This can be part of a very long and thorough list in your Terms and Conditions agreements so as to encompass the most amount of negative uses.'
                    }
                }
            ],
            bottomSections:[
                
            ]
        }		
    })
    const[topSectioncheckedItems, setTopSectioncheckedItems] = useState(new Map())
    const[bottomSectioncheckedItems, setBottomSectioncheckedItems] = useState(new Map())
    const[showAddNew, setShowAddNew] = useState()
    const[updateScenario, setUpdateScenario] = useState()
    const[prevState,setPrevState] = useState()

        useEffect(() => {
            var fDetail = formDetail;
            fDetail['basicinfo']['topSections'] = TOP_SECTIONS;
            fDetail['basicinfo']['bottomSections'] = BOTTOM_SECTIONS;
            setFormDetail(fDetail)

            let formId = props.match.params.formId;
            console.log('FormId obtained : ', formId);
            if(formId && formId > 0){
                console.log('This is update scenario');
                setUpdateScenario('true')
            }
        },[])

        const openAddNewSectioModal = () => {
            setShowAddNew('')

        setTimeout(() =>{ 
            setShowAddNew('false')
        },500)
        }

        const toggleAddNewSectionModal = () => {

        }

        const gotopage = (page) => {
            props.history.push('/'+page);
        }

        const addEmptyRow = () => {
            var fdetail = formDetail;
            var sectionsList = formDetail.basicinfo.middleSections;
            console.log('Sectionslist obtained : ', sectionsList);
            
            if(!sectionsList){
                sectionsList = [];
            }

            var initSlNo = sectionsList && sectionsList.length > 0 ? sectionsList[sectionsList.length -1].msectioninfo.slno + 1 : 1; 
             console.log('initSlNo : ', initSlNo);
            var sectioninfo = {};
            sectioninfo.slno = initSlNo;
            sectioninfo.sheader = '';
            sectioninfo.contents = '';

            var msectioninfo = {};
            msectioninfo.msectioninfo = sectioninfo

            sectionsList.push(msectioninfo);

            console.log('Sectionslist obtained 1: ', sectionsList);

            fdetail['basicinfo']['middleSections'] = sectionsList;

            this.setState({ formDetail: fdetail });
        }

        const handleInputChange = (name, value) => {
            console.log('In handleinput change');
            var fDetail = formDetail;
            fDetail['basicinfo'][name]= value;
            setFormDetail(fDetail)
        }

        const handleTopCheck = (event) => {
            var isChecked = event.target.checked;  
            var item = event.target.value;  
            
            this.setState(prevState => ({ topSectioncheckedItems: prevState.topSectioncheckedItems.set(item, isChecked) }));  
        }

        const handleBottomCheck = (event) => {
            var isChecked = event.target.checked;  
            var item = event.target.value;  
               
            this.setState(prevState => ({ bottomSectioncheckedItems: prevState.bottomSectioncheckedItems.set(item, isChecked) }));  
        }

        const handleSectionInputChange = (name, value, id) => {
            var fdetail = this.state.formDetail;
            var msections = this.state.formDetail.basicinfo.middleSections;

            for(let i=0;i<msections.length ; i++){
                if(msections[i].msectioninfo.slno === id){
                    msections[i].msectioninfo[name] = value;
                    break;
                }
            }

            fdetail['basicinfo']['middleSections'] = msections;
            this.setState({formDetail : fdetail});            
        }

        const toggleAddModal = (event) => {
            this.setState({showAddNew : ''});

            if(event.sectioninfo){
            var fdetail = this.state.formDetail;
            var sectionsList = this.state.formDetail.basicinfo.middleSections;
            console.log('Sectionslist obtained : ', sectionsList);
            
            if(!sectionsList){
                sectionsList = [];
            }

            var initSlNo = sectionsList && sectionsList.length > 0 ? sectionsList[sectionsList.length -1].msectioninfo.slno + 1 : 1; 
             console.log('initSlNo : ', initSlNo);
            var sectioninfo = {};
            sectioninfo.slno = initSlNo;
            sectioninfo.sheader = event.sectioninfo.sheader;
            sectioninfo.contents = event.sectioninfo.contents;

            var msectioninfo = {};
            msectioninfo.msectioninfo = sectioninfo

            sectionsList.push(msectioninfo);

            console.log('Sectionslist obtained 1: ', sectionsList);

            fdetail['basicinfo']['middleSections'] = sectionsList;

            this.setState({ formDetail: fdetail });
            }
        }

        const handleSubmit = () =>{
            console.log('Top sections included : ', topSectioncheckedItems);
            console.log('Bottom sections included : ', bottomSectioncheckedItems);

            var tItemsSelected = getFinalListOfSectionsSelected(TOP_SECTIONS, topSectioncheckedItems);
            var bItemsSelected = getFinalListOfSectionsSelected(BOTTOM_SECTIONS, bottomSectioncheckedItems);

            console.log('Final top sections : ',tItemsSelected );
            console.log('Final bottom sections : ',bItemsSelected );

            console.log('Updated middle sections : ', this.state.formDetail.basicinfo.middleSections);
        }

        const getFinalListOfSectionsSelected = (sections, checkedItems) => {
            var tItemsFinal = [];

            for(let item of checkedItems){
                if(item[1] === true){
                    for(let tsections of sections){
                        if(tsections.id === item[0]){
                            tItemsFinal.push(tsections.value);
                            break;
                        }
                    }
                }
            }

            return tItemsFinal;
            
        }
        
            const context = {componentParent: (props) => this.handleRendererCall(props)};
            return (
                <main className="mainsection">
                <ListGroup flush>
                    <ListGroupItem className="p-3">
                        <Row>
                            <Col>
                                <Form>
                                    <Row form className="bbtm">
                                        <Col md="2" className="form-group">
                                            <label htmlFor="fname"></label>
                                            <div className="flabel">Form Title : *</div>
                                        </Col>
                                        <Col md="6" className="form-group">
                                            <label htmlFor="gcount"></label>
                                            <FormInput
                                            id="gcount"
                                            value={this.state.formDetail.basicinfo.formTitle}
                                            autoComplete="off"
                                            onChange={event => { this.handleInputChange('gcount', event.target.value) }}/>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md="4" className="form-group">
                                            <label htmlFor="fname"></label>
                                            <div className="flabel">Include in Top Section</div>
                                        </Col>
                                        </Row>
                                        <Row form className="bbtm">
                                        {
                                            this.state.formDetail.basicinfo.topSections.map((tscntent, idx) => (
                                                <Col md="6" className="form-group nmbtm" key = {idx}>
                                                    <label htmlFor="gcount"></label>
                                                    <input type="checkbox" onChange={(event) => this.handleTopCheck(event)} value={tscntent.id}/>&nbsp;&nbsp;
                                                    {tscntent.value}
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                   
                                    <Row form className="bbtm ptop">
                                        <Col>
                                            <Card small className="mb-4">
                                                <CardBody className="p-0 pb-3">
                                                <div className="ag-theme-balham" style={{height: 400, width: `100%`}}>
                                                    <AgGridReact 
                                                    frameworkComponents={{
                                                        BtnCellRenderer: BtnCellRenderer,
                                                        
                                                        InputRenderer:InputRenderer,
                                                        TextAreaRenderer:TextAreaRenderer,
                                                    }}
                                                    
                                                    pagination={this.pagination} paginationPageSize={this.paginationPageSize}
                                                        rowData={this.state.formDetail.basicinfo.middleSections}
                                                        context = {context}
                                                    >
                                                        <AgGridColumn field="msectioninfo.slno" sortable={ true } filter={ true } resizable={ true }
                                                        width={100} floatingFilter={true} ></AgGridColumn>
                                                        <AgGridColumn field="Name" sortable={ true } filter={ true } flex={1} resizable={ true }
                                                        floatingFilter={true} 
                                                        cellRenderer="InputRenderer"></AgGridColumn> 
                                                        <AgGridColumn field="Actions" sortable={ true } filter={ true }  flex={1} resizable={ true }
                                                        width={150}
                                                        cellRenderer="TextAreaRenderer"
                                                        
                                                    >
                                                        
                                                        </AgGridColumn>
                                                    </AgGridReact>
                                                </div>
                                                    {/* <table className="table mb-0 tcol">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th scope="col" className="border-0">Sl. No.</th>
                                                            <th scope="col" className="border-0">Section Header</th>
                                                            <th scope="col" className="border-0">Contents</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.formDetail.basicinfo.middleSections.map((msection, idx) => (
                                                        <tr key = {idx} >
                                                            <td><FormInput
                                                                className="sheader"
                                                                value={msection.msectioninfo.slno}
                                                                autoComplete="off"/>
                                                            </td>
                                                            <td><FormInput
                                                                className="sheader"
                                                                id="sheader"
                                                                value={msection.msectioninfo.sheader}
                                                                onChange={event => { this.handleSectionInputChange('sheader', event.target.value, msection.msectioninfo.slno) }}
                                                                autoComplete="off"/>
                                                            </td>
                                                            <td>
                                                                <textarea className="scontent tcol sheader"
                                                                maxlength="4096"
                                                                value= {msection.msectioninfo.contents}
                                                                onChange={event => { this.handleSectionInputChange('contents', event.target.value, msection.msectioninfo.slno) }}
                                                                >
                                                                </textarea>
                                                            </td>
                                                        </tr>
                                                        ))}
                                                    </tbody>
                                                    </table> */}
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md="4" className="form-group">
                                            <label htmlFor="fname"></label>
                                            <div className="flabel">Include in Bottom Section</div>
                                        </Col>
                                        </Row>
                                        <Row form className="bbtm">
                                        {
                                            this.state.formDetail.basicinfo.bottomSections.map((tscntent, idx) => (
                                                <Col md="6" className="form-group nmbtm" key = {idx}>
                                                    <label htmlFor="gcount"></label>
                                                    <input type="checkbox" onChange={(event) => this.handleBottomCheck(event)} value={tscntent.id}/>&nbsp;&nbsp;
                                                    {tscntent.value}
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                    
                                    <div className="cbtn">
                                        <div className="btnspc">
                                            <Button onClick={(event) => this.openAddNewSectioModal()}>Add New Section</Button>
                                        </div>
                                        <div className="btnspc">
                                            <Button onClick={(event) => this.gotopage('FormPreview')}>Preview</Button>
                                        </div>

                                        {
                                            this.state.updateScenario === 'true'?
                                                <div className="btnspc">
                                            <Button onClick={(event) => this.handleSubmit()}>Update</Button>
                                        </div> : 
                                         <div className="btnspc">
                                         <Button onClick={(event) => this.handleSubmit()}>Save</Button>
                                     </div>
                                        }
                                        
                                    </div>

                                </Form>
                            </Col>
                            <div></div>
                            {
                    this.state.showAddNew === 'false'  ?  
                    
                         <Components.SectionAdd
                         onAddModalClose={(event) => this.toggleAddModal(event)}/>
                         : ''
                }
                           
                        </Row>
                       
                    </ListGroupItem>
                    
                </ListGroup>
                
                </main>

               
            );

    }

/*     function mapStateToProps(state) {
        return {
            formDetail : state.formDetailsState.formDetail,
        };
    }
      
    function mapDispatchToProps(dispatch) {
        return {
            getFormDetails: (pDetail,resCb) => dispatch(formActions.getFormDetails(pDetail,resCb))
        };
    }
    
    const HomeWithProps = connect(mapStateToProps, mapDispatchToProps)(FormDetail); */
    
    export default FormDetail;
    