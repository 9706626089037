import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import doctorActions, { getRoleList } from "../../../core/actions/doctorActions";
import MessageStrip from "../../../shared/messagestrip/MessageStrips";
import { format } from "date-fns";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button,
  Card,
  CardBody,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  FormGroup,
  FormRadio,
} from "shards-react";

function AddStaff(props) {
  const dispatch = useDispatch();

  const [addFlag, setTextFlag] = useState(false);


  const [formErrors, setFormErrors] = useState({
    first_name: "",
    dob: "",
    phoneno: "",
    email: "",
    type: "",
    qualification: "",
    
  });

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const [submitted, setSubmitted] = useState([]);

  const [staffDetail, setstaffDetail] = useState({
    id: "",
    patientId: "",
    first_name: "",
    mname: "",
    last_name: "",
    age: "",
    adhar: "",
    phoneno: "",
    area: "",
    city: "",
    state: "",
    pincode: "",
    gender: "",
    bloodgroup: "",
    occupation: "",
    address: "",
    dob: "",
    email: "",
    imgUrl: "",
    udt: "",
    //department: "",
    //salarytype:"",
    type: "RegistrationDesk",
    qualification: "",
    pincode: "",
    //displaytitle: "",
    visit: {},
    upload: {},
    //desk: "",
  });
  const [selectedGender, setSelectedGender] = useState('Male');
  const [showMsgStrip, setShowMsgStrip] = useState(false);
  const [messageDetails, setMessageDetails] = useState({});
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
 // const [getRoleList, setgetRoleList] = useState([]);
  //const [deskList, setDeskList] = useState([]);
  ////const [salaryTypeList, setsalaryTypeList] = useState([]);
 const [roleList, setroleList] = useState([]);
  const handleInputChange = (name, value) => {
    formErrors[name] = "";
    console.log("In handleinput change", name, value);
    var pDetail = staffDetail;
    console.log('pDetail["basicinfo"][name]', pDetail[name]);
    pDetail[name] = value;
    setstaffDetail({ ...pDetail });
    console.log("pDetail", pDetail);
    console.log("staffDetail is", staffDetail);
  };
  let errormsg="Email is Invalid";
  const handleSubmit = (event) => {
    console.log("event");
    event.preventDefault();
    setSubmitted(true);

    // if (props && props.location.rowData) {
    //   staffDetail["dob"] = new Date(staffDetail.dob);
    //   console.log("edit case");
    //   dispatch(
    //     doctorActions.modifyDoctor(staffDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("modifyDoctor", resCb);
    //         // props.onModalClose();
    //         props.history.push("/doctorlist");
    //       }
    //     })
    //   );
    // } else {
      // console.log("save case", staffDetail);

    //   dispatch(
    //     doctorActions.saveDoctor(staffDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("saveDoctor", resCb);
    //         // props.onModalClose();
    //         props.history.push("/doctorlist");
    //       }
    //     })
    //   );
    // }

    Object.keys(staffDetail).forEach((key) => {
      console.log("key", key)
      console.log("key", staffDetail[key])
      validateField(key, staffDetail[key])
    })
    validateForm(event);
  };

  useEffect(() => {
    //getSalaryTypeList()
    getRoleList()
    //getDeskList()
    console.log("dr action props", props);
    if (props.location && props.location.rowData) {
      let docData = props.location.rowData;
      console.log("doctor data:", docData);
      setSelectedGender(docData.gender);
      setTextFlag(!addFlag);

      let date = new Date(props.location.rowData.dob);
      var dd = date.getDate();
      var mm = date.getMonth() + 1;
      let formattedNumber = mm.toLocaleString("en-US", {
        minimumIntegerDigits: 2,
        useGrouping: false,
      });
      var yyyy = date.getFullYear();
      var newDate = yyyy + "-" + formattedNumber + "-" + dd;
      docData["dob"] = newDate;
      // docData['dob'] = helperServices.formatDate(props.doctorData.dob);
      // console.log(" newDate,", newDate.toISOString().slice(0,10));

      console.log(" docData,", docData);
      console.log(" formattedNumber,", formattedNumber);

      // let formatteddate = format(docData['dob'], "YYYY-MM-DD' 'HH:mm");
      // console.log(" formatteddate,", formatteddate);
      // getFormattedDate()
      // console.log("date converted" , format(props.doctorData.dob, 'dd/MM/yyyy'))

      setstaffDetail(docData);
    } else {
      setTextFlag(addFlag);

      // setstaffDetail(docData)
    }
  }, []);
  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  };
  const toggle = (event) => {
    props.history.push("/staffList");
  };


  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = staffDetail;

    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
    var currentDate = new Date().getDate;
    switch (fieldName) {
      case "first_name":
        console.log("first_name check");

        fieldValidationErrors.first_name = value !== '' ? '' : 'Please Enter First Name';
        basicInfo.first_name = value !== '' ? value : '';
        break;

      // case "qualification":
      //   console.log("qualification check");
      //   fieldValidationErrors.qualification = value !== '' ? '' : 'Please Enter Qualification';
      //   basicInfo.qualification = value !== '' ? value : '';
      //   break;

      // case "dob":
      //   fieldValidationErrors.dob = value !== '' ? '' : 'Please Select Date of Birth';
      //   basicInfo.dob = value <= currentDate ? value : '';
      //   break;

      case "phoneno":
        // fieldValidationErrors.first_name = value !== '' ? '' : 'Please Enter First Name';
        // fieldValidationErrors.phoneno = value.length == 10? '' : 'Please Enter Valid Phone Number';
        // basicInfo.phoneno = value !== '' ? value : '';
        // break;  
        if (fieldValidationErrors.phoneno = value.length == 0) {
          fieldValidationErrors.phoneno = value.length == 0 ? 'Please Enter Phone Number' : '';
          basicInfo.phoneno = value !== '' ? value : '';
          break;
        } else {
          fieldValidationErrors.phoneno = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
          basicInfo.phoneno = value !== '' ? value : '';
          break;
        };

      case "email":
        if (!isEmail(value)) {
          fieldValidationErrors.email = value == isEmail ? '' : 'Please Enter Email';
          basicInfo.email = value !== '' ? value : '';
        };
        break;


      // case "displaytitle":
      //   console.log("title check");
      //   fieldValidationErrors.displaytitle = value !== '' ? '' : 'Please Enter Display Title';
      //   basicInfo.displaytitle = value !== '' ? value : '';
      //   break;

      // case "type":
      //   console.log("type check");
      //   fieldValidationErrors.type = value !== '' ? '' : 'Please Select Type';
      //   basicInfo.type = value !== '' ? value : '';
      //   break;

      default:
        break;
    }
    setFormErrors({ ...fieldValidationErrors });
  }



  var d = new Date();
  const todayDate = format(d.setDate(d.getDate() - 6570), "yyyy-MM-dd");

  const onSelectedGender = (value) => {
    setSelectedGender(value)
    console.log("setSelectedGender", value);
    staffDetail.gender = value;
  }

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  }
  const validateForm = (event) => {
    event.preventDefault();

    // var Detail = staffDetail;
    // var age = getAge(staffDetail.dob)
    // setstaffDetail(Detail);
    console.log("formErrors.::", formErrors);
    let formValid;
    formValid = formErrors.first_name === "" &&
      formErrors.dob === "" &&
      formErrors.email === "" &&
      formErrors.qualification === "" &&
      formErrors.phoneno === "" &&
      formErrors.type === "" 
     console.log("formValid", formValid);

    if (formValid) {

      if (props && props.location.rowData) {
        staffDetail["dob"] = new Date(staffDetail.dob);
        console.log("edit case", staffDetail);
        dispatch(
          doctorActions.modifyDoctor(staffDetail, (resCb) => {
            if (resCb) {
              console.log("modifystaff", resCb);
              // props.onModalClose();
              props.history.push("/staffList");
            }
          })
        );
      } else {
        console.log("save case", staffDetail);
        if (staffDetail.gender == "") {
          staffDetail.gender = 'Male';
        }
        console.log("save case", staffDetail);

        dispatch(
          doctorActions.saveDoctor(staffDetail, (resCb) => {
            console.log("staff details", resCb);
            if (resCb.accresp) {
              var ServerResVal=ServerRes;
              console.log("saveStaff", resCb);
              
              ServerResVal.success = "Staff added Successfully";
              setServerRes({...ServerResVal});
               setTimeout(() => {
                 props.history.push("/stafflist");

                   closeAlert();
                   props.history.push("/stafflist");

              //     ServerResVal.success = "Doctor added Successfully";
                }, 3000);
              // props.onModalClose();
              //props.history.push("/stafflist");
            }
            else {
              console.log("saveStaff error", resCb);
              setShowMsgStrip(true);
              setMessageDetails({error: 'error',
              message: resCb});
            }
          })
        );
      }
    }
    else {
      console.log("invalid");
    }
  }

  const getRoleList = () => {
    dispatch(doctorActions.getRoleList( (resCb) => {
      if (resCb) {
        let rolelist = [];
        for (let role of resCb.roleList) {
          let roleopt = {}
          if(role.role!="Doctor"){
            roleopt['id'] = role.id;
            roleopt['role'] = role.role;
            rolelist.push(roleopt);
          }
          
         }
         setroleList([...rolelist])
         console.log("role",roleList);
        console.log("rolelist:",rolelist );
      }
    }))
  }

  // const getSalaryTypeList = () => {
  //   dispatch(doctorActions.getSalaryTypeList(resCb => {
  //     if (resCb) {
        
  //       setsalaryTypeList(resCb)
  //       console.log("SalaryTypeList:", salaryTypeList);
  //     }
  //   }))
  // }

  // const getDeskList = (data) => {
  //   dispatch(doctorActions.getDeskList(data,resCb => {
  //     if(resCb) {
  //       console.log("desklist response",resCb);
  //       let deskList = [];
  //       for(let desk of resCb){
  //         let deskopt = {}
  //         deskopt['id'] = desk;
  //         deskopt['name'] = desk;
  //         deskList.push(deskopt);
  //       }
  //       setDeskList([...deskList])
  //       console.log("deskLists:", deskList);
  //     }
  //   }))
  // }
  const closeMessageStrip = () => {
    console.log("closing msg strip fn in stafflist");
    setShowMsgStrip(!showMsgStrip);
  }

  return (
    <>
    {showMsgStrip ?
        <MessageStrip
          msgStrip={messageDetails}
          closeMessageStrip={(event) => closeMessageStrip(event)}
        />
        : ''}
         {ServerRes.success && (
                      <Alert theme="success">{ServerRes.success}</Alert>
                    )}
                    {ServerRes.error && (
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    )}
      <div className="insuranceFormContainer p-3">
        <div >
          <h5> {addFlag ? "Edit" : "Add"}  Staff </h5>{" "}
        </div>

        <main className="mainsection">
          <ListGroup flush className="mb-3 ">
            <div className="p-2 bg-none">
              <Row>
                <Col>
                  <Form>
                    <Row form>
                      <Col md="4" className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <FormInput
                          id="first_name"
                          value={staffDetail.first_name}
                          placeholder="First Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("first_name", event.target.value);
                          }}
                          invalid={formErrors.first_name === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.first_name}</span>
                        </div>
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="mname">Middle Name</label>
                        <FormInput
                          id="mname"
                          value={staffDetail.mname}
                          placeholder="Middle Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("mname", event.target.value);
                          }}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <FormInput
                          id="last_name"
                          value={staffDetail.last_name}
                          placeholder="Last Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("last_name", event.target.value);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row form>
                      {/* <Col md="4" className="form-group">
                        <label htmlFor="gender">Gender</label> */}
                        {/*<FormInput
                          id="gender"
                          value={staffDetail.gender}
                          placeholder="Gender"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("gender", event.target.value);
                          }} */}
                        

                        <Col md="4" className="form-group">
                        <label htmlFor="phoneno">Phone Number</label>
                        <FormInput
                          id="phoneno"
                          type="number"
                          value={staffDetail.phoneno}
                          placeholder="Phone Number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phoneno", event.target.value);
                          }}
                          invalid={formErrors.phoneno === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.phoneno}</span>
                        </div>
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="gender">Gender</label> 
                        <Col md="12 d-flex justify-content-between">
                          <FormRadio
                            name="Male"
                            checked={selectedGender === "Male"}
                            onChange={(event) => { onSelectedGender('Male') }}
                          >Male{" "}
                          </FormRadio>
                          <FormRadio

                            name="Female"
                            checked={selectedGender === "Female"}
                            onChange={(event) => { onSelectedGender('Female') }}
                          >
                            Female{" "}
                          </FormRadio>
                          <FormRadio

                            name="Others"
                            checked={selectedGender === "Others"}
                            onChange={(event) => { onSelectedGender('Others') }}
                          >
                            Others{" "}
                          </FormRadio>
                        </Col>

                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="birthdetails">Date Of Birth</label>
                        <FormInput
                          type="date"
                          id="dob"
                          value={staffDetail.dob}
                          placeholder="Date Of Birth"
                          max={todayDate}
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("dob", event.target.value);
                          }}
                          invalid={formErrors.dob === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.dob}</span>
                        </div>
                      </Col>
                      </Row>
                      <Row form>
                      <Col md="4" className="form-group">
                        <label htmlFor="phoneno">Blood Group</label>
                        <br />
                        {/* <FormInput
                          id="bloodgroup"
                          type="type"
                          value={staffDetail.bloodgroup}
                          placeholder="Blood Group"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("bloodgroup", event.target.value);
                          }} />*/}
                        <select name="selectrole" className='w-100 form-control'
                          value={staffDetail.bloodgroup}
                          onChange={(event) => { handleInputChange("bloodgroup", event.target.value); }} >
                          <option disabled={true} value="">
                            --Choose an option--
                          </option>
                          <option value="A +ve">A +ve</option>
                          <option value="A -ve">A -ve</option>
                          <option value="B +ve">B +ve</option>
                          <option value="B -ve">B -ve</option>
                          <option value="AB +ve">AB +ve</option>
                          <option value="AB -ve">AB -ve</option>
                          <option value="O +ve">O +ve</option>
                          <option value="O-ve">O -ve</option>
                        </select>

                      </Col>
                    
                    
                      <Col md="4" className="form-group">
                        <label htmlFor="gender">Qualification</label>
                        <FormInput
                          id="qualification"
                          value={staffDetail.qualification}
                          placeholder="Qualification"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "qualification",
                              event.target.value
                            );
                          }}
                          invalid={formErrors.qualification === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.qualification}</span>
                        </div>
                      </Col>
                      {/* <Col md="4" className="form-group">
                        <label htmlFor="birthdetails">Display Title</label>
                        <FormInput
                          type="type"
                          id="displaytitle"
                          value={staffDetail.displaytitle}
                          placeholder="Display Title"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "displaytitle",
                              event.target.value
                            );
                          }}
                          invalid={formErrors.displaytitle === "" ? false : true}
                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.displaytitle}</span>
                        </div>
                      </Col> */}
                      {/* <Col md="4" className="form-group">
                        <label htmlFor="email">Department</label> */}
                        {/* <FormInput
                          id="department"
                          value={staffDetail.department}
                          placeholder="Department"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("department", event.target.value);
                          }}
                        /> */}

                        {/* <select value={staffDetail.department} name="selectfloor" className='w-100 form-control'
                          onChange={(event) => { handleInputChange("department", event.target.value); }}>
                          <option selected disabled hidden>--Select Department--</option>
                          {departmentList.map((options, index) => (
                            <option key={index} value={options.name}>{options.name}</option>
                          ))}
                        </select> */}
                     {/* </Col> */}
                    {/* </Row> */}

                    {/* <Row form> */}
                      <Col md="4" className="form-group">
                        <label htmlFor="feInputAddress">Address</label>
                        <FormInput
                          id="feInputAddress"
                          placeholder="address"
                          value={staffDetail.address}
                          onChange={(event) => {
                            handleInputChange("address", event.target.value);
                          }}
                        />
                      </Col>

                      <Col md="4" className="form-group">
                        <label htmlFor="email">Email Id</label>
                        <FormInput
                          id="email"
                          value={staffDetail.email}
                          placeholder="Email Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("email", event.target.value);
                          }}
                          invalid={formErrors.email === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.email}</span>
                        </div>
                      </Col>

                      {/* <Col md="4" className="form-group">
                        <label htmlFor="phoneno">Phone Number</label>
                        <FormInput
                          id="phoneno"
                          type="number"
                          value={staffDetail.phoneno}
                          placeholder="Phone Number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phoneno", event.target.value);
                          }}
                          invalid={formErrors.phoneno === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.phoneno}</span>
                        </div>
                      </Col> */}
                    {/* </Row> */}

                    {/* <Row form> */}
                      <Col md="4" className="form-group">
                        <label htmlFor="feInputCity">City</label>
                        <FormInput
                          id="feInputCity"
                          placeholder="City"
                          autoComplete="off"
                          value={staffDetail.city}
                          onChange={(event) => {
                            handleInputChange("city", event.target.value);
                          }}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="feInputState">State</label>
                        <FormInput
                          id="feInputState"
                          placeholder="State"
                          autoComplete="off"
                          value={staffDetail.state}
                          onChange={(event) => {
                            handleInputChange("state", event.target.value);
                          }}
                        ></FormInput>
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="phoneno">Pincode</label>
                        <FormInput
                          id="pincode"
                          type="number"
                          value={staffDetail.pincode}
                          placeholder="Pincode"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("pincode", event.target.value);
                          }}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="phoneno">Type</label>
                        <select value={staffDetail.type} name="Select Role" className={formErrors.type === "" ? 'w-100 form-control' : 'w-100 form-control is-invalid'}

                          onChange={(event) => { handleInputChange("type", event.target.value); }}>
                          {/* <option >--Select Role--</option> */}
                          {roleList.map((options, index) => (
                            <option key={index} value={options.role}>{options.role}</option>
                          ))}
                          
                        </select>

                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.type}</span>
                        </div>

                      </Col>
                      {/* <Col md="4" className="form-group">
                        <label htmlFor="email">Salary Type</label>
                        <select value={staffDetail.salarytype} name="selectfloor" className='w-100 form-control'
                          onChange={(event) => { handleInputChange("salarytype", event.target.value); }}>
                          <option selected disabled hidden>--Select Options--</option>
                          {salaryTypeList.map((options, index) => (
                            <option key={index} value={options}>{options}</option>
                          ))}
                        </select>
                      </Col> */}
                   


                    </Row>


                   
                  </Form>
                  <div className="buttonsetconfirm">
                    <Button
                      className="btn-danger"
                      type="submit"
                      onClick={(event) => toggle(event)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={(event) => handleSubmit(event)}
                    >
                      Save
                    </Button>
                  </div>
                  <br />
                </Col>
              </Row>
            </div>
          </ListGroup>
        </main>
      </div>
    </>
  );
}
 export default AddStaff;
