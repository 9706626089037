import React, { useState, useEffect } from 'react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {useDispatch, useSelector} from "react-redux";
import { patientActions } from '../../core/actions';
import {
  Row,FormSelect
} from "shards-react";

  function BasicLayoutHeader(props) {

   
    const dispatch = useDispatch();

    useEffect(() => {
      console.log('props',props)
    },[])

    const closeModal = () => {
        props.onModalClose()
    }
    


        return (  <>
            <div className='pres-header' > 
              {/*   <Col md="3"></Col> */}
                <div className="basic-layout-header">
                    <div className="main-content-container container-fluid p-0">
                          <div className="mb-12 col-md-12 hpcol bg menu-head">
                            <div className="blog-comments__item d-flex cpointer  p-2 menu-content">
                              <div style={{display:`flex`}} className=""
                               onClick={(event) => props.onPatientBasic()}>
                                <div className="blog-comments__content layout-head-content">
                                  <div className="blog-comments__meta text-mutes">
                                    <div className="text-secondary pname">
                                        Contact Info
                                    </div>
                                  </div>
                                </div> 
                                
                              </div>
                            </div>
                            
                          </div>
                    </div>
                </div>
            </div>
          
                    </>
            
        );


  }


export default BasicLayoutHeader;
