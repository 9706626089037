import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { bedActions } from "../../core/actions";
import { BED_CATEGORIES, VISIT_CATEGORIES } from "../../shared/config";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  // FormGroup,
  Button,
  // FormFeedback
} from "shards-react";

function DocVisitFee(props) {
  const [basicinfo, setBasicinfo] = useState({});
  const [bedFeesDetails, setBedFeesDetails] = useState({
    category: "",
    sharePerc: "",
  });
  const [bedCategories, setBedCategories] = useState([]);
  const [visitCategories, setVisitCategories] = useState([]);

  const dispatch = useDispatch();


  useEffect(() => {
    setBedCategories(BED_CATEGORIES);
    setVisitCategories(VISIT_CATEGORIES);
    for (let i = 0; i < BED_CATEGORIES.length; i++) {
      var basicInfo = basicinfo;
      basicInfo[BED_CATEGORIES[i].value + "count"] = "";
      basicInfo[BED_CATEGORIES[i].value + "rename"] = "";
      basicInfo[BED_CATEGORIES[i].value + "rate"] = "";
      setBasicinfo(basicInfo);
    }
    console.log("Final state obtained : ", basicinfo);
    console.log("Final state obtained : ", bedCategories);
    console.log("visitCategories Final state obtained : ", visitCategories);
    setTimeout(() => {
      console.log("Final state obtained : ", basicinfo);
      console.log("Final state obtained : ", bedCategories);
      console.log("visitCategories Final state obtained : ", visitCategories);
    },100)
  },[])

  const handleInputChange = (name, value) => {
    console.log("In handleinput change");
    var pDetail = basicinfo;
    pDetail[name] = value;
    setBasicinfo({...pDetail});
  }

  const handleSubmit = (event) => {
    /*[{name:"Regular (Comes from constant)"count:"",rate:"",rename:"",
    changedBedRates:{rate:"",effectiveFrom:""}}] */
    var dataList = [];
    for (let i = 0; i < BED_CATEGORIES.length; i++) {
      var bedCatg = {};
      bedCatg.name = BED_CATEGORIES[i].value;
      bedCatg.count = basicinfo[BED_CATEGORIES[i].value + "count"];
      bedCatg.rename = basicinfo[BED_CATEGORIES[i].value + "rename"];
      bedCatg.rate = basicinfo[BED_CATEGORIES[i].value + "rate"];

      var cBedRates = {};
      cBedRates.rate = basicinfo[BED_CATEGORIES[i].value + "rate"];
      cBedRates.effectiveFrom = "";
      bedCatg.changedBedRates = cBedRates;

      dataList.push(bedCatg);
    }

    console.log("Data list formed finally : ", dataList);

    props.onAddModalClose({ close: "true" });
  }

  const closeModal = (event) => {
    props.onAddModalClose({ close: "true" });
  }

  const getFormId = (category, type) => {
    return category + type;
  }

    return (
      <div className="modalmn">
        <div className="mb-4 col-sm-12 col-md-6 modal-main">
          <div style={{ display: `flex`, justifyItems: `flex-end` }}>
            <span
              onClick={(event) => closeModal(event)}
              className="topright"
            >
              X
            </span>
          </div>
          <div className="blog-comments card pcard">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="3" className="form-group">
                          <label htmlFor="fname">Fees</label>
                        </Col>
                      </Row>

                      {visitCategories.map((vcatg, idx) => (
                        <>
                          <Row form key={idx}>
                            <Col md="2" className="form-group">
                              <label htmlFor="vcatg+visit"></label>
                              {vcatg.value} Visit
                            </Col>

                            {bedCategories.map((bedCatg, idx1) => (
                              <Col md="2" className="form-group">
                                {idx === 0 ? (
                                  <label htmlFor="bedCatg.value+count">
                                    {bedCatg.value}
                                  </label>
                                ) : (
                                  ""
                                )}

                                <FormInput
                                  id={getFormId(bedCatg.value, "count")}
                                  type="number"
                                  value={
                                    basicinfo[
                                      bedCatg.value + "count"
                                    ]
                                  }
                                  autoComplete="off"
                                  onChange={(event) => {
                                    handleInputChange(
                                      bedCatg.value + "count",
                                      event.target.value
                                    );
                                  }}
                                />
                              </Col>
                            ))}

                            <Col md="2" className="form-group">
                              {idx === 0 ? (
                                <label htmlFor="bedCatg.value+count">
                                  Doctor Share
                                </label>
                              ) : (
                                ""
                              )}

                              <FormInput
                                id=""
                                type="number"
                                autoComplete="off"
                                onChange={(event) => {
                                  handleInputChange(
                                    "count",
                                    event.target.value
                                  );
                                }}
                              />
                            </Col>
                          </Row>
                        </>
                      ))}

                      <div className="fbtn">
                        <Button
                          type="submit"
                          onClick={(event) => handleSubmit(event)}
                        >
                          Create
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </div>
    );
}

/* function mapStateToProps(state) {
  return {
    bedDetail: state.bedDetailsState.bedDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getBedDetails: (pDetail, resCb) =>
      dispatch(bedActions.getBedDetails(pDetail, resCb)),
  };
}

const HomeWithProps = connect(mapStateToProps, mapDispatchToProps)(DocVisitFee); */

export default DocVisitFee;
