import React, { useState, useEffect } from 'react';
import { Card , CardBody, Button} from "shards-react";
import {useDispatch, useSelector} from "react-redux";
import {ZOOMNOTESLABEL} from "../../shared/config";

function NotesLabel(props) {
    
    const[draggedLabel, setDragedLabel] = useState({});
    const[isDragged, setIsDragged] = useState(false);
    const[selectedLable, setSelectedLable] = useState({});
    const[clearSelect]= useState({})
    useEffect(() => {
        console.log('zoomMeetingReview')
        console.log("props:",props);
    },[])

    const onSelect = (ev, value) => {
       
        console.log("handle drag leave e:",ev,"value : ",value)
         setSelectedLable(value);
         props.selectedLable(value)
    }
    const clearSelection = (event) => {
   
        props.clearSelect(event)
    }
 
    const onDragStart = (ev, value) => {
        //this.setState({toolType:''})
        console.log('on drag of section')
        console.log('dragstart:',ev.target,value);
    }

    const handleDragLeave = (e, value) => {
        console.log("handle drag leave e:",e,"value : ",value)
        setDragedLabel(value)
        props.draggedLabel(value)
    }
    const onDragOver = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        console.log('onDragOver:',ev);
    }

    console.log('zoomMeetingReview:');
    return (  
        <>  
                {/* <div className="row my-3"> */}
                    {/* <div className="mb-12 col-sm-12 col-md-12 hpcol"> */}
                        {/* <div className="blog-comments card pcard"> */}
                            {/* <div className="blog-comments__item p-3 cpointer patient-cont-sec"> */}
                          {/*   <div className="notes-label-card-body model-notes-body">
                            <Card>
                                <div className="blog-comments__content" style={{ padding: "2% 0px 0px 16%" }}>
                                    <div className="blog-comments__meta text-mutes" style={{ padding: "5px 0px 0px 0px" }}>
                                        {props.selectedPatient ? (
                                            <div>
                                            <span style={{ padding: "0px 0px 0px 60px" }}>{props.selectedPatient.fname} {props.selectedPatient.lname}</span>
                                            <div className="d-flex">
                                            <p className="m-0 text-muted">
                                                Age {props.selectedPatient.age} years&nbsp;
                                            </p> 
                                            <p className="fas fa-mobile mobile_line"></p>
                                            <p className="m-0 text-muted">
                                                &nbsp;{props.selectedPatient.phno}
                                            </p> 
                                            </div>
                                            </div>
                                        ) : (
                                            <div className="d-flex">
                                            <p className="m-0 text-muted">
                                                {/* Age {props.selectedPatient.age} years&nbsp; */}
                                            {/* </p> 
                                            <p className="fas fa-mobile mobile_line"></p>
                                            <p className="m-0 text-muted">
                                                {/* &nbsp;{props.selectedPatient.phno} */}
                                            {/*</p> 
                                            </div>
                                        )}
                                        
                                    </div>
                                </div>
                                </Card>
                                </div> */}
                            {/* </div> */}
                        {/*  </div> */}
                    {/*  </div> */}
                {/*  </div> */}
                {/* <div className="row my-3"> */}
                    {/* <div className="mb-12 col-sm-12 col-md-12 hpcol"> */}
                        {/* <div className="blog-comments card pcard"> */}
                            {/* <div className="blog-comments__item p-3 cpointer patient-cont-sec"> */}
                            <div className="notes-label-card-body model-notes-body h-100">
                            <div className='h-100'>
                                <div className="blog-comments__content h-100">
                                    <div className="blog-comments__meta text-mutes p-3 d-flex flex-column justify-content-between h-100">
                                    <div className="notes-model-title border-bottom pb-2 h5">Label</div>
                                        <div className='my-2 border-bottom flex-fill'>
                                        {ZOOMNOTESLABEL && ZOOMNOTESLABEL.length > 0 &&
                                                ZOOMNOTESLABEL.map((notesLabel, idx) => (
                                                    <div>
                                                    <div className="notes-card-body model-notes-body model-label" key={idx}>
                                                    <Card small className="p-2 mb-3 w-100 h-100 note-card text-center" key={idx}  
                                                    style={{ 'backgroundColor': `${notesLabel.color}` }} draggable>
                                                        <span className='label-title'
                                                        onDragStart = {(e) =>onDragStart(e, notesLabel) }
                                                        onDragLeave={e =>handleDragLeave(e, notesLabel) }
                                                        
                                                        onClick= {e=>onSelect(e, notesLabel) }
                                                        onDragOver={(e)=>onDragOver(e) }
                                                    >{notesLabel.label}</span>
                                                    </Card>
                                                    </div>
                                                    </div>
                                                ))
                                        }
                                        </div>
                <div className="d-flex new-label-btn">
                    {/* <Button type="submit">
                        CREATE NEW LABLE
                    </Button><br></br> */}
                    <Button type="submit" className='btn-block' onClick = {event=>clearSelection(event) }>
                     Show All Notes
                    </Button>
                </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                            {/* </div> */}
                        {/* </div> */}
                    {/* </div> */}
                {/* </div> */}
                
                
                 {/* <Form className="add-new-card">
            <FormInput className="mb-3" placeholder="Your Text" 
            value={newCard.notes}
            onChange={(event) => {
                handleInputChange('notes',event.target.value);
            }}/>
            <Button className="save-card" type="submit" onClick={(event) => saveCard(event,newCard)}>
                SAVE
            </Button>
        </Form>  */}
       
        </>

    );
}


export default NotesLabel;
