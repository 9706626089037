import FormList from './formsList';
import FormDetail from './formDetail';
import SectionAdd from './sectionAdd';
import FormPreview from './formPreview';

const fcomponents = {
    FormDetail,
    SectionAdd,
    FormList,
    FormPreview
}

export default fcomponents;