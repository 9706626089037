import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { Alert } from "shards-react";
import BtnCellRenderer from "../common/BtnCellRenderer";
import { patientActions, doctorActions } from "../../core/actions";
import Components from "..";
import { format } from "date-fns";
//import { useNavigation } from '@react-navigation/native';
//import AdminHomeView from '../patient/AdminHomeView';
import { useHistory } from "react-router-dom";
import { helperServices } from "../../shared/helper";
import {
  BASIC,
  PRE_AUTH_REQUEST,
  PRE_AUTH_RESPONSE,
  CLAIM_REQUEST,
  CLAIM_APPROVAL,
  INVOICE,
  BILLCLEAR,
} from "../../shared/constants";
import { moveToBilling } from "../../core/actions/patientActions";
import {
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  FormRadio,
} from "shards-react";
import { PATIENT_SECTION } from "../../shared/constants";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
function PatientListing(props) {
  let ids;
  const history = useHistory();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(15);
  const [patientVisit, setPatientVisit] = useState([]);
  const [showPatientVisitModal, setshowPatientVisitModal] = useState(false);
  const [showPatientRevisitModal, setshowPatientRevisitModal] = useState(false);
  const [selectPatientData, setselectPatientData] = useState();
  const [selectedPatientRowData, setSelectedPatientRowData] = useState();
  const [selectedPatientSummary, setSummary] = useState();
  const [showPatientSection, setShowPatientSection] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("OutPatient");
  const [revisitDetail, setRevisitDetail] = useState({
    patientId: "",
    visitId: "",
    closecomment: "",
    closeScheduledTime: "",
    scheduleddate :"",

  })
  const [summary, setSummaryData] = useState({
    preparedBy: "",
    approver: "",
    patientId: "",
    visitId: "",
    notes: "",
    groupname: "",
    notes: "",
    subgroup: "",
    type: "",
  });
  const [type, setType] = useState("All");
  const [typeList, setTypeList] = useState([]);
  const [typeListCopy, setTypeListCopy] = useState([]);
  const [reloadData, setreloadData] = useState(false);
  const [insurancestatus, setInsuranceStatus] = useState();
  const [billaction, setBillAction] = useState();
  const [closebtnAcion, setCloseBtnAction] = useState();
  const [onmodal, setOnModal] = useState(false);
  const [onBoardmodal, setOnBoardModal] = useState(false);
  const [selectedType, setSelecetdType] = useState();
  const [visitList, setVisitList] = useState();
  const [countAll, setCountAll] = useState();
  const [countIn, setCountIn] = useState();
  const [countOut, setCountOut] = useState();
  const [countOnline, setCountOnline] = useState();
  const [IpLists, setIpLists] = useState(false);
  const [roomDealocateModal, setRoomDealocateModal] = useState(false);
  const [pdf, setPdf] = useState();
  const dispatch = useDispatch();

  const todayDate = format(new Date(), 'yyyy-MM-dd');

  // Get the current time and format it as "HH:mm" for the time input field
  const currentTime = format(new Date(), 'HH:mm');

  // Set the default values
  const [selecteddate, setSelecteddate] = useState(todayDate);
  const [selectedTime, setSelectedTime] = useState(currentTime);

  const [doctorList, setdoctorList] = useState({
    doctors: "",
  });

  const [ServerRes, setServerRes] = useState({ success: "", error: "" });

  const customComparator = (valueA, valueB) => {
    return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
  };

  const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy");
    return formatteddate;
  };
  const monthToNum = (visitDate) => {
    if (
      visitDate === undefined ||
      visitDate === null ||
      visitDate.length !== 10
    ) {
      return null;
    }

    var yearNumber = visitDate.substring(6, 10);
    var monthNumber = visitDate.substring(3, 5);
    var dayNumber = visitDate.substring(0, 2);

    var result = yearNumber * 10000 + monthNumber * 100 + dayNumber;
    // 29/08/2004 => 20040829
    return result;
  };

  const dateComparator = (date1, date2) => {
    var date1Number = monthToNum(date1);
    var date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  };

  const getFormatedReqtDate = (date) => {
    const reqtdate = new Date(date);
    // const apphours=new Date(date);
    //  const appminites= new Date(date);
    let formatedreqtdate = format(reqtdate, "MMM dd yyyy");
    //  let formatedappdate= format(appdate, "MMM dd yyyy",apphours,"HH",appminites,"MM" );
    return formatedreqtdate;
  };
  const getFormatedApptDate = (date) => {
    const apptdate = new Date(date);
    let formatedapptdate = format(apptdate, "MMM dd yyyy");
    return formatedapptdate;
  };

  const [columnDefs] = useState([
    // {
    //   headerName: "File Number",
    //   field: "fileNo",
    //   cellClass: ["ag-cell-center"],
    //   tooltipField: "fileNo",
    // },
    {
      headerName: "MAIN",
      field: "maIn",
      cellClass: ["ag-cell-center"],
      tooltipField: "maIn",
    },
    {
      headerName: "Name",
      field: "name",
      cellClass: ["ag-cell-center", "table-head"],
      tooltipField: "name",
    },
    {
      headerName: "Doctor",
      field: "doc",
      cellClass: ["ag-cell-center"],
      tooltipField: "doctor",
    },
    {
      headerName: "Counselor",
      field: "Counsellor",
      cellClass: ["ag-cell-center"],
      tooltipField: "counsellor",
    },
    {
      headerName: "Category",
      field: "category",
      cellClass: ["ag-cell-center"],
      tooltipField: "category",
    },
    {
      headerName: "Visit/Appointment Time",
      field: "visitDate",
      headerTooltip: "Visit/Appointment Time",
      comparator: dateComparator,
      sort: "desc",
      cellClass: ["ag-cell-center"],
      cellRenderer: (params) => {
        if (params.data.appTime != "") {
          return (
            '<p class="mb-0" style="height:20px">' +
            params.value +
            '</p><p class="mt-1 mb-0" style="height:20px">Time:&nbsp;' +
            params.data.appTime +
            "</p>"
          );
        } else {
          return '<p class="mb-0" style="height:20px">' + params.value + "</p>";
        }
      },
    },
    {
      headerName: "Insurance Status",
      field: "insuranceStatus",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      onGridReady: "open",
      cellClass: ["ag-cell-center"],
    },
    {
      headerName: "Insurance",
      field: "insurance",
      cellClass: ["ag-cell-center"],
    },
    { headerName: "Room", field: "room", cellClass: ["ag-cell-center"] },
    {
      headerName: "Actions",
      field: "Actions",
      cellRenderer: "BtnCellRenderer",
      cellStyle: { textAlign: "center" },
      cellClass: ["ag-cell-center"],
    },
  ]);

  const [OpcoulmnDefs] = useState([
    // {
    //   headerName: "File Number",
    //   field: "fileNo",
    //   cellClass: ["ag-cell-center"],
    //   tooltipField: "fileNo",
    // },
    {
      headerName: "MAIN",
      field: "maIn",
      cellClass: ["ag-cell-center"],
      tooltipField: "maIn",
    },
    {
      headerName: "Name",
      field: "name",
      cellClass: ["ag-cell-center", "table-head"],
      tooltipField: "name",
    },
    {
      headerName: "Doctor",
      field: "doc",
      cellClass: ["ag-cell-center"],
      tooltipField: "doctor",
    },
    {
      headerName: "Counselor",
      field: "Counsellor",
      cellClass: ["ag-cell-center"],
      tooltipField: "counsellor",
    },
    {
      headerName: "Category",
      field: "category",
      cellClass: ["ag-cell-center"],
      tooltipField: "category",
    },
    {
      headerName: "Visit/Appointment Time",
      field: "visitDate",
      headerTooltip: "Visit/Appointment Time",
      comparator: dateComparator,
      sort: "desc",
      cellClass: ["ag-cell-center"],
      cellRenderer: (params) => {
        console.log("Params Data",params,params.value)
        if (params.data.apptdate != undefined) {
          const options = { year: 'numeric', month: 'short', day: 'numeric' };
          const formattedDate = new Date(params.data.apptdt).toLocaleDateString('en-US', options);
          return (
            '<p class="mb-0" style="height:20px">' +
            //TC_06
            formattedDate +
            (params.data.appTime ? '</p><p class="mt-1 mb-0" style="height:20px">Time:&nbsp;' +
            params.data.appTime +
            "</p>" : "")
            // '</p><p class="mt-1 mb-0" style="height:20px">Time:&nbsp;' +
            // params.data.appTime +
            // "</p>"
          );
        } else {
          return '<p class="mb-0" style="height:20px">' + params.value + "</p>";
        }
      },
    },
    {
      headerName: "Status",
      field: "status",
      onGridReady: "open",
      cellClass: ["ag-cell-center"],
    },
    {
      headerName: "Actions",
      field: "Actions",
      cellRenderer: "BtnCellRenderer",
      cellStyle: { textAlign: "center" },
      cellClass: ["ag-cell-center"],
    },
  ]);

  const defaultColDef = useMemo(() => {
    return {
      editable: true,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      floatingFilter: true,
    };
  }, []);

  const [AddPatientmodel, setaddPatientModel] = useState(false);

  const patientsList = useSelector(
    (state) => state.patientDetailsState.patientsList
  );
  const page = useSelector((state) => state.patientDetailsState.page);

  const patientVisitClick = () => {
    setshowPatientVisitModal(true);
  };

  const patientCloseClick = () => {
    setshowPatientRevisitModal(true);
  };

  const Addpatient = () => {
    setaddPatientModel(true);
  };

  const closeModal = (event) => {
    console.log("event:", event);

    setshowPatientVisitModal(false);
    setshowPatientRevisitModal(false);
    setaddPatientModel(false);
    // setSummary(false);
    var ServerResVal = event;
    if (event && event.successMessages) {
      ServerResVal.success = event.successMessages[0];
    } 
    if  (event && event.errorMessages){
      ServerResVal.error = event.errorMessages[0];
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 3000);
  };

  const handleRendererCall = (props) => {
    setselectPatientData(props.visitsList);
    dispatch(patientActions.setPatientDetails(props.visitsList));

    switch (props.visitsList.typeFlag) {
      case "assignDoctor":
        console.log("----appointment-------", props);
        history.push({
          pathname: `/PatientSections/${props.visitsList.id}/${props.visitsList.visit.id}`,
        });
        console.log("----appointment-------", props);
        break;

      case "patientClose":
        // setselectPatientData(props.visitsList);
        patientCloseClick(props.visitsList);
        let patientId=props.visitsList.id;
        let visitId=props.visitsList.visit.id;
        dispatch(
              patientActions.getrevisitbyId(patientId,visitId, (resCb) => {
                if (resCb) {
                  console.log("resCb doctor Response:", resCb)
                  console.log('resCbe : ', resCb);
                  revisitDetail['scheduleddate'] = resCb.scheduleddate;
                  revisitDetail.patientId=resCb.patientId;
                  revisitDetail.visitId =resCb.visitId;
                }
                
              })
            );
        break;

      case "patientOnBoard":
        // onboardPatient(props.visitsList);
        onBoardModalOpen(props.visitsList);
        break;

      case "summary":
        setSummary(true);
        //setselectPatientData(props.visitsList);
        break;

      case "Complaint":
        console.log("----patientComplaint-------", props);
        history.push({
          pathname: `/PatientSections/${props.visitsList.id}/${props.visitsList.visit.id}`,
        });
        break;

      case "Insurance":
        console.log("----patientInsurance-------");
        history.push({
          pathname: `/PatientSections/${props.visitsList.id}/${props.visitsList.visit.id}`,
        });
        break;

      case "AttachFile":
        console.log("----patientAttachFile-------");
        history.push({
          pathname: `/PatientSections/${props.visitsList.id}/${props.visitsList.visit.id}`,
        });
        //setselectPatientData(props.visitsList);

        break;

      case "AllocateRoom":
        console.log("----AllocateRoom-------");
        history.push({
          pathname: `/PatientSections/${props.visitsList.id}/${props.visitsList.visit.id}`,
        });
        //setselectPatientData(props.visitsList);

        break;
      case "MoveToBill":
        ModalOpen(props.visitsList);
        break;

      default:
        //setselectPatientData(props.visitsList);
        patientVisitClick(props.visitsList);
        break;
    }

    console.log("patient data", selectPatientData, props.visitsList);
  };

  const onboardPatient = () => {
    var ServerResVal = ServerRes;
    console.log("log in onboard method:", selectedRadio);
    //visitList['type'] = data;
    let type = selectedRadio;
    console.log("type in log:", type);
    let visitListDetails = { ...visitList };
    console.log("visitlist details in onboard", visitListDetails);

    let admissionDateTime="";
    if(type=="InPatient"){
      const dateTimeString = `${selecteddate} ${selectedTime}:00`;
      admissionDateTime = dateTimeString;
      console.log("timestamp",admissionDateTime);
    }
    // console.log("log in onboard method:", value);
    dispatch(
      patientActions.onboardPatient(visitListDetails, type,admissionDateTime, (resCb) => {
        if (resCb) {
          console.log("onboardPatient response", resCb);
          if (resCb.successMessages) {
            let visitId = resCb.patientresp.visit.id;
            let patientId = resCb.patientresp.id;
            let name = resCb.patientresp.fname;
            let visitStatus = resCb.patientresp.visit.status;
            let erpCustId = resCb.patientresp.erpCustId;
            ServerResVal.success = "Patient onboarded successfully";
            setServerRes({ ...ServerResVal });
            setTimeout(() => {
              closeAlert();
            }, 3000);
            setTimeout(() => {
              if (visitStatus && visitStatus == "open") {
                history.push({
                  pathname: `/PatientSections/${patientId}/${visitId}`,
                });
              } else {
                history.push({
                  pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
                });
              }
            }, 2000);
          } else if (resCb.state === "error") {
            ServerResVal.error = "Patient onboard failed";
            setServerRes({ ...ServerResVal });
            setTimeout(() => {
              closeAlert();
            }, 3000);
          }

          setOnBoardModal(false);
          renderVisitLists();
        }
      })
    );
  };

  const doctorlist = (data) => {
    dispatch(
      doctorActions.getDoctorsBasednId(data, (resCb) => {
        if (resCb) {
          setdoctorList(resCb);
          doctorList.doctors = resCb;
          console.log("list doctor Response:", doctorList);
        }
      })
    );
  };

  const getAggriddata = (e) => {
    if (e.colDef.field == "Actions") return;
    dispatch(patientActions.setPatientDetails(e.data));
    console.log("ACTIONS:", props);
    e.data["typeFlag"] = "all";
    setSelectedPatientRowData(e.data);
    sessionStorage.setItem("patientId", JSON.stringify(e.data.id));

    setTimeout(() => {
      setShowPatientSection(true);
    }, 500);
    history.push({
      pathname: `/PatientSections/${e.data.id}/${e.data.visit.id}`,
    });
  };

  const categoryFilter = (type) => {
    props.parentCallBack()
    // sessionStorage.removeItem("searchvalue");
    // props.searchText.length == 0;
    console.log("type selcted:", type);
    console.log("typeList before filter", typeList);
    setType(type);
    let filterdType =
      type === "All"
        ? typeListCopy
        : typeListCopy.filter((visit) => visit.visit.type == type);
    console.log("filterdType", filterdType);
    setTypeList(filterdType);
    setreloadData(!reloadData);
    if (type === "InPatient") {
      setIpLists(true);
    } else {
      setIpLists(false);
    }
  };

  const getInsurancestatus = (visitIdList) => {
    console.log("visit id in method", visitIdList);
    dispatch(
      patientActions.getInsuranceStatus(visitIdList, (resCb) => {
        if (resCb) {
          console.log("Insurance getstatus:", resCb.insurancestatus);

          setInsuranceStatus(resCb.insurancestatus);
        }
      })
    );
  };

  const getActionsList = (visitIdList) => {
    console.log("visit id in method", visitIdList);
    dispatch(
      patientActions.getActionList(visitIdList, (resCb) => {
        if (resCb) {
          // console.log("Bill action:",resCb.actionList);
          // console.log("close Btn action:",resCb.closeButtonActionList);
          setBillAction(resCb.actionList);
          setCloseBtnAction(resCb.closeButtonActionList);
        }
      })
    );
  };

  const ModalOpen = (visitList) => {
    console.log("move to bill method:", visitList);
    setVisitList(visitList);
    setOnModal(true);    
  };

  const onBoardModalOpen = (visitList) => {
    setVisitList(visitList);
    setOnBoardModal(true);
  };

  const toggle = (event) => {
    setOnModal(false);
    setOnBoardModal(false);
    setRoomDealocateModal(false);
  };

  const MoveToBilling = (event) => {
    var ServerResVal = ServerRes;
    console.log("submit method move to bill : ", visitList);
    let visitListDetails = { ...visitList };
    console.log("submit method move to bills : ", visitListDetails);
    let data = {};
    data["patientId"] = visitListDetails.visit.patientId;
    data["visitId"] = visitListDetails.visit.id;

    dispatch(
      patientActions.moveToBilling(data, (resCb) => {
        if (resCb) {
          console.log("response of move to bill api:", resCb);
          if (resCb.errorMessages) {
            // ServerResVal.error = "Failed Move to biling";
            if (resCb.errorMessages =="Redirecting to deallocate the room Service"){
            ServerResVal.success = resCb.errorMessages;
          }else{
          ServerResVal.error = resCb.errorMessages;
          }
            console.log("errormsg:", resCb.errorMessages);
            if (resCb.count && resCb.count == 2) {
              setTimeout(() => {
                setRoomDealocateModal(true);
              }, 3000);
            }
            // setTimeout(() => {
            //   setRoomDealocateModal(true);
            // }, 3000);
          } else if (resCb.successMessages) {
            ServerResVal.success = resCb.successMessages;
            // ServerResVal.success = "Succesfully Moved to billing";
            console.log("successmsg:", ServerResVal.success);
          }
          setServerRes({ ...ServerResVal });

          setTimeout(() => {
            closeAlert();
          }, 3000);
          setOnModal(false);
          renderVisitLists();
        }
      })
    );
    // event.preventdefault()
  };

  const AlreadyMovedBill = (event) => {
          setOnModal(false);
          renderVisitLists();
  };

  const roomDealocate = (event) => {
    var ServerResVal = ServerRes;
    console.log("submit method move to bill : ", visitList);
    let visitListDetails = { ...visitList };
    console.log("submit method move to bills : ", visitListDetails);
    let data = {};
    data["patientId"] = visitListDetails.visit.patientId;
    data["visitId"] = visitListDetails.visit.id;

    dispatch(
      patientActions.roomDealocate(data, (resCb) => {
        if (resCb) {
          console.log("response of move to bill api:", resCb);
          if (resCb.errorMessages) {
            // ServerResVal.error = "Failed Move to biling";
            ServerResVal.error = resCb.errorMessages;
            console.log("errormsg:", resCb.errorMessages);
          } else if (resCb.successMessages) {
            ServerResVal.success = resCb.successMessages;
            // ServerResVal.success = "Succesfully Moved to billing";
            console.log("successmsg:", ServerResVal.success);
          }
          setServerRes({ ...ServerResVal });

          setTimeout(() => {
            closeAlert();
          }, 3000);
          setRoomDealocateModal(false);
          renderVisitLists();
        }
      })
    );
    //event.preventdefault()
  };

  const renderVisitLists = () => {
    let data = {};
    if (props.searchText.length == 0) {
      console.log("text length is:", props.searchText.length);
      dispatch(
        patientActions.ListVisits(data, (resCb) => {
          if (resCb && resCb.patsearchreslt) {
            console.log("All Bed Details - Response", resCb);
            dispatch(patientActions.setPageHeader("Admin"));
            console.log("visitsList1", resCb);
            console.log("Object", Object.values(resCb));
            // const pdfDocGenerator = pdfMake.createPdf(resCb.pdfbyte);
            console.log("typebyteArray", typeof (resCb.pdfbyte));
            const byteArray = new Uint8Array(resCb.pdfbyte);
            const textDecoder = new TextDecoder('utf-8');
            console.log("byteArray", byteArray);
            const decodedText = textDecoder.decode(byteArray);
            console.log("decodedText", decodedText);
            const pdfDefinition = {
              content: [
                {
                  text: 'My PDF Document',
                  style: 'header'
                },
                {
                  text: resCb.pdfbyte,
                  style: 'body'
                }
              ],
              styles: {
                header: {
                  fontSize: 18,
                  bold: true,
                  margin: [0, 0, 0, 10]
                },
                body: {
                  fontSize: 12,
                  margin: [0, 0, 0, 10]
                }
              }
            };

            // pdfMake.createPdf(pdfDefinition).download();

            // var pdfBytes;
            // pdfDocGenerator.getBlob((blob) => {
            //   const pdfBytes = new Uint8Array(blob);
            //   console.log("pdfBytes",pdfBytes);
            // });
            // const blob = new Blob([pdfBytes], { type: "application/pdf" });
            // const link = document.createElement("a");
            // link.href = window.URL.createObjectURL(blob);
            // link.download = "file.pdf";
            // link.click();
            //setPdf({data: JSON.parse(resCb.pdfbyte).content});
            // let patientVisitNew = resCb.patsearchreslt;
            //resCb.patsearchreslt.sort(function(a,b){return a.visist.visitDate.getTime() - b.visist.visitDate.getTime()});
            let patientVisit = resCb.patsearchreslt.sort(function (a, b) {
              // console.log("a.visitdate:", a);
              // console.log("b.visitdate:", b);
              return new Date(b.visit.visitDate) - new Date(a.visit.visitDate);
            });
            console.log("sorted visitlist:", patientVisit.length);
            console.log("patientVisit ", patientVisit);
            let visitIdList = [];
            for (let status of patientVisit) {
              visitIdList.push(status.visit.id);
            }
            console.log("All visit idlist:", visitIdList);
            //api is called to get insurance status of visit
            getInsurancestatus(visitIdList);
            //api is called to get closebutton and movetobillbutton action
            getActionsList(visitIdList);
            let patientProperties = [];

            if (patientVisit) {
              for (let item of patientVisit) {
                let role = localStorage.getItem("role");
                console.log("Role is:", role);
                console.log("patientvisit length in for loop:", patientVisit.length);
                console.log("item ", item);
                //Setting visit values to Ag-Grid
                setAggridByRole(item);
                buttonActions(item);
                if (helperServices.CheckAccessLevel("FrontDeskPatients")) {
                  if (
                    item &&
                    (!item.person || !item.counsellor) &&
                    item.visit.status == "open"
                  ) {
                    patientProperties.push(item);
                    setreloadData(!reloadData);
                  } else {
                  }
                } else {
                  console.log("patientVisit in patientlist", patientVisit);
                  patientProperties.push(item);
                }
              }
            }
            console.log("patientvisit length in typelist:", patientProperties);
            setTypeList(patientProperties);
            setTypeListCopy(patientProperties);
            setreloadData(!reloadData);
            let IP = patientProperties.filter(
              (item) => item.visit.type == "InPatient"
            ).length;
            setCountIn(IP);
            let OP = patientProperties.filter(
              (item) => item.visit.type == "OutPatient"
            ).length;
            setCountOut(OP);
            let Online = patientProperties.filter(
              (item) => item.visit.type == "OnlinePatient"
            ).length;
            setCountOnline(Online);
            setCountAll(patientProperties.length);
            setType("All");
            console.log("Type",type);
          }
        })
      );
    } else {
      let pslist = patientsList ? patientsList : [];
      console.log("patientlist in search:", patientsList);
      if (
        props.searchText.length > 2 &&
        patientsList &&
        patientsList.length > 0
      ) {
        let patientProperties = [];
        for (let item of pslist) {
          //Setting visit values to Aggrid while search the patients
          setAggridByRoleSearch(item);
          buttonActions(item);
          if (helperServices.CheckAccessLevel("FrontDeskPatients")) {
            if (
              item &&
              (!item.person || !item.counsellor) &&
              item.visit.status == "open"
            ) {
              patientProperties.push(item);
              setreloadData(!reloadData);
            } else {
            }
          } else {
            console.log("patientVisit in patientlist", patientVisit);
            patientProperties.push(item);
          }
        }
        console.log("patientlist::", pslist);
        setPatientVisit(patientProperties);
        setTypeList(patientProperties);
        setType("All");
        setTypeListCopy(patientProperties);
        //     setPatientVisit([...pslist]);
        //     setTypeList([...pslist]);
        //     setType('All');
        //    setTypeListCopy([...pslist]);
        setreloadData(!reloadData);
      } else {
        setTypeList([...pslist]);
        setreloadData(!reloadData);
      }
    }
  };

  //Setting visit values to Ag-Grid
  const setAggridByRole = (item) => {
    item["name"] = item.fname + " " + item.mname + " " + item.lname;
    // item['appdate']=item.visit.appdate +" "+ item.visit.apphours +" "+ item.visit.appminites;
    if (item.visit.reqtdate) {
      item["reqtdt"] =
        getFormatedReqtDate(item.visit.reqtdate) +
        " " +
        item.visit.reqthours +
        ":" +
        item.visit.reqtminutes;
    }
    item["status"] = item.visit.status;
    if (item.visit.apptdate) {
      item["apptdt"] =
        getFormatedApptDate(item.visit.apptdate) +
        " " +
        item.visit.appthours +
        ":" +
        item.visit.apptminutes;
    }
    item["apptdate"] = item.visit.apptdate;
    item["appthours"] = item.visit.appthours;
    item["apptminutes"] = item.visit.apptminutes;
    //setting meeting status
    item["meetingStatus"] = item.visit.meetingStatus;
    if (item.person && item.person.first_name) {
      item["doc"] = getFullName(item.person.first_name, item.person.last_name);
    }
    if (item.counsellor) {
      item["Counsellor"] = getFullName(
        item.counsellor.first_name,
        item.counsellor.last_name
      );
    }

    item["category"] =
      item.visit.type && item.visit.type != "undefined"
        ? item.visit.type
        : "--";
    item["visitDate"] = item.visit.visitDate
      ? getFormattedDate(item.visit.visitDate)
      : "--";
    item["appTime"] =
      item.visit.appthours > 0
        ? item.visit.appthours + ":" + item.visit.apptminutes
        : "";

    // item['visitDate'] = item.visit.reqtdate ? getFormattedDate(item.visit.reqtdate) : item.visit.apptdate ?  getFormattedDate(item.visit.apptdate) :  item.visit.visitDate ? getFormattedDate(item.visit.visitDate) :'--';

    item["insurance"] = item.visit.insurance;
    item["room"] =
      item.room.roomName && item.room.roomName != null
        ? item.room.roomName
        : null;
    item["fileNo"] = item.fileNo;
    item["maIn"] = item.id;
    console.log("item['room']:", item["room"]);

    // }
    // console.log("counselor-- ",item)
  };

  const setAggridByRoleSearch = (item) => {
    item["fullName"] = getFullName(item.fname, item.mname, item.lname);
    item["name"] = item.fname + " " + item.mname + " " + item.lname;
    if (item.visit.reqtime) {
      item["reqTime"] = getFormattedDate(item.visit.reqtime);
    }
    item["status"] = item.visit.status;
    if (item.visit.appointment) {
      item["starttime"] = getFormattedDate(item.visit.appointment);
    }
    if (item.person && item.person.first_name) {
      // item['doctor'] = getFullName(item.person.first_name, item.person.last_name);
      item["doc"] = getFullName(item.person.first_name, item.person.last_name);
    }
    if (item.counsellor) {
      item["Counsellor"] = getFullName(
        item.counsellor.first_name,
        item.counsellor.last_name
      );
    }
    item["category"] =
      item.visit.type && item.visit.type != "undefined"
        ? item.visit.type
        : "--";

    item["visitDate"] = item.visit.visitDate
      ? getFormattedDate(item.visit.visitDate)
      : "--";
    //item['visitDate'] = item.visit.reqtdate ? getFormattedDate(item.visit.reqtdate) : item.visit.apptdate ?  getFormattedDate(item.visit.apptdate) :  item.visit.visitDate ? getFormattedDate(item.visit.visitDate) :'--';
    item["appTime"] =
      item.visit.appthours > 0
        ? item.visit.appthours + ":" + item.visit.apptminutes
        : "";

    item["insurance"] = item.visit.insurance;
    item["room"] =
      item.room && item.room.roomName != null ? item.room.roomName : null;
    item["fileNo"] = item.fileNo;
    item["maIn"] = item.id;
    console.log("item['room']:", item["room"]);
  };

  const buttonActions = (item) => {
    console.log("state variable:", insurancestatus);
    //Setting insurance Status
    if (insurancestatus) {
      Object.keys(insurancestatus).forEach((key) => {
        if (key == item.visit.id) {
          console.log("value in log:", insurancestatus[key]);

          switch (insurancestatus[key].type) {
            case "Basic":
              item["insuranceStatus"] = BASIC;
              break;

            case "UploadDoc":
              item["insuranceStatus"] = BASIC;
              break;

            case "Pre Auth Request":
              item["insuranceStatus"] = PRE_AUTH_REQUEST;
              break;

            case "Pre Auth Response":
              item["insuranceStatus"] = PRE_AUTH_RESPONSE;
              break;

            case "Claim Request":
              item["insuranceStatus"] = CLAIM_REQUEST;
              break;

            case "Claim Approval":
              item["insuranceStatus"] = CLAIM_APPROVAL;
              break;

            case "InvoiceGen":
              item["insuranceStatus"] = INVOICE;
              break;

            case "BillClear":
              item["insuranceStatus"] = BILLCLEAR;
              break;
          }
        }
      });
    }

    if (billaction) {
      Object.keys(billaction).forEach((key) => {
        if (key == item.visit.id) {
          item.visit["moveToBillBtnAction"] = billaction[key];
        }
      });
    }

    if (closebtnAcion) {
      Object.keys(closebtnAcion).forEach((key) => {
        if (key == item.visit.id) {
          item.visit["closeBtnAction"] = closebtnAcion[key];
        }
      });
    }
  };

  useEffect(() => {
    dispatch(patientActions.setPage(PATIENT_SECTION));

    if (page != "PATIENT_SECTION") {
      sessionStorage.removeItem("searchvalue");
    }
    console.log("props are:", props);
    //console.log('patientsList in list',patientsList)
    //Three Apis called
    renderVisitLists();

    console.log("page:", page);
  }, [props, patientsList]);

  const getFullName = (fname, mname, lname) => {
    var fullName = "";
    if (!fname || fname == null) {
      fname = "";
    }
    if (!mname || mname == null) {
      mname = "";
    }
    if (!lname || lname == null) {
      lname = "";
    }
    var pfname = fname;
    var pmname = mname;
    var plname = lname;
    if (pfname == null) {
      pfname = "";
    }
    if (pmname == null) {
      pmname = "";
    }
    if (plname == null) {
      plname = "";
    }
    pfname = pfname.charAt(0).toUpperCase() + pfname.slice(1);
    pmname = pmname.charAt(0).toUpperCase() + pmname.slice(1);
    plname = plname.charAt(0).toUpperCase() + plname.slice(1);
    if (fname && lname) {
      fullName = pfname + " " + plname;
    } else if (fname && mname) {
      fullName = pfname + " " + pmname;
    } else if (fname && mname && lname) {
      fullName = pfname + " " + pmname + " " + plname;
    } else {
      fullName = pfname + "      ";
      console.log("fullName : ", fullName);
    }
    return fullName;
  };

  const context = { componentParent: (props) => handleRendererCall(props) };

  const generatezoomtoken = () => {
    dispatch(
      doctorActions.generateZoomToken((resCb) => {
        if (resCb) {
          setdoctorList(resCb);
          doctorList.doctors = resCb;
          console.log("list doctor Response:", doctorList);
        }
      })
    );
  };
  // set background colour on every row
  const rowStyle = { background: "white" };

  // set background colour on meeting status completed  rows
  const getRowStyle = (params) => {
    console.log("params", params);
    if (params.data.visit.meetingStatus == "completed") {
      return { background: "lightblue" };
    }
  };

  const onGridReady = (e) => {
    console.log("e:", e.api);
  };

  const getRowHeight = (params) => {
    console.log("params:", params);
    return params.node.data.appTime != "" ? 55 : 35;
  };

  const closeAlert = (e) => {
    setServerRes({ ..."" });
    renderVisitLists();
  };

  const onValueChange = (value) => {
    setSelectedRadio(value);
    console.log("selectedOptionRadio", value);
  };

  const overlayNoRowsTemplate = "No record found";

  const handleOnChange=(value,event)=>{
    if(value==="date"){
      setSelecteddate(event.target.value);
      console.log("Seleected Date is",event.target.value,event)
      console.log("Seleected Date is",typeof selecteddate)
    }
    if(value==="time"){
      setSelectedTime(event.target.value);
      console.log("Seleected Date is",event.target.value,event)
      console.log("Seleected Date is",typeof selectedTime)
    }
  
  }


  return (
    <>
      <div className="m-2">
        {ServerRes.success && (
          <Alert theme="success" className="alert-div">
            {ServerRes.success}{" "}
            <span>
              <i
                className="material-icons"
                onClick={(event) => closeAlert(event)}
                style={{ cursor: "pointer" }}
              >
                close
              </i>
            </span>
          </Alert>
        )}
        {ServerRes.error && (
          <Alert theme="danger" className="alert-div">
            {ServerRes.error}{" "}
            <span>
              <i
                className="material-icons"
                onClick={(event) => closeAlert(event)}
                style={{ cursor: "pointer" }}
              >
                close
              </i>
            </span>
          </Alert>
        )}
      </div>
      <div className="d-flex justify-content-between flex-wrap align-items-baseline">
        <div>
          <h5 className="page-title mx-3">List Of Patients</h5>
        </div>
        <div>
          <span
            md="2"
            className="patient-list mx-3 my-2 d-flex justify-content-between flex-wrap"
          >
            <div
              className={
                type == "All"
                  ? "btn btn-primary mb-1 mr-2 active"
                  : "btn btn-info mb-1 mr-2"
              }
              onClick={(event) => categoryFilter("All")}
            >
              All ({countAll})
            </div>
            <div
              className={
                type == "InPatient"
                  ? "btn btn-primary mb-1 mr-2 active"
                  : "btn btn-info mb-1 mr-2"
              }
              onClick={(event) => categoryFilter("InPatient")}
            >
              In Patient ({countIn})
            </div>
            <div
              className={
                type == "OutPatient"
                  ? "btn btn-primary mb-1 mr-2 active"
                  : "btn btn-info mb-1 mr-2"
              }
              onClick={(event) => categoryFilter("OutPatient")}
            >
              Out Patient ({countOut})
            </div>
            <div
              className={
                type == "OnlinePatient"
                  ? "btn btn-primary mb-1 mr-2 active"
                  : "btn btn-info mb-1 mr-2"
              }
              onClick={(event) => categoryFilter("OnlinePatient")}
            >
              Online Patient ({countOnline})
            </div>
          </span>
        </div>
      </div>

      <div key={reloadData}>
        <div className="p-0 preview">
          {IpLists ? (
            <div
              className="ag-theme-balham ag-style card rounded table mb-0 tcol option"
              style={{ fontSize: "14px", height: 490, width: `100%` }}
            >
              <AgGridReact
                frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                context={context}
                rowData={typeList}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                onCellClicked={(e) => {
                  getAggriddata(e);
                }}
                onGridReady={onGridReady}
                getRowHeight={getRowHeight}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
              ></AgGridReact>
            </div>
          ) : (
            <div
              className="ag-theme-balham ag-style card rounded table mb-0 tcol option"
              style={{ fontSize: "14px", height: 490, width: `100%` }}
            >
              <AgGridReact
                frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
                pagination={pagination}
                paginationPageSize={paginationPageSize}
                context={context}
                rowData={typeList}
                columnDefs={OpcoulmnDefs}
                defaultColDef={defaultColDef}
                rowStyle={rowStyle}
                getRowStyle={getRowStyle}
                onCellClicked={(e) => {
                  getAggriddata(e);
                }}
                onGridReady={onGridReady}
                getRowHeight={getRowHeight}
                overlayNoRowsTemplate={overlayNoRowsTemplate}
              ></AgGridReact>
            </div>
          )}
          {AddPatientmodel ? (
            <div className="modalmn">
              <Components.AddPatientmodel
                onModalClose={(event) => closeModal(event)}
              />
            </div>
          ) : (
            ""
          )}
          {showPatientVisitModal ? (
            //  <Components.GiveAppointment
            //  patientData={selectPatientData}
            //  onModalClose={(event) => closeModal(event)} />
            <Components.AppointmentScheduleModal
              from="list"
              patientData={selectPatientData}
              onModalClose={(event) => closeModal(event)}
            />
          ) : (
            ""
          )}
          {showPatientRevisitModal ? (
            <Components.PatientRevisitModal
              patientData={selectPatientData}
             patientRevisitDetails={revisitDetail}
              onModalClose={(event) => closeModal(event)}
            />
          ) : (
            ""
          )}
          {selectedPatientSummary ? (
            <div className="modalmn">
              <Components.Discharge
                patientData={selectPatientData}
                dischargesummary={summary}
                onModalClose={(event) => closeModal(event)}
              />
            </div>
          ) : (
            ""
          )}
        </div>

        <Modal
          className="d-flex w-95 h-90 flex-column-reverse "
          id="closemodalopen"
          open={onmodal}
          toggle={toggle}
        >
          <div>
            <ModalHeader>
              Warning!
              <span className="close-model" onClick={(event) => toggle(event)}>
                X
              </span>
            </ModalHeader>
            <ModalBody>
            {visitList && visitList.visit && visitList.visit.internalStatus && (visitList.visit.internalStatus ==="MOVED_TO_BILLING" || visitList.visit.internalStatus ==="INVOICE_GENERATED" || visitList.visit.internalStatus ==="BILL_CLEARED") ? (
            <>
            <span>Patient {visitList.fname ? visitList.fname : ''} already has been sent for Billing.  If you need to add/delete services/charges please do it in the Billing module.</span>
            </>
              ) : (
                
                <span> Are you sure you want to Move {visitList ? visitList.fname : ''} {visitList ? visitList.lname : ''} ({visitList ? visitList.id : ''}) to Billing?</span>
             )}
            </ModalBody>
            <ModalFooter className="justify-content-between">
            {visitList && visitList.visit && visitList.visit.internalStatus && visitList.visit.internalStatus ==="MOVED_TO_BILLING"  ? (
            <button
              className="btn btn-submit btn-primary btn-style bottomright"
              onClick={(event) => AlreadyMovedBill(event)}
            >
              Cancel
            </button>
              ) : ( 
              <>
                 <button
              className="btn btn-submit btn-primary btn-style bottomright"
              onClick={(event) => AlreadyMovedBill(event)}
            >
              Cancel
            </button>

            <button
                   className="btn btn-submit btn-primary btn-style bottomright"
                   onClick={(event) => MoveToBilling(event)}
                 >
                   Move To Bill
                 </button>
               </>
             )}
             
            </ModalFooter>
          </div>
        </Modal>

        <Modal
          className="d-flex w-95 h-90 flex-column-reverse "
          id="closemodalopen"
          open={onBoardmodal}
          toggle={toggle}
        >
          <div>
            <ModalHeader>
              Select Patient Category
              <span className="close-model" onClick={(event) => toggle(event)}>
                X
              </span>
            </ModalHeader>
            <ModalBody>
              <span>Are you sure to OnBoard a patient?</span>
            </ModalBody>
            <ModalFooter className="d-flex flex-column align-items-start ">
              {/* <button className="btn btn-submit btn-primary btn-style bottomright"
                   onClick={(event) => MoveToBilling(event)}>Move To Bill</button> */}
              {/* <button className="btn btn-submit btn-primary btn-style bottomright" onClick={(event) => onboardPatient('InPatient')}>InPatient</button>
                   <button className="btn btn-submit btn-primary btn-style bottomright" onClick={(event) => onboardPatient('OutPatient')}>OutPatient</button>
                   <button className="btn btn-submit btn-primary btn-style bottomright" onClick={(event) => onboardPatient('OnlinePatient')}>OnlinePatient</button> */}
              <div className="d-flex w-100 justify-content-between ">
                <FormRadio
                  name="InPatient"
                  checked={selectedRadio === "InPatient"}
                  onChange={(event) => {
                    onValueChange("InPatient");
                  }}
                >
                  InPatient{" "}
                </FormRadio>
                <FormRadio
                  name="OutPatient"
                  checked={selectedRadio === "OutPatient"}
                  onChange={(event) => {
                    onValueChange("OutPatient");
                  }}
                >
                  OutPatient{""}
                </FormRadio>
                <FormRadio
                  name="OnlinePatient"
                  checked={selectedRadio === "OnlinePatient"}
                  onChange={(event) => {
                    onValueChange("OnlinePatient");
                  }}
                >
                  OnlinePatient{""}
                </FormRadio>
              </div>
              {selectedRadio === "InPatient" ? 
                  <div>
                  <label  style={{ paddingTop: '5px' }}>Select a Admission Date:</label>
                  <div className="d-flex pl-0 col-lg-7 col-xl-7 col-md-12 col-12">
                    {/* Date Input */}
                  <div className="mr-2">
                  <input
                    name="admissionDate"
                    id="admissionDate"
                    className="form-control filterin Quantity"
                    placeholder="Enter Scheduled Date"
                    required
                    type="date"
                    defaultValue={selecteddate}
                    onChange={(event)=>handleOnChange("date",event)}
                  />
                  </div>
                  {/* Time Input */}
                  <div className="ml-2">
                  <input
                    name="admissionTime"
                    id="admissionTime"
                    className="form-control filterin Quantity"
                    placeholder="Enter Scheduled Time"
                    required
                    type="time"
                    defaultValue={selectedTime}
                    onChange={(event)=>handleOnChange("time",event)}
                  />
                  </div>
                  </div>
                  </div>
                  :"" }
               <div className="w-100 text-right">
                <button
                   className="btn btn-submit btn-primary btn-style bottom right"
                  onClick={(event) => onboardPatient()}
                >
                  Save
                </button>
              </div>
            </ModalFooter>
          </div>
        </Modal>

        <Modal
          className="d-flex w-95 h-90 flex-column-reverse "
          id="closemodalopen"
          open={roomDealocateModal}
          toggle={toggle}
        >
          <div>
            <ModalHeader>
              Warning!
              <span className="close-model" onClick={(event) => toggle(event)}>
                X
              </span>
            </ModalHeader>
            <ModalBody>
              <span>
                Are you sure you want Dealocate the room and move to Billing?
              </span>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn btn-submit btn-primary btn-style bottomright"
                onClick={(event) => roomDealocate(event)}
              >
                Dealocate room and Move to Bill
              </button>
            </ModalFooter>
          </div>
        </Modal>
      </div>
    </>
  );
}
export default PatientListing;
