import React, { useState, useEffect } from 'react';
import {useSelector} from "react-redux";
import Select, { createFilter } from "react-select";
import Components from "../index";
import { format } from "date-fns";
import {
    Button,
    Card,
    CardBody,

  } from "shards-react";
import { helperServices } from '../../shared/helper';

import { COUNSELLOR_ROLE, RESET } from "../../shared/constants/index"


function Counsellor(props) {

    const [reload, setReload] = useState(false);
    const [viewCounslDetails, setviewCounslDetails] = useState(false);
    const [assignedCounsellor, setAssignedCounsellor] = useState();
    const [showAppointmentModal, setShowAppointmentModal] = useState();
    const [selectedOption, setSelectedOption] = useState();
    const [deptId, setDeptId] = useState('');
    const [options, setOption] = useState('');
    const patientVisitList = useSelector(state => state.patientDetailsState.patientVisitLists);
    const counsellors = useSelector(state => state.doctorDetailsState.doctorsDetail);
    const [patientCalender, setPatientCalender]=useState([]);
    useEffect(() => {
        console.log('props in counsellor',props.counsInfo)
        console.log('assigned counsellor',assignedCounsellor)
        
     
        getPatientCalender();
        if(props.counsInfo.gvId && props.counsInfo.gvId != ""){
            setviewCounslDetails(true);
        } else {
            setviewCounslDetails(false);
   
        }
        getassignedCounsDetails();    
        if(props.patientInfo && props.patientInfo.status && props.patientInfo.status === "close"){
            setviewCounslDetails(true);
          }
   
   }, [props]);
   

const getPatientCalender = () => {
    let arr = [];
    let patientId = props.patientInfo.patientId ? props.patientInfo.patientId : '';
    let patCalender = [];
  
    console.log("For patient details:", props.patientCalenderInfo);
  
    if (Array.isArray(props.patientCalenderInfo) && props.patientCalenderInfo.length > 0) {
        props.patientCalenderInfo.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
                console.log(`Value `, value);
                if (key !== 'id' && key !== 'gvid' && key !== 'date') {
                    if (value !== "") {
                      if(value === patientId.toString()){
                        console.log("typeof patientId, typeof value",typeof patientId, typeof value);
                        console.log(`Value  `, value);
                        arr.push(obj);
                        console.log(`Value in if`, arr);
                      }
                    }
                }
            });
        });
  
        // Set patCalender after filtering
        patCalender = [...arr];
        console.log('Patient calendar found:', patCalender);
    } else {
        console.log('props.patientCalenderInfo is not an array or has length 0');
    }
  
    setPatientCalender([...patCalender]);
    console.log('Patient calendar found:', patientCalender);
  };
  

   const getassignedCounsDetails = () => {
    let counsList = counsellors;
    console.log("counsellor list obtained:", counsList);
   
    if(counsList){
        counsList = counsList.filter((doc)=>{
         if(doc!=null){
            return doc["type"] === 'counsellor' || doc["type"] === 'Counsellor'         
           }
           return false;
        });
        console.log("counsellor list before:", counsList);



        console.log("counsellors list obtained:", counsList)

        let deptOptions = [];

        for(let det of counsList){
            console.log("det:", det)
        
            let opt = {}
            if (!det.first_name){det.first_name = "" }
            if(!det.last_name){det.last_name = ""}
            opt['id'] = det.personId;
            opt['value'] = det.email
            opt['role'] = COUNSELLOR_ROLE;
            opt['department'] = det.department;
            opt['firstName'] = det.first_name;
            opt['lastName'] = det.last_name;
            opt['departmentId'] = det.departmentId;
            opt['displaytitle'] = det.displaytitle;
            opt['gvId'] = det.gvId;
            const name=  helperServices.getFullName(det.first_name,null,det.last_name);
            console.log("Camel case:",name);
                    opt['label'] = det.displaytitle+"."+" "+name +", "+"Dep of "+det.department +" ("+det.qualification+" )";
    
            deptOptions.push(opt)
            let docname="";
            console.log("counselor id in js",props.counsInfo.gvId);
            console.log("defined id",det.gvId);
            if(props.counsInfo && props.counsInfo.gvId == det.gvId){
                console.log("matching counsilor id",props.counsInfo.gvId)
                setSelectedOption(opt);  
                if(props.counsInfo.type === 'edit'){

                    docname = opt['label']; 
                    setAssignedCounsellor(docname);
                    console.log("assignedCounsellor:",docname);

                 } 
             
                setReload(!reload)
            } 
    
        }
         
          setOption(deptOptions);
          console.log(" deptOptions Response:", deptOptions);
    }
 
}


    const openAppointment = () => {
        setShowAppointmentModal(true);
    }
    
    const closeAppntmentModal = (event) =>{
        console.log("close appointment modal:",event);
        props.onModalClose(event);
        setShowAppointmentModal(false);
    }
    
    const getFormattedDate = (date) => {
        const reqdate = new Date(date); 
        let formatteddate = format(reqdate, "MMM dd yyyy");
        return formatteddate;
    }

    const toggle = () =>{
        setviewCounslDetails(!viewCounslDetails);
        //setReload(!reload);
    }

    const handleSubmit = () => {
        console.log("selected option:",selectedOption);
        if (selectedOption != null) {
            props.parentCallBack(selectedOption);
            } 
    }

     
const handleChange = (selectedOption) => {
    console.log("event", selectedOption);
    setSelectedOption(selectedOption);
    console.log("select option 2",selectedOption);
    if(props.from == "appointment"){
       props.parentCallBack(selectedOption);
    }
    
}


    return (
        <main key={reload}>
           {/* { props.from != "appointment" &&
            <>
           
            <h5 className="">Assign Counsellor
            {props.patientInfo && props.patientInfo.status && props.patientInfo.status === "open" ?
            <>
            {(viewCounslDetails) ?
           
             <span className="ml-3" onClick={(event) => toggle()} style={{cursor:'pointer'}} >
                <i className="material-icons" title='Edit'>{setviewCounslDetails == false ? 'edit' : 'visibility'}</i>
            </span>
            :
            <span className="ml-3" onClick={(event) => toggle()} style={{cursor:'pointer'}} >
                <i className="material-icons" title='View'>{setviewCounslDetails == true ? 'visibility' : 'edit'}</i>
            </span>
  
            }
            </>
            :''}
            </h5>
            {props.lastassigned && props.lastassigned.counsellor ?
            <label>Last Assigned Counsellor :&nbsp;{props.lastassigned.counsellor}</label>
            :''}
            </>} */}
            {props && props.patientInfo && props.patientInfo.status != 'preReg' ?
            <Card className="mb-0 p-2">
               
                    <h6>Counsellor Appointment Details</h6>
                    {(assignedCounsellor)  ?
                    <>
                        <label className="mb-1"><span className="text-muted">{assignedCounsellor}</span></label>
                        {patientCalender.map((calenderItem) => (
                            <span className="text-muted">{calenderItem.date}</span>
                         ))}
                          {props.lastassigned && props.lastassigned.counsellor ?
                        <label><span className="text-muted">Last Assigned Counsellor :&nbsp; {props.lastassigned.counsellor}</span></label>
                        :''}
                    </>
                   
                    :
                    <label className='text-center'>No counsellor assigned.</label>
                    }
                {props && props.patientInfo && props.patientInfo.status != 'close' ?
                    <button className='btn btn-primary btn-sm m-auto'  onClick={(event) => openAppointment(event)}>
                        Schedule Appointment
                    </button>
                :''}  
                    </Card>
                    :''}
                    {showAppointmentModal &&
                <>
                <Components.AppointmentScheduleModal 
                    patientData={patientVisitList[0]}
                    type="Counsellor"
                    CounsellorInfo = {props.counsInfo}
                    selectedCousellerInfo = {selectedOption}
                    onModalClose={(event) => closeAppntmentModal(event)}
                   />
                </>
                }
            {/* {(viewCounslDetails) ? 
             <div>
                <Card className="border">
                    <CardBody className="p-3">
                        {(assignedCounsellor != "")  ?
                        <>
                            <label>Counselor :&nbsp;{assignedCounsellor}</label>
                            {patientVisitList.length > 0 &&  patientVisitList[0].visit.capptdate && 
                                <>
                                <br/><label>Appointment Date :&nbsp;{getFormattedDate(patientVisitList[0].visit.capptdate)}</label><br/>
                                <label>Appointment Time :&nbsp;{patientVisitList[0].visit.cappthours}:{patientVisitList[0].visit.capptminutes}</label>
                                
                                </>
                            }
                        </>
                  
                        :
                        <label className='text-center'>No counsellor assigned.</label>
                        }
                    </CardBody>
                </Card>
               
                </div>
            :
             
            <div className={props.counsInfo.type && props.from != "appointment" ? 'card p-4':""}>
                                                                                                                       
                <div >
                <Select
                className="select-tag"
                name="Search Doctor"
                value={selectedOption}
                onChange={handleChange}
                isClearable
                isSearchable
                options={options}
                filterOption={createFilter(options)}
                defaultValue={selectedOption}
                ></Select>
                </div>

                <div className=" mt-4 fbtn justify-content-between">
                    {patientVisitList && patientVisitList.length> 0 && patientVisitList[0].visit && patientVisitList[0].visit.type != 'InPatient' &&
                        <Button type="submit"   onClick={(event) => openAppointment(event)} style={{float:'left'}}>Schedule Appointment</Button> 

                    }
                <Button type="submit" onClick={handleSubmit} style={{float:'right'}}>Save</Button>  
                </div>

              
               
            
            </div>
            
        
            
            } */}

           
             
        </main>
   
    );

}

export default Counsellor;
