import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { diagnosisActions} from "../../core/actions";
import PageTitle from "../common/PageTitle";
import { Row,FormInput } from "shards-react";

function ListPatient(props) {
  const [diagnosisData, setDiagnosisData] = useState([]);
  const [showPatientDetail, setShowPatientDetail] = useState(false);
  const [addNew, setAddNew] = useState(false);
  const [searchError, setSearchError] = useState('');
  const [searchText, setSearchText] = useState('');
  const [diagnosis, setDiagnosis] = useState(
    {page:1,pageSize:50,searchText: '',
    }
  );
  const dispatch = useDispatch();

  useEffect(() => {
   // listAllDiagnosis()
  },[])

  

  const listAllDiagnosis = () => {
    //Get all diagnosis of patients
    dispatch(diagnosisActions.getAllDiagnosis(diagnosis,(resCb) => {
      console.log("ResCb", resCb, "Props", diagnosisDetail);
      if (resCb) {
        if (diagnosisDetail) {
          let diagnosisInfo = diagnosisDetail;
          Object.keys(diagnosisInfo).forEach((key) => {
            if (!diagnosisInfo[key]) {
              diagnosisInfo[key] = "";
            }
          });
          setDiagnosisData(diagnosisInfo);
        }
      }
    }));
  }

  const diagnosisDetail = useSelector(state => state.diagnosisDetailsState.diagnosisDetail)

  const handleDiagnosisClick = (pDetail, isAddNew) => {
    isAddNew === true
      ? setAddNew(true)
      : setAddNew(false);

    dispatch(diagnosisActions.setDiagnosisPatientDetail(pDetail));

    console.log("add new : ", addNew);
    setShowPatientDetail(false);

    setTimeout(() => {
      setShowPatientDetail(true);
    }, 500);

    //Navigating to Diagnosis 
    props.history.push({
      pathname: `/DiagnosisMaster/${pDetail.basicinfo.patientId}`,
      state: {
        patientDetail: pDetail,
      },
    });
  }

  const handleInputChange = (value) => {
    if (value.length < 3) {
      setSearchText(value );
      setSearchError("Search Text should contain atleast 3 letters");
    } else {
      setSearchError("");
      setSearchText(value);
      handleSearch(value);
    }
  }

  const handleSearch = (value) => {
    var diagnosisState = diagnosis;
    diagnosisState['searchText'] = value;
    //this.setState({doctor:diagnosisState})
   // listAllDiagnosis()
  }

    return (
      <main className="mainsection list-patient">
        {/* Page Header */}
        <Row noGutters className="page-header mb-2">
          <h5 
            // subtitle="Dashboard" 
            className="text-sm-left mb-3">List of Patients</h5>
        </Row>

        <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <i style={{color:`#111b21`}} className="material-icons">search</i>
              </span>
            </div>
            <FormInput
              className="navbar-search bg-white"
              placeholder="Search Patients..."
              value={searchText}
              type="search"
              onChange={(e) => handleInputChange(e.target.value)}
            />
          </div>
        </form>
        {searchError && (
          <div style={{ color: "#EC4147" }}>
            <p>
              <span>{searchError}</span>
            </p>
          </div>
        )}

        <div className="main-content-container container-fluid cont ">
          <div className="row">
            <div className="mb-12 col-sm-12 col-md-12 hpcol bg">
              <div className="blog-comments card pcard">
                {diagnosisDetail &&
                  diagnosisDetail.length > 0 &&
                  diagnosisDetail.map((diagnosisDetail, idx) => (
                    <div
                      className="blog-comments__item d-flex p-1 cpointer"
                      key={idx}
                      onClick={(event) =>
                        handleDiagnosisClick(diagnosisDetail, false)
                      }
                    >
                      <div className="blog-comments__content">
                        <div className="blog-comments__meta">
                          <div className="text-dark">
                            {diagnosisDetail.basicinfo.fname}&nbsp;
                            {diagnosisDetail.basicinfo.mname}&nbsp;
                            {diagnosisDetail.basicinfo.lname}
                          </div>
                          <p className="m-0 text-muted">
                            Age : {diagnosisDetail.basicinfo.age} Years
                            &nbsp;Phone: {diagnosisDetail.basicinfo.phno}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
}

/* function mapStateToProps(state) {
    return {
      diagnosisDetail:state.diagnosisDetailsState.diagnosisDetail,
      dPatient:state.diagnosisDetailsState.dPatient,
    };
  }
  
  function mapDispatchToProps(dispatch) {
    return {
      getAllDiagnosis:(data,resCb) => dispatch(diagnosisActions.getAllDiagnosis(data,resCb)),
      setDiagnosisPatientDetail:(val) => dispatch(diagnosisActions.setDiagnosisPatientDetail(val)),
    };
  }
  
  const ListPatientWithProps = connect(mapStateToProps, mapDispatchToProps)(ListPatient); */
  
  export default ListPatient;
