import { Tabs, Tab, Fab, Container } from '@mui/material';
import React, { useState, useEffect } from 'react';

import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Search } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { doctorActions, ehrActions, patientActions } from '../../core/actions';
import { EPRESCRIPTION, PRODUCT_PHARMACY_LOCATION } from "../../shared/constants";
import Components from '..';

function Prescription(props) {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [tabValue, setTabValue] = useState(0);
    const dispatch = useDispatch();
    const [productList, setProductList] = useState([]);
    const consumptionOptions = ['0-0-0-0',
        '0-0-0-1',
        '0-0-1-0',
        '0-0-1-1',
        '0-1-0-0',
        '0-1-0-1',
        '0-1-1-0',
        '0-1-1-1',
        '1-0-0-0',
        '1-0-0-1',
        '1-0-1-0',
        '1-0-1-1',
        '1-1-0-0',
        '1-1-0-1',
        '1-1-1-0',
        '1-1-1-1'];
    const [dataDrop, setDataDrop] = useState(consumptionOptions.slice());
    const [selectedData, setSelectedData] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);
    const [focusedInputId, setFocusedInputId] = useState(null);
    const [formschema, setFormschema] = useState(false);
    const [schemajson, setSchemajson] = useState();
    const [patientId, setPatientId] = useState();
    const [visitId, setVisitId] = useState();
    const [isReadonly, setIsReadonly] = useState(false);
    const patientEhrid = useSelector((state) => state.patientDetailsState.ehrid);
    const [showpdfscreen, setpdfscreen] = useState(false);
    const [print, setPrint] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    var count = 0;



    const modifyNotes = {
        localMedicalBrand: '',
        instructionstoPatient: '',
        advicetoDispenser: '',
        comments: '',
        problemDiagnosis: '',
        dose: ''
    }
    useEffect(() => {
        setPatientId(props.patientId);
        setVisitId(props.visitId);
        getTemplateById();
        console.log("props in pres: ", props, props.data);
    }, [])

    // to get schema and get the prescription data in edit case
    const getTemplateById = () => {
        console.log("prescription method call in use effect");
        let data = [];
        console.log("resCb present");
        let dose = '';
        let consumption = '';
        let advice = '';
        let comments = '';
        let text = '';
        let id = '';
        if (props.data) {
            console.log("prescription data : ", props.data);
            let val = [];
            val = props.data;
            for (let items of val) {
                console.log("itms", items)
                if (items._type == "CLUSTER") {
                    for (let item of items.items) {
                        if (item._type === "CLUSTER") {
                            for (let i of item.items) {
                                console.log("i: ", i);
                                console.log("item value : ", i.name.value, i.value.value)
                                dose = i.value.value;
                            }
                        } else {
                            advice = item.value.value;
                            console.log("data of advice to dispencer after", item.value.value);
                        }
                    }
                }
                if (items._type == "ELEMENT") {
                    console.log("element is", items._type)
                    if (items.name.value == "Local medicinal product brand") {
                        console.log("item value : ", items.value.value);
                        text = items.value.value;
                    }
                    else if (items.name.value == 'Instructions to patient') {
                        consumption = items.value.value;
                        console.log("data: ", items.value.value);
                    } else {
                        comments = items.value.value;
                        console.log("data: ", items.value.value);
                    }
                }

            }
            console.log("onformchangeschemajson", val);

            console.log("modifynote : ", modifyNotes)
        }
        dispatch(
            ehrActions.getTemplateById(data, EPRESCRIPTION, (resCb) => {
                console.log("resCb check", resCb);
                if (resCb) {
                    let productsearch = '';
                    if (text == '') {

                        productsearch = "dol";
                    } else {
                        productsearch = text;
                    }
                    dispatch(
                        doctorActions.getProductDetails(productsearch, PRODUCT_PHARMACY_LOCATION, (productResponse) => {
                            console.log("productResponse check");
                            if (productResponse) {
                                console.log("productResponse present", productResponse);
                                console.log("Response of searched product", productResponse.erpProductList
                                );
                                if (productResponse.erpProductList) {
                                    let productList = productResponse.erpProductList;
                                    console.log("product List : ", productList)
                                    console.log("value ::: ", productList[0].productId)
                                    id = productList[0].productId;
                                    console.log("id : ", id);
                                    const newData = {
                                        id: id,
                                        text: text,
                                        dose: dose,
                                        consumption: consumption,
                                        advice: advice,
                                        comments: comments,
                                    };

                                    const newKey = newData.id.toString();
                                    if (props.mode == "edit") {

                                        setSearchResults(prevData => {
                                            return [{ ...newData, key: newKey }];
                                        });
                                        setIsReadonly(true);
                                    }
                                }
                                console.log("schema is ", resCb);
                                setSchemajson(resCb);

                                console.log("schema data: ", schemajson)
                                console.log("formschema1", formschema);
                            }
                        }
                        )
                    );
                }
            }
            ));
        console.log(" data get : ", dose, advice, consumption, comments, text, id)

    }

    // method called when the tab is changed
    const handleChangeTab = (event, newValue) => {
        setTabValue(newValue);
        setSearchResults([]);
        // setSelectedData('');
        setSearchQuery('');
        setShowDropdown(false);
    };

    // method to search on keypress
    const handleSearchInputChange = (event) => {
        const inputValue = event.target.value;
        setShowDropdown(false);
        dispatch(
            doctorActions.getProductDetails(inputValue, PRODUCT_PHARMACY_LOCATION, (resCb) => {
                if (resCb) {
                    console.log("Response of searched product", resCb.erpProductList
                    );
                    console.log("schema is ", resCb);
                    console.log("form obtained:", resCb.erpProductList);
                    resCb.erpProductList.forEach(element => {
                        if (!productList.some(item => item.id === element.productId)) {
                            const newData = {
                                id: parseInt(element.productId),
                                text: element.productName || '',
                                dose: '',
                                consumption: '',
                                advice: '',
                                comments: '',
                            };

                            const newKey = newData.id.toString();

                            setProductList(prevData => {
                                if (!prevData.some(item => item.id === newData.id)) {
                                    return [...prevData, { ...newData, key: newKey }];
                                }
                                return prevData;
                            });
                        }
                    });
                    console.log("data : ", productList)
                }
            }
            )
        );
        if (inputValue.length > 0) {
            setSearchQuery(inputValue);
            handleSearch();
        } else {
            setSearchQuery(inputValue);
            setSearchResults([]);
        }
    };

    // method to search 
    const handleSearch = () => {
        if (searchQuery.trim() === '') {
            setSearchResults([]);
        } else {
            console.log("data ::: ", productList)
            const results = productList.filter(item => item.text.toLowerCase().includes(searchQuery.toLowerCase()));
            setSearchResults(results);
        }
    };

    // method to add or update prescription
    const handleAdd = (result) => {
        console.log("result : ", result, selectedData)
        const existingItemIndex = selectedData.findIndex(item => item.id === result.id);
        if (existingItemIndex !== -1) {
            console.log("existingItemIndex : ", existingItemIndex)
            // Update existing item
            console.log("update")
            const updatedSelectedData = [...selectedData];
            const existingData = [...selectedData];
            updatedSelectedData[existingItemIndex] = {
                id: result.id,
                text: result.text,
                dose: { label: 'Dose', value: result.dose ? result.dose : existingData[existingItemIndex]?.dose?.value },
                consumption: { label: 'Consumption', value: result.consumption ? result.consumption : updatedSelectedData[existingItemIndex]?.consumption?.value },
                advice: { label: 'Advice', value: result.advice ? result.advice : existingData[existingItemIndex]?.advice?.value },
                comments: { label: 'Comments', value: result.comments ? result.comments : existingData[existingItemIndex]?.comments?.value }
            };
            console.log("updatedSelectedData : ", updatedSelectedData)
            if (result.dose || result.consumption || result.advice || result.comments) {

                setSelectedData(updatedSelectedData);
                console.log("data is : ", selectedData)
            }
        } else {
            // Add new item
            console.log("add")
            const newData = {
                id: result.id,
                text: result.text,
                dose: { label: 'Dose', value: result.dose },
                consumption: { label: 'Consumption', value: result.consumption },
                advice: { label: 'Advice', value: result.advice },
                comments: { label: 'Comments', value: result.comments }
            };
            if (result.dose || result.consumption || result.advice || result.comments) {
                setSelectedData([...selectedData, newData]);
                console.log("data is : ", selectedData)
            }
        }

        setShowDropdown(false);

        // Reset item in searchResults
        setSearchResults((prevResults) =>
            prevResults.map((prevResult) =>
                prevResult.id === result.id ? { ...prevResult, dose: '', consumption: '', advice: '', comments: '' } : prevResult
            )
        );
    };

    // method to remove selected prescription
    const handleCancel = (index) => {
        const updatedSelectedData = [...selectedData];
        updatedSelectedData.splice(index, 1);
        setSelectedData(updatedSelectedData);
    };

    // method to handle consumption dropdown select
    const handleOptionSelect = (option, id) => {
        console.log("option : ", option, id)
        setSearchResults((prevResults) =>
            prevResults.map((prevResult) =>
                prevResult.id === id ? { ...prevResult, consumption: option } : prevResult
            )
        );

        setShowDropdown(false);
    };

    //method to handle consumption dropdown arrow buttons navigation
    const handleKeyDown = (event, id) => {
        console.log("event.key : ", event.key, dataDrop)
        if (event.key === 'ArrowDown' && selectedOptionIndex < dataDrop.length - 1) {
            setSelectedOptionIndex(selectedOptionIndex + 1);
        } else if (event.key === 'ArrowUp' && selectedOptionIndex > 0) {
            setSelectedOptionIndex(selectedOptionIndex - 1);
        } else if (event.key === 'Enter' && selectedOptionIndex !== -1) {
            console.log("dataDrop[selectedOptionIndex] : ", dataDrop[selectedOptionIndex])
            handleOptionSelect(dataDrop[selectedOptionIndex], id);
            setShowDropdown(false);
        }
        setFocusedInputId(id);
    };

    // method to auto fill data from selected prescription to the search result
    const handleSelectedItemClick = (selectedItem) => {
        console.log("selectedItemId : ", selectedItem.id, selectedItem);
        console.log("productList : ", productList)
        const results = productList.filter(item => item.id == selectedItem.id);
        console.log("results : ", results)
        const newData = {
            id: selectedItem.id,
            text: selectedItem.text,
            dose: selectedItem.dose.value,
            consumption: selectedItem.consumption.value,
            advice: selectedItem.advice.value,
            comments: selectedItem.comments.value,
        };

        const newKey = newData.id.toString();

        setSearchResults(prevData => {
            return [{ ...newData, key: newKey }];
        });
        console.log("SearchResults : ", searchResults)
        setSearchQuery('');

    };

    // method to handle changes done in dose field
    const handleDoseChange = (id, value) => {
        console.log("In handleinput change", id, value,);
        setSearchResults(prevData =>
            prevData.map(item =>
                item.id === id ? { ...item, dose: value } : item
            )
        );
        console.log("data : ", productList);
    };

    // method to handle changes done in consumption field
    const handleConsumptionChange = (result, e) => {
        let value = e.target.value;

        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            value = value.slice(0, -1);
        } else {
            value = value.replace(/[^0-1]/g, '');

            if (value.length > 1) {
                value = value.split('').join('-');
            }
            value = value.slice(0, 7);
        }

        setSearchResults((prevResults) =>
            prevResults.map((prevResult) =>
                prevResult.id === result.id ? { ...prevResult, consumption: value } : prevResult
            )
        );

        const userInput = value.toLowerCase();
        const filteredOptions = consumptionOptions.filter((option) =>
            option.toLowerCase().startsWith(userInput)
        );

        setDataDrop(filteredOptions);
        console.log("filteredOptions : ", filteredOptions)
        // Update showDropdown based on whether there are matching options
        setShowDropdown(userInput.length > 0 && filteredOptions.length > 0);

        setSelectedOptionIndex(-1);
        setFocusedInputId(result.id);
    };

    // method to handle changes done in advise field
    const handleAdviceChange = (id, value) => {
        console.log("In handleAdviceChange ", id, value,);
        setSearchResults(prevData =>
            prevData.map(item =>
                item.id === id ? { ...item, advice: value } : item
            )
        );
        console.log("data : ", productList);
    };

    // method to handle changes done in comments field
    const handleCommentsChange = (id, value) => {
        console.log("In handleCommentsChange", id, value,);
        setSearchResults(prevData =>
            prevData.map(item =>
                item.id === id ? { ...item, comments: value } : item
            )
        );
        console.log("data : ", productList);
    };

    // method to handle submit
    const handleSubmit = (flag) => {
        console.log("schema in sumbit : ", schemajson)
        console.log("data : ", patientId, visitId, patientEhrid, print);
        setPrint(true);
        onConvertSchematoJsonprescription(schemajson, flag);
    }

    // method to modify cluster
    const modifyCluster = (feildname, filedvalue) => {
        if (feildname == "Advice to dispenser") {
            modifyNotes.advicetoDispenser = filedvalue;
        }
    }

    // method to modify element
    const modifyElement = (feildname, filedvalue) => {
        console.log("feildname,filedvalue", feildname, filedvalue)
        if (feildname == "Local medicinal product brand") {
            modifyNotes.localMedicalBrand = filedvalue;
        }
        else if (feildname == "Instructions to patient") {
            if (filedvalue === null || filedvalue === '') {
                modifyNotes.instructionstoPatient = '';
            } else {
                modifyNotes.instructionstoPatient = filedvalue
            }
        }
        else if (feildname == "Comment") {
            modifyNotes.comments = filedvalue;
        }
        else if (feildname == "Dose unit") {
            modifyNotes.dose = filedvalue;
        }
    }

    //method to convert schema to json
    const onConvertSchematoJsonprescription = (data, flag) => {
        console.log("schemajson : ", schemajson);
        console.log("jsonconvert:", data);
        console.log("schema :", schemajson);
        selectedData.forEach(element => {
            console.log("element : ", element)

            for (let content of schemajson.content) {
                for (let events of content.activities) {
                    for (let items of events.description.items) {
                        console.log("itms", items)
                        if (items._type == "CLUSTER") {
                            for (let item of items.items) {
                                if (item._type === "CLUSTER") {
                                    for (let i of item.items) {
                                        console.log("i: ", i);
                                        let elementName1 = i.name.value;
                                        i.value.value = element['dose'].value;
                                        console.log("item value : ", i.name.value, i.value.value)
                                        modifyElement(elementName1, i.value.value);
                                    }
                                } else {
                                    console.log("field", item.name.value, data[item.name.value]);
                                    item.value.value = element['advice'].value;
                                    modifyCluster(item.name.value, item.value.value);
                                    console.log("data of advice to dispencer after", item.name.value, data[item.name.value]);
                                }
                            }
                        }
                        if (items._type == "ELEMENT") {
                            console.log("element is", items._type)
                            if (items.name.value == "Local medicinal product brand") {
                                console.log("item value : ", items.name.value);
                                items.value.value = element['text'];
                                modifyElement(items.name.value, items.value.value);
                            }
                            else if (items.name.value == 'Instructions to patient') {
                                items.value.value = element['consumption'].value;
                                modifyElement(items.name.value, items.value.value);
                                console.log("data: ", items.value.value);
                            } else {
                                items.value.value = element['comments'].value;
                                modifyElement(items.name.value, items.value.value);
                                console.log("data: ", items.value.value);
                            }
                        }
                    }

                }
            }
            console.log("onformchangeschemajson", schemajson);
            console.log("modifynote : ", modifyNotes, print)
            onFormChange(schemajson, flag);

        });
        setTimeout(() => {
            console.log("filteredList.length , count : ", filteredList.length, count, selectedData.length)
            if (flag && selectedData.length == count && filteredList.length == count) {
                console.log("filteredList.length = count : ", filteredList.length, count)
                console.log("Data to print : ", filteredList)
                setpdfscreen(true);
                setSelectedData('');
                props.onModalClose();
            }
        }, 3000);

    };

    // method to save the data
    const onFormChange = (data, flag) => {
        console.log("---Form changed---", data);
        console.log("---patientEhrid---", patientEhrid);
        let notesDetails = " ";
        notesDetails = modifyNotes.localMedicalBrand + ' ' + ' ' + ' ' + modifyNotes.dose + ' ' + modifyNotes.advicetoDispenser + ' ' + modifyNotes.instructionstoPatient;
        console.log("prescriptionDetails", notesDetails);
        dispatch(
            ehrActions.saveComposition(
                data,
                patientId,
                visitId,
                EPRESCRIPTION,
                patientEhrid,
                notesDetails,
                (resCb) => {
                    if (resCb) {
                        console.log("Diagnosis Master API Response:", resCb);
                        console.log("forjjfnjgjgi", flag, print)
                        if (flag) {
                            console.log("its print")
                            if (resCb.successMessages) {
                                let proList = resCb.procedureList;
                                console.log("proList : ", proList, proList.length)
                                proList.forEach(element => {
                                    filteredList.push(element);
                                    count++;
                                });
                                console.log("count is : ", count, " : list is : ", filteredList)
                            } else {
                                if (resCb.errorMessages) {
                                    props.onModalClose({ error: resCb.errorMessages[0] });
                                }
                            }

                        } else {
                            console.log("its not print")
                            if (resCb.successMessages) {
                                props.onModalClose({ success: resCb.successMessages[0] });
                            } else {
                                if (resCb.errorMessages) {
                                    props.onModalClose({ error: resCb.errorMessages[0] });
                                }
                            }

                        }
                    }


                }


            )
        );
    };

    // method to save and print
    const openPdfModal = () => {
        console.log("print is set to : ", print)
        handleSubmit(true);
    };

    return (
        <>
            <div className='w-100 flex-wrap flex-column border rounded p-3 align-items-center ' >
                <div className="prescription-container mb-1">
                    <Tabs value={tabValue} onChange={handleChangeTab} indicatorColor="primary" textColor="primary">
                        <Tab label="ALL" />
                        <Tab label="FAV" />
                    </Tabs>
                    {tabValue === 0 && (
                        <div className='mt-2'>
                            {props.mode == "new" && (
                                <div className='d-flex align-items-center searchbox my-2'>
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        value={searchQuery}
                                        className='border rounded flex-fill p-2 '
                                        onChange={handleSearchInputChange}
                                    />
                                    <Fab size="small" className='ml-3 bg-primary text-white ' aria-label="remove" variant="extended" onClick={() => handleSearch()}>
                                        <Search />
                                    </Fab>
                                </div>
                            )}
                            {searchResults.map((result) => (

                                <div key={result.id} className='d-flex align-items-center p-2 border rounded w-100 presc-serach-result bg-white mb-2'>

                                    <div className='d-flex border rounded align-items-center mr-2 presc-serach-result presc-img' style={{
                                        width: '15%'
                                    }}>
                                        <img src="https://tse4.mm.bing.net/th?id=OIP._FRJCbrjh93KCZTzNVyP3AHaE8&pid=Api&P=0&h=180" alt="Item" className='rounded w-100 h-100'
                                        />
                                    </div>
                                    <div className='d-flex flex-column flex-fill'>
                                        <div className='d-flex mb-2'>
                                            <label className='text-primary text-left mr-2 m-0'><b>{result.text}</b></label>
                                        </div>
                                    </div>

                                    <div className='d-flex flex-column '>
                                        <div className='d-flex align-items-center mb-2'>
                                            <label className='text-left mr-2 w-25'
                                            >Dose</label>
                                            <input
                                                type="text"
                                                placeholder="Enter dosage here..."
                                                className='border rounded p-2 w-100 '
                                                disabled={isReadonly}
                                                value={result.dose}
                                                onChange={(e) => handleDoseChange(result.id, e.target.value)}
                                            />
                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <label className='text-left mr-2'
                                            >Consumption</label>

                                            <div className='relativeInlineBlock'>
                                                <input
                                                    type="text"
                                                    value={result.consumption}
                                                    className='border rounded p-2 w-100 '
                                                    disabled={isReadonly}
                                                    onChange={(e) => handleConsumptionChange(result, e)}
                                                    onKeyDown={(e) => handleKeyDown(e, result.id)}
                                                    placeholder="e.g., 0-0-0-0"
                                                />

                                                {showDropdown && dataDrop.length > 1 && focusedInputId === result.id && (
                                                    <div className='position-relative' style={{ zIndex: '1001' }}>
                                                        <ul key={result.id} className='border rounded w-100 bg-white position-absolute p-0 list-unstyled'>
                                                            {dataDrop.map((option, index) => (
                                                                <li key={option}>
                                                                    <button
                                                                        onClick={() => handleOptionSelect(option, result.id)}
                                                                        className='rounded text-left p-2 w-100'
                                                                        style={{
                                                                            backgroundColor: index === selectedOptionIndex ? '#f0f0f0' : 'white'
                                                                        }}
                                                                    >
                                                                        {option}
                                                                    </button>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>

                                        </div>
                                        <div className='d-flex align-items-center mb-2'>
                                            <label className='text-left mr-2 w-25'
                                            >Advice</label>
                                            <input
                                                type="text"
                                                placeholder="Advice to the patient..."
                                                className='border rounded p-2 w-100 '
                                                disabled={isReadonly}
                                                value={result.advice}
                                                onChange={(e) => handleAdviceChange(result.id, e.target.value)} />
                                        </div>
                                        <div className='d-flex align-items-center text-left'>
                                            <label className='mr-2 w-25'
                                            >Comments</label>
                                            <input
                                                type="text"
                                                placeholder="Add your comments here..."
                                                className='border rounded p-2 w-100 '
                                                disabled={isReadonly}
                                                value={result.comments}
                                                onChange={(e) => handleCommentsChange(result.id, e.target.value)} />
                                        </div>
                                    </div>

                                    {props.mode == "new" && (
                                        < div className='d-flex align-items-center mr-2 justify-content-center h-10em' style={{ width: '15%' }}>
                                            <button type="submit" className="btn-primary p-2 border rounded w-75"
                                                onClick={(e) => handleAdd(result)}
                                            >
                                                Add
                                            </button>
                                        </div>
                                    )}
                                </div>

                            ))}
                        </div>
                    )}
                    {tabValue === 1 && (
                        <div>
                            Your favourites will be here soon...
                        </div>
                    )}


                    {selectedData.length > 0 && (
                        <div className='text-left w-100' >
                            <label><b>Prescription Selected</b></label>
                            {selectedData.map((selected, index) => (
                                <div key={index} className='d-flex p-3 border rounded justifySpaceBetween w-100 bg-white mb-2'
                                >
                                    <div className='mr-2'>
                                        <label className='text-primary'><b>{selected.text}</b></label>
                                    </div>
                                    <Container style={{ overflowY: 'auto' }} onClick={() => handleSelectedItemClick(selected)}>
                                        <div className='d-flex flex-wrap justify-content-between'>
                                            {Object.keys(selected).map((label, labelIndex) => (
                                                typeof selected[label] === 'object' && (
                                                    <div key={labelIndex} className='mb-2'>
                                                        <div>
                                                            <p><b>{selected[label].label}:</b> {selected[label].value}</p>
                                                        </div>
                                                    </div>
                                                )
                                            ))}
                                        </div>
                                    </Container>
                                    <Fab size="small" className='text-white bg-danger' aria-label="remove" onClick={() => handleCancel(index)}>
                                        <RemoveCircleOutlineIcon />
                                    </Fab>
                                </div>
                            ))}
                            <div className='p-3 w-100 text-right '>
                                <button
                                    type="submit"
                                    className="btn-primary p-2 border rounded col-2"
                                    onClick={(e) => handleSubmit()}
                                >
                                    Save
                                </button>
                                <button
                                    type="submit"
                                    className="btn-primary p-2 border rounded col-2"
                                    onClick={(e) => {
                                        openPdfModal();
                                    }}
                                >
                                    Print
                                </button>
                            </div>
                        </div>
                    )}



                </div >
            </div >
            {showpdfscreen ? (
                <div className="">
                    <Components.pdfprint
                        doctorInfo={''}
                        prescriptionList={filteredList}
                        presc={props.form}
                        ehrid={patientEhrid}
                        selectedPatient={props.patientInfo}
                    />
                </div>
            ) : (
                ""
            )}
        </>
    );
};
export default Prescription;
