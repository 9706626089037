import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Components from "..";
import {
    align,
    font,
    fontColor,
    fontSize,
    formatBlock,
    hiliteColor,
    horizontalRule,
    lineHeight,
    list,
    paragraphStyle,
    table,
    template,
    textStyle,
    image,
    link
} from "suneditor/src/plugins";
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import { Col, Form, FormInput, Alert, Row } from "shards-react";
import { doctorActions, patientActions } from '../../core/actions';
import { CASERECORD, DISCHARGESUMMARY } from '../../shared/constants';
import {
    FormTextarea
} from "shards-react";
import { format } from "date-fns";

function DischargeSummary(props) {

    const options = {
        showPathLabel: false,
        plugins: [
            align,
            font,
            fontColor,
            fontSize,
            formatBlock,
            hiliteColor,
            horizontalRule,
            lineHeight,
            list,
            paragraphStyle,
            table,
            template,
            textStyle,
            image,
            link
        ],
        buttonList: [
            ["undo", "redo"],
            ["font", "fontSize", "formatBlock"],
            ["paragraphStyle"],
            [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript"
            ],
            ["fontColor", "hiliteColor"],
            ["removeFormat"],
            "/", // Line break
            ["outdent", "indent"],
            ["align", "horizontalRule", "list", "lineHeight"],
            ["table", "link", "image"]
        ],
        formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
        font: [
            "Arial",
            "Calibri",
            "Comic Sans",
            "Courier",
            "Garamond",
            "Georgia",
            "Impact",
            "Lucida Console",
            "Palatino Linotype",
            "Segoe UI",
            "Tahoma",
            "Times New Roman",
            "Trebuchet MS"
        ]
    }

    const [summary, setSummary] = useState(
        {
            "Chief Complaints": "",
            "Clinical Summary": "",
            "Past and Treatment History": "",
            "Relevant Medical History": "",
            "Relevant Family History": "",
            "Personal History": "",
            "Premorbid Personality": "",
            "Psycho-social Factors": "",
            "Mental State Examination": "",
            "Final Diagnosis": "",
            "General physical examination": "",
            "Clinical Investigations": "",
            "Course of treatment in hospital": "",
            "Advice on discharge": "",
            "Appointment Confirmation Number": "",
            "Admission Type": "",
            "Ksmha Intimation Number": "",
            "Nominated Representative": "",
            dischargedt: ""
        })
    const [dischargeId, setDischargeId] = useState()
    const [patientdetails, setPatientDetails] = useState({
        name: '',
        visitDate: ''

    })
    const [dischrgeDetails, setDischrgeDetails] = useState({
        id: '',
        patientId: '',
        dischargeDate: ''
    })
    const [patientRevisitdetails, setPatientRevisitdetails] = useState('')
    const [dischargedt, setDischargedt] = useState('')
    const [filterdProList, setfilterdProList] = useState();
    const [loadsummary, setLoadSummary] = useState(false)
    const [formErrors, setFormErrors] = useState({
        "Final Diagnosis": '',
        "Clinical Summary": ''
    });
    const [details, setDetails] = useState(
        {
            id: '',
            patientId: '',
            visitId: '',
            markForDel: '',
            groupname: '',
            preparedBy: '',
            approver: ''
        }

    )
    const [doctorList, setDoctorList] = useState([]);
    const [ServerRes, setServerRes] = useState({ success: '', error: '' })
    const dispatch = useDispatch();
    const [dischargePrint, setdischargePrint] = useState(false);
    const [fullName, setFullName] = useState('');
    const [roomName, setRoomName] = useState('');
    const [phoneNum, setPhoneNum] = useState('');
    const doctorsDetail = useSelector(state => state.doctorDetailsState.doctorList);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [followUpDateFlag, setFollowUpDateFlag] = useState(false);
    const [dischargeDateFlag, setDischargeDateFlag] = useState(false);
    const [todayDate, setTodayDate] = useState(new Date());
    const [doctors, setDoctors] = useState([])
    useEffect(() => {
        getDoctorList();
        getPatientProfile();
        console.log("selected : ", selectedItems)
        document.getElementById('mainpage').style.paddingLeft = "0px";
        document.getElementById('sidebar').style.display = "none";
    }, [])
    const patientSummaryClick = (docList) => {
        console.log("patientSummaryClick data : ", props);
        details.patientId = props.match.params.patientid;
        details.visitId = props.match.params.visitid;
        details.groupname = DISCHARGESUMMARY;
        details.markForDel = false;

        dispatch(patientActions.getDischargeSummary(summary, details, resCb => {
            console.log('discharge summary in api call', resCb);
            if (resCb) {
                console.log("valist:", resCb.vaList);
                for (let key of resCb.vaList) {
                    console.log("id : ", key.targetid);
                    docList.forEach(element => {
                        if (element) {
                            console.log("element : ", element);
                            if (element.personId) {
                                console.log("personId present")
                                if (element.personId == key.targetid) {
                                    console.log("data matched")
                                    if (!selectedItems.some(item => item.personId === element.personId)) {
                                        setSelectedItems(prevItems => [...prevItems, element]);
                                        console.log("Added to selectedItems is :", element);
                                        console.log("Updated selectedItems:", selectedItems);
                                    }
                                }
                            }
                        }
                    })
                }
                if (resCb && resCb.procedureList != null && resCb.procedureList.length > 0) {
                    if (resCb.visit) {
                        console.log("date is present");
                        let discharge = resCb.visit.dischargeDate;
                        if (discharge == null || discharge == '') {

                            console.log("date is not present");
                            var dateObject = new Date();

                            var year = dateObject.getFullYear();
                            var month = String(dateObject.getMonth() + 1).padStart(2, '0');
                            var day = String(dateObject.getDate()).padStart(2, '0');
                            var hours = String(dateObject.getHours()).padStart(2, '0');
                            var minutes = String(dateObject.getMinutes()).padStart(2, '0');

                            var formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
                            console.log("Discharge date is", discharge)

                            setDischargedt(formattedDateTime);
                        } else {

                            console.log("date ::: ", discharge)
                            var dt = new Date(discharge);
                            var year = dt.getUTCFullYear();
                            var month = String(dt.getUTCMonth() + 1).padStart(2, '0');
                            var day = String(dt.getUTCDate()).padStart(2, '0');
                            var hours = String(dt.getUTCHours()).padStart(2, '0');
                            var minutes = String(dt.getUTCMinutes()).padStart(2, '0');
                            var formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

                            // Output the result
                            console.log("formatted date : ", formattedDateTime);

                            setDischargedt(formattedDateTime);
                            console.log("date is", dischargedt);
                        }
                    }
                    if (resCb.revisit) {
                        console.log("date is present");
                        let scheduleddt = resCb.revisit.scheduleddate;
                        console.log("date ::: ", scheduleddt)
                        setPatientRevisitdetails(scheduleddt);
                        console.log("date is", patientRevisitdetails);
                    } else {
                        console.log("date is not present");
                        let followUpDateVar;
                        let folDate = new Date();
                        followUpDateVar = format(folDate.setDate(folDate.getDate() + 14), "yyyy-MM-dd");
                        console.log("followUpDateVar", followUpDateVar)
                        setPatientRevisitdetails(followUpDateVar);
                    }

                    const filterList = resCb.procedureList.filter(items => items.groupname === DISCHARGESUMMARY);
                    const keys = Object.keys(summary);
                    var dischargeNotes = { ...summary };
                    var filteredProList = [];
                    filterList.forEach(item => {
                        for (let key of keys) {
                            if (item.claSS == key) {
                                dischargeNotes[key] = item.notes;
                                filteredProList.push(item);
                                setSummary({ ...dischargeNotes });
                            }
                        }
                        setfilterdProList([...filteredProList])

                    });

                } else {
                    details.groupname = CASERECORD
                    console.log("patientSummaryClick data : ", summary)
                    dispatch(patientActions.getDischargeSummary(summary, details, resCb => {
                        if (resCb) {
                            console.log("resCb doctor Response:", resCb);
                            if (resCb.procedureList) {
                                if (resCb.revisit) {
                                    console.log("date is present");
                                    let scheduleddt = resCb.revisit.scheduleddate;
                                    console.log("date ::: ", scheduleddt)
                                    setPatientRevisitdetails(scheduleddt);
                                    console.log("date is", patientRevisitdetails);
                                } else {
                                    console.log("date is not present");
                                    let followUpDateVar;
                                    let folDate = new Date();
                                    followUpDateVar = format(folDate.setDate(folDate.getDate() + 14), "yyyy-MM-dd");
                                    console.log("followUpDateVar", followUpDateVar)
                                    setPatientRevisitdetails(followUpDateVar);
                                }
                                if (resCb.visit) {
                                    console.log("date is present");
                                    let discharge = resCb.visit.dischargeDate;
                                    if (discharge == null || discharge == '') {

                                        console.log("date is not present");
                                        var dateObject = new Date();

                                        var year = dateObject.getFullYear();
                                        var month = String(dateObject.getMonth() + 1).padStart(2, '0');
                                        var day = String(dateObject.getDate()).padStart(2, '0');
                                        var hours = String(dateObject.getHours()).padStart(2, '0');
                                        var minutes = String(dateObject.getMinutes()).padStart(2, '0');
                                        var formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
                                        console.log("Discharge date is", discharge)

                                        setDischargedt(formattedDateTime);
                                    } else {

                                        console.log("date ::: ", discharge)
                                        var dt = new Date(discharge);

                                        var year = dt.getUTCFullYear();
                                        var month = String(dt.getUTCMonth() + 1).padStart(2, '0');
                                        var day = String(dt.getUTCDate()).padStart(2, '0');
                                        var hours = String(dt.getUTCHours()).padStart(2, '0');
                                        var minutes = String(dt.getUTCMinutes()).padStart(2, '0');

                                        var formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;

                                        console.log("formatted date : ", formattedDateTime);

                                        setDischargedt(formattedDateTime);
                                        console.log("date is", dischargedt);
                                    }
                                }
                                const filterList = resCb.procedureList.filter(items => items.groupname === CASERECORD);
                                const keys = Object.keys(summary);
                                var dischargeNotes = { ...summary };
                                var filteredProList = [];
                                filterList.forEach(item => {
                                    for (let key of keys) {
                                        if (item.claSS == key) {
                                            dischargeNotes[key] = item.notes;
                                            filteredProList.push(item);
                                            setSummary({ ...dischargeNotes });
                                        }
                                    }
                                    setfilterdProList([...filteredProList])

                                });

                            }
                        }
                    })
                    );
                    console.log("inside discharge resCb  summary.type = 'save' ", summary);
                }

            }
        }
        ))


    }

    const getPatientProfile = () => {
        let patientId = props.match.params.patientid;
        console.log("doctorlist : ", doctorsDetail);
        dispatch(
            patientActions.getPatientProfile(patientId, (resCb) => {
                if (resCb) {
                    console.log(
                        "getPatientProfile resp age phno and last visited:",
                        resCb
                    );
                    dispatch(patientActions.setPatientDetails(resCb));
                    let fullname = `${resCb.fname} ${resCb.mname || ''} ${resCb.lname || ''}`;
                    console.log("Full Name:", fullname);
                    setFullName(fullname);
                    let room = resCb.room.roomName;
                    let phone = resCb.phno
                    setRoomName(room);
                    setPhoneNum(phone);
                    setPatientDetails({ resCb });
                    console.log("patient details data : ", patientdetails)
                }
            })
        );
    }

    const getDoctorList = () => {
        let data = {};
        let docList = [];
        dispatch(
            doctorActions.getDoctorList(data, (resCb) => {
                if (resCb) {
                    console.log("doctorlist in packageservice", resCb);
                    for (let doctorList of resCb) {
                        if (doctorList) {
                            if (doctorList.personId) {
                                docList.push(doctorList);
                                setDoctorList([...docList]);
                            }
                        }
                    }
                    console.log("doctorlist", docList)
                    patientSummaryClick(docList);

                }
            })
        );

    };

    const handleInputChange = (name, value) => {
        console.log("In handleinput change", name, value);
        var summarydata = summary;
        formErrors[name] = "";
        var note = details;
        var keyList = [];
        summarydata[name] = value;
        note[name] = value;
        //  To handle data in edit case
        if (filterdProList && filterdProList.length > 0) {
            filterdProList.forEach(item => {
                if (item.claSS == name) {
                    keyList.push({ claSS: item.claSS, notes: value, id: item.id, patientId: item.patientId, visitId: item.visitId });
                }
                setfilterdProList([...keyList]);
            });
            console.log("List filtered : ", filterdProList);
        }
        setSummary({ ...summarydata });
        setDetails({ ...note });
    };

    const handlePrint = (event, isupvote) => {
        console.log('In handleinput change', summary);
        var summarydata = summary;
        if (selectedItems) {
            let nameList = [];
            selectedItems.forEach(element => {
                console.log("selectedItems has doctors")
                let name = element.displaytitle + " " + element.first_name;
                console.log("name : ", name);
                nameList.push(name);
                console.log("nameList : ", nameList);
            });
            setDoctors(nameList)
        }
        setSummary({ ...summarydata });
        setdischargePrint(true)

    }
    const handleDischargePrint = () => {
        setdischargePrint(false);
    }

    const validateField = (fieldName, value) => {
        let fieldValidationErrors = formErrors;

        switch (fieldName) {
            case "Final Diagnosis":
                fieldValidationErrors["Final Diagnosis"] = value.length > 0 ? '' : 'Please Enter Final Diagnosis';
                break;
            case "Clinical Summary":
                fieldValidationErrors["Clinical Summary"] = value.length > 0 ? '' : 'Please Enter Clinical Summary';
                break;
            default:
                break;
        }
        setFormErrors(fieldValidationErrors);
    }

    const handleSubmit = (event, isupvote) => {
        Object.keys(summary).forEach((key) => {
            validateField(key, summary[key])
            console.log("patientInfo:", event)
        })
        validateForm(event);
        setMessageStrip({ success: event.successMessages });
        setTimeout(() => {
            console.log("props:", props);
        }, 3000);

    };
    const setMessageStrip = (e) => {
        console.log("message obtained", e);
        var ServerResVal = ServerRes;
        if (e.success) {
            console.log("success message:", e.success);
            ServerResVal.success = e.success;
        }
        else {
            console.log("error message:", e.error);
            ServerResVal.error = e.error;
        }
        setServerRes({ ...ServerResVal });
        setTimeout(() => {
            closeAlert();
        }, 5000);
    };

    const validateForm = (event) => {
        console.log('formErrors : ', formErrors)
        let formValid = formErrors["Final Diagnosis"] === "" && formErrors["Clinical Summary"] === "";
        if (formValid) {
            details.patientId = props.match.params.patientid
            details.visitId = props.match.params.visitid
            details.id = dischargeId;
            details.groupname = DISCHARGESUMMARY
            console.log('In handleinput change', summary);
            var proList = [];
            var doctorlist = [];
            var dischargeddt = []
            const keys = Object.keys(summary);
            var ServerResVal = ServerRes;

            // handle submit in edit case
            if (filterdProList && filterdProList.length > 0) {
                filterdProList.forEach(item => {
                    proList.push({ id: item.id, claSS: item.claSS, notes: item.notes, patientId: item.patientId, visitId: item.visitId })
                    if (item.claSS == 'dischargedt') {
                        dischargeddt.push({ id: item.visitId, patientId: item.patientId, dischargeDate: item.notes })
                    }
                    console.log("Update : ", proList);
                });
            } else {
                // handle submit in add
                for (let key of keys) {
                    proList.push({ claSS: key, notes: summary[key], patientId: props.match.params.patientid, visitId: props.match.params.visitid })
                    console.log("Insert : ", proList);
                    if (key == 'dischargedt') {
                        console.log("summary[key] : ", summary[key])
                        dischargeddt.push({ id: props.match.params.visitid, patientId: props.match.params.patientid, dischargeDate: summary[key] })
                        setDischrgeDetails(dischargeddt);
                    }
                }
            }
            //handles selected doctorlist during submit
            if (selectedItems && selectedItems.length > 0) {
                selectedItems.forEach(item => {
                    console.log("item : ", item)
                    doctorlist.push({ targetid: item.personId, visitid: props.match.params.visitid, status: 'open', type: 'Doctor' })
                    console.log("Insert Visit Assignment : ", doctorlist);
                });
            }
            // handles revisit date during submit
            let tempArray = {
                patientId: props.match.params.patientid,
                lastvisitId: props.match.params.visitid,
                scheduleddate: patientRevisitdetails,

            }
            console.log("tempArray : ", tempArray, dischargeddt, dischrgeDetails)
            dispatch(patientActions.saveDischargeSummary(proList, resCb => {
                if (resCb) {
                    if (resCb.successMessages) {
                        ServerResVal.success = resCb.successMessages;
                        setServerRes({ success: 'Discharge summary added successfully' });
                    } else {
                        ServerResVal.error = resCb.errorMessages;
                        setServerRes({ error: 'Failed to add Discharge summary' });
                    }
                    setTimeout(() => {
                        setServerRes({ success: '', error: '' });
                    }, 3000);
                    setServerRes({ ...ServerResVal });
                    console.log('resCb : ', resCb);
                }
            }))
            dispatch(patientActions.followUpByDoctor(tempArray, resCb => {
                if (resCb) {
                    if (resCb.successMessages) {
                        ServerResVal.success = resCb.successMessages;
                        setServerRes({ success: 'Discharge summary added successfully' });
                    } else {
                        ServerResVal.error = resCb.errorMessages;
                        setServerRes({ error: 'Failed to add Discharge summary' });
                    }
                    setTimeout(() => {
                        setServerRes({ success: '', error: '' });
                    }, 3000);
                    setServerRes({ ...ServerResVal });
                    console.log('resCb : ', resCb);
                }
            }))
            dispatch(patientActions.saveVisitAssignment(doctorlist, resCb => {
                if (resCb) {
                    if (resCb.successMessages) {
                        ServerResVal.success = resCb.successMessages;
                        setServerRes({ success: 'Discharge summary added successfully.' });
                    } else {
                        ServerResVal.error = resCb.errorMessages;
                        setServerRes({ error: 'Failed to add Discharge summary' });
                    }
                    setTimeout(() => {
                        setServerRes({ success: '', error: '' });
                    }, 3000);
                    setServerRes({ ...ServerResVal });
                    console.log('resCb : ', resCb);
                }
            }))
            dispatch(patientActions.saveDischargeDate(dischargeddt, resCb => {
                if (resCb) {
                    if (resCb.successMessages) {
                        ServerResVal.success = resCb.successMessages;
                        setServerRes({ success: 'Discharge summary added successfully.' });
                    } else {
                        ServerResVal.error = resCb.errorMessages;
                        setServerRes({ error: 'Failed to add Discharge summary' });
                    }
                    setTimeout(() => {
                        setServerRes({ success: '', error: '' });
                    }, 3000);
                    setServerRes({ ...ServerResVal });
                    console.log('resCb : ', resCb);
                }
            }))
            setTimeout(() => {
                closeAlert();
            }, 3000);

        } else {
            setLoadSummary(!loadsummary)
        }
    }

    const closeAlert = () => {
        setServerRes({ ..."" });
    }

    const handleOptionSelect = (selectedDoctorId) => {
        doctorList.forEach(doc => {
            if (doc.personId == selectedDoctorId) {
                console.log("Checking doctor:", doc.personId);
                console.log("Selected doctorId:", selectedDoctorId);
                console.log("Current selectedItems:", selectedItems);

                if (!selectedItems.some(item => item.personId === doc.personId)) {
                    setSelectedItems(prevItems => [...prevItems, doc]);
                    console.log("Added to selectedItems:", doc);
                    console.log("Updated selectedItems:", selectedItems);
                }
            }
        });
        setSelectedOption(selectedDoctorId);
        console.log("selected item ::: ", selectedItems)
    };

    const handleRemoveItem = (item) => {
        const updatedItems = selectedItems.filter((selectedItem) => selectedItem !== item);
        setSelectedItems(updatedItems);
    };

    const ChangeRevisitDate = (event) => {
        var val = event.target.value;
        setPatientRevisitdetails(val);
        console.log("revisit date : ", patientRevisitdetails, val)
    }

    return (
        <>
            {ServerRes.success && (
                <Alert theme="success" className="alert-div"  >{ServerRes.success}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
            )}
            {ServerRes.error && (
                <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
            )}
            <div className='bg-none nav-wrapper h-100 d-flex flex-column summary-view' key={loadsummary}>
                <div className="pres-header">
                    <div className="pres-layout-basic-info">
                        <div className="main-content-container container-fluid p-0">
                            <div className="mb-12 col-md-12 hpcol bg menu-head">
                                <div className="blog-comments__item d-flex cpointer  p-2 menu-content">
                                    <div className="">
                                        <div className="blog-comments__content layout-head-content">
                                            <div className="blog-comments__meta text-mutes d-flex img-name justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="text-secondary pname px-2" style={{ textTransform: "capitalize", margin: "right", }}>
                                                        {fullName}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex-column">
                                                    {roomName && (
                                                        <small
                                                            className="text-muted"
                                                            style={{ fontWeight: "500" }}
                                                        >

                                                            <span>
                                                                Room Name: {roomName}
                                                            </span>
                                                        </small>
                                                    )}
                                                    {phoneNum && (
                                                        <small
                                                            className="text-muted"
                                                            style={{ fontWeight: "500" }}
                                                        >

                                                            <span>
                                                                Phone No: {phoneNum}
                                                            </span>
                                                        </small>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex h-100 mt-4 px-3">
                    <div className="px-2 w-100">
                        <div className="w-100 service-container" style={{ overflowY: "auto" }}>
                            <div className="w-100 flex-wrap flex-column">
                                <Col>
                                    <Form className="row service-form">
                                        <div className="py-3 pl-0 col-lg-3">
                                            <label className="service-lable">Appointment Confirmation Number</label>
                                            <FormInput
                                                className="p-2"
                                                value={summary["Appointment Confirmation Number"]}
                                                placeholder="Appointment Confirmation Number"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange("Appointment Confirmation Number", event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-3">
                                            <label className="service-lable">
                                                Admission Type
                                            </label>
                                            <select
                                                className="form-control filterin Quantity"
                                                value={summary["Admission Type"]}
                                                onChange={(event) => { handleInputChange("Admission Type", event.target.value); }} >
                                                <option selected disabled={true} value="">
                                                    --Choose admission type--
                                                </option>
                                                <option value="Independent" >Independent</option>
                                                <option value="Supported">Supported</option>
                                            </select>
                                        </div>
                                        <div className="py-3 pl-0  col-lg-3">
                                            <label className="service-lable">
                                                Ksmha Intimation Number
                                            </label>
                                            <FormInput
                                                className="p-2"
                                                value={summary["Ksmha Intimation Number"]}
                                                placeholder="Ksmha Intimation Number"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Ksmha Intimation Number",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-12">
                                            <label className="service-lable">
                                                Nominated Representative
                                            </label>
                                            <FormInput
                                                className="p-2"
                                                value={summary["Nominated Representative"]}
                                                placeholder="Nominated Representative"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Nominated Representative",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Final Diagnosis <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <FormInput
                                                className="p-2"
                                                placeholder="Final Diagnosis"
                                                value={summary["Final Diagnosis"]}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Final Diagnosis",
                                                        event.target.value);
                                                }}
                                                invalid={formErrors["Final Diagnosis"] === "" ? false : true}
                                            />
                                            <div style={{ color: '#EC4147' }} >
                                                <span className="summary-label">{formErrors["Final Diagnosis"]}</span>
                                            </div>
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Chief Complaints
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                placeholder="Chief Complaints"
                                                value={summary["Chief Complaints"]}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange("Chief Complaints", event.target.value);
                                                }}
                                            />
                                            <div style={{ color: "#EC4147" }}>
                                            </div>
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Clinical Summary <span style={{ color: 'red' }}>*</span>
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                value={summary["Clinical Summary"]}
                                                placeholder="Clinical Summary"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Clinical Summary",
                                                        event.target.value);
                                                }}
                                                invalid={formErrors["Clinical Summary"] === "" ? false : true}
                                            />
                                            <div style={{ color: '#EC4147' }} >
                                                <span className="summary-label">{formErrors["Clinical Summary"]}</span>
                                            </div>
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Past and Treatment History
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                value={summary["Past and Treatment History"]}
                                                placeholder="Past and Treatment History"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Past and Treatment History",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Relevant Medical History
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                placeholder="Relevant Medical History"
                                                value={summary["Relevant Medical History"]}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Relevant Medical History",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Relevant Family History
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                placeholder="Relevant Family History"
                                                value={summary["Relevant Family History"]}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Relevant Family History",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Personal History
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                value={summary["Personal History"]}
                                                placeholder="Personal History"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Personal History",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Premorbid Personality
                                            </label>
                                            <FormInput
                                                className="p-2"
                                                value={summary["Premorbid Personality"]}
                                                placeholder="Premorbid Personality"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Premorbid Personality",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Mental State Examination
                                            </label>
                                            <FormTextarea
                                                className="p-2"
                                                placeholder="Mental State Examination"
                                                value={summary["Mental State Examination"]}
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Mental State Examination",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <span ><label className='summary-label'>General physical examination</label></span>
                                            <FormTextarea
                                                className="p-2"
                                                placeholder="General physical examination"
                                                value={summary["General physical examination"]}
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "General physical examination", event.target.value);
                                                }} />
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <span ><label className='summary-label'>Clinical Investigations</label></span>
                                            <FormInput
                                                className="p-2"
                                                placeholder="Clinical Investigations"
                                                value={summary["Clinical Investigations"]}
                                                onChange={event => { handleInputChange("Clinical Investigations", event.target.value) }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <span ><label className='summary-label'>Course of treatment in hospital</label></span>
                                            <FormInput
                                                className="p-2"
                                                placeholder="Course of treatment in hospital"
                                                value={summary["Course of treatment in hospital"]}
                                                onChange={(event) => { handleInputChange("Course of treatment in hospital", event.target.value) }} />
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <span ><label className='summary-label'>Advice on discharge</label></span>
                                            <FormInput
                                                className="p-2"
                                                placeholder="Advice on discharge"
                                                value={summary["Advice on discharge"]}
                                                onChange={event => { handleInputChange("Advice on discharge", event.target.value) }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Psycho-social Factors
                                            </label>
                                            <FormInput
                                                className="p-2"
                                                value={summary["Psycho-social Factors"]}
                                                placeholder="Psycho-social Factors"
                                                autoComplete="off"
                                                onChange={(event) => {
                                                    handleInputChange(
                                                        "Psycho-social Factors",
                                                        event.target.value);
                                                }}
                                            />
                                        </div>
                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">
                                                Doctor List
                                            </label>
                                            <select
                                                name="category"
                                                className="form-control filterin Quantity"
                                                value={selectedOption}
                                                onChange={(e) => handleOptionSelect(e.target.value)}
                                            >
                                                <option selected disabled value="">---Select doctor---</option>
                                                {doctorList.map((doctor) => (
                                                    <option key={doctor.personId} value={doctor.personId}>
                                                        {doctor.displaytitle} {doctor.first_name}
                                                    </option>
                                                ))}
                                            </select>
                                            <br />
                                            <div className="py-3 pl-0 col-lg-12">
                                                <div className="row">
                                                    <div className="col-lg-6">
                                                        <label className="service-lable">
                                                            Follow Up Date
                                                        </label>
                                                        <input name="scheduleddate"
                                                            id="scheduleddate" className="form-control filterin Quantity"
                                                            required
                                                            type="date"
                                                            readOnly={followUpDateFlag}
                                                            min={todayDate}
                                                            defaultValue={patientRevisitdetails}
                                                            onChange={ChangeRevisitDate}
                                                        />
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <label className="service-lable">
                                                            Discharge Date
                                                        </label>
                                                        <input
                                                            name="dischargedate"
                                                            id="dischargedate"
                                                            className="form-control filterin Quantity"
                                                            required
                                                            type="datetime-local"
                                                            readOnly={dischargeDateFlag}
                                                            min={todayDate}
                                                            defaultValue={dischargedt}
                                                            onChange={(e) => handleInputChange('dischargedt', e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                                            <label className="summary-label">Selected Doctors</label>
                                            {selectedItems.length > 0 && (
                                                <div className="border" style={{ maxHeight: '120px', overflowY: 'auto', borderRadius: '5px', background: 'white' }}>
                                                    {selectedItems.map((item) => (
                                                        <div
                                                            key={item.personId}
                                                            className="d-flex p-1"
                                                            style={{ justifyContent: 'space-between', alignItems: 'center' }}
                                                        >
                                                            <label>{item.displaytitle} {item.first_name}</label>
                                                            <button type="button" className="btn btn-primary" onClick={() => handleRemoveItem(item)}>Cancel</button>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                        </div>

                                        <div className="text-right my-3">
                                            <button type="button" className="btn btn-primary " onClick={(event) => handleSubmit(event)}>Save</button>
                                        </div>
                                        <div className="col-md-6 text-left my-3">
                                            <button type="button" className="btn btn-primary " onClick={(event) => handlePrint(event)}>Print</button>
                                        </div>
                                        {dischargePrint && dischargePrint === true ? (
                                            <div className="">
                                                <Components.PrintDischargeSummary parentCallBack={handleDischargePrint}
                                                    patientdetails={patientdetails}
                                                    revisitDetails={patientRevisitdetails}
                                                    doctorList={doctors}
                                                    summary={summary}

                                                />
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </Form>
                                </Col>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default DischargeSummary;
