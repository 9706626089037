import {
  ZOOM_NOTES_SAVE_SUCCESS,ZOOM_NOTES_SAVE_FAILURE,
  SET_PATIENT_MEETING_INFO,ZOOM_NOTES_LIST,ZOOM_NOTES_UPDATE_SUCCESS,ZOOM_NOTES_UPDATE_FAILURE
} from "../../shared/constants";
import { zoomService } from "../services";

export function saveZoomNotes(data, resCb) {
  return (dispatch) => {
    zoomService.saveZoomNotes(data).then(
      (response) => {
        console.log("ReSult", response);
        dispatch(success(ZOOM_NOTES_SAVE_SUCCESS, response));
        resCb(response);
      },
      (error) => {
        dispatch(failure(ZOOM_NOTES_SAVE_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
} 

export function updateZoomNotes(data, resCb) {
  return (dispatch) => {
    zoomService.saveZoomNotes(data).then(
      (response) => {
        console.log("ReSult", response);
        dispatch(success(ZOOM_NOTES_UPDATE_SUCCESS, response));
        resCb(response);
      },
      (error) => {
        dispatch(failure(ZOOM_NOTES_UPDATE_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
} 

export function listZoomNotes(data, resCb) {
  console.log( " listZoomNotes   ",);
  return (dispatch) => {
    console.log( " listZoomNotes   ",);
    zoomService.listZoomNotes(data).then(
      (response) => {
        console.log( " listZoomNotes ReSult in actions", response);
        dispatch(success(ZOOM_NOTES_LIST, response));
        resCb(response);
      },
      (error) => {
        //dispatch(failure(ZOOM_NOTES_UPDATE_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
} 

function setPatientMeetingInfo(value) {
  return { type: SET_PATIENT_MEETING_INFO, payload : value };
}

function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, value) {
  return { type: type, payload: value };
}
function request(type, value) {
  return { type: type, payload: value };
}

const zoomActions = {
  saveZoomNotes,
  setPatientMeetingInfo,
  updateZoomNotes,
  listZoomNotes
};

export default zoomActions;
