import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import BtnCellRenderer from '../../common/BtnCellRenderer';

import Components from "../roomMaster/index";

import { roomMasterActions } from '../../../core/actions';

import {Row,FormInput,Button,} from "shards-react";

function RoomMasterList(props){

  const dispatch = useDispatch();    

  const [searchText, setSearchText] = useState("");
  const [roomSearchList, setroomSearchList] = useState({});
  const [roomMasterSearchFilterList, setroomMasterSearchFilterList] = useState({});
  const [addRoomMaster, setRoomMaster] = useState(false);
  
  const handleServices = (service) => {
  console.log("service selected :",service);
  }
  
  
      useEffect(() => {
               dispatch(roomMasterActions.roomList( (resCb) => {
                 console.log("roomMasterActions.roomList",resCb);
                 setroomSearchList(resCb.roomList);
                 setroomMasterSearchFilterList(resCb.roomList);
          })
        );

  
      },[]);
      const closeServiceModal = (event) =>{
          props.onModalClose(event)
        }
  
        const handleClick = (pDetail, isAddNew) => {
          setRoomMaster(!addRoomMaster);
          console.log("props.history",props);
          props.history.push("/roommaster");
        }
  
        const handleInputChange = (event) => {
          let emptymsg = {};
          let value = event.target.value;
          validateAndChange(value);
          console.log("value...",value);
  
        };
      
        const validateAndChange = (value) => {
          let nullarray = [];
      
          if (value.length < 3) {
            setSearchText(value);
            setroomMasterSearchFilterList(roomSearchList);
  
          }
          if (!value) {
         
            console.log(
              "roomSearchList null array !value : ",
              value,
              roomSearchList
            );
         
            setroomMasterSearchFilterList(roomSearchList);
  
          }

          if (value.length >= 3) {
   
            setSearchText(value);    
            let search = { patient: value };
            console.log("search input : ", value);
            searchRoomResult(value);
          }
       
        };
        
        const searchRoomResult = (data) => {
  
          console.log("data searched ",data);
      
        let filteredData = roomSearchList.filter((item) => {
        return (item.roomName)&&(item.roomName).includes(data)});
  
          setroomMasterSearchFilterList(filteredData);

    console.log("filteredData",roomMasterSearchFilterList);
    
    return roomMasterSearchFilterList;
      };
      const closeModal = (data) => {
        console.log("data ::",data);
        setRoomMaster(!addRoomMaster);

      }
      const callBackGrid = (data) => {
        console.log("datadata", data);
        dispatch(
          roomMasterActions.roomList((resCb) => {
            if (resCb) {
              console.log("resCb::",resCb);
              console.log("resCb::", resCb.roomList);
              setroomSearchList(resCb.roomList);
              setroomMasterSearchFilterList(resCb.roomList);
              console.log("response from api : ", resCb);
         
            }
          })
        );
      };
   return( 
      <>
      <div class="mainsection">
      {/* <div className='border-bottom'>
          <span className="topright d-flex justify-content-end pl-4 pt-3 mr-4"  onClick={(event)=>closeServiceModal(event)}>X</span>       
       </div> */}
       <div className="listTitles">
          <Row noGutters className="page-header mb-3">
            <h5
              // subtitle="Dashboard"
              className="text-sm-left mb-0"
            >Rooms</h5>
            <Button
                type="submit"
                className="floatingbtn"
                onClick={(event) => handleClick("", true)}
              >
                Add New
            </Button>
          </Row>
          </div>
          <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{ color: `#111b21` }} className="material-icons">
                  search
                </i>
              </span>
            </div>
            <FormInput
              className="navbar-search mb-2 menu-search bg-white"
              placeholder="Search RoomName..."
              value={searchText}
              type="search"
              onChange={handleInputChange}
            />
          </div>
        </form>
        {roomMasterSearchFilterList && roomMasterSearchFilterList.length > 0 &&
          <Components.RoomMasterGrid  roommaster={roomMasterSearchFilterList}  
          onGridAction={(event) => callBackGrid(event)}

          />  
        }
        
        {/* {addRoomMaster && ( */}
        {/* <div > */}
  
  
        {/* <Components.addRoomMaster onModalClose={(event) => closeModal(event)} /> */}
  
  
        {/* </div>)} */}
  
      </div>
  
        </>
      );
  }
export default RoomMasterList;
