import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { doctorActions, patientActions, ehrActions } from "../../core/actions";

import {
  Row, Form,
  FormTextarea,
  FormInput, Button,
  Col,
} from "shards-react";
import { CASERECORD } from '../../shared/constants';
import Components from "..";
import { format } from "date-fns";
import { contextType } from "react-datetime";

function Notes(props) {
  const dispatch = useDispatch();
  const [followUpDateFlag, setFollowUpDateFlag] = useState(false);
  const inputRefs = useRef([]);
  const [status, setStatus] = useState(undefined);
  const [todayDate, setTodayDate] = useState(new Date());
  const [revisitDate, setRevisitDate] = useState(format(new Date().setDate(new Date().getDate() + 14), "yyyy-MM-dd"));
  const [diagnosistemplate, setDiagnosistemplate] = useState();
  const [followUpdateFlag, setFollowUpdateFlag] = useState(true);
  const [details, setDetails] = useState({
    patientId: '',
    visitId: '',
    groupname: '',
    fileNo: '',
    fname: '',
    mname: '',
    lname: '',
    age: '',
    gender: '',
    phno: '',
    address: '',
    srcref: "",
    sociostatus: '',
    education: '',
    occupation: '',
    language: '',
    doctor: ''
  });
  const [patientDetail, setPatientDetail] = useState({
    patientId: "",
    lastvisitId: "",
    scheduleddate: "",
  })
  const [notePrint, setNotePrint] = useState(false);
  const [notes, setNotes] = useState(
    {
      "Chief Complaints": "",
      Onset: "",
      duration: "",
      progression: "",
      "Precipitating factor": "",
      "Clinical Summary": "",
      "Past and Treatment History": "",
      "Relevant Medical History": "",
      "Relevant Family History": "",
      "Personal History": "",
      "Premorbid Personality": "",
      "Psycho-social Factors": "",
      pulse: "",
      bp: "",
      weight: "",
      bmi: "",
      "Mental State Examination": "",
      "Provisional Diagnosis": "",
      "Final Diagnosis": "",
      "Consultant Notes and Management Plan": "",
      "Follow Up Date": ""
    }

  )
  const [ServerRes, setServerRes] = useState({ success: '', error: '' });
  const [loadsummary, setLoadSummary] = useState(false);
  const patientEhrid = useSelector((state) => state.patientDetailsState.ehrid);
  const [formErrors, setFormErrors] = useState({
    "Final Diagnosis": '',
    "Clinical Summary": '',
    "Consultant Notes and Management Plan": '',
  });
  const [filterdProList, setfilterdProList] = useState();

  useEffect(() => {
    let patientId = props.patientData.id;
    let visitId = props.patientData.visit.id;
    console.log("props data is : ",props)
    dispatch(
      patientActions.getrevisitbyId(patientId, visitId, (resCb) => {

        if (resCb) {
          console.log("resCb doctor Response:", resCb)
          console.log('resCbe : ', resCb);
          patientDetail['scheduleddate'] = resCb.scheduleddate;
        }

      })
    );


    console.log("diagnosis template:", patientDetail);
    //if(patientDetail['scheduleddate']===" " || patientDetail['scheduleddate']===undefined || patientDetail['scheduleddate']===null){
    //var followUpDateVar;
    // var folDate = new Date();
    // format(folDate.setDate(folDate.getDate() + 14), "yyyy-MM-dd");
    //   //  console.log("diagnosis template:", patientDetail);
    //   // patientDetail['scheduleddate'] = revisitDate;
    //   // setRevisitDate(revisitDate);
    // }

    getNotesDetails();
    getDiagnosistemplateById();
    console.log("diagnosis template:", diagnosistemplate)
  }, [])

  const getNotesDetails = () => {
    details.patientId = props.patientData.id;
    details.visitId = props.patientData.visit.id;
    details.groupname = CASERECORD;
    details.fileNo = props.patientData.fileNo;
    details.fname = props.patientData.fname;
    details.mname = props.patientData.mname;
    details.lname = props.patientData.lname;
    details.age = props.patientData.age;
    details.gender = props.patientData.gender;
    details.phno = props.patientData.phno;
    details.address = props.patientData.address;
    details.sociostatus = props.patientData.sociostatus;
    details.srcref = props.patientData.srcref;
    details.education = props.patientData.education;
    details.occupation = props.patientData.occupation;
    details.language = props.patientData.language;
    details.doctor = props.person.fullName;
    console.log("details :: ", details);
    dispatch(patientActions.getDischargeSummary(notes, details, resCb => {
      if (resCb) {
        console.log("resCb doctor Response:", resCb)
        if (resCb.procedureList) {
          const filterList = resCb.procedureList.filter(items => items.groupname === CASERECORD);
          const keys = Object.keys(notes);
          var patientNotes = { ...notes };
          var filteredProList = [];
          filterList.forEach(item => {
            for (let key of keys) {
              if (item.claSS == key) {
                patientNotes[key] = item.notes;
                filteredProList.push(item);
                setNotes({ ...patientNotes });
              }
            }
            setfilterdProList([...filteredProList])

          });

        }
      }
    })
    );
  }
  const closeServiceModal = (event) => {
    props.onModalClose(event);
  };
  //save Diagnosis to EHR 
  const saveDiagnosisinEHR = (finaldiagnosis) => {
    let responseresCb = {};

    console.log("---Form changed---", diagnosistemplate);
    console.log("---patientEhrid---", patientEhrid);


    let templateId = "Diagnosis";
    let patientId = props.patientData.id;
    let visitId = props.patientData.visit.id;

    console.log("patientidddd", patientId);
    console.log("visitIdddd", visitId);
    console.log("Templateiddddd", diagnosistemplate.content[0].data);
    for (let item of diagnosistemplate.content[0].data.items) {
      console.log("ii", item);
      let element = item.name.value;
      console.log("Elementtttooo", element);
      const time = new Date(new Date().toLocaleString('en-US', { timeZone: 'Asia/Kolkata' })).toISOString();
      switch (element) {
        case 'Problem/Diagnosis name':
          item.value.value = finaldiagnosis.notes;
          break;

        case 'Date/time of onset':
          item.value.value = time;
          break;

        case 'Date/time of resolution':
          item.value.value = time;
          break;
        case 'Date/time clinically recognised':
          item.value.value = time;
          break;

        default:


      }
      console.log("---Form changed---", diagnosistemplate);

    }
    // console.log("prescription value",prescriptionValue);
    // console.log("modifynotes",modifyNotes);
    // const  prescriptionDetails=modifyNotes.localMedicalBrand+' '+' '+modifyNotes.advicetoDispenser+' '+modifyNotes.instructionstoPatient;
    // const prescriptionDetails=modifyNotes.problemDiagnosis;
    let notesDetails = finaldiagnosis.notes;



    dispatch(
      ehrActions.saveComposition(
        diagnosistemplate,
        patientId,
        visitId,
        templateId,
        patientEhrid,
        notesDetails,
        (resCb) => {
          if (resCb) {
            console.log("Diagnosis Master API Response:", resCb);
            console.log("Templateiddddd", templateId);
            // setSelectedPatientInfo(resCb)
            if (resCb.successMessages) {
              //  if(flag == undefined && flag != "add new"){
              props.onModalClose({ success: resCb.successMessages[0] });
              // }
            } else {
              if (resCb.errorMessages[0]) {
                //if(flag == undefined && flag != "add new"){
                props.onModalClose({ error: resCb.errorMessages[0] });
                //}
              }
            }
          }
        }
      )
    );
  };
  const getDiagnosistemplateById = () => {
    let data = {};
    const jsonSchema = {};
    let templateId = "Diagnosis"
    dispatch(
      ehrActions.getTemplateById(data, templateId, (resCb) => {
        if (resCb) {
          console.log("Getdiagnosiss rescb", resCb);
          console.log("Diagnosisss ", resCb);
          setDiagnosistemplate(resCb);
        }
      }
      )
    );
    return diagnosistemplate;
  };

  // console.log("Templateiddddd",templateId);
  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value,);
    formErrors[name] = "";
    var patientNotes = notes;
    var keyList = [];
    patientNotes[name] = value;
    //  To handle data in edit case
    if (filterdProList && filterdProList.length > 0) {
      filterdProList.forEach(item => {
        if (item.claSS == name) {
          keyList.push({ claSS: item.claSS, notes: value, id: item.id, patientId: item.patientId, visitId: item.visitId });
        }
        setfilterdProList([...keyList]);
      });
    }
    setNotes({ ...patientNotes });

  };

  const handleSubmit = (e) => {
    Object.keys(notes).forEach((key) => {
      validateField(key, notes[key])
    })
    validateForm(e);
    setMessageStrip({ success: e.successMessages });
    setTimeout(() => {
      console.log("props:", props);
    }, 3000);

    //getDiagnosistemplateById();

  };
  const setMessageStrip = (e) => {
    console.log("message obtained", e);
    var ServerResVal = ServerRes;
    if (e.success) {
      console.log("success message:", e.success);
      ServerResVal.success = e.success;
    }
    else {
      console.log("error message:", e.error);
      ServerResVal.error = e.error;
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 5000);
  };
  const UpdateRevisitDetails = () => {
    console.log(" insert  props", props);
    patientDetail.patientId = props.patientData.id;
    patientDetail.lastvisitId = props.patientData.visit.id;
    console.log("patientdetail.....", patientDetail, revisitDate);

    let date = format(new Date(revisitDate), 'dd-MM-yyyy')

    notes['Follow Up Date'] = date;
    patientDetail.scheduleddate = date;
    setFollowUpdateFlag(date);
    console.log("notes adte:", notes['Follow Up Date'])
    return patientDetail;
  }
  const handlePrint = (event, isupvote) => {
    console.log('In handleinput change', notes);

    var notesData = notes;
    setNotes({ ...notesData });


    setNotePrint(true)

  }

  const handleNotePrint = () => {
    setNotePrint(false);
  }
  const closeAlert = (e) => {
    setServerRes({ ..."" });
  }
  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    switch (fieldName) {
      case "Final Diagnosis":
        fieldValidationErrors["Final Diagnosis"] = value.length > 0 ? '' : 'Please Enter Final Diagnosis';
        break;
      case "Clinical Summary":
        fieldValidationErrors["Clinical Summary"] = value.length > 0 ? '' : 'Please Enter Clinical Summary';
        break;
      case "Consultant Notes and Management Plan":
        fieldValidationErrors["Consultant Notes and Management Plan"] = value.length > 0 ? '' : 'Please Enter Consultant Notes and Management Plan ';
        break;
      default:
        break;
    }
    console.log("fieldValidationErrors:", fieldValidationErrors);
    setFormErrors({ ...fieldValidationErrors });
  }
  const validateForm = (e) => {
    console.log('formErrors : ', formErrors)
    let formValid = formErrors["Final Diagnosis"] === "" && formErrors["Clinical Summary"] === "" && formErrors["Consultant Notes and Management Plan"] === ""
    if (formValid) {
      var ServerResVal = ServerRes;
      e.preventDefault();
      var proList = [];
      let finaldiagnosis = {};
      const followUpDetails = UpdateRevisitDetails();
      const keys = Object.keys(notes);
      // handle submit in edit case
      if (filterdProList && filterdProList.length > 0) {
        filterdProList.forEach(item => {
          if (item.claSS === "Follow Up Date") {
            proList.push({ id: item.id, claSS: item.claSS, notes: followUpDetails.scheduleddate, patientId: item.patientId, visitId: item.visitId })
          }
          else {
            proList.push({ id: item.id, claSS: item.claSS, notes: item.notes, patientId: item.patientId, visitId: item.visitId })
          }
        });
        console.log("proList in edit case: ", proList)
      } else {
        // handle submit in add
        for (let key of keys) {
          proList.push({ claSS: key, notes: notes[key], patientId: props.patientData.id, visitId: props.patientData.visit.id })
          console.log("proList in add case: ", proList)
        }
      }
      notes['notes'] = "NOTE";
      proList.map(item => {
        if (item.claSS === "Final Diagnosis") {
          finaldiagnosis = item;
          console.log("hellowww", finaldiagnosis);
        }

      });
      dispatch(
        doctorActions.addCaseRecord(proList, (resCb) => {
          if (resCb) {
            console.log("resCb doctor Response:", resCb)
            if (resCb.successMessages) {
              saveDiagnosisinEHR(finaldiagnosis);
              props.onModalClose({ 'success': "Notes added successfully" });
            } else {
              props.onModalClose({ 'error': "Failed to add notes" });
            }
            console.log('resCbe : ', resCb);

          }
        })
      );

      setServerRes({ ...ServerResVal });

      setTimeout(() => {
        closeAlert();
      }, 3000);

    }
    else {
      setLoadSummary(!loadsummary)
    }
  }

  const ChangeRevisitDate = (event) => {
    console.log('date: ', event.target.value);
    setRevisitDate(event.target.value);
  }

  return (
    <>
      <div className=" bg-none nav-wrapper h-100 d-flex flex-column service-view">
        <div className='"d-flex'>
          <span
            className="topright d-flex justify-content-end pl-4 pt-3 mr-4"
            onClick={(event) => closeServiceModal(event)}
          >
            X
          </span>
        </div>

        <div className="w-100 service-container" style={{ overflowY: "auto" }}>
          <div className="d-flex m-3 justify-content-between px-4">
            <div className="w-100 flex-wrap flex-column">
              <Col>
                <Form className="row service-form">
                  <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Chief Complaints
                    </label>
                    <FormTextarea
                      className="p-2"
                      placeholder="Chief Complaints"
                      value={notes["Chief Complaints"]}
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange("Chief Complaints", event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Onset
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes.Onset}
                      placeholder="Onset"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange("Onset", event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Duration
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes.duration}
                      placeholder="Duration"
                      autoComplete="off"
                      type="text"
                      onChange={(event) => {
                        handleInputChange("duration", event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Progression
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes.progression}
                      placeholder="Progression"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange("progression", event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Precipitating factor
                    </label>
                    <FormInput
                      className="p-2"
                      placeholder="Precipitating factor"
                      value={notes["Precipitating factor"]}
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Precipitating factor",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Clinical Summary <span style={{ color: 'red' }}>*</span>
                    </label>
                    <FormTextarea
                      className="p-2"
                      value={notes["Clinical Summary"]}
                      placeholder="Clinical Summary"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Clinical Summary",
                          event.target.value);
                      }}
                      invalid={formErrors["Clinical Summary"] === "" ? false : true}
                    />
                    <div style={{ color: '#EC4147' }} >
                      <span className="service-lable">{formErrors["Clinical Summary"]}</span>
                    </div>
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Past and Treatment History
                    </label>
                    <FormTextarea
                      className="p-2"
                      value={notes["Past and Treatment History"]}
                      placeholder="Past and Treatment History"
                      required
                      autoComplete="off"
                      type="text"
                      onChange={(event) => {
                        handleInputChange(
                          "Past and Treatment History",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Relevant Medical History
                    </label>
                    <FormTextarea
                      className="p-2"
                      value={notes["Relevant Medical History"]}
                      placeholder="Relevant Medical History"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Relevant Medical History",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Relevant Family History
                    </label>
                    <FormTextarea
                      className="p-2"
                      placeholder="Relevant Family History"
                      value={notes["Relevant Family History"]}
                      required
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Relevant Family History",
                          event.target.value);
                      }}
                    />
                  </div>

                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Personal History
                    </label>
                    <FormTextarea
                      className="p-2"
                      value={notes["Personal History"]}
                      placeholder="Personal History"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Personal History",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Premorbid Personality
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes["Premorbid Personality"]}
                      placeholder="Premorbid Personality"
                      required
                      autoComplete="off"
                      type="text"
                      onChange={(event) => {
                        handleInputChange(
                          "Premorbid Personality",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Psycho-social Factors
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes["Psycho-social Factors"]}
                      placeholder="Psycho-social Factors"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Psycho-social Factors",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="w-100">
                    <label className="service-lable">
                      Relevant Examination Findings :
                    </label>
                    <Row className="notes-exam-finds">
                      <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-12">
                        <label className="service-lable">
                          Pulse
                        </label>
                        <FormInput
                          className="p-2"
                          value={notes.pulse}
                          placeholder="Pulse"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "pulse",
                              event.target.value);
                          }}
                        />
                      </div>
                      <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-12">
                        <label className="service-lable">
                          BP
                        </label>
                        <FormInput
                          className="p-2"
                          value={notes.bp}
                          placeholder="BP"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "bp",
                              event.target.value);
                          }}
                        />
                      </div>
                      <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-12">
                        <label className="service-lable">
                          Weight
                        </label>
                        <FormInput
                          className="p-2"
                          value={notes.weight}
                          placeholder="Weight"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "weight",
                              event.target.value);
                          }}
                        />
                      </div>
                      <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-12">
                        <label className="service-lable">
                          BMI
                        </label>
                        <FormInput
                          className="p-2"
                          value={notes.bmi}
                          placeholder="BMI"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "bmi",
                              event.target.value);
                          }}
                        />
                      </div>
                    </Row>
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Mental State Examination
                    </label>
                    <FormTextarea
                      className="p-2"
                      value={notes["Mental State Examination"]}
                      placeholder="Mental State Examination"
                      required
                      autoComplete="off"
                      type="text"
                      onChange={(event) => {
                        handleInputChange(
                          "Mental State Examination",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Provisional Diagnosis 
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes["Provisional Diagnosis"]}
                      placeholder="Provisional Diagnosis"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Provisional Diagnosis",
                          event.target.value);
                      }}
                    />
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Final Diagnosis <span style={{ color: 'red' }}>*</span>
                    </label>
                    <FormInput
                      className="p-2"
                      value={notes["Final Diagnosis"]}
                      placeholder="Final Diagnosis"
                      required
                      autoComplete="off"
                      type="text"
                      onChange={(event) => {
                        handleInputChange(
                          "Final Diagnosis",
                          event.target.value);
                      }}
                      invalid={formErrors["Final Diagnosis"] === "" ? false : true}
                      />
                      <div style={{ color: '#EC4147' }} >
                        <span className="service-lable">{formErrors["Final Diagnosis"]}</span>
                      </div>
                  </div>
                  <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                    <label className="service-lable">
                      Consultant Notes and Management Plan <span style={{ color: 'red' }}>*</span>
                    </label>
                    <FormTextarea
                      className="p-2"
                      value={notes["Consultant Notes and Management Plan"]}
                      placeholder="Consultant Notes and Management Plan"
                      autoComplete="off"
                      onChange={(event) => {
                        handleInputChange(
                          "Consultant Notes and Management Plan",
                          event.target.value);
                      }}
                      invalid={formErrors["Consultant Notes and Management Plan"] === "" ? false : true}
                    />
                    <div style={{ color: '#EC4147' }} >
                      <span className="service-lable">{formErrors["Consultant Notes and Management Plan"]}</span>
                    </div>
                  </div>

                  <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12 ">
                    <label className="service-lable">
                      Follow Up Date
                    </label>
                    <input name="scheduleddate"
                      id="scheduleddate" className="form-control filterin Quantity"
                      placeholder="Enter ScheduledTime"
                      required
                      type="date"
                      readOnly={followUpDateFlag}
                      min={todayDate}
                      defaultValue={patientDetail['scheduleddate'] === undefined ? revisitDate : patientDetail['scheduleddate']}
                      onChange={ChangeRevisitDate} />
                  </div>
                </Form>
              </Col>
              <div className="d-inline-block text-left my-3">
                <button type="submit" className="btn btn-primary" onClick={(event) => handleSubmit(event)}>Save</button>
              </div>
              <div className="d-inline-block col-md-6 text-left my-3">
                <button type="button" className="btn btn-primary" onClick={(event) => handlePrint(event)}>Print</button>
              </div>
              {notePrint && notePrint === true ? (
                <div className="">
                  <Components.NotesPrint parentCallBack={handleNotePrint}
                    patientdetails={details}
                    notes={notes}

                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Notes;
