
import React, { useState, useEffect } from 'react';
import { format } from "date-fns";

function VisitList(props){
    const [selectedDate, setSelectedDate] = useState(); 

    useEffect(() => {
        console.log("------props in visit list---------",props);
        setSelectedDate(props.selectedDate);
    },[props])

    const handleVisitSelect = (e) => {    
        // setSelectedDate(e.target.value);
        // selectedDate = e.target.value
        console.log("handleVisitSelect",e);
        {props.handleVisitSelect ? props.handleVisitSelect(e) : props.handlevisitChange(e)}
    
    //    props.handlevisitChange(e);
    }

    const getFormateDate = (date) => {
        //  procedureDate = date;
        const reqdate = new Date(date);
        // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min;
        let formatedate = format(reqdate, "MMM dd yyyy");
        return formatedate;
      };
    return (
        <>
           <div className='col-lg-12 col-md-12 col-xs-12 col-sm-12 d-flex justify-content-end align-items-center'>
                        {/* <div className='col-6 d-flex align-items-center'> */}
                        <span className="col-4">Select Date :</span>
                            <select
                                name="Select Date"
                                value={selectedDate}
                                className="form-control"
                                onChange={(e) => handleVisitSelect(e)}
                                >
                                {props.allVisitdates &&
                                    props.allVisitdates.map((lastVisited, index) => (
                                    <option key={index} value={lastVisited.id}>
                                        {getFormateDate(lastVisited.visitDate)}
                                    </option>
                                    ))}
                            </select>
                        {/* </div> */}
                           
            </div>
        </>
    )

}

export default VisitList;
