import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink,
} from "shards-react";

export default function UserActions() {
  const [visible, setVisible] = useState(false);
  const [loginId, setLoginId] = useState("");
  const [name, setName] = useState("");
  useEffect(() => {
    var loginId = localStorage.getItem("loginId");
    var name = localStorage.getItem("name");
    setLoginId(loginId);
    setName(name.charAt(0).toUpperCase() + name.slice(1));
  });

  const toggleUserActions = () => {
    setVisible(true);
  };

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <NavItem tag={Dropdown} caret toggle={toggleUserActions}>
      <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <span className="d-md-inline-block">{name}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem divider />
        <DropdownItem
          tag={Link}
          to="/"
          onClick={(event) => logout()}
          className="text-danger"
        >
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
}
