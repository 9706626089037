import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { doctorActions} from "../../core/actions";
// import { DOC_CATEGORIES } from "../../shared/constants";
import Components from "./index";
import PageTitle from "../common/PageTitle";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormSelect,
  Alert,
  Button
} from "shards-react";

function DocSetup() {
  const [docDetail, setDocDetail] = useState({
    id: '',
    type:'',
    salarytype:'',
    fname : '',
    mname :'',
    lname : '',
    landLnNo:'',
    mobileNo:'',
    emailId:'',
    practice:'',
    deptId:'',
    regNo:0,
    ProfDegree:'',
    city:'',
    state : '',
    pincode : '',
    annualCTC : 0,
    effectiveFrom : '',
    udt : '',
  });
  const [formErrors, setFormErrors] = useState({
    fname:'',
    landLnNo:'',
    practice:'',
});
  const [showVisitFeeModal, setShowVisitFeeModal] = useState(false);
  const [callType, setCallType] = useState('');
  const [selectedDept, setSelectedDept] = useState('');
  const [deptId, setDeptId] = useState('');
  const [ServerRes, setServerRes] = useState(
    {success : '', error : ''}
  );
  const dispatch = useDispatch();
  const doctor = useSelector(state => state.doctorDetailsState.doctor)
  useEffect(() => {
      
    if(doctor){
      setDocDetail(doctor)
    }
    if(doctor === '' || doctor === undefined){
      setCallType('Add')
    }
    else{
      setCallType('Edit')
    }
  },[])

  const handleInputChange = (name, value) => {
    console.log("In handleinput change");
    var dDetail = docDetail;
    dDetail[name] = value;
    setDocDetail({...dDetail});
    console.log("In handleinput change",dDetail);
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    Object.keys(docDetail).forEach((key)=>{
        console.log("key",key)
        console.log("key",docDetail[key])
        validateField(key,docDetail[key])
    })
    validateForm(event)
  }

  const validateField = (fieldName,value) => {
    let fieldValidationErrors = formErrors;
    let doctInfo = docDetail;
    console.log("Doctor Info", doctInfo)
    console.log("FieldName",fieldName,"Value",value)
    switch(fieldName){
      case "fname":
        fieldValidationErrors.fname = value !== '' ? '' : 'Please Enter First Name';
        doctInfo.fname = value !== '' ? value : '';
        break;
      case "landLnNo":
        fieldValidationErrors.landLnNo = value !== '' ? '' : 'Please Enter Land Line Number';
        doctInfo.landLnNo = value !== '' ? value : '';
        break;
      case "practice":
        fieldValidationErrors.practice = value !== '' ? '' : 'Please Enter Practice ';
        doctInfo.practice = value !== '' ? value : '';
        break;
      default:
          break
    }
    console.log("fieldValidationErrors",fieldValidationErrors)
    setFormErrors({...fieldValidationErrors});
  }

  const validateForm = (event) => {
    event.preventDefault();
    var ServerResVal =ServerRes;

    let formValid =
      formErrors.fname === "" &&
      formErrors.landLnNo === "" &&
      formErrors.practice === "" ;
    
    if(formValid){
      if(callType === 'Add'){
        var docState = docDetail;
        console.log("D-docState",docState)
        docState['deptId'] = deptId;
        setDocDetail(docState)
        console.log("D-Details",docDetail)
        dispatch(doctorActions.addDoctor(docDetail, resCb => {
          if(resCb){
            console.log("Rescb",resCb.state,resCb.message)
            if(resCb.state === 'success'){
              ServerResVal.success = resCb.message 
            }
            else if(resCb.state === 'error'){
              ServerResVal.error = resCb.messages.message
            }
            setServerRes({...ServerResVal});
          }
        }));
      }
      else{
        dispatch(doctorActions.updateDoctor(docDetail, resCb => {
          if(resCb){
            console.log("Rescb",resCb.state,resCb.message)
            if(resCb.state === 'success'){
              ServerResVal.success = resCb.message
            }
            else if(resCb.state === 'error'){
              ServerResVal.error = resCb.messages.message
            }
            setServerRes({...ServerResVal});
          }
        }));
      }
    }
  }

  const openVisitFeeModal = () => {
    setShowVisitFeeModal(true);
  }

  const toggleAddModal = (event) => {
    setShowVisitFeeModal(false);
  }

  const handleDeptChange = (childSelectedDept,childDeptId) =>{
    console.log("**",childSelectedDept,childDeptId)

    setSelectedDept(childSelectedDept)
    
    var docState = docDetail;
    docState['deptId'] = childDeptId;
    setDeptId(childDeptId)
    setDocDetail(docState)
    console.log("D-Details",docDetail)
  }

    console.log("Sate",docDetail)
    return (
      <main className="mainsection">
        <Row noGutters className="page-header">
          <PageTitle title={callType === 'Add' ? "Create Doctor / Counsellor" : "Edit Doctor / Counsellor"  }
            className="text-sm-left mb-3" />
        </Row>
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <Row>
              <Col>
                <Form>
                  <Row form>
                    <Col md="6" className="form-group">
                      <label htmlFor="department">Department</label>
                      <Components.SearchDept parentCallBack={handleDeptChange} className="mb-2"/>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="4" className="form-group">
                      <label htmlFor="fname">First Name</label>
                      <FormInput
                        id="fname"
                        value={docDetail.fname}
                        placeholder="First Name"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("fname", event.target.value);
                        }}
                        invalid={formErrors.fname === "" ? "":true }
                      />
                      <div style={{ color: '#EC4147' }} >
                        <span>{formErrors.fname}</span>
                      </div> 
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="mname">Middle Name</label>
                      <FormInput
                        id="mname"
                        value={docDetail.mname}
                        placeholder="Middle Name"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("mname", event.target.value);
                        }}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="lname">Last Name</label>
                      <FormInput
                        id="lname"
                        value={docDetail.lname}
                        placeholder="Last Name"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("lname", event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="4" className="form-group">
                      <label htmlFor="llno">LandLine No</label>
                      <FormInput
                        id="llno"
                        value={docDetail.landLnNo}
                        placeholder="LandLine No"
                        type="number"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange(
                            "landLnNo",
                            event.target.value
                          );
                        }}
                        invalid={formErrors.landLnNo === "" ? "":true }
                      />
                      <div style={{ color: '#EC4147' }} >
                        <span>{formErrors.landLnNo}</span>
                      </div> 
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="mname">Mobile No</label>
                      <FormInput
                        id="mno"
                        type="number"
                        value={docDetail.mobileNo}
                        placeholder="Mobile No"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange(
                            "mobileNo",
                            event.target.value
                          );
                        }}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="emailid">Email ID</label>
                      <FormInput
                        id="emailid"
                        type="email"
                        value={docDetail.emailId}
                        placeholder="Email ID"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("emailId", event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="4" className="form-group">
                      <label htmlFor="fname">Practice</label>
                      <FormInput
                        id="practice"
                        value={docDetail.practice}
                        placeholder="Practice"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange(
                            "practice",
                            event.target.value
                          );
                        }}
                        invalid={formErrors.practice === "" ? "":true }
                      />
                      <div style={{ color: '#EC4147' }} >
                        <span>{formErrors.practice}</span>
                      </div> 
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="ProfDegree">Professional Degree(s)</label>
                      <FormInput
                        id="ProfDegree"
                        value={docDetail.ProfDegree}
                        placeholder="Professional Degree"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange(
                            "ProfDegree",
                            event.target.value
                          );
                        }}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="mregno">Medical Registration No.</label>
                      <FormInput
                        id="mregno"
                        value={docDetail.regNo}
                        placeholder="Medical Registration No"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("regNo", event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="4" className="form-group">
                      <label htmlFor="city">City</label>
                      <FormInput
                        id="city"
                        value={docDetail.city}
                        placeholder="City"
                        required
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("city", event.target.value);
                        }}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="state">State</label>
                      <FormInput
                        id="state"
                        value={docDetail.state}
                        placeholder="State"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("state", event.target.value);
                        }}
                      />
                    </Col>
                    <Col md="4" className="form-group">
                      <label htmlFor="pcode">Pin Code</label>
                      <FormInput
                        id="pcode"
                        type="number"
                        value={docDetail.pincode}
                        placeholder="Pin Code"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("pincode", event.target.value);
                        }}
                      />
                    </Col>

                    <Col md="4" className="form-group">
                      <label htmlFor="pcode">Role</label>
                      <FormInput
                        id="pcode"
                        type="number"
                        value={docDetail.role}
                        placeholder="role"
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("role", event.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row form>
                    <Col
                      md="4"
                      className="form-group docvisit"
                      onClick={(event) => openVisitFeeModal()}
                    >
                      Set Doctor Visit fees
                    </Col>
                  </Row>
                  <div className="fbtn">
                    <Button
                        type="submit"
                        onClick={(event) => handleSubmit(event)}
                    >
                        Save
                    </Button>
                    </div>
                  <br/>
                  {ServerRes.success &&
                    <Alert theme="success">{ServerRes.success}</Alert>
                  }
                  {ServerRes.error &&
                    <Alert theme="danger">{ServerRes.error}</Alert>
                  }
                </Form>
              </Col>
              {showVisitFeeModal === true ? (
                <Components.DocVisitFee
                  onAddModalClose={(event) => toggleAddModal(event)}
                />
              ) : (
                ""
              )}
            </Row>
          </ListGroupItem>
        </ListGroup>
      </main>
    );
}

/* function mapStateToProps(state) {
  return {
    doctor: state.doctorDetailsState.doctor,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addDoctor: (data, resCb) => dispatch(doctorActions.addDoctor(data, resCb)),
    updateDoctor: (data, resCb) => dispatch(doctorActions.updateDoctor(data, resCb))
  };
}

const DocSetUpWithProps = connect(mapStateToProps, mapDispatchToProps)(DocSetup); */

export default DocSetup;
