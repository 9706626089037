import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import BtnCellRenderer from '../common/BtnCellRenderer';
import { doctorActions, patientActions } from '../../core/actions';

import Select from 'react-select';
import Components from "./index";
import { format } from "date-fns";
import { DatePicker, FormRadio } from 'shards-react';
import { DateTimePicker } from 'react-datetime-picker';
import "./"
import { ListGroup, ListGroupItem, Row, Col, Form, FormInput, Alert, Button, Card, CardBody, Modal, ModalBody, ModalHeader, ModalFooter } from "shards-react";
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import * as ReactDOM from 'react-dom';
//import { event } from 'react-ga';

function PatientRevisitModel(props) {

  const [reDetail, setReDetail] = useState({
    id: "",
    comment: "",
    reDate: "",
  })
  const [revisitDetail, setRevisitDetail] = useState({
    patientId: "",
    visitId: "",
    closecomment: "",
    closeScheduledTime: "",
    scheduleddate :"",

   })

  const [reShDetail, setReShDetail] = useState({
    id: "",
    scheduledTime: "",
    scheduleddate: "",

  })

  const [patientDetail, setPatientDetail] = useState({
    patientId: "",
    visitId: "",
    closecomment: "",
    closeScheduledTime: "",
  })

  const dispatch = useDispatch();

  const [status, setStatus] = useState(undefined);

  const [flag, setFlag] = useState({})
  const[revisitFlag,setRevisitFlag]=useState(true)

  const[followDateFlag,setFollowDateFlag]=useState(true);

  const d = new Date()
  const date = format(d.setDate(d.getDate() + 90), "yyyy-MM-dd");
  console.log("date1", date);
  var today = new Date();
  const todayDate = format(today.setDate(today.getDate()), "yyyy-MM-dd");
  const[selectedFollowUpDate, setSelectedFollowUpDate] = useState('2Week');  
  var dftDate = new Date();
  const defaultDate = format(dftDate.setDate(dftDate.getDate() + 14), "yyyy-MM-dd");
  const [followUpDate,setFollowUpDate] = useState("");


  const onSelectedFollowUpDate = (value) => {
    setSelectedFollowUpDate(value)
    console.log("Selected FollowUp Date",value);
    var followUpDateVar;
    var folDate = new Date();
    if(value === '1Week'){
      patientDetail.closeScheduledTime = '';
      patientDetail['closescheduleddate'] = '';
       followUpDateVar = format(folDate.setDate(folDate.getDate() + 7), "yyyy-MM-dd");
       setSelectedFollowUpDate('1Week');
       setFollowUpDate(followUpDateVar);
       patientDetail.closeScheduledTime = followUpDateVar;
       patientDetail['closescheduleddate'] = followUpDateVar;
       //closescheduleddate.value ='';


      }else if(value === '2Week'){
        patientDetail.closeScheduledTime = '';
        patientDetail['closescheduleddate'] = '';

      followUpDateVar = format(folDate.setDate(folDate.getDate() + 14), "yyyy-MM-dd");
      setSelectedFollowUpDate('2Week');
      setFollowUpDate(followUpDateVar);
      patientDetail.closeScheduledTime = followUpDateVar;
      patientDetail['closescheduleddate'] = followUpDateVar;
    }else if(value === '3Week'){
      patientDetail.closeScheduledTime = '';
      patientDetail['closescheduleddate'] = '';

      followUpDateVar = format(folDate.setDate(folDate.getDate() + 21), "yyyy-MM-dd");
      setSelectedFollowUpDate('3Week');
      setFollowUpDate(followUpDateVar);
      patientDetail.closeScheduledTime = followUpDateVar;
      patientDetail['closescheduleddate'] = followUpDateVar;
    }
    console.log("Selected FlowUp date is --->", patientDetail['closescheduleddate'])
  }

  
  

  useEffect(() => {
    UpdatefollowUpDate();
    //setting default followup to 14 days
    //setFollowUpDate(defaultDate);
    let patientVisit = props.patientData;
    setFlag(props.patientData.typeFlag);
    if (props.patientData.typeFlag == 'patientClose') {
      patientDetail.patientId = patientVisit.id;
      patientDetail.visitId = patientVisit.visit.id;
      
      //patientDetail.scheduleddate=patientVisit.scheduleddate;
      // if(patientDetail.closeScheduledTime === ''){
      //   //patientDetail.closeScheduledTime = defaultDate;
      //   patientDetail['closescheduleddate'] = defaultDate;
      // }
      // if(props.patientRevisitDetails.schedduledDate === ""){
      //   props.patientRevisitDetails['scheduleddate'] = defaultDate;
      // }
      console.log("data", patientDetail);
    } else {
      reDetail.id = patientVisit.revisit.id;
      reDetail.comment = patientVisit.revisit.comment;
      reDetail.reDate =props.patientRevisitDetails['scheduleddate'];
      reShDetail.id = patientVisit.revisit.id;
    }
  }, [])
  const UpdatefollowUpDate=()=>{
    let patientId=props.patientData.id;
    let visitId=props.patientData.visit.id;
   

    dispatch(
      patientActions.getrevisitbyId(patientId,visitId, (resCb) => {
        if (resCb) {
          console.log("resCb doctor Response:", resCb)
          console.log('resCbe : ', resCb);
          //}patientDetail['closescheduleddate']=resCb.scheduleddate;
          patientDetail['closescheduleddate']=defaultDate;
          if(resCb.scheduleddate != null && resCb.scheduleddate != ""){
            patientDetail['closescheduleddate']=resCb.scheduleddate;
          }
          
          revisitDetail.patientId=resCb.patientId;
          revisitDetail.visitId =resCb.visitId;
        }
        
        setFollowDateFlag(!followDateFlag);
        if(resCb.scheduleddate != null && resCb.scheduleddate != ""){
          setRevisitFlag(true);          
          console.log("schedule date...",patientDetail.scheduleddate)
        }else{
          setRevisitFlag(false)
        }
      })
    );
  }

  const closeModal = () => {
    props.onModalClose()
  }

  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value);
    if (props.patientData.typeFlag == 'cancel') {
      var pDetail = reDetail;
      pDetail[name] = value;
      console.log("pDetail[name] change", pDetail[name], value);
      setReDetail({ ...pDetail });
      console.log("patient:", reDetail);
    } else if (props.patientData.typeFlag == 'reschedule') {
      var pDetail = reShDetail;
      pDetail[name] = value;
      console.log("pDetail[name] change", pDetail[name], value);
      setReShDetail({ ...pDetail });
      console.log("patient:", reShDetail);
    } else if (props.patientData.typeFlag == 'patientClose') {
      var pDetail = patientDetail;
      //value.DateTimePicker = value.DateTimePicker !== '' ? value.DateTimePicker :  followUpDate;
      pDetail[name] = value;
      console.log("pDetail[name] change one", pDetail[name], value);
      setPatientDetail({ ...pDetail });
      console.log("patientDetail :", patientDetail);
    }
  }

  const revisitadd = (data) => {
    dispatch(patientActions.addRevisit(data, (resCb) => {
      console.log("data:", data);
      if (resCb) {
        setStatus({ type: 'success' });
        // setStatus({ type: 'error', error });
        props.onModalClose(resCb)
      }
    }));
  }



  const submit = () => {
    if (props.patientData.typeFlag == 'cancel') {
      revisitadd(reDetail);
    } else if (props.patientData.typeFlag == 'reschedule') {
      revisitadd(reShDetail);
    } else if (props.patientData.typeFlag == 'patientClose') {
      dispatch(patientActions.updateVAStatus(patientDetail, (resCb) => {
        console.log("response close button:", resCb);
        if (resCb) {
          setStatus({ type: 'success' });
          console.log("response while close:", resCb);

          props.onModalClose(resCb)
        }
      }));
    }
  }

  return (<>
    <div className="nav-wrapper modalmn preview">
      <div className='col d-flex ag-style'>
        <div className="nav mb-12 col-sm-12 col-md-6 col-lg-6 pres-modal-main modal-main border-rad-bottom schedule-appnt">
          <Col className='py-3'>
            <div className='col-md-12 border-bottom'>
              <div className='w-100  d-flex justify-content-between align-items-start pb-1rem'>
                {flag == 'cancel' && (
                  <span className='m-2 h6'>Cancel Schedule to {props.patientData.fname + ' ' + props.patientData.mname + ' ' + props.patientData.lname} </span>
                )}
                {flag == 'reschedule' && (
                  <span className='m-2 h6'>Re-Schedule Time to {props.patientData.fname + ' ' + props.patientData.mname + ' ' + props.patientData.lname} </span>
                )}
                {flag == 'patientClose' && (
                  <span className='m-2 h6'>Follow-Up date to {props.patientData.fname + ' ' + props.patientData.mname + ' ' + props.patientData.lname} </span>
                )}
                <span className="topright d-flex justify-content-end" onClick={(event) => closeModal(event)}>X</span>
              </div>
            </div>
            <div className='d-flex align-items-center form-group col-md-12 mb-0 '>
              <Form className='w-100'>
                <Row form className='d-block'>
                  <div className=' flex-wrap: wrap justify-content: center'>
                    <div className='col-md-12 '>
                      <div className='pb-2rem'>
                        {flag == 'cancel' && (
                          <><div className='mt-2rem appdate d-flex align-items-center'>
                            <label>Comment </label>
                            <div className='px-4 py-3 w-100'>
                              <input name="Comment"
                                id="Comment" className="form-control filterin Quantity"
                                value={reDetail.comment}
                                onChange={(event) => { handleInputChange("comment", event.target.value); }} />
                            </div>
                          </div><div className='mt-2rem appdate d-flex align-items-center'>
                              <label>Reschedule After 3 Months</label>
                              <div className='px-4 py-3 w-100'>
                                <input name="rescheduledTime"
                                  id="rescheduledTime" className="form-control filterin Quantity"
                                  readOnly
                                  value={date} />
                              </div>
                            </div></>

                        )}
                        {flag == 'reschedule' && (
                          <div className='mt-2rem appdate d-flex align-items-center'>
                            <label>Reschedule Date -</label>
                            <div className='px-4 py-3 w-100'>
                              <input name="scheduledTime"
                                id="scheduledTime" className="form-control filterin Quantity"
                                placeholder="Enter scheduledTime"
                                required
                                type="date"
                                min={todayDate}
                                defaultValue={patientDetail['closescheduleddate']}
                                onChange={(event) => { handleInputChange("scheduleddate", event.target.value); }}
                              />
                            </div>

                          </div>
                        )}
                        {flag == 'patientClose' && (
                          <><div className='mt-2rem appdate d-flex align-items-center'>

                            <label>Comment </label>
                            <div className='px-4 py-3 w-100'>
                              <input name="closecomment"
                                id="closecomment" className="form-control filterin Quantity"
                                placeholder="Enter Comment Please"
                                onChange={(event) => { handleInputChange("closecomment", event.target.value); }} />
                            </div>
                          </div>
                            {/* <div className='mt-2rem appdate d-flex align-items-center'>

                              <label>Schedule Time </label><div className='px-4 py-3 w-100'>
                                <input name="closeScheduledTime"
                                  id="closeScheduledTime" className="form-control filterin Quantity"
                                  placeholder="Enter ScheduledTime"
                                  required
                                  type="datetime-local"
                                  onChange={(event) => { handleInputChange("closeScheduledTime", event.target.value); } } />
                              </div></div> */}
                             {revisitFlag=== false && (
                            <Row form>
                              <Col md="4" className="form-group">
                                <label htmlFor="followUpFor">Follow Up For</label>
                                <Col md="12 d-flex justify-content-between">
                                  <FormRadio
                                    name="1Week"
                                  checked={selectedFollowUpDate === "1Week"}
                                   onChange={(event) => {onSelectedFollowUpDate('1Week')}}
                                  >1 Week{" "}
                                  </FormRadio>
                                  <FormRadio

                                    name="2Week"
                                   checked={selectedFollowUpDate === "2Week"}
                                   onChange={(event) => {onSelectedFollowUpDate('2Week')}}
                                  >
                                    2 Week{" "}
                                  </FormRadio>
                                  <FormRadio

                                    name="3Week"
                                    checked={selectedFollowUpDate === "3Week"}
                                      onChange={(event) => {onSelectedFollowUpDate('3Week')}}
                                  >
                                    3 Weeks{" "}
                                  </FormRadio>
                                </Col>
                              </Col>

                            </Row>
                             )}


                           
                            <div className='mt-2rem appdate d-flex align-items-center' key={revisitFlag}>

                              <label>FollowUp Date</label><div className='px-4 py-3 w-100'>
                                <input name="closescheduleddate"
                                  id="closescheduleddate" className="form-control filterin Quantity"
                                  placeholder="Enter ScheduledTime"
                                  required
                                  type="date"
                                  min={todayDate}
                                 // defaultValue={patientDetail['closescheduleddate']===''?patientDetail['closescheduleddate']:patientDetail['closescheduleddate']}
                                  value={patientDetail.closescheduleddate}
                                  //defaultValue={followUpDate}
                                  onChange={(event) => { handleInputChange("closescheduleddate", event.target.value); }} />
                              </div>

                            </div></>


                        )}
                      </div>
                    </div>
                    <div className="modal-footer border-top-0 ng9-footer bordernull">
                      <button type="button" className="btn btn-primary " onClick={(event) => submit(event)}>Save</button>
                    </div>
                  </div>
                </Row>
              </Form>
            </div>
          </Col>
        </div>
      </div>
    </div>
  </>

  );
}

export default PatientRevisitModel;
