import {
  BED_DETAILS,BED_SEARCH_FAILURE,BED_SAVE_SUCCESS,BED_SAVE_FAILURE,
  BED_UPDATE_SUCCESS,BED_UPDATE_FAILURE,
} from "../../shared/constants";
import { bedServices } from "../services";


// export function getAllBedInfo(resCb) {
//   return (dispatch) => {
//     dispatch(request(BED_DETAILS, {}));
//     bedServices.getAllBedInfo().then(
//       (res) => {
//         if (res) {
//           dispatch(success(BED_DETAILS, res.data));
//         }
//         resCb(res.data);
//       },
//       (error) => {
//         resCb(error.toString);
//       }
//     );
//   };
// }

export function getAllBedInfo(searchText, resCb) {
    return (dispatch) => {
        bedServices.getAllBedInfo(searchText)
            .then(
                user => { 
                    console.log("Res",user)
                    dispatch(success(BED_DETAILS, user.data));
                    resCb(user.data);
                },
                error => {
                    dispatch(failure(BED_SEARCH_FAILURE, error.toString()));
                   resCb(error.toString);
                }
            );
    };
}

export function addNewBeds(profileValue, resCb) {
  return (dispatch) => {
    bedServices.addNewBeds(profileValue).then(
      (response) => {
        console.log("Add-Bed",response)
          dispatch(success(BED_SAVE_SUCCESS, response))
          resCb(response);
      },
      (error) => {
        console.log(error);
        dispatch(failure(BED_SAVE_FAILURE, error.toString()))
        resCb(error.toString);
      }
    );
  };
}

export function updateBed(data, resCb) {
  return (dispatch) => {
    bedServices.updateBed(data).then(
      (user) => {
        console.log("ReSult", user);
        dispatch(success(BED_UPDATE_SUCCESS, user));
        resCb(user);
      },
      (error) => {
        dispatch(failure(BED_UPDATE_FAILURE, error.toString()));
        resCb(error.toString);
      }
    );
  };
}


function success(type, data) {
  return { type: type, payload: data };
}
function failure(type, value) {
  return { type: type, payload: value };
}
function request(type, value) {
  return { type: type, payload: value };
}

const bActions = {
  getAllBedInfo,
  // getSearchBeds,
  addNewBeds,
  updateBed, 
};

export default bActions;
