import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { bedActions } from "../../core/actions";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  FormInput,CardHeader
} from "shards-react";
import { helperServices } from "../../shared/helper";

function TextAreaRenderer(props) {

    const handleEditClick = (bedDetailList,event)=>{
    console.log("data : ",bedDetailList)
    let instInfo = {}
   // instInfo['instId'] = instId
   // instInfo['userTopicid'] = userTopicid
    props.context.componentParent({bedDetailList});
  }

  const handleSectionInputChange = (name,value,id) => {
    console.log('edit:',id)
    props.context.componentParent({name,value,id});
  }

  return (
   <>
    <textarea className="scontent tcol sheader"
    maxlength="4096"
    value= {props.data.msectioninfo.contents}
    onChange={event => 
        { handleSectionInputChange('contents', event.target.value, props.data.msectioninfo.slno) }}
    >
    </textarea></>
  )
  
}

export default TextAreaRenderer;
