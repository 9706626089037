import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageTitle from "../common/PageTitle";
import Components from '..';

import {
  Row,
  Col,
} from "shards-react";
import { zoomActions } from '../../core/actions';
import {useDispatch, useSelector} from "react-redux";

function Zoom(props) {
  console.log("inside zoom",props)
  // props.patientzoomjoin
  const[zoompatient, setZoompatient] = useState({});
  const dispatch = useDispatch();
    const[showAddCardModel, setShowAddCardModel] = useState({});
    const[cardInfo, setCardInfo] = useState({})
    const[currentCardInfo, setCurrentCardInfo] = useState({})
    const[load,setLoad] = useState(false)
    const meetingInfo = useSelector(state => state.zoomDetailsState.meetingInfo)
    console.log('meetingInfo',meetingInfo)
    var meeting = {};
    meeting['meetingId'] = props.match.params.meetingId
    meeting['patientId'] = props.match.params.patientId
    meeting['visitId'] = props.match.params.visitId
    meeting['groupId'] = props.match.params.groupId
    meeting['name'] = props.match.params.name
      console.log('meeting details :',meeting)
      console.log("props.match.params.meetingId :",props.match.params, props.match.params.meetingId);
    useEffect(() => {
        console.log("meeting props :",props);
      //  dispatch(zoomActions.setPatientMeetingInfo(meetingInfo))
        setLoad(true)
        console.log("state",meeting);
       
    },[])


    const addCard = () =>{
        setShowAddCardModel(!showAddCardModel);
    }

    const handleInputChange = (event,value) => {
        event.preventDefault();
        console.log("In handleinput change",value);
        setCurrentCardInfo(value)
      //  var cardDetail = cardInfo;
       // cardDetail = value;
      //  console.log("pDetail[name] change",cardDetail, value);
       // setCardInfo({...cardDetail});
    }

    const saveCard = () =>{

    }

return (  
<div className='zoom'>
<main className="h-100">
    <Row noGutters className="h-100" key={load}>
        <Col className="form-group h-100 form-group"  md="8">
            <div className="main-content-container container-fluid h-100">
                <div className="row h-100">
                    <div className="mb-12 col-sm-12 col-md-12 hpcol bg menu-head ">
                   
                    <Components.Zoommeeting
                     key={load}
                   meetingInfo={meeting}

                        />:
                            
                    </div>
                </div>
            </div>
        </Col>
        <Col className="form-group h-100 "   md="4" >
       
        <Components.ZoomNotes
         key={load}
        meetingInfo = {meeting}/>

        </Col>
    </Row>
</main>
</div>    
);
}


export default Zoom
