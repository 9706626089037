import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { connect } from "react-redux";
import { patientActions } from "../../core/actions";
import { format } from "date-fns";
import { DOCTOR_ROLE } from '../../shared/constants';
import { helperServices } from "../../shared/helper";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  FormGroup,
  Button,
  FormRadio,
  Card,
  CardBody,
  CardImg,
  CardTitle,
  CardHeader,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Collapse,
  FormCheckbox
} from "shards-react";
import Webcam from "react-webcam";
import Components from "./index";
import { useBarcode } from 'react-barcodes';
import ProcedureListing from './ProcedureListing';


function PatientForm(props) {

  let videoRef = useRef(null);
  let photoRef = useRef(null)
  const [patientDetail, setPatientDetail] = useState(
    {
      id: "",
      patientId: "",
      fname: "",
      mname: "",
      lname: "",
      age: "",
      adhar: "",
      phno: "",
      area: "",
      city: "Arkula",
      state: "Karnataka",
      pincode: "",
      gender: "",
      bloodgroup: "",
      occupation: "",
      address: "",
      dob: "",
      age: "",
      email: "",
      imgUrl: "",
      udt: "",
      visit: {},
      upload: {},
      type: 'OutPatient',
      insurance: "",
      fileNo: "",
      srcRef: "",
      language: "",
      education: "",
      sociostatus: "",
      tokenNumber: "",
      deskNo: "",
      comments: "",
      status: "",
      ehrid: "",
      erpCustId:"",
      altPhno:"",
      emrPhono:""
    }
  );
  const [appliedFilters, setAppliedFilters] = useState({
    type: false
  })
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [formErrors, setFormErrors] = useState(
    {
      fname: '',
      phno: '',
      age: '',
      adhar: '',
      email: '',
      city:'',
      state:'',
      emrPhono:''
    },
  )
  const [imagSrc, setimagSrc] = React.useState(null);
  const [uploadFile, setUploadFile] = useState({ id: '', patientId: '', name: '', phno: '', imgurl: '', type: '', file: '' });
  const [imgSrc, setImgSrc] = useState(null);
  const [showWebCam, setShowWebCam] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [submitted, setSubmitted] = useState([]);
  const [patientData, setPatientData] = useState([]);
  const [callType, setCallType] = useState([]);
  const [showWebCamModal, setShowWebCamModal] = useState(false);
  const [printTOkenModal, setPrintTOkenModal] = useState(false);
  const [modalopen, setModalopen] =useState(false);

  const [videoConstraintsSelfie, setVideoConstraintsSelfie] = useState({
    width: 220,
    height: 200,
    facingMode: "user",
  });
  const [videoConstraints, setVideoConstraints] = useState({
    facingMode: { exact: "environment" },
  });
  const [ServerRes, setServerRes] = useState(
    { success: '', error: '', }
  );
  const dispatch = useDispatch();

  const [barcodeNo, setbarcodeNo] = useState();

  //const webcamRef = React.createRef();
  const webcamRef = React.useRef(null);
  //this.capture = this.capture.bind(this);
  const patient = useSelector(state => state.patientDetailsState.patient)
  const [viewBasicInfo, setviewBasicInfo] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedGender, setSelectedGender] = useState("");
  var d = new Date();
  const todayDate = format(d.setDate(d.getDate()), "yyyy-MM-dd");
  //const length = patientDetail.age.length < 11;
  const [convertModal, setconvertModal] = useState(false);
  const [editProfile, setEdiProfile] = useState(props.openPatientForm);

  const todayDate1 = format(new Date(), 'yyyy-MM-dd');

  // Get the current time and format it as "HH:mm" for the time input field
  const currentTime = format(new Date(), 'HH:mm');

  // Set the default values
  const [selecteddate, setSelecteddate] = useState(todayDate1);
  const [selectedTime, setSelectedTime] = useState(currentTime);


  useEffect(() => {
    console.log("patientFormDetail in patient form : ", patient);
    console.log("props.patientInfo : ", props.patientInfo);
    console.log("props patient form : ", props);

    //setPatientDetail(props.patientInfo);
    if (patient.length > 0) {
      setPatientDetail(patient);
    } else {
      if(props && props.patientInfo != ''){
       setPatientDetail({ ...props.patientInfo });
      }
    }


    if (patient.length == 0) {
      setCallType('Add')
      setviewBasicInfo(false);
      setSelectedGender(props.patientInfo.gender);
    
    }
    else {
      setCallType('Edit')
      setviewBasicInfo(true);
      setSelectedGender(props.patientInfo.gender);
      
    }
    setReload(!reload);
    console.log("callType", callType);
    console.log("patientDetail:", patientDetail);


  }, [props]
  )
  const handleOnChange=(value,event)=>{
    if(value==="date"){
      setSelecteddate(event.target.value);
      console.log("Seleected Date is",event.target.value,event)
      console.log("Seleected Date is",typeof selecteddate)
    }
    if(value==="time"){
      setSelectedTime(event.target.value);
      console.log("Seleected Date is",event.target.value,event)
      console.log("Seleected Date is",typeof selectedTime)
    }
  
  }
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleInputChange = (name, value) => {
    formErrors[name] = "";
    console.log("In handleinput change", name, value);
    var pDetail = patientDetail;
    console.log('pDetail["basicinfo"][name]', pDetail[name])
    pDetail[name] = value;
    setPatientDetail({ ...pDetail });
    console.log('pDetail', pDetail)
    console.log('patientDetail', patientDetail)
  }

  const onValueChange = (e) => {
    e = 'selfie'
    //setSelectedOption(e.target.value);
    setSelectedOption(e);
    setShowWebCamModal(true)
    console.log("camera option selected event.target.value", e, '..', e)
    console.log("camera option selected", selectedOption);
  }

  const handleCheckbox = (e) => {
    //patientDetail.insurance = value;
    console.log("value:", e.target);
    let pDetail = patientDetail;
    pDetail.insurance = pDetail.insurance == 'YES' ? 'NO' : 'YES';
    console.log("pDetail:", pDetail);
    setPatientDetail({ ...pDetail });
  }

  const capture = (event) => {
    event.preventDefault();
    let picture = webcamRef.Blob;
    // webcam.snap();
    console.log("webcamRef pic ", picture);
    const imageSrc = webcamRef.current.getScreenshot();
    console.log('imageSrc : ', imageSrc)
    let video = videoRef.current
    let photo = photoRef.current
    console.log("let photo = photoRef.current :", photo, "    let video = videoRef.current :", video);
    const blob = fetch(imageSrc).then((res) => res.blob());
    console.log("selfie blob : ", blob, blob.Blob);
    var data = imageSrc.toString().replace(/^data:image\/jpg;base64,/, "");
    var buf = Buffer.from(data, 'base64');
    console.log("data and buf :", data, buf);
    var file = picture;
    var reader = new FileReader();
    var url = null;
    // reader.readAsDataURL(blob);
    // var objurl = URL.createObjectURL(file);
    setImgSrc(imageSrc);
    setUploadFile({ patientId: patientDetail.id, name: patientDetail.fname, phno: patientDetail.phno, imgurl: '', type: 'profilepic', file: imageSrc });
    console.log("image src data : ", imageSrc.file, imageSrc.data, "imgSrc :", imgSrc, imageSrc);
    // patientDetail.upload.file = imageSrc;
    setShowWebCam(true);
  }

  const onImageSubmit = (event) => {
    event.preventDefault();

    const blob = fetch(imgSrc).then((res) => res.blob());
    console.log("selfie blob : ", blob, blob.Blob);
    const formData = new FormData();
    formData.append('image', blob)
    formData.append('imageurl', imgSrc);
    console.log("formData", formData);
    console.log("selfie image : ", formData);
    var setPatient = { patientDetail }
    console.log("uploadFile :", uploadFile);
    setPatient.upload = uploadFile;
    patientDetail.upload = uploadFile
    console.log("patient detail : ", patientDetail);

  }
  const handleTokenPrint = () => {
    console.log("patientDetail", patientDetail);
    setPrintTOkenModal(true);
    setTimeout(() => {
      setPrintTOkenModal(false);
    }, 500);
  }

  const handleOnboard = () =>{
    props.openOnboardModal(props.patientInfo);
  };
  const onFileCancel = (event) => {
    event.preventDefault();
    setImgSrc(null);
    setShowWebCam(false);
  }

  const toggleModal = () => {
    setShowWebCamModal(!showWebCamModal);
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    setSubmitted(true);
    if (patientDetail.type == "") {
      patientDetail.type = "OutPatient";
    }
    if (patientDetail.gender == "") {
     // patientDetail.gender = "Male";
    }
    console.log('patientdetail before foreach loop', patientDetail);
    console.log('patientdetail before foreach loop', Object.keys(patientDetail));
    Object.keys(patientDetail).forEach((key) => {
      console.log("key", patientDetail[key])
      validateField(key, patientDetail[key])
      console.log("patientInfo:", event)
    })
    validateForm(event);
    setMessageStrip({ success: event.successMessages });
    setTimeout(() => {
      console.log("props:", props);
    }, 3000);

   
  }

  const setMessageStrip = (e) => {
    console.log("message obtained", e);
    var ServerResVal = ServerRes;
    if (e.success) {
      console.log("success message:", e.success);
      ServerResVal.success = e.success;
    }
    else {
      console.log("error message:", e.error);
      ServerResVal.error = e.error;
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 5000);
  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = patientDetail;
    console.log("Basic Info", basicInfo)
    console.log("FieldName", fieldName, "Value", value)
    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/);
    var phoneNoPattern = new RegExp(/^[0-9]{10}$/);
    var currentDate = new Date().getDate;
    switch (fieldName) {
      case "fname":
        fieldValidationErrors.fname = value.length > 0 ? '' : 'Please Enter First Name';
        basicInfo.fname = value !== '' ? value : '';
        break;

      case "adhar":
        if (fieldValidationErrors.adhar = value.length == 0) {
          fieldValidationErrors.adhar = value.length == 0 ? 'Please Enter Aadhar Number' : '';
          basicInfo.adhar = value !== '' ? value : '';
          break;
        } else {
          fieldValidationErrors.adhar = value.length == 12 ? '' : 'Please Enter Valid Aadhar Number';
          basicInfo.adhar = value !== '' ? value : '';
          break;
        };

      case "gender":
        fieldValidationErrors.gender = value.length > 0 ? '' : 'Please Select Gender';
        basicInfo.gender = value !== '' ? value : '';
        break;
      // case "dob":
      //   fieldValidationErrors.dob = value.length > 0 ? '' : 'Please Select Date of Birth';
      //   basicInfo.dob = value <=currentDate ? value : '';
      //   break;
      // case "adhar":
      //   fieldValidationErrors.adhar = value !== '' ? '' : 'Please Enter Adhar Number';
      //   fieldValidationErrors.adhar = !pattern.test(value) ? 'Please enter valid Adhar Number': '';
      //   basicInfo.adhar = value !== '' ? value : '';

      case "phno":
        //fieldValidationErrors.phno = value !== '' ? '' : 'Please Enter Phone Number';
        //basicInfo.phno = value !== '' ? value : '';
        // fieldValidationErrors.phno = !phoneNoPattern.test(value) ? 'Please enter valid Phone Number': '';
        // basicInfo.phno = value !== '' ? value : '';
        if (fieldValidationErrors.phno = value.length == 0) {
          fieldValidationErrors.phno = value.length == 0 ? 'Please Enter Phone Number' : '';
          basicInfo.phno = value !== '' ? value : '';
          break;
        } else {
          fieldValidationErrors.phno = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
          basicInfo.phno = value !== '' ? value : '';
          break;
        };
        case "city":
            fieldValidationErrors.city = value.length == 0 ? 'Please Enter City' : '';
            basicInfo.city = value !== '' ? value : '';
            break;
          // };
          case "state":
              fieldValidationErrors.state = value.length == 0 ? 'Please Enter State' : '';
              basicInfo.state = value !== '' ? value : '';
              break;
            // };

      case "age":
        fieldValidationErrors.age = value.length == 0 ? 'Please Enter Age' : '';
        if(value.length > 0){
        fieldValidationErrors.age = value < 0 ? 'Please enter valid Age' : '';
        }
        basicInfo.age = value !== '' ? value : '';
        break;
      case "emrPhono":
        // if (fieldValidationErrors.emrPhono = value.length == 0) {
        //   fieldValidationErrors.emrPhono = value.length == 0 ? 'Please Enter Phone Number' : '';
        //   basicInfo.emrPhono = value !== '' ? value : '';
        //   break;
        // } else {
          if(value.length >0){
          fieldValidationErrors.emrPhono = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
          basicInfo.emrPhono = value !== '' ? value : '';
          break;
          }
         
        // };
      // case "email":
      //   if(!isEmail(value)){
      //     fieldValidationErrors.email = value == isEmail ? '' : 'Please Enter Email';
      //     basicInfo.email = value !== '' ? value : '';
      //     break;
      //   }else{
      //     fieldValidationErrors.email = value.length <= 0 ? '' : '';
      //     basicInfo.email = value !== '' ? value : '';
      //     break;
      //   };

      default:
        break
    }
    console.log("fieldValidationErrors:", fieldValidationErrors);
    setFormErrors({ ...fieldValidationErrors });
  }

  const validateForm = (event) => {
    event.preventDefault();
    var ServerResVal = ServerRes;

    var pDetail = patientDetail;
    // var age = patientDetail.age == '' ? getAge(patientDetail.dob) : patientDetail.age;
    // console.log("Age", age)
    // pDetail['age'] = age;
    setPatientDetail(pDetail);

    console.log("PDetails", patientDetail)

    let admissionDateTime="";
    if(patientDetail.type=="InPatient"){
      const dateTimeString = `${selecteddate} ${selectedTime}:00`;
      admissionDateTime = dateTimeString;
      console.log("timestamp",admissionDateTime);
    }

    let formValid =
      formErrors.fname === "" &&
      formErrors.age === "" &&
      formErrors.email === "" &&
      formErrors.adhar === "" &&
      formErrors.phno === "" &&
      formErrors.city === "" &&
      formErrors.state === ""&& 
      formErrors.emrPhono === ""&&
      formErrors.gender === "";
    console.log("formValid:", formValid);

    if (formValid) {
      if (callType === 'Add') {
        let flag;
        if(props && props.appointment){
         flag = 'appointment'
        }else{
          flag = 'preReg';
        }
        dispatch(patientActions.addPatient(patientDetail, flag,admissionDateTime, resCb => {
          if (resCb) {
            console.log("Rescb", resCb)
            if (resCb.successMessages) {
              props.onSuccess(resCb, 'payment');
              // ServerResVal.success = resCb.successMessages;
              // resCb.data.id
              props.closePatientForm();
            }
            setServerRes({ ...ServerResVal });
            if (resCb.errorMessages) {
              props.onSuccess(resCb, 'payment');
              // ServerResVal.error = resCb.errorMessages
              props.closePatientForm();
            }
           
           

          }

        }));
      }
      else {
        // patientDetail ['']
        // patientDetail.upload = uploadFile;
        console.log("update patient input :", patientDetail);
        let convert = "";
        dispatch(patientActions.updatePatient(patientDetail,convert,admissionDateTime, resCb => {
          if (resCb) {
           
            console.log("Rescb", resCb)
            if (resCb.successMessages) {
              ServerResVal.success = "Patient details updated successfully."
            }
            else if (resCb.state === 'error') {
              ServerResVal.error = "Failed to update patient details."
            }
            setServerRes({ ...ServerResVal });
            // closeProfileEdit();
            props.onSuccess(resCb,'');
            props.closePatientForm();
          }
        }));
      }
    } else {
      setTimeout(() => {
        var errors = document.querySelectorAll(".is-invalid");
        console.log("errors:", errors);
        if (errors.length > 0) {
          errors[0].focus();
        }
      }, 500);


    }

  }

  const getAge = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }


  const toggleBasicInfo = () => {
    setviewBasicInfo(!viewBasicInfo);
  };

  const onSelectedGender = (value) => {
    setSelectedGender(value)
    console.log("setSelectedGender", value);
    patientDetail.gender = value;
  }

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  }
  const closePdfModal = (event) => {
    setPrintTOkenModal(false);

  };
  const backToUserList = () => {
    console.log("props:", props)
    props.props.history.push({
      pathname: `/patient`,
    });
    if (document.getElementById('user-list'))
      document.getElementById('user-list').classList.remove('mobile-view');
    if (document.getElementById('user-info'))
      document.getElementById('user-info').classList.remove('mobile-view');
  }

  const toggle = (event) => {
     setModalopen(!modalopen)
  }
 
  const closeProfileEdit = () => {
    props.closePatientForm();
  }

  const modalPopUp =() => {
    setModalopen(true)
    // var ServerResVal = ServerRes;
    // ServerResVal.success = "Patient details updated successfully."
    // setServerRes({ ...ServerResVal });
  }

   const addZeroSuffix=(number)=> {
    const numberString = number.toString();
    if (numberString.length !== 8) {
      const suffix = "0".repeat(8 - numberString.length);
      return suffix + numberString;
    }
    return numberString;
  }

  const convertToInPatient = () => {
    //setModalopen(false);
    var ServerResVal = ServerRes;
    console.log("convert to inpatient button clicked");
    patientDetail.type = "InPatient";
    let convert = "convertToInPatient";
    dispatch(patientActions.updatePatient(patientDetail,convert, resCb => {
      if (resCb) {
        console.log("Rescb", resCb.state, resCb.message)
        if (resCb.successMessages) {
          console.log("success message");
          ServerResVal.success = "Patient details updated successfully."
        }
        else if (resCb.state === 'error') {
          ServerResVal.error = "Failed to update patient details."
        }
        setServerRes({ ...ServerResVal });
        // setTimeout(() => {
        //   closeAlert();
        // }, 3000);
        setModalopen(false);
      }
     
     


    }));


    
    
  }
  return (
    <main className="" key={reload}>
       {ServerRes.success &&
                        <Alert theme="success" className="alert-div">{ServerRes.success}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
                      }
                      {ServerRes.error &&
                        <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
                      }
      {/* {(!helperServices.CheckAccessLevel("Patient")) &&
        <h5 className="">Patient Basic Information
       

          {(!helperServices.CheckAccessLevel("InsurancePatients")) ?
            <span className="ml-3" onClick={toggleBasicInfo} style={{ cursor: 'pointer' }} ><i className="material-icons" title={viewBasicInfo ? 'Edit Detials' : 'View Details'}>{viewBasicInfo ? 'edit' : 'visibility'}</i></span>
            : ''}
            {patientDetail && patientDetail.status != 'close' && patientDetail.type == 'OutPatient' ?
        <button className="btn btn-submit btn-primary ml-3" type="submit" onClick={modalPopUp}>convert To Inpatient</button>
          :''}
            
        </h5>



      }
      {(helperServices.CheckAccessLevel("Patient")) &&
        <>
          <span className='px-3 d-flex justify-content-between align-items-end'>

            <h5 className="px-2">New Patient</h5>

            <div className="back-icon" id="back-btn" onClick={(event) => backToUserList()}>
              <span class="material-icons">arrow_back</span>

            </div>

          </span>
        </>
      }
      {printTOkenModal ? (
        <div className="">
          <Components.TokenPrint
            tokenDetails={patientDetail}
            deskDetails={props.deskDetails}
            onModalClose={(event) => closePdfModal(event)}

          />
        </div>
      ) : (
        ""
      )} */}

      {/* {viewBasicInfo ?


        <div style={{ fontSize: '14px' }}>

          <Card className="border">

            <CardBody className="p-3">

              <div className='p-2'>

                <div style={{ fontWeight: '400', float: 'left' }}>Category : {patientDetail.type}</div>
               
                {props.patientInfo.status === "close" &&
                  <div className="mb-3" style={{ fontWeight: '400', display: 'flex', width: '25%', float: 'right', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div className="fbtn mb-2 mt-2 mr-1">
                      <Button type="submit" onClick={(event) => handleOnboard(event)}>Onboard Patient</Button>
                      </div>
                  </div>
                  }
                
                {patientDetail.tokenNumber ?
                  <div className="mb-3" style={{ fontWeight: '400', display: 'flex', width: '25%', float: 'right', alignItems: 'center', justifyContent: 'space-between' }}>Token : {patientDetail.tokenNumber}
                    <div className="fbtn mb-2 mt-2">
                      <Button type="submit" onClick={(event) => handleTokenPrint(event)}>Print Token</Button>
                    </div>
                  </div>
                  : ''}
                <br></br>
                <div className="mb-3" style={{ fontWeight: '400' }}>Code : {patientDetail.patientcode}</div>
               

                <div className='d-flex justify-content-between flex-wrap basic-details'>
                  <div>
                    <h6 className='border-bottom header'>Personal Info</h6>

                    <div>

                      {patientDetail.fileNo &&
                        <div >
                          <label>File Number</label>
                          <span><span className="mr-2">:</span>{patientDetail.fileNo}</span>
                        </div>
                      }

                      {patientDetail.fname &&
                        <div >
                          <label>Name</label>
                          <span><span className="mr-2">:</span>{patientDetail.fname}</span>
                        </div>
                      }
                      {patientDetail.gender &&
                        <div>
                          <label>Gender</label>
                          <span><span className="mr-2">:</span>{patientDetail.gender}</span>
                        </div>
                      }
                      {patientDetail.dob &&
                        <div>
                          <label>Birth Date</label>
                          <span>
                            <span className="mr-2">:</span>
                            {patientDetail.dob}</span>
                        </div>
                      }
                      {patientDetail.age &&
                        <div>
                          <label>Age</label>
                          <span>
                            <span className="mr-2">:</span>
                            {patientDetail.age}</span>
                        </div>
                      }
                      {patientDetail.bloodgroup &&
                        <div>
                          <label>Blood Group</label>
                          <span>
                            <span className="mr-2">:</span>
                            {patientDetail.bloodgroup}</span>
                        </div>
                      }
                      {patientDetail.insurance &&
                        <div>
                          <label>Insurance</label>
                          <span>
                            <span className="mr-2">:</span>
                            {patientDetail.insurance}</span>
                        </div>
                      }
                    </div>
                  </div>


                  <div>
                    <h6 className='border-bottom header'>Contact Info</h6>
                    <div>
                      {patientDetail.adhar &&
                        <div>
                          <label>Adhar</label>
                          <span><span className="mr-2">:</span>{patientDetail.adhar}</span>
                        </div>
                      }

                      {patientDetail.phno &&
                        <div>
                          <label>Phone No</label>
                          <span><span className="mr-2">:</span>{patientDetail.phno}</span>
                        </div>
                      }
                      {patientDetail.altPhno &&
                        <div>
                          <label>Alternate Phone No</label>
                          <span><span className="mr-2">:</span>{patientDetail.altPhno}</span>
                        </div>
                      }
                      {patientDetail.email &&
                        <div>
                          <label>Email</label>
                          <span><span className="mr-2">:</span>{patientDetail.email}</span>
                        </div>
                      }
                    </div>
                  </div>

                    
                  <div>
                    <h6 className='border-bottom header'>Residential Info</h6>
                    <div>
                      {patientDetail.address &&
                        <div>
                          <label>Address</label>
                          <span><span className="mr-2">:</span>{patientDetail.address}</span>
                        </div>
                      }
                      {patientDetail.city &&
                        <div>
                          <label>City</label>
                          <span><span className="mr-2">:</span>{patientDetail.city}</span>
                        </div>
                      }
                      {patientDetail.state &&
                        <div>
                          <label>State</label>
                          <span><span className="mr-2">:</span>{patientDetail.state}</span>
                        </div>
                      }
                      {patientDetail.pincode &&
                        <div>
                          <label>Zip</label>
                          <span><span className="mr-2">:</span>{patientDetail.pincode}</span>
                        </div>
                      }
                    </div>
                  </div>

                  <div>
                    <h6 className='border-bottom header'>Other Info</h6>
                    <div>
                      {patientDetail.srcRef &&
                        <div>
                          <label>Source of Reference</label>
                          <span><span className="mr-2">:</span>{patientDetail.srcRef}</span>
                        </div>
                      }
                      {patientDetail.language &&
                        <div>
                          <label>Language</label>
                          <span><span className="mr-2">:</span>{patientDetail.language}</span>
                        </div>
                      }
                      {patientDetail.education &&
                        <div>
                          <label>Education</label>
                          <span><span className="mr-2">:</span>{patientDetail.education}</span>
                        </div>
                      }
                      {patientDetail.sociostatus &&
                        <div>
                          <label>Socio Status</label>
                          <span><span className="mr-2">:</span>{patientDetail.sociostatus}</span>
                        </div>
                      }

                    </div>
                  </div>
               

                </div>
              </div>
            </CardBody>
          </Card>


        </div>

        :


        <>
          <Collapse className={(!helperServices.CheckAccessLevel("Patient")) ? "px-3 card" : "px-3"} open={!viewBasicInfo}>

            <ListGroup flush className="pt-3 ">
              <ListGroupItem className="p-2 bg-none">
                <Row>
                  <Col className="patient-edit-form">
                    <Form style={{ fontSize: '14px' }}>
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="fname">First Name <span className='text-danger'>*</span></label>
                          <FormInput
                            id="fname"
                            value={patientDetail.fname}
                            placeholder="First Name"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("fname", event.target.value);
                            }}
                            invalid={formErrors.fname === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.fname}</span>
                          </div>
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="mname">Middle Name</label>
                          <FormInput
                            id="mname"
                            value={patientDetail.mname}
                            placeholder="Middle Name"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("mname", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="lname">Last Name</label>
                          <FormInput
                            id="lname"
                            value={patientDetail.lname}
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("lname", event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row form className='d-flex justify-content-between'>
                        <Col md="4" className="form-group">
                          <label htmlFor="fileNo">File Number</label>
                          <FormInput
                            id="fileNo"
                            value={patientDetail.fileNo}
                            placeholder="File Number"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("fileNo", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="gender">Gender</label>
                          <Col md="12 d-flex justify-content-between">
                            <FormRadio
                              name="Male"
                              checked={selectedGender === "Male"}
                              onChange={(event) => { onSelectedGender('Male') }}
                            >
                              Male{" "}
                            </FormRadio>
                            <FormRadio

                              name="Female"
                              checked={selectedGender === "Female"}
                              onChange={(event) => { onSelectedGender('Female') }}
                            >
                              Female{" "}
                            </FormRadio>
                            <FormRadio

                              name="Others"
                              checked={selectedGender === "Others"}
                              onChange={(event) => { onSelectedGender('Others') }}
                            >
                              Others{" "}
                            </FormRadio>
                          </Col>

                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="birthdetails">Birth Details</label>
                          <FormInput
                            type="date"
                            id="birthdetails"
                            max={todayDate}
                            value={patientDetail.dob}
                            placeholder="Birth Details"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("dob", event.target.value);
                            }}

                          />

                        </Col>


                      </Row>
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="age">Age</label>
                          <FormInput
                            type="number"
                            id="age"
                            min={0}
                            value={patientDetail.age}
                            placeholder="Age"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("age", event.target.value);
                            }}

                            invalid={formErrors.age === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.age}</span>
                          </div>

                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="category">Category</label>
                          <select name="category" className="form-control filterin Quantity"
                            defaultValue={patientDetail.type}
                            value={patientDetail.type}
                           
                            onChange={(event) => { handleInputChange("type", event.target.value); }} >
                          
                            <option value="InPatient" >In Patient</option>
                            <option value="OutPatient">Out Patient</option>
                            <option value="OnlinePatient">Online Patient</option>
                          </select>
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="srcRef">Source of Refrence</label>
                          <FormInput
                            id="srcRef"
                            value={patientDetail.srcRef}
                            placeholder="Source of Refrence"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("srcRef", event.target.value);
                            }}

                          />

                        </Col>
                      </Row>
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="language">Language</label>
                          <FormInput
                            id="language"
                            value={patientDetail.language}
                            placeholder="Language"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("language", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="education">Education</label>
                          <FormInput
                            id="education"
                            value={patientDetail.education}
                            placeholder="Education"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("education", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="sociostatus">Socio Status</label>
                          <FormInput
                            id="sociostatus"
                            value={patientDetail.sociostatus}
                            placeholder="Socio Status"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("sociostatus", event.target.value);
                            }}
                          />
                        </Col>
                      </Row>

                    
                      <Row form>
                        <Col md="12" className="form-group">
                          <p className="mb-2">Please select your webcam mode:</p>
                          <Col md="4" >
                            <FormRadio
                              value="selfie"
                              name="selfie"
                              checked={selectedOption === "selfie"}
                              onChange={onValueChange}
                            >
                              User/Selfie/forward facing camera{" "}
                            </FormRadio>
                          </Col>
                          <Col md="4">
                            <FormRadio
                              value="env"
                              name="env"
                              checked={selectedOption === "env"}
                              onChange={onValueChange}
                            >
                              Environment/Facing-Out camera
                            </FormRadio>
                          </Col>
                        </Col>
                      </Row>

                      <Row className="my-2">
                        <Col>
                          <FormCheckbox
                            checked={patientDetail.insurance === 'YES' ? true : false}
                            onChange={(event) => {
                              handleCheckbox(event);
                            }}
                          >
                            Do you have insurance?
                          </FormCheckbox>
                        </Col>
                      </Row>

                      <FormGroup>
                        <label htmlFor="feInputAddress">Address</label>
                        <FormInput
                          id="feInputAddress"
                          placeholder="1234 Main St"
                          value={patientDetail.address}
                          onChange={(event) => {
                            handleInputChange("address", event.target.value);
                          }}
                        />
                      </FormGroup>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="Adhar">Adhar</label>
                          <FormInput
                            id="Adhar"
                            type="number"
                            min="0"
                            max="999999999999"
                            value={patientDetail.adhar}
                            placeholder="Adhar No"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("adhar", event.target.value);

                            }}
                            invalid={formErrors.adhar === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.adhar}</span>
                          </div>
                        </Col>
                        <Col md="6" className="form-group">

                          <label htmlFor="phno">Phone Number <span className='text-danger'>*</span></label>
                          <FormInput
                            id="phno"
                            type="number"

                            value={patientDetail.phno}
                            placeholder="Phone Number"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("phno", event.target.value);
                            }}
                            invalid={formErrors.phno === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.phno}</span>
                          </div>
                        </Col>
                      </Row>
                      <Row form>
                    
                      <Col md="6" className="form-group">
                        <label htmlFor="email">Email Id</label>
                        <FormInput
                          id="email"
                          value={patientDetail.email}
                          placeholder="Email Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("email", event.target.value);
                          }}
                          invalid={formErrors.email === "" ? false : true}
                        />
                        <div style={{ color: '#EC4147' }} >
                          <span>{formErrors.email}</span>
                        </div>
                        </Col>

                        <Col md="6" className="form-group">
                        <label htmlFor="email">Alternate phone Number</label>
                        <FormInput
                          id="altNumber"
                          value={patientDetail.altPhno}
                          placeholder="Alternate phone number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("altPhno", event.target.value);
                          }}
                        />
                        </Col>

                   
                      </Row>
                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="bgproup">Blood Group</label>

                          <select name="category" className="form-control filterin Quantity"
                            value={patientDetail.bloodgroup}
                            onChange={(event) => { handleInputChange("bloodgroup", event.target.value); }} >
                            <option disabled={true} value="">
                              --Choose an option--
                            </option>
                            <option value="A +ve">A +ve</option>
                            <option value="A -ve">A -ve</option>
                            <option value="B +ve">B +ve</option>
                            <option value="B -ve">B -ve</option>
                            <option value="AB +ve">AB +ve</option>
                            <option value="AB -ve">AB -ve</option>
                            <option value="O +ve">O +ve</option>
                            <option value="O-ve">O -ve</option>
                          </select>
                        </Col>
                        <Col md="6" className="form-group">
                          <label htmlFor="oc">Occupation</label>
                          <FormInput
                            id="oc"
                            value={patientDetail.occupation}
                            placeholder="Occupation"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange(
                                "occupation",
                                event.target.value
                              );
                            }}
                          />
                        </Col>
                      </Row>

                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="feInputCity">City<span className='text-danger'>*</span></label>
                          <FormInput
                            id="feInputCity"
                            placeholder="city"
                            autoComplete="off"
                            value={patientDetail.city}
                            onChange={(event) => {
                              handleInputChange("city", event.target.value);
                            }}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.city}</span>
                          </div>
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="feInputState">State<span className='text-danger'>*</span></label>
                          <FormInput
                            id="feInputState"
                            placeholder="State"
                            autoComplete="off"
                            value={patientDetail.state}
                            onChange={(event) => {
                              handleInputChange("state", event.target.value);
                            }}
                          ></FormInput>
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.state}</span>
                          </div>
                        </Col>
                        <Col md="2" className="form-group">
                          <label htmlFor="feInputZip">Zip</label>
                          <FormInput
                            id="feInputZip"
                            placeholder="Zip"
                            type="number"
                            autoComplete="off"
                            value={patientDetail.pincode}
                            onChange={(event) => {
                              handleInputChange(
                                "pincode",
                                event.target.value
                              );
                            }}
                          />
                        </Col>
                      </Row>

                      <div className="fbtn mt-3">
                        <Button
                          type="submit"
                          onClick={(event) => handleSubmit(event)}
                        >
                          Next
                        </Button>
                      </div>
                      <br />

                      {ServerRes.success &&
                        <Alert theme="success" className="alert-div">{ServerRes.success}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
                      }
                      {ServerRes.error &&
                        <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
                      }
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>


            <Modal open={showWebCamModal}
              toggle={toggleModal}
            >
              <ModalHeader>
                <Row>
                  <Col md="10">Capture Patient Image</Col>
                  <Col md="2"><i className="material-icons cpointer" onClick={toggleModal} style={{ marginRight: `180px` }}>close</i></Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Card > 
                  {showWebCam === false ? (
                    <CardBody>

                      {selectedOption === "selfie" ? (
                        <Webcam
                          audio={false}
                          imageSmoothing={true}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          height={`250px`}
                          width={`350px`}
                          videoConstraints={videoConstraintsSelfie}
                        />
                      ) : (
                        <Webcam
                          audio={false}
                          imageSmoothing={true}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          height={`100px`}
                          width={`100px`}
                          videoConstraints={videoConstraints}
                        />
                      )}
                      <Button onClick={capture}>Capture</Button>
                    </CardBody>
                  ) : (
                    <>
                      {imgSrc && (
                        <>
                          <CardImg src={imgSrc} />
                          <CardBody>
                            <Button onClick={onImageSubmit}>Save Image</Button>
                            <Button onClick={onFileCancel} style={{ float: `right` }}>Cancel</Button>
                          </CardBody>
                        </>
                      )}
                    </>
                  )}
                </Card>
              </ModalBody>
              <ModalFooter>
                <Button onClick={toggleModal}>Close</Button>
              </ModalFooter>
            </Modal>
            </Collapse>
        </>
      } */}
      <Modal
          className="d-flex flex-column-reverse "
          id="editProfile"
          open={editProfile}
          toggle={closeProfileEdit}
          size="lg"
        >
          <div>
            <ModalHeader className="pr-3">
             Basic Information
             
             <Col md="6" className="form-group mr-6 m-0 d-flex flex-column" style={{ alignItems: 'flex-end' }}>
                          {/* <label htmlFor="category">Category</label> */}
                          <select name="category" className="form-control filterin Quantity" style={{ width: '225px' }}
                            defaultValue={patientDetail.type}
                            value={patientDetail.type}
                            disabled = {callType == 'Edit'}
                            //  defaultvalue={patientDetail.type}
                            onChange={(event) => { handleInputChange("type", event.target.value); }} >
                          
                            <option value="InPatient" >In Patient</option>
                            <option value="OutPatient">Out Patient</option>
                            <option value="OnlinePatient">Online Patient</option>
                          </select>
                        
                    <div className="ml-2 col-6 px-0" style={{"max-width": '46%'}}>
                    
                    </div> 
                     </Col>
                      <span className="close-model ml-2 mt-n3" onClick={(event) => closeProfileEdit()}>
                      X
                    </span>
            </ModalHeader>
            <ModalBody>
              <div>
          
                  <Col className="patient-edit-form">
                    <Form style={{ fontSize: '14px' }}>
                      <Row form> 
                       {patientDetail.type === "InPatient" ? 
                        <Col md="4" className="form-group">
                        <label htmlFor="birthdetails">Admission Date</label>
                        <input
                          name="admissionDate"
                          id="admissionDate"
                          className="form-control filterin Quantity"
                          placeholder="Enter Scheduled Date"
                          required
                          type="date"
                          defaultValue={selecteddate}
                          onChange={(event)=>handleOnChange("date",event)}
                      />
                        </Col> 
                        :''}

                        {patientDetail.type === "InPatient" ? 
                        <Col md="4" className="form-group">
                        <label htmlFor="birthdetails">Admission Time</label>
                        <input
                      name="admissionTime"
                      id="admissionTime"
                      className="form-control filterin Quantity"
                      placeholder="Enter Scheduled Time"
                      required
                      type="time"
                      defaultValue={selectedTime}
                      onChange={(event)=>handleOnChange("time",event)}
                    />
                        </Col> 
                        :''}
                        {patientDetail.type === "InPatient" ? 
                        <Col md="4" className="form-group">
                        
                        </Col> 
                        :''}
                        <Col md="4" className="form-group">
                          <label htmlFor="fname">First Name <span className='text-danger'>*</span></label>
                          <FormInput
                            id="fname"
                            value={patientDetail.fname}
                            placeholder="First Name"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("fname", event.target.value);
                            }}
                            invalid={formErrors.fname === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.fname}</span>
                          </div>
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="mname">Middle Name</label>
                          <FormInput
                            id="mname"
                            value={patientDetail.mname}
                            placeholder="Middle Name"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("mname", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="lname">Last Name</label>
                          <FormInput
                            id="lname"
                            value={patientDetail.lname}
                            placeholder="Last Name"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("lname", event.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row form className='d-flex justify-content-between'>
                      <Col md="4" className="form-group">

                        <label htmlFor="phno">Phone Number <span className='text-danger'>*</span></label>
                        <FormInput
                          id="phno"
                          type="number"

                          value={patientDetail.phno}
                          placeholder="Phone Number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phno", event.target.value);
                          }}
                          invalid={formErrors.phno === "" ? false : true}
                        />
                        <div style={{ color: '#EC4147' }} >
                          <span>{formErrors.phno}</span>
                        </div>
                      </Col>
                      
                        <Col md="4" className="form-group">
                          <label htmlFor="age">Age <span className='text-danger'>*</span></label>
                          <FormInput
                            type="number"
                            id="age"
                            min={0}
                            value={patientDetail.age}
                            placeholder="Age"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("age", event.target.value);
                            }}

                            invalid={formErrors.age === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.age}</span>
                          </div>

                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="gender">Gender<span className='text-danger'>*</span></label>
                          <Col md="12 d-flex justify-content-between">
                            <FormRadio
                              name="Male"
                              checked={selectedGender === "Male"}
                              onChange={(event) => { onSelectedGender('Male') }}
                            >
                              Male{" "}
                            </FormRadio>
                            <FormRadio

                              name="Female"
                              checked={selectedGender === "Female"}
                              onChange={(event) => { onSelectedGender('Female') }}
                            >
                              Female{" "}
                            </FormRadio>
                            <FormRadio

                              name="Others"
                              checked={selectedGender === "Others"}
                              onChange={(event) => { onSelectedGender('Others') }}
                            >
                              Others{" "}
                            </FormRadio>
                          </Col>
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.gender}</span>
                          </div>
                        </Col>


                      </Row>
                      <Row form>
                      <Col md="4" className="form-group">
                        <label htmlFor="email">Alternate phone Number</label>
                        <FormInput
                          id="altNumber"
                          value={patientDetail.altPhno}
                          placeholder="Alternate phone number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("altPhno", event.target.value);
                          }}
                        />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="birthdetails">Birth Details</label>
                          <FormInput
                            type="date"
                            id="birthdetails"
                            max={todayDate}
                            value={patientDetail.dob}
                            placeholder="Birth Details"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("dob", event.target.value);
                            }}

                          />

                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="bgproup">Blood Group</label>

                          <select name="category" className="form-control filterin Quantity"
                            value={patientDetail.bloodgroup}
                            onChange={(event) => { handleInputChange("bloodgroup", event.target.value); }} >
                            <option disabled={true} value="">
                              --Choose an option--
                            </option>
                            <option value="A +ve">A +ve</option>
                            <option value="A -ve">A -ve</option>
                            <option value="B +ve">B +ve</option>
                            <option value="B -ve">B -ve</option>
                            <option value="AB +ve">AB +ve</option>
                            <option value="AB -ve">AB -ve</option>
                            <option value="O +ve">O +ve</option>
                            <option value="O-ve">O -ve</option>
                          </select>
                        </Col>
                      </Row>
                     
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="language">Language</label>
                          <FormInput
                            id="language"
                            value={patientDetail.language}
                            placeholder="Language"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("language", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="education">Education</label>
                          <FormInput
                            id="education"
                            value={patientDetail.education}
                            placeholder="Education"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("education", event.target.value);
                            }}
                          />
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="sociostatus">Socio Status</label>
                          <FormInput
                            id="sociostatus"
                            value={patientDetail.sociostatus}
                            placeholder="Socio Status"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("sociostatus", event.target.value);
                            }}
                          />
                        </Col>
                      </Row>

                      <FormGroup>
                        <label htmlFor="feInputAddress">Address</label>
                        <FormInput
                          id="feInputAddress"
                          placeholder="1234 Main St"
                          value={patientDetail.address}
                          onChange={(event) => {
                            handleInputChange("address", event.target.value);
                          }}
                        />
                      </FormGroup>
                      <Row form>
                        <Col md="4" className="form-group">
                          <label htmlFor="Adhar">Adhar<span className='text-danger'>*</span></label>
                          <FormInput
                            id="Adhar"
                            type="number"
                            min="0"
                            max="999999999999"
                            value={patientDetail.adhar}
                            placeholder="000011112222 "
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("adhar", event.target.value);

                            }}
                            invalid={formErrors.adhar === "" ? false : true}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.adhar}</span>
                          </div>
                        </Col>
                        <Col md="4" className="form-group">

                        <label htmlFor="EmergencyPhno">Emergency Phone Number </label>
                        <FormInput
                          id="EmergencyPhno"
                          type="number"
                          value={patientDetail.emrPhono}
                          placeholder="Emergency Phone Number"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("emrPhono", event.target.value);
                          }}
                          invalid={formErrors.emrPhono === "" ? false : true}
                        />
                        <div style={{ color: '#EC4147' }} >
                          <span>{formErrors.emrPhono}</span>
                        </div>
                      </Col>
                        {/* <Col md="4" className="form-group">
                          <label htmlFor="fileNo">File Number</label>
                          <FormInput
                            id="fileNo"
                            value={patientDetail.fileNo}
                            placeholder="File Number"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("fileNo", event.target.value);
                            }}
                          />
                        </Col> */}
                        <Col md="4" className="form-group">
                          <label htmlFor="srcRef">Source of Reference</label>
                          <FormInput
                            id="srcRef"
                            value={patientDetail.srcRef}
                            placeholder="Source of Refrence"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange("srcRef", event.target.value);
                            }}

                          />

                        </Col>
                       
                      </Row>
                      <Row form>
                      {/* <FormGroup> */}
                      <Col md="6" className="form-group">
                        <label htmlFor="email">Email Id</label>
                        <FormInput
                          id="email"
                          value={patientDetail.email}
                          placeholder="Email Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("email", event.target.value);
                          }}
                          invalid={formErrors.email === "" ? false : true}
                        />
                        <div style={{ color: '#EC4147' }} >
                          <span>{formErrors.email}</span>
                        </div>
                        </Col>

                        <Col md="6" className="form-group">
                          <label htmlFor="oc">Occupation</label>
                          <FormInput
                            id="oc"
                            value={patientDetail.occupation}
                            placeholder="Occupation"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange(
                                "occupation",
                                event.target.value
                              );
                            }}
                          />
                        </Col>


                      {/* </FormGroup> */}
                      </Row>
                      {/* <Row form>
                        
                        <Col md="6" className="form-group">
                          <label htmlFor="oc">Occupation</label>
                          <FormInput
                            id="oc"
                            value={patientDetail.occupation}
                            placeholder="Occupation"
                            autoComplete="off"
                            onChange={(event) => {
                              handleInputChange(
                                "occupation",
                                event.target.value
                              );
                            }}
                          />
                        </Col>
                      </Row> */}

                      <Row form>
                        <Col md="6" className="form-group">
                          <label htmlFor="feInputCity">City<span className='text-danger'>*</span></label>
                          <FormInput
                            id="feInputCity"
                            placeholder="city"
                            autoComplete="off"
                            value={patientDetail.city}
                            onChange={(event) => {
                              handleInputChange("city", event.target.value);
                            }}
                          />
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.city}</span>
                          </div>
                        </Col>
                        <Col md="4" className="form-group">
                          <label htmlFor="feInputState">State<span className='text-danger'>*</span></label>
                          <FormInput
                            id="feInputState"
                            placeholder="State"
                            autoComplete="off"
                            value={patientDetail.state}
                            onChange={(event) => {
                              handleInputChange("state", event.target.value);
                            }}
                          ></FormInput>
                          <div style={{ color: '#EC4147' }} >
                            <span>{formErrors.state}</span>
                          </div>
                        </Col>
                        <Col md="2" className="form-group">
                          <label htmlFor="feInputZip">Zip</label>
                          <FormInput
                            id="feInputZip"
                            placeholder="Zip"
                            type="number"
                            autoComplete="off"
                            value={patientDetail.pincode}
                            onChange={(event) => {
                              handleInputChange(
                                "pincode",
                                event.target.value
                              );
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col>
                          <FormCheckbox
                            checked={patientDetail.insurance === 'YES' ? true : false}
                            onChange={(event) => {
                              handleCheckbox(event);
                            }}
                          >
                            Do you have insurance?
                          </FormCheckbox>
                        </Col>
                      </Row>
                         {/* Patient Image Capturing */}
                         <Row form>
                        <Col md="12" className="form-group">
                          <p className="mb-2">Please select your webcam mode:</p>
                          <Col md="4" >
                            <FormRadio
                              value="selfie"
                              name="selfie"
                              checked={selectedOption === "selfie"}
                              onChange={onValueChange}
                            >
                              User/Selfie/forward facing camera{" "}
                            </FormRadio>
                          </Col>
                          <Col md="4">
                            <FormRadio
                              value="env"
                              name="env"
                              checked={selectedOption === "env"}
                              onChange={onValueChange}
                            >
                              Environment/Facing-Out camera
                            </FormRadio>
                          </Col>
                        </Col>
                      </Row>
{/* 
                      <div className="fbtn mt-3">
                        <Button
                          type="submit"
                          onClick={(event) => handleSubmit(event)}
                        >
                          Next
                        </Button>
                      </div> */}
                      <br />
{/* 
                      {ServerRes.success &&
                        <Alert theme="success" className="alert-div">{ServerRes.success}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
                      }
                      {ServerRes.error &&
                        <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
                      } */}
                    </Form>
                  </Col>
                
              </div>
           


            <Modal open={showWebCamModal}
              toggle={toggleModal}
            >
              <ModalHeader>
                <Row>
                  <Col md="10">Capture Patient Image</Col>
                  <Col md="2"><i className="material-icons cpointer" onClick={toggleModal} style={{ marginRight: `180px` }}>close</i></Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <Card > {/*style={{ maxWidth: "300px" }} */}
                  {showWebCam === false ? (
                    <CardBody>

                      {selectedOption === "selfie" ? (
                        <Webcam
                          audio={false}
                          imageSmoothing={true}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          height={`250px`}
                          width={`350px`}
                          videoConstraints={videoConstraintsSelfie}
                        />
                      ) : (
                        <Webcam
                          audio={false}
                          imageSmoothing={true}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          height={`100px`}
                          width={`100px`}
                          videoConstraints={videoConstraints}
                        />
                      )}
                      <Button onClick={capture}>Capture</Button>
                    </CardBody>
                  ) : (
                    <>
                      {imgSrc && (
                        <>
                          <CardImg src={imgSrc} />
                          <CardBody>
                            <Button onClick={onImageSubmit}>Save Image</Button>
                            <Button onClick={onFileCancel} style={{ float: `right` }}>Cancel</Button>
                          </CardBody>
                        </>
                      )}
                    </>
                  )}
                </Card>
              </ModalBody>
              <ModalFooter>
                <Button onClick={toggleModal}>Close</Button>
              </ModalFooter>
            </Modal>
            </ModalBody>
            <ModalFooter>
            <Button
                          type="submit"
                          onClick={(event) => handleSubmit(event)}
                        >
                          {callType && callType === 'Add' ? 'Next' : 'Submit'}
                        </Button>
            </ModalFooter>
          </div>
        </Modal>
      
       
    </main>

  );

}

/* function mapStateToProps(state) {
  return {
    patient:state.patientDetailsState.patient
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addPatient: (data, resCb) => dispatch(patientActions.addPatient(data, resCb)),
    updatePatient: (data, resCb) => dispatch(patientActions.updatePatient(data, resCb)),
  };
}

const PatientFormWithProps = connect(mapStateToProps, mapDispatchToProps)(PatientForm); */

export default PatientForm;
