import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageTitle from "../common/PageTitle";
import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';
import { patientActions } from '../../core/actions';
import {useDispatch, useSelector} from "react-redux";
import Components from '..';

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button, Card, CardBody,ModalBody, Modal, ModalHeader, ModalFooter
} from "shards-react";

  function FileUploadPreview (props) {
const[submit , setsubmit] = useState(false);
const[type, setType] = useState();

const [uploadDetails, setuploadDetails] = useState();
const [fileUploadData, setfileUploadData] = useState();
const [addshow, setAddShow] = useState(false);
const [patientId, setPatientId] = useState();
const dispatch = useDispatch();
let id;
useEffect(() => {
    setPatientId(props.fileUploadcontent.id)
    id = props.fileUploadcontent;
    console.log("patient id in const let : ", patientId, id);
},[])

const closeModal = () => {
    props.uploadPreview()
}
const removeSelectedFiles = (deselectImage) => {
    let imagePreview = [];
    for (let uploadimage of patientProcedureInfo){
        if(deselectImage != uploadimage.name) {
        imagePreview.push(uploadimage);
        } 
    }
    dispatch(patientActions.setUploadedFiles(imagePreview))
    console.log(" deselected images   :",imagePreview)
}
const patientProcedureInfo = useSelector(state => state.patientDetailsState.procedureuploadinfo)
console.log("patientProcedureInfo :", patientProcedureInfo)
const showattachicon = () => {
    showattachicon(!showattachicon);
}
const onFileSubmit = (file) => {
    console.log(" Event drop zone :",file) 
      let filesData = {};
      let otherparam = {};
      console.log(" Event drop zone file check :",file.acceptedFiles, file.acceptedFiles.length) 
      if(file.acceptedFiles &&  file.acceptedFiles.length > 0){
        setAddShow(true);
        filesData["fileData"] = file.acceptedFiles;
        console.log("console of filesdata :",filesData)
    
        if(fileUploadData == null){
   
            dispatch(patientActions.setUploadedFiles(file.acceptedFiles))
            setTimeout(() => {
            setfileUploadData(file.acceptedFiles);
        }, 100);
        }else{
            
            let files = [];
            for (let i = 0; i < fileUploadData.length; i++){
                files.push(fileUploadData[i]);
            }
            console.log("console of files :",files)
            for(let i = 0; i < file.acceptedFiles.length; i++){
                files.push(file.acceptedFiles[i]);
            }
            console.log("console of files 2 :",files)
            dispatch(patientActions.setUploadedFiles(files))
            console.log("patient action upload files : ", props.SET_UPLOAD)
            setTimeout(() => {
                    setfileUploadData(files);
                }, 100);
        }
   
}
}

const patientdetail = useSelector(state => state.patientDetailsState.patientInfo)
const UploadFiles = (uploadData) => {
 
    closeModal();
  
    let fileUploadData = {};
    fileUploadData["fileData"]=uploadData;
  fileUploadData['id'] = patientId;

    console.log(" upload fileuploadData data : ",fileUploadData)

    console.log(" upload prevew closed ")
    dispatch(patientActions.uploadFile(fileUploadData, resCb => {
        //  props.uploadFile(fileUploadData, resCb => {
            if(resCb && resCb.response && resCb.response.SUCCESS_CODE){
                //   setState({filePath:resCb.response.path})
                //   setState({flieUploadMsg:'File uploaded successfully'})
                closeModal();       
            }
            else{
                setTimeout(() => {
                  //  setState({flieUploadErrorMsg:'Uploaded rough sheets will no more be accepted.'})
                    console.log("Failed to upload file",resCb)
                }, 2000);
            }
            
        }))

}
const [onmodal, setOnModal] =useState(true);
const toggle =(event)=>{
   
   setOnModal(false)
  }

return (
    <>
         
       
        <Modal className='d-flex w-95 h-90 flex-column-reverse ' open={onmodal}   toggle={toggle} >
         <div>
             <ModalHeader>Upload 
                 <span className="p-2 justify-content-end"  onClick={(event) => toggle(event)}>X</span>
                 </ModalHeader>
                 <ModalBody>
             
                     <div>
                              <div className='justify-content-center'>
                                  <Components.Dropzone childToParent={(event) => onFileSubmit(event) }/>
                                </div>
                                {patientProcedureInfo && patientProcedureInfo.map((imagepreview,index)=>(
                                    <div className= 'card container imgprocedure'>
                                     <img src={imagepreview.name}></img>
                                        <button className="btn btn-submit " type="button" onClick={() => removeSelectedFiles(imagepreview.name)}>
								        Remove</button>
                                    </div >
                                        
                                  ))}
                              </div>
                              <ModalFooter>
         
                                <button className="btn btn-submit btn-primary btn-style bottomright" type="submit"
								onClick={(uploadData) => UploadFiles(patientProcedureInfo)}
                                >
								Save</button>
                               
                                </ModalFooter>
                 </ModalBody>
            </div>       
        </Modal>
         </>
        );


  }
  

export default FileUploadPreview;
