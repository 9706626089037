import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doctorActions, patientActions } from "../../../core/actions";
import { insuranceActions } from '../../../core/actions';


import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button, Card, CardBody, ModalBody, Modal, ModalHeader, ModalFooter,
  FormGroup,
  FormTextarea

} from "shards-react";



function Tpaadd(props) {
  const dispatch = useDispatch();
  const [addFlag, setTextFlag] = useState(false);
  const [submitted, setSubmitted] = useState([]);


  const [formErrors, setFormErrors] = useState(
    {
      email: '',
      phone1: '',
    },
  )

  const [tpaDetail, setTpaDetail] = useState(
    {
      id: "",
      companyName: "",
      address: "",
      email: "",
      phone1: "",
      phone2: "",
      description: ""



    }
  );
  const [ServerRes, setServerRes] = useState(
    { success: '', error: '', }
  );
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value);
    var pDetail = tpaDetail;
    console.log('pDetail["basicinfo"][name]', pDetail[name])
    pDetail[name] = value;
    formErrors[name] = "";
    setTpaDetail({ ...pDetail });

  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);

    // if(props.location.state&&props.location.state.rowData){
    //   console.log("edit case");
    //   //to change the label

    //   dispatch(
    //     insuranceActions.modifyTpa(tpaDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("modifyTpa", resCb);
    //         props.history.push("/tpamasterlist");

    //       }
    //     })
    //   );
    //   event.preventDefault();

    // }
    // else{
    //   console.log("save case");

    //   dispatch(
    //     insuranceActions.addTpaMaster(tpaDetail, (resCb) => {
    //       if (resCb) {
    //         console.log("tpasave", resCb);
    //         props.history.push("/tpamasterlist");

    //       }
    //     })
    //   );
    // }
    Object.keys(tpaDetail).forEach((key) => {
      console.log("key", key)
      console.log("key", tpaDetail[key])
      validateField(key, tpaDetail[key])
    })
    validateForm(event);
  }

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo = tpaDetail;

    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
    var currentDate = new Date().getDate;
    switch (fieldName) {

      case "phone1":
        if (fieldValidationErrors.phone1 = value.length == 0) {
          fieldValidationErrors.phone1 = value.length == 0 ? 'Please Enter Phone Number' : '';
          basicInfo.phone1 = value !== '' ? value : '';
          break;
        } else {
          fieldValidationErrors.phone1 = value.length == 10 ? '' : 'Please Enter Valid Phone Number';
          basicInfo.phone1 = value !== '' ? value : '';
          break;
        };
      case "email":
        if (!isEmail(value)) {
          fieldValidationErrors.email = value == isEmail ? '' : 'Please Enter Valid Email';
          basicInfo.email = value !== '' ? value : '';
          break;
        }

      default:
        break
    }
    setFormErrors({ ...fieldValidationErrors });
  }

  const validateForm = (event) => {
    event.preventDefault();

    // var Detail = doctorDetail;
    // var age = getAge(doctorDetail.dob)
    // setdoctorDetail(Detail);
    console.log("formErrors.::", formErrors);
    let formValid =

      formErrors.email === "" &&
      formErrors.phone1 === "";
    console.log("formValid", formValid);

    if (formValid) {
      if (props.location.state && props.location.state.rowData) {
        console.log("edit case");
        //to change the label

        dispatch(
          insuranceActions.modifyTpa(tpaDetail, (resCb) => {
            if (resCb) {
              console.log("modifyTpa", resCb);
              props.history.push("/tpamasterlist");

            }
          })
        );
        event.preventDefault();

      }
      else {
        console.log("save case");

        dispatch(
          insuranceActions.addTpaMaster(tpaDetail, (resCb) => {
            if (resCb) {
              console.log("tpasave", resCb);
              props.history.push("/tpamasterlist");

            }
          })
        );
      }

    }
    else {
      console.log("invalid");
    }
  }



  useEffect(() => {


    console.log("propsprops", props);

    if (props.location.state && props.location.state.rowData) {
      setTextFlag(!addFlag);
      let tpaData = props.location.state.rowData
      setTpaDetail(tpaData)
    } else {
      setTextFlag(addFlag);

      setTpaDetail(tpaDetail)
    }

  }, []);

  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  }
  const toggle = (event) => {
    // props.onModalClose();
    props.history.push("/tpamasterlist")
  }

  //  const getFormattedDate = (date) => {
  //       const reqdate = new Date(date);
  //       // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min; 
  //       let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");

  //   return formatteddate;
  //   }
  return (
    <>


      <div class="mainsection">
        <div ><h5> {addFlag ? "Edit" : "Add"}  Third Party Administrator </h5> </div>


        <main className="mainsection">
          <ListGroup flush className="mb-3 ">
            <div className="p-2 bg-none">
              <Row>
                <Col>
                  <Form>



                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="gender">Company Name</label>
                        <FormInput
                          id="companyname"
                          value={tpaDetail.companyName}
                          placeholder="Company Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "companyName",
                              event.target.value
                            );
                          }}
                        />

                      </Col>
                      <Col md="6" className="form-group">

                        <label htmlFor="email">Email Id</label>
                        <FormInput
                          id="email"
                          value={tpaDetail.email}
                          placeholder="Email Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("email", event.target.value);
                          }}
                          invalid={formErrors.email === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.email}</span>
                        </div>
                      </Col>

                    </Row>


                    <Row form>
                      <Col md="6" className="form-group">
                        <label htmlFor="feInputAddress">Phone Number 1</label>
                        <FormInput
                          id="phoneno"
                          type="number"
                          max="10"
                          maxlength="4"
                          placeholder="Phone Number 1"
                          value={tpaDetail.phone1}
                          onChange={(event) => {
                            handleInputChange("phone1", event.target.value);
                          }}
                          invalid={formErrors.phone1 === "" ? false : true}

                        />
                        <div style={{ color: "#EC4147" }}>
                          <span>{formErrors.phone1}</span>
                        </div>
                      </Col>



                      <Col md="6" className="form-group">
                        <label htmlFor="phoneno">Phone Number 2</label>
                        <FormInput
                          id="phoneno"
                          type="number"
                          value={tpaDetail.phone2}
                          placeholder="Phone Number 2"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("phone2", event.target.value);
                          }}
                        />
                      </Col>
                    </Row>



                    <Row form>

                      <Col md="6" className="form-group">
                        <label htmlFor="feInputDescription">Description</label>
                        <FormTextarea

                          id="feInputDescription"
                          placeholder="Description"
                          autoComplete="off"
                          type="textarea"

                          value={tpaDetail.description}
                          onChange={(event) => {
                            handleInputChange("description", event.target.value);
                          }}
                        />
                      </Col>
                    </Row>

                    {ServerRes.success &&
                      <Alert theme="success">{ServerRes.success}</Alert>
                    }
                    {ServerRes.error &&
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    }
                  </Form>
                  <div className="buttonsetconfirm">
                    <Button
                      className="btn-danger"
                      type="submit"
                      onClick={(event) => toggle(event)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      onClick={(event) => handleSubmit(event)}
                    >
                      Save
                    </Button>
                  </div>
                  <br />
                </Col>
              </Row>
            </div>
          </ListGroup>


        </main>


      </div>


    </>
  );
}
export default Tpaadd;
