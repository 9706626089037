import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { connect } from "react-redux";
import { bedActions } from "../../core/actions";
import { BED_CATEGORIES } from "../../shared/config";

import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button
} from "shards-react";

function BedAddForm(props) {
  const[basicinfo, setBasicinfo] = useState({})
  const[bedCategories, setBedCategories] = useState([])
  const[submitted, setSubmitted] = useState('')
  const[ServerRes, setServerRes] = useState({success : '', error : ''})
  const dispatch = useDispatch();

  useEffect(() => {
    setBedCategories(BED_CATEGORIES);

    for (let i = 0; i < BED_CATEGORIES.length; i++) {
      var basicInfo = basicinfo;

      basicInfo[BED_CATEGORIES[i].value + "count"] = "";
      basicInfo[BED_CATEGORIES[i].value + "rename"] = "";
      basicInfo[BED_CATEGORIES[i].value + "rate"] = "";
      basicInfo[BED_CATEGORIES[i].value + "effectiveFrom"] = "";

      setBasicinfo(basicInfo);
    }
    console.log("Final state obtained : ", basicinfo);
  },[])

  const handleInputChange = (name, value) => {
    console.log("In handleinput change",name, value);
    var pDetail = basicinfo;
    pDetail[name] = value;
    console.log("pDetail[name] change",pDetail[name], value);
    setBasicinfo({...pDetail});
  }

  const handleSubmit = (event) => {
    /*[{name:"Regular (Comes from constant)"count:"",rate:"",rename:"",
    changedBedRates:{rate:"",effectiveFrom:""}}] */
    var dataList = [];
    for (let i = 0; i < BED_CATEGORIES.length; i++) {
      var bedCatg = {};
      bedCatg.name = BED_CATEGORIES[i].value;
      bedCatg.count = basicinfo[BED_CATEGORIES[i].value + "count"];
      //bedCatg.rename = basicinfo[BED_CATEGORIES[i].value+'rename'];
      bedCatg.rate = basicinfo[BED_CATEGORIES[i].value + "rate"];
      bedCatg.effectiveFrom =
        basicinfo[BED_CATEGORIES[i].value + "effectiveFrom"];

      var cBedRates = {};
      cBedRates.rate = basicinfo[BED_CATEGORIES[i].value + "rate"];
      cBedRates.effectiveFrom = "";
      bedCatg.changedBedRates = cBedRates;

      dataList.push(bedCatg);
    }

    console.log("Data list formed finally : ", dataList);
    
    var ServerResVal = ServerRes;
    dispatch(bedActions.addNewBeds(dataList, resCb => {
      if (resCb) {
        console.log("Add Bed - resCb", resCb,resCb.state);
        if(resCb.state === 'success'){
          ServerResVal.success = resCb.message
        }
        else if(resCb.state === 'error'){
          ServerResVal.error = resCb.messages.message
        }
        setServerRes({...ServerResVal});
        
      }
      // this.closeModal();
    }));
    event.preventDefault();
    //  this.props.onAddModalClose({close : 'true'});
  }

  const closeModal = (event) => {
    props.onAddModalClose({ close: "true" });
  }

  const getFormId = (category, type) => {
    return category + type;
  }

    return (
      <div className="modalmn">
        <div className="mb-4 col-sm-12 col-md-7 modal-main">
          <div className="blog-comments card pcard">
            <ListGroup flush>
              <ListGroupItem className="p-3">
                <Row>
                  <Col><h5>Add New Beds</h5></Col>
                </Row>
                <Row>
                  <Col>
                    <Form>
                      <Row form>
                        <Col md="3" className="form-group">
                          <label htmlFor="bedtype" className="thead">Bed Type</label>
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="count" className="thead">Count</label>
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="rate" className="thead">Rate</label>
                        </Col>
                        <Col md="3" className="form-group">
                          <label htmlFor="effectiveFrom" className="thead">Rate Effective From</label>
                        </Col>
                      </Row>

                      {bedCategories.map((bedCatg, idx) => (
                        <Row form key={idx}>
                          <Col md="3" className="form-group">
                            <label htmlFor="bedCatg.value+count">
                              {bedCatg.value}
                            </label>
                          </Col>
                          <Col md="3" className="form-group">
                            <FormInput
                              id={getFormId(bedCatg.value, "count")}
                              type="number"
                              value={
                                basicinfo[bedCatg.value + "count"]
                              }
                              autoComplete="off"
                              onChange={(event) => {
                                handleInputChange(
                                  bedCatg.value + "count",
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                          <Col md="3" className="form-group">
                            <FormInput
                              id={getFormId(bedCatg.value, "rate")}
                              value={
                                basicinfo[bedCatg.value + "rate"]
                              }
                              autoComplete="off"
                              onChange={(event) => {
                                handleInputChange(
                                  bedCatg.value + "rate",
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                          <Col md="3" className="form-group">
                            <FormInput
                              id={getFormId(
                                bedCatg.value,
                                "effectiveFrom"
                              )}
                              type="date"
                              value={
                                basicinfo[
                                  bedCatg.value + "effectiveFrom"
                                ]
                              }
                              autoComplete="off"
                              onChange={(event) => {
                                handleInputChange(
                                  bedCatg.value + "effectiveFrom",
                                  event.target.value
                                );
                              }}
                            />
                          </Col>
                        </Row>
                      ))}  
                      
                      <div className="btns-wrap">
                          <Button
                            type="submit"
                            onClick={(event) => closeModal(event)}
                          >
                            Cancel
                        </Button>
                        <Button
                          type="submit"
                          onClick={(event) => handleSubmit(event)}
                        >
                          Save
                        </Button>
                      </div>  
                      <br/>
                      {ServerRes.success &&
                        <Alert theme="success">{ServerRes.success}</Alert>
                      }
                      {ServerRes.error &&
                        <Alert theme="danger">{ServerRes.error}</Alert>
                      }    
                    </Form>
                  </Col>
                </Row>
              </ListGroupItem>
            </ListGroup>
          </div>
        </div>
      </div>
    );
}

/* function mapStateToProps(state) {
  return {
    bedDetail: state.bedDetailsState.bedDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    addNewBeds: (dataList, resCb) => dispatch(bedActions.addNewBeds(dataList, resCb)),
  };
}

const BedAddFormWithProps = connect(mapStateToProps, mapDispatchToProps)(BedAddForm); */

export default BedAddForm;
