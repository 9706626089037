import BedList from './BedList';
import BedForm from './BedForm';
import BedAddForm from './BedAddForm';
import BedSearch from './BedSearch';



const bcomponents = {
    BedList,
    BedForm,
    BedAddForm,
    BedSearch
}

export default bcomponents;