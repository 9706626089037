import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import {
    Form, FormTextarea, Button, Alert, Card, CardBody, Modal,
    ModalHeader,
    ModalBody,
} from "shards-react";
import { doctorActions, patientActions } from "../../core/actions";
import { format } from "date-fns";
import { helperServices } from "../../shared/helper";
import Components from "./index";


function Complaints(props) {
    const [complaintText, setComplaintText] = useState([]);
    const [newcomplaintText, setNewComplaintText] = useState();
    const [newComplaint, setNewComplaint] = useState();
    //let complaintText = [];
    const [ServerRes, setServerRes] = useState(
        { success: '', error: '', }
    );
    const dispatch = useDispatch();
    const patientProceduresList = useSelector(state => state.doctorDetailsState.patientprocedurelist);
    console.log("patientProceduresList complaints :", patientProceduresList);
    const [reload, setReload] = useState(false);
    let patientDetail;
    let procedureList;
    var complaintList = '';
    const [patientids, setPatientIDs] = useState(
        { id: '', visitId: '' })
    const [viewComplaints, setviewComplaints] = useState(false);
    const [openComplaintModal, setOpenComplaintModal] = useState();

    const patient = useSelector((state) => state.patientDetailsState.patient);
    // const [pa]
    //   const dispatch = useDispatch();

    useEffect(() => {
        console.log("props in complaints :", props);
        console.log("patientProceduresList in useeffect :", patientProceduresList);
        if (patientProceduresList && patientProceduresList.procedureslist) {
            procedureList = patientProceduresList.procedureslist
            console.log("copy procedurelist :", procedureList);
        }
        // console.log("procedures complaint props 11:", patientProceduresList)
        listComplaints(patientProceduresList.procedureslist);
        if (helperServices.CheckAccessLevel("InsurancePatients")) {
            setviewComplaints(true)
        }

        if (props.patientInfo && props.patientInfo.status && props.patientInfo.status === "close") {

            setviewComplaints(true)
        }
        if (props.viewComplaint) {
            setOpenComplaintModal(props.viewComplaint);
            setviewComplaints(true);
        }
        if (props.newComplaint) {
            setOpenComplaintModal(props.newComplaint);
            setviewComplaints(false);
        }


    }, [patientProceduresList])

    const listComplaints = (procedureList) => {
        //procedureList = patientProceduresList.procedureslist

        if (procedureList) {
            let list = [];
            for (let proc of procedureList) {
                let complaintList = {};
                console.log("procedures complaint 45:", proc);
                if (proc.claSS == "complaint") {
                    console.log("procedures complaint 55:", proc.complaint);
                    if (patientProceduresList.visit.id && patientProceduresList.visit.id == proc.visitId && proc.complaint) {
                        console.log("procedures 1: ", patientProceduresList.visit.id, "procedures complaint 1: ", proc.visitId, "visit id in patient and procedure :", proc.class, " complaint in loop proc : ", proc.complaint);
                        // props.patientInfo.
                        complaintList["id"] = proc.id;
                        complaintList["text"] = proc.complaint;
                        complaintList["edit"] = false;
                        complaintList["cdt"] = proc.cdt;
                        list.push(complaintList);

                    }
                }
            }
            setComplaintText([...list])
            if (list.length > 0) {
                setviewComplaints(true);

            } else {
                setviewComplaints(false);

            }

            setReload(!reload);
            //complaintText = complaintList;
            console.log("complaintlist : ", list);

        }

    }

    const handleChange = (e) => {
        let text = e.target.value;
        setNewComplaintText(text);
        //complaintText = e.target.value;
    }

    const handleSubmit = (event) => {
        console.log("edited complain text:", complaintText.filter((item) => item.edit === true));
        console.log("new compliant text:", newcomplaintText);
        event.preventDefault()
        var ServerResVal = ServerRes;
        var complianText = "";
        console.log("event", event)
        let visitid = '';
        // let newComplaint = {};
        // newComplaint["text"] = newcomplaintText  ? newcomplaintText : complaintText.filter((item)=>item.edit === true)[0].text;
        let newComplaint = {};
        if (newcomplaintText) {
            newComplaint["text"] = newcomplaintText;
        } else {
            const filteredComplaint = complaintText.filter((item) => item.edit === true);
            if (filteredComplaint.length > 0) {
                newComplaint["text"] = filteredComplaint[0].text;
            } else {
                // Handle the case when no matching item is found.
                console.error("No matching item found for edit === true");
            }
        }


        if (patientProceduresList && patientProceduresList.visit && patientProceduresList.visit.id) {
            visitid = patientProceduresList.visit.id
        } else {
            visitid = patient.visit.id;
        }
        console.log("visitid : ", visitid);
        let tempArray = {
            claSS: "complaint",
            // complaint: newcomplaintText ? newcomplaintText : complaintText.filter((item) => item.edit === true)[0].text,
            complaint: newcomplaintText ? newcomplaintText : (complaintText.find((item) => item.edit === true) || { text: "" }).text,
            patientId: patientProceduresList ? patientProceduresList.id : "",
            visitId: visitid,
            // id: newcomplaintText ? "" : complaintText.filter((item) => item.edit === true)[0].id,
            id: newcomplaintText ? "" : (complaintText.find((item) => item.edit === true)|| { id: "" }).id,

        }
        console.log("patientids.visitId b4 temp array input: ", patientids.visitId);

        console.log("complaint on submit : ", tempArray);

        dispatch(patientActions.addComplaint(tempArray, resCb => {
            if (resCb) {
                console.log("ResCb-File", resCb.state, resCb.message);
                if (resCb.successMessages) {
                    ServerResVal.success = "Complaint Added Successfully";
                    setServerRes({ ...ServerResVal });
                    patientProceduresList.procedureslist.push(resCb.procedureList[0]);
                    //listComplaints(patientProceduresList.procedureslist);
                    getProcedureList(patientProceduresList.id, visitid);
                    props.onCloseComplaintModal(ServerResVal);
                } else {
                    ServerResVal.error = "Failed to add complaint";
                    setServerRes({ ...ServerResVal });
                    props.onCloseComplaintModal(ServerResVal);
                }
                console.log('ServerResVal', ServerResVal)
                console.log('ServerRes', ServerRes)
            }
        }))


    }

    const getProcedureList = (patId, vId) => {
        let type = "";
        console.log("-------PROCEDURE LIST API----------");
        dispatch(
            patientActions.getPatientProcedureInfo(patId, vId, type, (resCb) => {
                if (resCb) {
                    console.log("procedure list in api in complaints: ", resCb);
                    dispatch(doctorActions.setPatientProcedureList(resCb.patientresp));
                }
            })
        );
    }
    const toggleComplaints = () => {
        setviewComplaints(!viewComplaints);
        setNewComplaintText("");
        console.log("viewComplaints on toggle", viewComplaints);
        // if(viewComplaints) {
        //     setComplaintText("");
        //     setReload(!reload);
        // } else {
        //     listComplaints(patientProceduresList);
        // }
    };

    const toggleEdit = (list) => {
        let editableList = complaintText;
        console.log("list to edit:", list);
        editableList.forEach(element => {
            if (element.id == list.id) {
                element.edit = !list.edit;

            } else {
                element.edit = false;
            }

        });

        console.log("editableList:", editableList);
        setComplaintText([...editableList])
        // setReload(!reload);

    }

    const updateComplaint = (list, event) => {
        console.log("event:", event);
        let editableList = complaintText;
        console.log("list to edit:", list);
        editableList.forEach(element => {
            if (element.id == list.id) {
                element.text = event.target.value;
            } else {
                element.text = element.text;
            }
        });
        //list.text = event.target.value;
        console.log("editableList:", editableList);
        setComplaintText([...editableList])
        //setReload(!reload);

    }

    const getFormattedDate = (date) => {
        const reqdate = new Date(date);
        let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
        return formatteddate;
    }

    const closeAlert = (e) => {
        setServerRes({ ..."" });
    }

    const closeModal = (e) => {
        console.log("--onCloseComplaintModal--", e);
        props.onCloseComplaintModal(e);
    }

    const addNewComplaint = (event) => {
        console.log("new compliant:", event.target.value);
        setNewComplaint(event.target.value);
    }

    const handleVisitSelect = (e) => {
        props.handlevisitChange(e);
    }

    return (
        <main className="" key={reload}>
            {ServerRes.success && (
                <Alert theme="success" className="alert-div"  >{ServerRes.success}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
            )}
            {ServerRes.error && (
                <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
            )}
            {/* <h5 className="">Complaints
            {props.patientInfo && props.patientInfo.status && props.patientInfo.status === "open" ?
            <>
            {(!helperServices.CheckAccessLevel("InsurancePatients")) &&
            <span className="ml-3"  onClick={(event) => toggleComplaints(event)} style={{cursor:'pointer'}} >
                <i className="material-icons" title={viewComplaints ? 'Edit' : 'View'}>{viewComplaints ? 'edit' : 'visibility'}</i>
                </span>
            }
            </>
            :''}
            </h5> */}
            <Modal
                className="d-flex flex-column-reverse "
                id="closemodalopen"
                open={openComplaintModal}
                size="lg"

            >
                <div>
                    <ModalHeader>
                        Complaint Details
                        <span
                            className="close-model"
                            onClick={(e) => closeModal(e)}
                        >
                            X
                        </span>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <Components.VisitList
                                allVisitdates={props.allVisitdates}
                                selectedDate={props.selectedDate}
                                handleVisitSelect={(e) => handleVisitSelect(e)}

                            />
                        </div>


                        {(viewComplaints && complaintText) &&
                            <div>
                                <div className="">
                                    <div className="p-0">
                                        <div>
                                            {(complaintText && complaintText.length > 0) ?
                                                <>
                                                    {complaintText.map((list, idx) => (
                                                        <>
                                                            <div key={idx} className="my-2 d-flex justify-content-between" style={{ fontWeight: '400' }}>
                                                                <span>{list.text} </span>
                                                                <i className='text-muted' style={{ fontSize: '11px' }}>Added On : {getFormattedDate(list.cdt)} </i>
                                                                {/* <span> <i className="material-icons"   onClick={(event) => toggleEdit(list)}  style={{cursor:'pointer'}}>edit</i></span>  */}
                                                            </div>
                                                        </>

                                                    ))}
                                                </>
                                                :
                                                <div>No complaints yet</div>
                                            }

                                        </div>

                                    </div>
                                </div>
                            </div>
                        }
                        {(!viewComplaints) &&
                            <Form className="mb-2 p-1">

                                <div>
                                    {(complaintText && complaintText.length > 0) &&
                                        <>
                                            {complaintText.map((list, idx) => (
                                                <>
                                                    <div key={idx} className="my-2 d-flex align-items-baseline"  >
                                                        <span className='mr-2'>{list.text}</span>
                                                        <i class="material-icons" onClick={(event) => toggleEdit(list)}>edit</i>
                                                        {/* <small className='text-muted' onClick={(event) => toggleEdit(list)} style={{cursor:'pointer',fontWeight:'400'}}>Edit</small>  */}
                                                    </div>
                                                    {list.edit === true &&
                                                        <FormTextarea placeholder="Add new complaint" onChange={(event) => updateComplaint(list, event)} value={list.text} />
                                                    }
                                                </>

                                            ))}
                                        </>
                                    }
                                    <div className="mb-2 mt-3">
                                        <FormTextarea placeholder="Add new complaint..." onChange={handleChange} value={newcomplaintText} />
                                    </div>

                                </div>

                                <div className="fbtn mb-0 mt-4">
                                    <Button type="submit" onClick={(event) => handleSubmit(event)}>Save</Button>
                                </div>

                            </Form>
                        }
                    </ModalBody>

                </div>
            </Modal>

        </main>
    )
}

/* function mapStateToProps(state) {
    return {
      patient: state.patientDetailsState.patient,
    };
}
  
function mapDispatchToProps(dispatch) {
    return {
        addComplaint: (data, resCb) => dispatch(patientActions.addComplaint(data, resCb)),
    };
}
  
const ComplaintsWithProps = connect(
    mapStateToProps,
    mapDispatchToProps
)(Complaints); */

export default Complaints;

