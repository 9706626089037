import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { patientActions } from "../../core/actions";
import ReactDOM from "react-dom";
import { DatePicker } from "shards-react";
import { FormGroup } from "@mui/material";
import { Form, FormInput, Button } from "shards-react";
// import { toHaveFormValues } from "@testing-library/jest-dom/dist/matchers";
// import { Row, Col, Form, FormInput, Button } from "shards-react";

//import React from 'react';

//import 'bootstrap/dist/css/bootstrap.min.css';




function AddPatientmodel(props) {
  const [addPatient, addPatinentaccount] = useState({
    fname: "",
    lname: "",
    phno: "",
   // reqTime: "",
    apptdate:"",
    appthours:"",
    apptminutes:"",
   
  });

const handleSelectChange=(event)=>{
  var value={};
  console.log("In handleselect change", event );
}


  const dispatch = useDispatch();

  const handleInputChange = (name, value) => {
    console.log("In handleinput change", name, value, addPatient);
    var patient = addPatient;
    patient[name] = value;
    console.log("patient[name] change", patient[name], value);
    addPatinentaccount({ ...patient });
  };
  const closeModal = () => {
    props.onModalClose();
  };

  const handleSubmit = (event) => {
    console.log("In handle submit of login page");
    // let hidbtnid=document.getElementById("loginbutton")
    dispatch(
      patientActions.addPatient(addPatient, (resCb) => {
        if (resCb) {
          //     setStatus({ type: 'success' });
          //     // setStatus({ type: 'error', error });
          //     props.onModalClose()
          console.log("response from addPatient api", resCb);
          props.onModalClose();
        }
      })
    );
    // dispatch(patientActions.addAppointment(appointmentDetail,(resCb) => {

    //   if(resCb){
    //     setStatus({ type: 'success' });
    //     // setStatus({ type: 'error', error });
    //     props.onModalClose()

    // }

    //  setSubmitted(true);
    //  Object.keys(user).forEach((key) => {
    //    validateField(key, user[key]);
    //  });

    //  validateForm(event);
    //   }
  };

  return (
    <>
      {/* <div className='mix-blend-mode:overlay'></div>
        <div className='modal-overlay'></div>
        <div className='modal-dialog modal-lg'></div>
          */}
      <div className="col d-flex ag-style">
        {/* <div className="nav-wrapper modalmn mainsection preview backgrnd-filter" > */}
        <div className="nav-wrapper mainsection">
          <div className="col-sm-12 col-md-6 col-lg-6 pres-modal-main modal-main">
            {/* <div className='d-flex align-items-center form-group col-sm-12'> */}

            <div className="d-flex justify-content-center flex-column">
              <div className="inner-wrapper">
                {/* <div className="slide-in visible"> */}
                {/* <div className="Row verticalCenter"> */}

                  <div className="x_content ">
                    <div className="my-3 border-bottom">
                      <span className="d-flex justify-content-between h6">
                        Add patient
                        <span
                          className="topright ml-auto d-flex justify-content-end"
                          onClick={(event) => closeModal(event)}
                        >
                          X
                        </span>{" "}
                      </span>
                      {/* <span className="p-2 d-flex justify-content-end"  onClick={(event) => closeModal(event)}>X</span> */}
                    </div>

                    {/* // <div className=' d-flex justify-content-center '> */}

                    <Form>
                      <FormGroup>
                        <label htmlFor="#fname">First Name</label>
                        <FormInput
                          type="text"
                          id="#fname"
                          className="mb-2"
                          placeholder="First Name"
                          value={addPatient.fname}
                          required
                          onChange={(event) => {
                            handleInputChange("fname", event.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <label htmlFor="#lname">Last Name</label>
                        <FormInput
                          type="text"
                          id="#lname"
                          className="mb-2"
                          placeholder="Last Name"
                          value={addPatient.lname}
                          required
                          onChange={(event) => {
                            handleInputChange("lname", event.target.value);
                          }}
                        />
                      </FormGroup>{" "}
                      <FormGroup>
                        <label htmlFor="#phno">Phone Number</label>
                        <FormInput
                          type="number"
                          id="#phno"
                          className="mb-2"
                          placeholder="Phone Number"
                          pattern="[0-9]+"
                          value={addPatient.phno}
                          required
                          onChange={(event) => {
                            handleInputChange("phno", event.target.value);
                          }}
                        />
                     </FormGroup>{" "}
                     {/*   <FormGroup>
                        <label htmlFor="#date">Appointment Request Time</label>
                        <FormInput
                          type="datetime-local"
                          id="#date"
                          className="mb-2"
                          placeholder="dd-MM-yyyy  HH:mm"
                          value={addPatient.date}
                          required
                          step={30}
                          onChange={(event) => {
                            handleInputChange("reqTime", event.target.value);
                          }}
                        />
                        </FormGroup> */}
                      <FormGroup>
                        <label htmlFor="#date">Appointment Raquest Date</label>
                        <FormInput
                          type="date"
                          id="#date"
                          className="mb-2"
                          placeholder="dd-MM-yyyy"
                          value={addPatient.date}
                          required
                          step={30}
                          onChange={(event)=>{
                            handleInputChange("apptdate",event.target.value);
                          }}
                          />
                      </FormGroup>

                      
{/* <DropDownList
                       label="Appointment Raquest Hours"
                        name="apphours"
                        data={[key:"8",value:"8"]}
                       required={true}
                        /> */}





                      <FormGroup>
                       <label htmlFor="#date">Appointment Raquest Hours</label>
                      
                        <FormInput
                         
                          type="text"
                          id="apphours"
                          className="mb-2"
                     
                          placeholder="hours"
                        
                          value={addPatient.date}
                          
                         /* select={toHaveFormValues.toString()} options = 
                          {[
                            { label: "8", value: "8"},
                            { label: "9", value: "9" },
                            { label: "10", value: "10"},
                            { label: "11", value: "11"},
                            { label: "12", value: "12"},
                            { label: "13", value: "13"},
                          ]}*/
                          required
                          step={30}
                         onChange={(event)=>{
                            handleSelectChange("appthours",event.target.value);
                          }
                        }
                          
                          />
                      </FormGroup>


                      <FormGroup>
                        <label htmlFor="#date">Appointment Raquest Minites</label>
                        <FormInput
                          type="text"
                          id="#date"
                          className="mb-2"
                          placeholder="minutes"
                          value={addPatient.date}
                          required
                          step={30}
                          onChange={(event)=>{
                            handleInputChange("apptminutes",event.target.value);
                          }}
                          />
                      </FormGroup>


                    </Form>
                    <div className="my-3 d-flex justify-content-end">
                      <Button
                        id="loginbutton"
                        onClick={(event) => handleSubmit(event)}
                      >
                        Submit
                      </Button>
                    </div>
                    {/* // </div> */}
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*  </div>
              </div>
          
          </div> */}
      {/* </div> */}
      {/* // </div> */}
    </>

    //  {/* <form>
    //         <label>
    //           First Name:
    //           <input type="text" name="name" placeholder=' First Name' />
    //         </label><br></br>
    //         <label>
    //          Last Name:
    //           <input type="text" name="name" placeholder=' Last Name' />
    //         </label><br></br>
    //         <label>
    //           Phone
    //           <input type="tel" name="phone number" placeholder=' Phone Number'/>
    //         </label><br></br>
    //         <label >Select a time:
    //         <input type="datetime-local"  name="appt"></input>
    //         </label>
    //         <input type="submit" value="Submit" />
    //       </form>   */}
  );

  {
    /* } */
  }
}

export default AddPatientmodel;
