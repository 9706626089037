import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridColumn, AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardDeck,
  FormRadio,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter,
  Alert,
  FormInput,
} from "shards-react";
import BtnCellRenderer from "../common/BtnCellRenderer";
import { patientActions, doctorActions } from "../../core/actions";
import Components from "..";
import { SET_PAGE_HEADER } from "../../shared/constants";
import { format } from "date-fns";
import CustomTooltip from "../common/CustomTooltip";
import { useHistory } from "react-router-dom";

function PatientRevisit() {
  let ids;
  const history = useHistory();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const [patientRevisit, setpatientRevisit] = useState();
  const [showPatientRevisitModal, setshowPatientRevisitModal] = useState(false);
  const [showPatientVisitModal, setshowPatientVisitModal] = useState(false);
  const [onBoardmodal, setOnBoardModal] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("OutPatient");
  const [selectPatientData, setselectPatientData] = useState();
  const [selectedPatiemtSummary, setSummary] = useState();
  const dispatch = useDispatch();
  const [doctorList, setdoctorList] = useState({
    doctors: "",
  });
  const [ServerRes, setServerRes] = useState({ success: "", error: "" });
  const [patientForm,setPatientForm] = useState(false);
  const todayDate = format(new Date(), 'yyyy-MM-dd');

  // Get the current time and format it as "HH:mm" for the time input field
  const currentTime = format(new Date(), 'HH:mm');

  // Set the default values
  const [selecteddate, setSelecteddate] = useState(todayDate);
  const [selectedTime, setSelectedTime] = useState(currentTime);
  const [rowData] = useState([
    {
      pName: "Pat1",
      status: "In",
      rTime: "2:00",
      aTime: "",
      counselor: "Doc1",
    },
    {
      pName: "manu",
      status: "Out",
      rTime: "2:00",
      aTime: "",
      counselor: "Doc2",
    },
    {
      pName: "upadhya",
      status: "Out",
      rTime: "3:30",
      aTime: "",
      counselor: "Doc3",
    },
  ]);

  const [columnDefs] = useState([
    // {
    //   headerName: "File Number",
    //   field: "fileNo",
    //   sortable: true,
    //   filter: true,
    //   flex: 1,
    //   resizable: true,
    //   floatingFilter: true,
    // },
    {
      headerName: "MAIN",
      field: "maIn",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Name",
      field: "name",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Phone Number",
      field: "phno",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Scheduled Time",
      field: "scheduleddate",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    // { headerName:"Appointment Time",field: "starttime", sortable: true, filter: true, flex: 1, resizable: true, floatingFilter: true },
    {
      headerName: "Comment",
      field: "comment",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      tooltipField: "comment",
    },
    {
      headerName: "Actions",
      field: "Actions",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      width: 150,
      cellRenderer: "BtnCellRenderer",
    },
  ]);

  const patientRevisitClick = () => {
    // setshowPatientVisitModal(true);
    setOnBoardModal(true);
  };

  const patientRescheduleClick = () => {
    setshowPatientRevisitModal(true);
  };
  const closeModal = (event) => {
    setshowPatientRevisitModal(false);
    setshowPatientVisitModal(false);
    console.log("event is:", event);
    if (event) {
      var ServerResVal = event;
      if (event.successMessages) {
        ServerResVal.success = event.successMessages[0];
      } else {
        ServerResVal.error = event.errorMessages[0];
      }
      setServerRes({ ...ServerResVal });
      setTimeout(() => {
        closeAlert();
      }, 3000);
    }
    getListRevisits();
  };

  const handleRendererCall = (props) => {
    console.log("props", props.revisitsList);
    setselectPatientData(props.revisitsList);
    if (props.revisitsList.typeFlag == "bookvisit") {
      patientRevisitClick(props.revisitsList);
    } else {
      patientRescheduleClick(props.revisitsList);
    }
  };

  var d1 = new Date();
  var d2 = new Date();
  const date1 = format(d1.setDate(d1.getDate() - 3), "yyyy/MM/dd");
  const date2 = format(d2.setDate(d2.getDate() + 7), "yyyy/MM/dd");
  console.log("date1", date1);
  console.log("date2", date2);

  const getFormattedDate = (date) => {
    const reqdate = new Date(date);
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    return formatteddate;
  };

  const getFormattedScheduledDate = (date) => {
    const scheduleddate = new Date(date);
    let formatedscheduleddate = format(scheduleddate, "MMM dd yyyy");
    return formatedscheduleddate;
  };

  const toggle = (event) => {
    setOnBoardModal(false);
  };

  const onValueChange = (value) => {
    setSelectedRadio(value);
    console.log("selectedOptionRadio", value);
  };

  const onboardPatient = () => {
    var ServerResVal = ServerRes;
    console.log("log in onboard method:", selectedRadio);
    //visitList['type'] = data;
    let type = selectedRadio;

    let admissionDateTime="";
    if(type=="InPatient"){
      const dateTimeString = `${selecteddate} ${selectedTime}:00`;
      admissionDateTime = dateTimeString;
      console.log("timestamp",admissionDateTime);
    }

    console.log("type in log:", type);
    let visitListDetails = { ...selectPatientData };
    console.log("visitlist details in onboard", visitListDetails.registeredOn);
    if (visitListDetails.registeredOn) {
      let originalDate = new Date(visitListDetails.registeredOn);
      let sixMonthsLater = new Date(
        originalDate.getFullYear(),
        originalDate.getMonth(),
        originalDate.getDate() - 180,
        originalDate.getHours(),
        originalDate.getMinutes(),
        originalDate.getSeconds(),
        originalDate.getMilliseconds()
      );
      console.log("formatted date is", sixMonthsLater);
    }
    // console.log("log in onboard method:", value);
    dispatch(
      patientActions.onboardPatient(visitListDetails, type,admissionDateTime, (resCb) => {
        if (resCb) {
          console.log("onboardPatient response", resCb);
          if (resCb.successMessages) {
            let visitId = resCb.patientresp.visit.id;
            let patientId = resCb.patientresp.id;
            let name = resCb.patientresp.fname;
            let erpCustId = resCb.patientresp.erpCustId;
            let visitStatus = resCb.patientresp.visit.status;
            ServerResVal.success = "Patient onboarded successfully";
            setServerRes({ ...ServerResVal });
            setTimeout(() => {
              closeAlert();
            }, 3000);
            setTimeout(() => {
              if (visitStatus && visitStatus == "open") {
                history.push({
                  pathname: `/PatientSections/${patientId}/${visitId}`,
                });
              } else {
                history.push({
                  pathname: `/Payment/${name}/${patientId}/${visitId}/${erpCustId}`,
                });
              }
            }, 2000);
          } else if (resCb.state === "error") {
            ServerResVal.error = "Patient onboard failed";
            setServerRes({ ...ServerResVal });
          }

          setOnBoardModal(false);
        }
      })
    );
  };

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  };

  // const getFormattedApptDate =(date)=>{
  //     const apptdate =new Date(date);
  //     let formatedapptdate= format(apptdate, "MMM dd yyyy");
  //   return formatedapptdate;
  // }

  const revisitsList = useSelector(
    (state) => state.patientDetailsState.revisitsList
  );
  console.log("visitsList", revisitsList);

  useEffect(() => {
    // let data ={}
    // let date1 ={}
    // let date2 ={}
    sessionStorage.removeItem("searchvalue");
    getListRevisits();
  }, []);

  const handleInputChange = (event)  => {
    console.log("You can search..!");
    console.log("Event is",event.target.value);
    if(event.target.value.length > 2){
      getSearchedFollowUps(event.target.value);
    } 
    else{
      getListRevisits();
    }
    
  }

  const getListRevisits = () => {
    let searchFollowUps = '';
    dispatch(
      patientActions.ListRevisits(date1, date2,searchFollowUps, (resCb) => {
        if (resCb) {
          console.log("All Bed Details - Response", resCb);
          dispatch(patientActions.setPageHeader("Followup"));
          console.log("visitsList1", resCb);
          console.log("Object", Object.values(resCb));
          let patientRevisit = resCb.patient;
          console.log("patientVisit:", patientRevisit);
          for (let item of patientRevisit) {
            console.log("revisit object is", item);
            item["fileNo"] = item.fileNo;
            item["maIn"] = item.id;
            item["name"] = item.fname + " " + item.mname + " " + item.lname;
            item["phno"] = item.phno;
            item["status"] = item.revisit.status;
            // item['scheduledTime'] = getFormattedDate(item.revisit.scheduledTime);
            item["scheduleddate"] = item.revisit.scheduleddate;
            item["comment"] = item.revisit.comment;
          }

          console.log("Object", patientRevisit);
          setpatientRevisit(patientRevisit);
        }
      })
    );
  };

  const getSearchedFollowUps = (searchFollowUps) =>{
    //  let searchFollowUps = 'abh';
      dispatch(patientActions.ListRevisits(date1,date2,searchFollowUps,(resCb) => {
        if(resCb){
            console.log("All Bed Details - Response", resCb);
            dispatch(patientActions.setPageHeader("Followup"));
            console.log('visitsList1',resCb);
            console.log('Object', Object.values(resCb));
            let patientRevisit = resCb.patient;
            console.log('patientVisit:', patientRevisit);
            for(let item of patientRevisit){
                console.log('item',item);
                item['fileNo'] = item.fileNo;
                item["maIn"] = item.id;
                item['name'] = item.fname +" "+ item.mname +" "+ item.lname;
                item['phno'] = item.phno;
                // item['status']=item.status;
               // item['scheduledTime'] = getFormattedDate(item.revisit.scheduledTime);
                item['scheduleddate'] = item.revisit.scheduleddate;
                item['comment'] = item.comment;
              
            }
           
            console.log('Object', patientRevisit)
            setpatientRevisit(patientRevisit);
        }
    }));

    }

    const openPatientForm = (event) => {
      setPatientForm(true);
    }

    const closePatientFormModal = () => {
      setPatientForm(false);
     }

     const updatePatientDetails = (event, payment) => {
      var ServerResVal = ServerRes;
      if(event.successMessages){
        ServerResVal.success = "Patient saved successfully";    
      }else{
        ServerResVal.error = "Failed to save Patient";
      }
        setServerRes({ ...ServerResVal });
        setTimeout(() => {
          closeAlert();
        }, 3000);
     }

  const context = { componentParent: (props) => handleRendererCall(props) };
  console.log("patientRevisit", revisitsList);
  console.log("revisitsList1", revisitsList.type);
  const rowHeight = 100;
  
  const handleOnChange=(value,event)=>{
    if(value==="date"){
      setSelecteddate(event.target.value);
      console.log("Seleected Date is",event.target.value,event)
      console.log("Seleected Date is",typeof selecteddate)
    }
    if(value==="time"){
      setSelectedTime(event.target.value);
      console.log("Seleected Date is",event.target.value,event)
      console.log("Seleected Date is",typeof selectedTime)
    }
  
  }

  return (
    <>
      <div className="m-2">
        {ServerRes.success && (
          <Alert theme="success" className="alert-div">
            {ServerRes.success}{" "}
            <span>
              <i
                className="material-icons"
                onClick={(event) => closeAlert(event)}
                style={{ cursor: "pointer" }}
              >
                close
              </i>
            </span>
          </Alert>
        )}
        {ServerRes.error && (
          <Alert theme="danger" className="alert-div">
            {ServerRes.error}{" "}
            <span>
              <i
                className="material-icons"
                onClick={(event) => closeAlert(event)}
                style={{ cursor: "pointer" }}
              >
                close
              </i>
            </span>
          </Alert>
        )}
      </div>
      <Row noGutters className="page-header mb-3 mx-2">
          <Col className='col-md-12 mt-2'>
          <form className="main-navbar__search w-100 d-md-flex d-lg-flex">
          <div className="input-group input-group-seamless">
            <div className="input-group-prepend mb-2">
              <span className="input-group-text">
                <i style={{color:`#111b21`}} className="material-icons">search</i>
              </span>
            </div>
            <FormInput
              className="navbar-search mb-2 menu-search bg-white"
              placeholder="Search Followup of Patients by Name/ MAIN/ PhoneNumber..."
            //value={searchText}
              type="search"
              onChange={handleInputChange}
            />
            
          </div>
        </form>
        <button className='btn btn-primary' type="submit" onClick={(event) => openPatientForm(event)}>Appointment</button>
          </Col>
          
         
        </Row>
      <h5 className="page-title my-2 mx-3">Follow-up</h5>
      <div>
        <div className="preview p-0">
          <div
            className="ag-theme-balham ag-style card rounded table mb-0 tcol"
            style={{ height: 500, width: `98%` }}
          >
            <AgGridReact
              frameworkComponents={{
                BtnCellRenderer: BtnCellRenderer,
                CustomTooltip: CustomTooltip,
              }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              context={context}
              rowData={patientRevisit}
              columnDefs={columnDefs}
              tooltipShowDelay={0}
              tooltipHideDelay={2000}
              // rowHeight={rowHeight}
            ></AgGridReact>
          </div>
          {showPatientRevisitModal ? (
            <Components.PatientRevisitModal
              patientData={selectPatientData}
              onModalClose={(event) => closeModal(event)}
            />
          ) : (
            ""
          )}
          {showPatientVisitModal ? (
            //  <Components.GiveAppointment
            <Components.AppointmentScheduleModal
              patientData={selectPatientData}
              onModalClose={(event) => closeModal(event)}
            />
          ) : (
            ""
          )}

          {patientForm ? (
            <Components.PatientForm
             patientInfo={[]}
             openPatientForm={true}
             appointment ={true}
             closePatientForm={() => closePatientFormModal()}
             onSuccess={(event) => updatePatientDetails(event, "payment")}
            />
          ) : (
            ""
          )}

          <Modal
            className="d-flex w-95 h-90 flex-column-reverse "
            id="closemodalopen"
            open={onBoardmodal}
            toggle={toggle}
          >
            <div>
              <ModalHeader>
                Select Patient Category
                <span
                  className="close-model"
                  onClick={(event) => toggle(event)}
                >
                  X
                </span>
              </ModalHeader>
              <ModalBody>
                <span>Are you sure to OnBoard a patient?</span>
              </ModalBody>
              <ModalFooter className="d-flex flex-column align-items-start">
                <div className="d-flex w-100 justify-content-between">
                  <FormRadio
                    name="InPatient"
                    checked={selectedRadio === "InPatient"}
                    onChange={(event) => {
                      onValueChange("InPatient");
                    }}
                  >
                    InPatient{" "}
                  </FormRadio>
                  <FormRadio
                    name="OutPatient"
                    checked={selectedRadio === "OutPatient"}
                    onChange={(event) => {
                      onValueChange("OutPatient");
                    }}
                  >
                    OutPatient{""}
                  </FormRadio>
                  <FormRadio
                    name="OnlinePatient"
                    checked={selectedRadio === "OnlinePatient"}
                    onChange={(event) => {
                      onValueChange("OnlinePatient");
                    }}
                  >
                    OnlinePatient{""}
                  </FormRadio>
                </div>
                {selectedRadio === "InPatient" ? 
                  <div>
                  <label  style={{ paddingTop: '5px' }}>Select a Admission Date:</label>
                  <div className="d-flex pl-0 col-lg-7 col-xl-7 col-md-12 col-12">
                    {/* Date Input */}
                  <div className="mr-2">
                  <input
                    name="admissionDate"
                    id="admissionDate"
                    className="form-control filterin Quantity"
                    placeholder="Enter Scheduled Date"
                    required
                    type="date"
                    defaultValue={selecteddate}
                    onChange={(event)=>handleOnChange("date",event)}
                  />
                  </div>
                  {/* Time Input */}
                  <div className="ml-2">
                  <input
                    name="admissionTime"
                    id="admissionTime"
                    className="form-control filterin Quantity"
                    placeholder="Enter Scheduled Time"
                    required
                    type="time"
                    defaultValue={selectedTime}
                    onChange={(event)=>handleOnChange("time",event)}
                  />
                  </div>
                  </div>
                  </div>
                  :"" }
                <div className="w-100 text-right">
                  <button
                    className="btn btn-submit btn-primary btn-style bottomright"
                    onClick={(event) => onboardPatient()}
                  >
                    Save
                  </button>
                </div>
              </ModalFooter>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
}
export default PatientRevisit;
