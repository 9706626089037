import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import Components from "./components/index";
import messageString from "./shared/messagestrip/index"
import { DefaultLayout } from "./layouts/index";
import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";

function App() {
    return (
      <div className="h-100">
        <Route
          path="/"
          children={(routeProps) =>
            routeProps.match.isExact ? <Redirect to="/Login/:code" /> : ""
          }
        />
        <Route path={"/Login/:code"} render={(props) => <Components.Login {...props} /> } />
        <Route path={"/AddPatient"} component={Components.AddPatientmodel} />
	      <Route path={"/ZoomMeeting/:name/:meetingId/:patientId/:visitId/:groupId"} 
        render={(props) => <Components.Zoom {...props}/>} /> 
        <Route path={"/Bed"} component={MyLayout} />
        <Route path={"/Admin"} component={MyLayout} />
        <Route path={"/Followup"} component={MyLayout} />
        <Route path={"/Assistant"} component={MyLayout} />
        <Route path={"/TokenDashBoard"} component={MyLayout}/>
        <Route path={"/MyReports"} component={MyLayout}/>
        <Route exact path="/FormDetail/:formId" component={MyLayout} />
        <Route path={"/AddForm"} component={MyLayout} />
        <Route path={"/Forms"} component={MyLayout} />
        <Route path={"/FormPreview"} component={MyLayout} />
        <Route path={"/Docsetup"} component={MyLayout} />
        <Route path={"/ListDoctor"} component={MyLayout} />
        <Route path={"/ListPatient"} component={MyLayout} />
        <Route path={"/Dashboard"} component={MyLayout}/>
        <Route path={"/Settlements"} component={MyLayout}/>
        <Route path={"/doctorregister"} component={MyLayout}/>
        <Route path={"/adddoctor"} component={MyLayout}/>
        <Route path={"/addstaff"} component={MyLayout}/>
        <Route path={"/reports"} component={MyLayout}/>
        <Route path={"/doctorlist"} component={MyLayout}/>
        <Route path={"/StaffList"} component={MyLayout}/>
{/* <Route path={"/StaffListGrid"} component={MyLayout}/> */}
        <Route path={"/insurancemaster"} component={MyLayout}/>
        <Route path={"/tpamaster"} component={MyLayout}/>
        <Route path={"/insuremasterlist"} component={MyLayout}/>
        <Route path={"/tpamasterlist"} component={MyLayout}/>
        <Route path={"/roomMasterList"} component={MyLayout}/>
        <Route path={"/roommaster"} component={MyLayout}/>
        <Route path={"/ServiceMaster"} component={MyLayout} />
        <Route path={"/PatientSections/*"} component={MyLayout} />
        <Route exact path="/DiagnosisMaster/:patientId" component={MyLayout} />
        <Route path={"/Home"} component={MyLayout} />
        <Route path={"/Discharge"} component={MyLayout} />
        <Route path={"/Services"} component={MyLayout} />
        <Route path={"/message"} component={MyLayout} />
        <Route path={"/patient/"} component={Components.DoctorHomeView}/>
        <Route path={"/notes/:patientid/:visitid/:groupid"} render={(props) => <Components.ZoomMeetingReviewModel {...props} /> }  />
        <Route path={"/PatientJson"} component={Components.PatientSchema} />
	      <Route path={"/DischargeSummary/:patientid/:visitid"} component={MyLayout} />
        <Route path={"/ZoomMeeting/:name/:meetingId/:patientId/:visitId/:groupId"} render={(props) => <Components.Zoommeeting {...props} /> } /> 
        <Route path={"/Payment/:name/:patientId/:visitId/:erpCustId"} component={Components.Payment}/>
        <Route path={"/activeTokenPatients"} component={MyLayout} />
        <Route path={"/packageList"} component={MyLayout} />
        <Route path={"/packageAdd"} component={MyLayout}/>
      </div>
    );
  }

function MyLayout() {
    return (
      <>
      <DefaultLayout>
       <Route path={"/PatientSections/:patientId/:visitId"} render={(props) => <Components.PatientSections {...props} /> } />
       <Route path={"/PatientSections/new"} component={Components.PatientSections} />
       <Route path={"/Admin"}  render={(props) => <Components.PatientListing {...props} /> } />
	<Route path={"/Followup"} component={Components.PatientRevisit} />
        <Route
          exact
          path="/FormDetail/:formId"
          render={(props) => <Components.FormDetail {...props} />}
        />
        <Route path={"/AddForm"} component={Components.FormDetail} />
        <Route path={"/Forms"} component={Components.FormList} />
        <Route path={"/FormPreview"} component={Components.FormPreview} />
        <Route path={"/ListDoctor"} component={Components.ListDoctor} /> 
        <Route path={"/Docsetup"} component={Components.DocSetup}  /> 
        <Route path={"/ServiceMaster"} component={Components.ServiceMaster} />
        <Route path={"/Dashboard"} component={Components.Dashboard}/>
        <Route path={"/Settlements"} component={Components.InsuranceProcedureSettlement}/>
        <Route path={"/doctorregister"} component={Components.DoctorRegister}/>
        <Route path={"/adddoctor"} component={Components.addDoctor}/>
        <Route path={"/doctorlist"} component={Components.DoctorList}/>
        <Route path={"/StaffList"} component={Components.StaffList}/>
        <Route path={"/addstaff"} component={Components.AddStaff}/>
        {/* <Route path={"/StaffListGrid"} component={Components.StaffListGrid}/> */}
        <Route path={"/insurancemaster"} component={Components.insuranceMasteradd}/>
        <Route path={"/tpamaster"} component={Components.Tpaadd}/>
        <Route path={"/insuremasterlist"} component={Components.insuranceMasterList}/>
        <Route path={"/tpamasterlist"} component={Components.tpamasterlist}/>
        <Route path={"/roomMasterList"} component={Components.roomMasterList}/>
        <Route path={"/roommaster"} component={Components.RoomMasterAdd}/>
        <Route path={"/reports"} component={Components.Reports}/>
        <Route path={"/TokenDashBoard"} component={Components.TokenDashBoard} />
        <Route path={"/MyReports"} component={Components.MyReports} />
        <Route path={"/DischargeSummary/:patientid/:visitid"} component={Components.Discharge}/>
        <Route path={"/Home"} component={Components.AdminHomeView} />
        <Route path={"/Discharge"} component={Components.Discharge} />
        <Route path={"/Services"} component={Components.Services} />
        <Route path={"/message"} component={messageString.MessageStrip} />  
        <Route path={"/activeTokenPatients"} component={Components.ActiveTokenPatients} />
        <Route path={"/packageList"} component={Components.Package}/>
        <Route path={"/packageAdd"} component={Components.PackageMasterAdd}/>
       </DefaultLayout>
      </>
    );
  }


App.propTypes = {};
export default App;
