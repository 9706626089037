import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { roomMasterActions } from '../../../core/actions';
import { doctorActions } from "../../../core/actions";



import {
    ListGroup,
    ListGroupItem,
    Row,
    Col,
    Form,
    FormInput,
    Alert,
    Button, Card, CardBody,ModalBody, Modal, ModalHeader, ModalFooter,
    FormGroup,
    FormTextarea

  } from "shards-react";
import { getPackageList } from "../../../core/actions/doctorActions";
import { RoomService } from "@mui/icons-material";




function RoomMasterAdd(props) {
  const dispatch = useDispatch();
  const [addFlag, setTextFlag] =useState(false);
  const [submitted, setSubmitted] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [displayServices, setDisplayServices] = useState([])
  const [search, SetSearch] = useState();
  const [packageServiceName, setPackageServiceName] = useState();


  const [formErrors, setFormErrors] = useState(
    {
      roomName:'',
      roomCode:'',
    }, 
  )
  const [loginUserName, setLoginUser] = useState();
  
  const [onmodal, setOnModal] =useState(true);

  const [roomDetail, setRoomDetail] = useState(
    {
      id: "",
      roomName:"",
      roomCode: "",
      type: "",
      uby:null,
      phone2:"",
      description:"",
      floorId:"",
      facilityId:"",
      section:"",
      cby:null,
      floorName: "",
      packageServiceId:"",
      packageServiceName: "",
    }
  );
  const [ServerRes, setServerRes] = useState(
    {success:'',error:'',}
  );
  //const serviceName = '';
  const handleInputChange = (name, value) => {
    console.log("In handleinput change",name, value);
    formErrors[name]="";
      var pDetail = roomDetail;
      console.log('pDetail["basicinfo"][name]',pDetail[name])
      pDetail[name] = value;
      setRoomDetail({...pDetail});
      console.log('roomsdetail: ', roomDetail)
      if (name === "packageServiceName") {
        SetSearch(value);
        console.log("keyword :", value.length);
        if (value.length > 2) {
          searchService(value);
        } else {
          setDisplayServices(null);
        }
      } 
  }

      const handleSubmit = (event) => {
      event.preventDefault();
      setSubmitted(true);
      Object.keys(roomDetail).forEach((key)=>{
        console.log("key",key)
        console.log("key",roomDetail[key])
        validateField(key,roomDetail[key])
      })
      validateForm(event); 
      }

      const validateField = (fieldName, value) => {
        let fieldValidationErrors = formErrors;
        let basicInfo = roomDetail;
     
    var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
    var patternforFloorid = new RegExp(/^[0-9]+/)
    var currentDate = new Date().getDate;   
    switch(fieldName){
  
      case "roomName":
        fieldValidationErrors.roomName = value !== '' ? '' : 'Please Enter  Room Name';
          basicInfo.roomName = value !== '' ? value : '';
          break;
  
      case "roomCode":
        console.log("roomCode check");
        fieldValidationErrors.roomCode = value !== '' ? '' : 'Please Enter Room Code';
        basicInfo.roomCode = value !== '' ? value : '';
        break;
      
        case "packageServiceName":
          console.log("packageservicename check");
          fieldValidationErrors.packageServiceName = value !== '' ? '' : 'Please choose the appropiate Package Service';
          basicInfo.packageServiceName = value !== ''? value : '';
        
        // case "floor":
        //   console.log("floorid check");
        //   fieldValidationErrors.floorId = value !== '' || value!==patternforFloorid ? '' : 'Please enter valid floor id';
        //   basicInfo.floorId = value !== ''? value : '';
      
      default:
        break
      }
    setFormErrors(fieldValidationErrors); 
      
  }

  const validateForm = (event) => {
    event.preventDefault();
    
       
    console.log("formErrors.::",formErrors);
    let formValid =
      
    formErrors.roomCode === ""&&
    formErrors.roomName === "" &&
    formErrors.packageServiceName==="";
    console.log("formValid",formValid);
    
    if(formValid){
      if(props.location.state&&props.location.state.rowData){
        console.log("edit case");
        //to change the label
        
        roomDetail.uby=localStorage.getItem("name");     
        roomDetail.cby=localStorage.getItem("name");
        dispatch(
          roomMasterActions.modifyRoom(roomDetail, (resCb) => {
            if (resCb) {
              console.log("modifyroom", resCb);
              console.log("props: ", props)
              props.history.push("/roomMasterList");

            }
          })
        );
        event.preventDefault();

      }
      else{
        console.log("save case");

        // setLoginUser(localStorage.getItem("name"));
        roomDetail.cby=localStorage.getItem("name");
        dispatch(
          roomMasterActions.addRoom(roomDetail, (resCb) => {
            if (resCb) {
              console.log("roomsave", resCb);
              props.history.push("/roomMasterList");
  
            }
          })
        );
      }
         
    }
    else{
      console.log("invalid");
      }
  }



  useEffect(() => {
    console.log("propsprops",props);
    getServiceList()
    if(props.location.state&&props.location.state.rowData){
      console.log('Room Details: ', packageServiceName)
      console.log('entered use effect if block')
      setTextFlag(!addFlag);
      let roomData = props.location.state.rowData
      console.log("let roomData =",roomData );
      let pid = roomData.packageServiceId;

      setRoomDetail(roomData);
    }else{
      console.log('Room Details: ', packageServiceName)
      console.log('use effect else bloc')
      setTextFlag(addFlag);

      setRoomDetail(roomDetail)
   }

   

  }, []);

  const getServiceList=() => {
    let data = {};
    //let services = [];
    dispatch(
      doctorActions.getServiceList(data, (resCb) => {
        if(resCb && resCb.packageServiceList){
          console.log("response of packages",resCb)
          setServiceList(resCb.packageServiceList); 
          let serviceArray = [];
          for (let service of resCb.packageServiceList){
            let services = {}
            services['packagename'] = service.packagename == undefined ? service.name : service.packagename;
            console.log("packages is", service)
            serviceArray.push(services)
          } 
          console.log('service array: ', serviceArray)


        }
      })
      
    );  
     
  }

  const searchService = (data) => {
    console.log("keyword searchService :", data);
    console.log("serviceList :", serviceList);
    const filteredData = serviceList.map((item) => ({
        name: item.name === "Doctor Charge" || item.name === "Hospital Charge" ?item.packagename + ': ' + item.name: item.name ,
        id: item.id,
        amount: item.amount,
        description: item.description,
      }));
    
      console.log('filtered Data: ', filteredData)
      let filteredPackageList = [...filteredData]; 
      const filtered = filteredPackageList.filter((service) =>
        service.name.toLowerCase().startsWith(data.toLowerCase()));
        console.log("filtered: ", filtered);
        //filtered.amount = filtered.amount
      setDisplayServices(filtered);
  
    
  };

  
  const closemodalopen = () => {
    setOnModal(true);
  }
  const toggle =(event)=>{
    // props.onModalClose();
    props.history.push("/roomMasterList")
   }
  
  const serviceSelected = (value, index) => {
    console.log("service selected: ", value)
    roomDetail.packageServiceId = value.id;
    roomDetail.packageServiceName = value.name;
    console.log('Room Details: ', roomDetail)
    // packageServiceName.id = value.id;
    setDisplayServices(null)



  }
  return (
    <>
   
       
          <div class="mainsection">
           <div ><h5> {addFlag ? "Edit" :"Add"}  Room  </h5> </div>
         

            <main className="mainsection">
        <ListGroup flush className="mb-3 ">
            <div className="p-2 bg-none">
              <Row>
                <Col>
                  <Form>
                 

                  
                    <Row form>
                      <Col md="8" className="form-group">
                        <label htmlFor="roomName">Room Name</label>
                        <FormInput
                          id="roomName"
                          value={roomDetail.roomName}
                          placeholder="Room Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange(
                              "roomName",
                              event.target.value
                            );
                          }}
                        />
                        <div style={{ color: "#EC4147" }}>
                        <span>{formErrors.roomName}</span>
                      </div>
                      </Col>
                      <Col md="4" className="form-group">

                         <label htmlFor="roomCode">Room Code</label>
                        <FormInput 
                          id="roomCode"
                          value={roomDetail.roomCode}
                          placeholder="Room Code"
                        autoComplete="off"
                            onChange={(event) => {
                        handleInputChange("roomCode", event.target.value);
                             }}

                            />
                              <div style={{ color: "#EC4147" }}>
                        <span>{formErrors.roomCode}</span>
                      </div>
                         </Col>

                    </Row>


                    <Row form>
                    <Col md="4" className="form-group">
                      <label htmlFor="type">Type</label>
                      <FormInput
                        id="type"                     
                        placeholder="Type"
                        value={roomDetail.type}
                        onChange={(event) => {
                          handleInputChange("type", event.target.value);
                        }}
                        // invalid={formErrors.phone1 === "" ? false : true}

                      />
                       {/* <div style={{ color: "#EC4147" }}>
                        <span>{formErrors.phone1}</span>
                      </div> */}
                  </Col>

                  

                    {/* <Col md="6" className="form-group">
                        <label htmlFor="facilityId">Facility Id</label>
                        <FormInput
                          id="facilityId"
                          type="number"
                          value={roomDetail.facilityId}
                          placeholder="Facility Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("facilityId", event.target.value);
                          }}
                        />
                      </Col> */}
                        <Col md="4" className="form-group">
                        <label htmlFor="section">Section</label>
                        <FormInput
                          id="section"
               
                          value={roomDetail.section}
                          placeholder="Section"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("section", event.target.value);
                          }}
                        />
                    </Col>

                    <Col md="4" className="form-group">
                      <label htmlFor="packageServiceName">Package Service</label>
                        {/* <select
                          name="category"
                          className="form-control filterin Quantity"
                          value={roomDetail.packageServiceId}
                          //disabled={isReadonly}
                          onChange={(event) => {
                          handleInputChange(
                            "packageServiceId",
                              event.target.value
                                );
                          }}
                        >
                        <option defaultValue={"selected Type"} >
                            ---Select Packages---
                        </option>
                                  
                        {serviceList.map((service, index) => (
                            <option key={index} value={service.id}>
                              {service.name}                                        
                            </option>
                          ))}
                      </select> */}
   
                      <FormInput  
                        
                        id="packageServiceName"
                        value={roomDetail.packageServiceName}
                        placeholder="Search Package"
                        autoComplete="off"
                        onChange={(event) => handleInputChange("packageServiceName", event.target.value)  
                      }
                      required
                      />
                      <div className="mt-2 shadow product-list-container position-absolute">
                        {displayServices && displayServices.length > 0 && displayServices.map((service, index) => (
                        <div
                          className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 justify-content-between"
                          onClick={(event) => serviceSelected(service, index)}>
                          <span className="mb-0">{service.name}</span>
                          <span className="text-muted">Rs. {service.amount}</span>
                          <span className="text-muted"> {service.description}</span>
                        </div>
                       ))}
                    </div>
                    <div style={{ color: "#EC4147" }}>
                      <span>{formErrors.packageServiceName}</span>
                    </div>
                  </Col>
                </Row>

                <Row form>
                    <Col md="4" className="form-group">
                        <label htmlFor="floorId">Floor Id</label>
                        <FormInput
                          id="floorId"
               
                          value={roomDetail.floorId}
                          placeholder="Floor Id"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("floorId", event.target.value);
                          }}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="floorName">Floor Name</label>
                        <FormInput
                          id="floorName"
               
                          value={roomDetail.floorName}
                          placeholder="Floor Name"
                          autoComplete="off"
                          onChange={(event) => {
                            handleInputChange("floorName", event.target.value);
                          }}
                        />
                      </Col>
                      <Col md="4" className="form-group">
                        <label htmlFor="feInputDescription">Description</label>
                        <FormTextarea
      
                          id="feInputDescription"
                          placeholder="Description"
                          autoComplete="off"
                          type="textarea"
                        
                          value={roomDetail.description}
                          onChange={(event) => {
                            handleInputChange("description", event.target.value);
                          }}
                        />
                      </Col>
                    
                      </Row>
                    

                    
                    {ServerRes.success &&
                      <Alert theme="success">{ServerRes.success}</Alert>
                    }
                    {ServerRes.error &&
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    }
                  </Form>
                  <div className="buttonsetconfirm">
                    <Button
                          className="btn-danger"
                          type="submit"
                          onClick={(event) => toggle(event)}
                        >
                          Cancel
                        </Button>
                      <Button
                        type="submit"
                        onClick={(event) => handleSubmit(event)}
                      >
                        Save
                      </Button>
                    </div>
                    <br/>
                </Col>
              </Row>
            </div>
          </ListGroup>
          
        
          </main>
     
          
          </div>
    
     
    </>
  );
}
export default RoomMasterAdd;
