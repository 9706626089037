import React, { useEffect, useState } from "react";
import { Nav } from "shards-react";
import SidebarNavItem from "./SidebarNavItem";
import { Store } from "../../../flux";

function SidebarNavItems() {
  const [navItems, setNavItems] = useState(Store.getSidebarItems());
  const onChange = () => {
    setNavItems(Store.getSidebarItems());
    console.log("nav items value : ", navItems);
  };

  useEffect(() => {
    Store.addChangeListener(onChange);
  }, []);
  
  useEffect(() => {
    Store.removeChangeListener(onChange);
  }, []);
  
 
  return (
    <div className="nav-wrapper">
      <Nav className="nav--no-borders flex-column">
        {navItems.map(
          (item, idx) => (
            console.log("nav items value item : ", item),
            (<SidebarNavItem key={idx} item={item} />)
          )
        )}
      </Nav>
    </div>
  );
}

export default SidebarNavItems;
