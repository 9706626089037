import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PageTitle from "../common/PageTitle";
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import BtnCellRenderer from '../common/BtnCellRenderer';
import { CFollowupNote, DFollowupNote, GET_UPLOAD_PATH } from '../../shared/constants';
import { jsonSchema } from "../../shared/schema";
import { patientActions, doctorActions, zoomActions, ehrActions } from '../../core/actions';
import { useDispatch, useSelector } from "react-redux";
import { helperServices } from '../../shared/helper';
import { DISCHARGESUMMARY, CASERECORD } from "../../shared/constants";
import { markForDel } from '../../core/actions/patientActions';
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button, Card, CardBody, Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader, NavItem, Collapse
} from "shards-react";
import { format } from "date-fns";
import Components from '..';
import { Component } from 'ag-grid-community';
import { Link } from "react-router-dom";
import { lowerCase, template } from 'lodash';
function ProcedureListing(props) {
  const [active, setActive] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showOptions, setShowOptions] = useState(true);
  //const[patientData, setPatientData] = useState();
  const [patientProcedureList, setPatientProcedureList] = useState();
  const [procedruelistCopy, setprocedruelistCopy] = useState()
  const [procedureNotes, setProcedureNotes] = useState([])
  const [showPatientBasic, setShowPatientBasic] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState({});
  //remove below state variable might not be needed pls check later
  const [selectedPatientInfo, setSelectedPatientInfo] = useState({});
  const [renderPrescription, setRenderPrescription] = useState(false);
  const [selectedDatePres, setSelectedDatePres] = useState({});
  const [showDischargeSummary, setShowDischargeSummary] = useState();
  const [dischargeSummary, setDischargeSummary] = useState([]);
  const [showMarkForDel, setShowMarkForDel] = useState(true);
  const [selectedProcedure, setselectedProcedure] = useState();
  const [deleteProcedure, setDeleteProcedure] = useState();
  const [selectMarkForDel, setSelectMarkForDel] = useState({
    patientId: '',
    visitId: '',
    id: '',
    markForDel: ''

  });

  const [pateintdetails, setPatientDetails] = useState({
    name: '',
    visitDate: ''
  })
  const [data, setData] = useState();
  const dispatch = useDispatch();
  const [showReviewModel, setShowReviewModel] = useState(false);

  const [showComposition, setShowComposition] = useState(false);
  const [templateId, setTemplateId] = useState();
  const [flag, setFlag] = useState();
  const [uploadedImages, setUploadedImages] = useState([]);

  const [dischargesummary, setDischargeSummaryData] = useState({
    preparedBy: '',
    approver: '',
    patientId: '',
    visitId: '',
    notes: '',
    groupname: '',
    notes: '',
    subgroup: '',
    type: '',
    id: ''

  });
  const [compositionPreviewValue, setCompositionPreviewValue] = useState(
    {
      brandName: '',
      icdName: ''
    }
  )
  const [appliedFilters, setAppliedFilters] = useState({
    uploads: false,
    discharge: false,
    templates: false,
    services: false,
    date: false,
    prescriptions: false
  });
  const [reloadData, setReloadData] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  //const [allOrFilteredProcedureList, setAllOrFilteredProcedureList] = useState();
  const [filterVisitDates, setFilterVisitDates] = useState({

    // '2022-07-25','2022-07-26','2022-07-27','2022-07-28','2022-07-29'
  })
  const [showFilerVisitDate, setshowFilerVisitDate] = useState(false);
  //const [procedureDate, setPrcedureDate] = useState();
  let procedureDate = '';
  const [selectedDate, setSelecetdDate] = useState(new Date);
  const [reloadModal, setReloadModal] = useState(false);
  const [state, setState] = useState({ open: false });
  const [ServerRes, setServerRes] = useState(
    { success: '', error: '', }
  );


  const allVisitdates = useSelector(
    (state) => state.patientDetailsState.patientBasic);
  //const eprescription = useSelector(state => state.patientDetailsState.ePresc);
  const [eprescription, seteprescription] = useState();
  const [print, setPrint] = useState(false);

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selecetdCount, setselecetdCount] = useState(0);

  const [proceduresToPrint, setproceduresToPrint] = useState();
  const [pData, setPData] = useState([]);

  const [markCompleteModal, setmarkCompleteModal] = useState(false);

  const [showpdfscreen, setpdfscreen] = useState(false);
  const [dischargepdfscreen, setdischargepdfscreen] = useState(false);

  const [doctorInformation, setDoctorInfo] = useState({});
  const [showService, setShowService] = useState(false);
  const [caseRecordModal, setCaseRecordModal] = useState(false);
  const patientEhrid = useSelector(state => state.patientDetailsState.ehrid)
  const [serviceDetails, setServiceDetails] = useState({});
  const selectedID = useSelector(state => state.patientDetailsState.selectedPatientId);
  const [showConsutant, setShowConsultant] = useState(false);
  const [consultantDetails, setconsultantDetails] = useState({});
  const [dischargePrint, setDischargePrint] = useState(false);
  const [labReportPrintStatus, setLabReportPrintStatus] = useState(false)
  const [docDetail, setDocDetail] = useState({});
  const [epriscTemplate, setepriscTemplate] = useState({});

  useEffect(() => {
    console.log("Data in the chat ...: ", props, props.selectedPatientInfo.person);
    setDocDetail(props.selectedPatientInfo.person);
    if (props.selectedPatientInfo.id != selectedID) {
      dispatch(patientActions.setSelectedPatientId(props.selectedPatientInfo.id));
      setShowReviewModel(false);
      setShowComposition(false);
      setShowDischargeSummary(false);
      setShowService(false);
      setShowOptions(true);
      setselecetdCount(0);
      setPrint(false);
      setpdfscreen(false);
    }
    console.log("listing procedures", props);
    let patientId = props.selectedPatientInfo.id
    let visitId = props.selectedPatientInfo.visit.id
    let patientDetails = []
    let pOpt = {}
    pOpt['patientId'] = patientId;
    pOpt['visitId'] = visitId;
    patientDetails.push(pOpt);
    setPData([...patientDetails]);
    // props.showPatientEditForm
    procedruelist(patientId, visitId)

    setShowFilterOptions(false)
    console.log('selectedDatePres', props, props.selectedPatientInfo.procedureslist)
    let patient;
    patient = props.selectedPatientInfo
    console.log('patient selectedDatePres', props.selectedPatientInfo)
    //setPatientData(patientProcedureList);
    if (props && props.selectedPatientInfo && props.selectedPatientInfo.procedureslist) {
      for (let prescription of props.selectedPatientInfo.procedureslist) {
        console.log("prescription notes update : ", prescription.notes);
      }
    }
    console.log("patientProcedureList : ", patientProcedureList);
    if (props.selectedPatientInfo.visit.status === 'close') {
      setSelecetdDate(props.selectedPatientInfo.visit.visitDate);
      procedurelistByVisitDate(props.selectedPatientInfo.visit.id);
    }

    setTimeout(() => {
      var element = document.getElementById('patient-pres');
      element.scrollTop = element.scrollHeight;
      console.log("element:", element.scrollHeight);
    }, 500);

  }, [props])


  const procedurelistByVisitDate = (data) => {
    console.log("patientProcedureList:", patientProcedureList);
    filterVisitDates.visitId = data
    filterVisitDates.patientId = props.selectedPatientInfo.id
    dispatch(patientActions.getPatientProcedureByLastVisitDate(filterVisitDates, resCb => {
      if (resCb && resCb.patientresp && Object.keys(resCb.patientresp).length > 0) {
        // setShowPatientProcedure(true)
        console.log("getPatientProcedureByLastVisitDate :", resCb)

        resCb.patientresp.procedureslist.forEach(element => {
          element["print"] = false;

        });
        setPatientProcedureList({ ...resCb.patientresp })
        setprocedruelistCopy({ ...resCb.patientresp });
        console.log("getPatientProcedureByLastVisitDate1 :", patientProcedureList)
        // setAllOrFilteredProcedureList(resCb.patientresp) 
        // setFilterVisitDates(resCb.patientresp)
        // console.log("PatientProcedureByLastVisitDate :", allOrFilteredProcedureList)

      }
    }))
  }

  const markForDel = (presc, event) => {

    console.log("event in presc.markForDel", event)
    var ServerResVal = ServerRes;
    console.log("delete proceudre", deleteProcedure)
    console.log("presc.markForDel", presc)
    const markForDel = {};
    //  markForDel['patientId']=presc.patientId;
    //  markForDel['visitId']=presc.visitId;
    //  markForDel['id']=presc.id;
    //  presc.markForDel=true;
    //  markForDel['markForDel']=presc.markForDel;

    markForDel['patientId'] = deleteProcedure.patientId;
    markForDel['visitId'] = deleteProcedure.visitId;
    markForDel['id'] = deleteProcedure.id;
    markForDel['markForDel'] = true;
    deleteProcedure.markForDel = true;
    // setSelectMarkForDel(markForDel);
    // console.log("presc.markForDel3",selectMarkForDel);
    // setShowMarkForDel(true);
    console.log("presc.markForDel3", markForDel);
    dispatch(patientActions.markForDel(markForDel, resCb => {
      if (resCb) {
        console.log('deleted procedure Response ', resCb);
        if (resCb.successMessages) {
          ServerResVal.success = "Procedure details deleted successfully"
        }
        else {
          ServerResVal.error = "Failed to delete procedure";
        }
        // handleopenDeleteModal(false);
        setOpenDeleteModal(false);
        setServerRes({ ...ServerResVal });
        procedruelist(presc.patientId, presc.visitId)
        setReloadData(true);
      }
      setTimeout(() => {
        closeAlert();
      }, 5000);
      setShowMarkForDel(false);
    }))
  }

  const closeAlert = (e) => {
    setServerRes({ ..."" });
  }


  const procedruelist = (patientId, visitId) => {
    let type = "showAllVisits";
    dispatch(patientActions.getPatientProcedureInfo(patientId, visitId, type, resCb => {
      if (resCb) {
        // setShowPatientProcedure(true)
        console.log("DiagetPatientProcedureInfo response in patient presc layout :", resCb)
        if (resCb.patientresp.procedureslist != null) {
          resCb.patientresp.procedureslist.forEach(element => {
            element["print"] = false;
          });
        }
        console.log("procedurelist with actions:", resCb.patientresp.procedureslist)
        let uploads = resCb.patientresp.uploadslist ? resCb.patientresp.uploadslist.filter((item) => item.imgurl != null) : [];
        setDischargeSummary(resCb.patientresp);
        setUploadedImages([...uploads]);
        let procedureResponse = resCb.patientresp.procedureslist;
        procedureResponse.map((procedure) => {
          //  let date = new Date(procedure.cdt);
          //  const hour = date.getHours();
          //  const minute = date.getMinutes(); 
          //  procedure.udt = `${hour}:${minute < 10 ? '0' + minute : minute}`;
          procedure["action"] = false;

        })
        //  const uniqueValues = {};
        //   const filteredData = resCb.patientresp.procedureslist.filter((item) => {
        //     if (!uniqueValues[item.groupid]) {
        //       uniqueValues[item.groupid] = true;
        //       return true;
        //     }
        //     return false;
        //   });


        setPatientProcedureList(resCb.patientresp);

        let notes = resCb.patientresp.procedureslist.filter(proc => proc.notes && !proc.groupname && !proc.markForDel);
        setProcedureNotes([...notes]);
        console.log("notes extracted:", notes);
        setprocedruelistCopy(resCb.patientresp);
        console.log("selectedPatientInfo patient layout :", patientProcedureList);
        dispatch(patientActions.tokenList(resCb.patientresp));
        setShowPatientBasic(false)
        setReloadModal(!reloadModal)
      }
    }))
  }

  const formatUdt = (cdt) => {
    let date = new Date(cdt);
    const hour = date.getHours();
    const minute = date.getMinutes();
    let udt = `${hour}:${minute < 10 ? '0' + minute : minute}`;
    return udt;
  }
  const showImage = (data) => {
    console.log('showImage', data)
    console.log('patientprocedurelist: ', patientProcedureList)
    // let uploadslist = patientProcedureList.uploadslist.filter((img)=>img.imgurl != null);
    //  setUploadedImages([...uploadslist]);
    setShowModal(true)
    setData(data)
  }

  const closeModal = (event) => {
    setShowModal(false)
  }

  const showAllOptions = (event) => {
    console.log("message obtained:", event);
    //setShowOptions(!showOptions);
    if (patientProcedureList.id) {
      procedruelist(patientProcedureList.id, patientProcedureList.visit.id);
    }
    if (event && event.success != "  ") {
      setMessageStrip(event);
    }
  }
  const setcloseAttachAction = (event) => {
    // procedruelist(patientProcedureList.id)


    setcloseAttachAction(!setcloseAttachAction)
    // setShowOptions(!showOptions)
    // props.openAttachment()
    // useEffect
  }
  const getFormattedDate = (date) => {
    procedureDate = date;
    const reqdate = new Date(date);
    // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min; 
    let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
    return formatteddate;
  }

  const getFormateDate = (date) => {
    procedureDate = date;
    const reqdate = new Date(date);
    // const formatteddate = day + "/" + month + "/" + year + "   " +  hour+ ":" + min; 
    let formatedate = format(reqdate, "MMM dd yyyy");
    return formatedate;
  }

  const zoomReviewModalClose = (event) => {
    setShowReviewModel(false);
    if (event) {
      setMessageStrip(event);
    }
    procedruelist(patientProcedureList.id, patientProcedureList.visit.id)

  }

  const zoomReviewModal = (presc, event) => {
    console.log("presc :", presc);
    let notes = [];
    notes.push(presc);
    dispatch(patientActions.setSelectedNotes(notes));
    if (presc.compositionId && presc.compositionId != null) {
      setShowComposition(true);
      setFlag("editComposition");
      setTemplateId(presc.compositionId);
      console.log("templateId", templateId)
      // setProceudreListTemplateId(presc.templateId);
      // setProceudreList(presc);
      setselectedProcedure(presc);

    } else if (!presc.compositionId && (presc.templateId == DFollowupNote || presc.templateId == CFollowupNote)) {
      setShowComposition(true);
      setFlag("editComposition");
      console.log("templateId", templateId)
      setTemplateId(presc.templateId);
      // setProceudreListTemplateId(presc.templateId);
      // setProceudreList(presc);
      setselectedProcedure(presc);
    } else if (!presc.compositionId) {
      console.log("presc2 :", presc);
      let procedure = { patientId: presc.patientId, groupid: presc.groupid, visitId: presc.visitId, tags: presc.tags }
      console.log("procedure :", procedure);
      console.log("props :", procedure.groupId);
      reviewNotes(procedure);
      // props.history.push(`/patient/${presc.patientId}/${presc.visitId}/${presc.groupId}`);
      // var leaveUrl = `http://localhost:3000/patient/${props.meetingInfo.patientId}/${props.meetingInfo.visitId}/${props.meetingInfo.groupId}`;
    }
    // }, 600);

  }
  const caseRecordView = (presc) => {
    console.log("presc :", presc);
    let notes = [];
    notes.push(presc);
    dispatch(patientActions.setSelectedNotes(notes));
    console.log("presc2 :", presc);
    let procedure = { patientId: presc.patientId, groupid: presc.groupid, visitId: presc.visitId, tags: presc.tags }
    console.log("procedure :", procedure);
    console.log("props :", procedure.groupId);
    setCaseRecordModal(true);
  }







  const listTemplates = (data) => {
    // let data = {}
    dispatch(doctorActions.listTemplates(data, resCb => {
      if (resCb) {
        console.log("listTemplates API Response:", resCb)
      }
    }))
  }
  const zoomMeetingReview = useSelector(state => state.doctorDetailsState.zoomReview)
  const reviewNotes = (data) => {
    // let data = {}
    dispatch(doctorActions.reviewNotes(data, resCb => {
      if (resCb) {
        console.log("reviewZoomNotes API Response:", resCb)

        // dispatch(patientActions.setSelectedNotes(notes));
        setShowReviewModel(true)
      }
    }))
  }



  const dischargemodal = (presc, event) => {
    console.log("dischargemodal :", presc, "event : ", event);
    let url = "/DischargeSummary/" + presc.patientId + "/" + presc.visitId;
    window.open(url, '_blank');
    // let procedure = { patientId: presc.patientId, groupname: presc.groupname, visitId: presc.visitId }
    // console.log("procedure", procedure);
    //  console.log("patientProcedureList : ",patientProcedureList);

    // let localDisSum = {};
    // localDisSum['id'] = presc.id
    // localDisSum['preparedBy'] = presc.preparedBy;
    // localDisSum['approver'] = presc.approver;
    // localDisSum['patientId'] = presc.patientId;
    // localDisSum['visitId'] = presc.visitId;
    // localDisSum['groupname'] = presc.groupname;
    // localDisSum['notes'] = presc.notes;
    // localDisSum['type'] = presc.type;
    // localDisSum['subgroup'] = presc.subgroup;
    // setDischargeSummaryData(localDisSum);

    // console.log("discharge Summary -", localDisSum);
    // setShowDischargeSummary(true)
  }

  useEffect(() => {
    console.log("inside discharge summary--", props);
    // patientSummaryClick(props.patientData)
  }, [props.dischargesummary])

  const closeDischargeSummaryModal = (event) => {
    setShowDischargeSummary(false);
    console.log("patientProcedureList:", patientProcedureList);
    procedruelist(patientProcedureList.id, patientProcedureList.visit.id)
    if (event) {
      setMessageStrip(event);
    }

  }


  const backToUserList = () => {
    document.getElementById('user-list').classList.remove('mobile-view');
    document.getElementById('user-info').classList.remove('mobile-view');

  }

  const applyFilter = (name, value) => {
    setActive(!active);
    var filters = appliedFilters;
    // if(name != 'date')
    filters[name] = !value;
    console.log("filters:", filters);
    setAppliedFilters(filters);


    // If none of the filters applied return original object
    if (Object.values(appliedFilters).every(item => item === false)) {
      setPatientProcedureList({ ...procedruelistCopy });
      setReloadData(!reloadData);
      return;

    }


    let filteredData = [];
    procedruelistCopy.procedureslist.map((value, index) => {
      console.log("procedurelistcopy", value);
      if (appliedFilters.uploads) {
        if (value.uploadid > 0) {
          filteredData.push(procedruelistCopy.procedureslist[index]);
        }
      }

      if (appliedFilters.discharge) {
        if (value.groupname == "Discharge Summary") {
          filteredData.push(procedruelistCopy.procedureslist[index]);
        }
      }

      if (appliedFilters.services) {
        if (value.categoryServiceId > 0) {
          filteredData.push(procedruelistCopy.procedureslist[index]);
        }
      }
      if (appliedFilters.prescriptions) {
        if (value.groupname == "EHR" && value.templateId == "ePrescription (epSoS/Contsys)") {
          filteredData.push(procedruelistCopy.procedureslist[index]);
        }
      }
      if (appliedFilters.templates) {
        // console.log("appliedFilters",appliedFilters);
        console.log("groupname", value.groupname)
        if (value.groupname == "EHR") {
          filteredData.push(procedruelistCopy.procedureslist[index]);
        }
      }



    });
    console.log("filteredlist:", filteredData);
    // setAllOrFilteredProcedureList({procedureslist:filteredData});
    // console.log("allOrFilteredProcedureList:",allOrFilteredProcedureList);
    // setPatientProcedureList({procedureslist:filteredData});
    setPatientProcedureList({ ...{ procedureslist: filteredData } });
    console.log("patientProcedureList:", patientProcedureList);

    setReloadData(!reloadData);
  }

  const toggleFilterOptions = () => {
    setShowFilterOptions(!showFilterOptions);
  }

  const handleChange = (event, value) => {

    //appliedFilters.date = event.target.value ? true : false;
    //selectedDate = event.target.value ;

    console.log("event.target.value:", event.target.value);
    setSelecetdDate(event.target.value);
    if (event.target.value == 'Select Visit Date') return;
    procedurelistByVisitDate(event.target.value);
    console.log(" appliedFilters.date:", appliedFilters.date);
    //applyFilter("date",value);

  }

  const expandOptions = (e) => {
    console.log("expnaded options:");
  }

  const toggle = (i, presc) => {
    console.log("i", i);
    console.log("presc", presc);
    console.log("patientProcedureList", patientProcedureList);
    let list = patientProcedureList;
    if (presc) {
      list.procedureslist[i].action = !list.procedureslist[i].action;
      // list.procedureslist.forEach(element => {
      //   if(element.id == presc.id){
      //     console.log("slected procedure is equal");

      //       element["action"] = true;


      //   } 
      // else {
      //   element["action"] = false;
      // }
      // });
      console.log("list", list);

      setPatientProcedureList({ ...list });
    }
    // setState(prevState => {
    //   return { open: !prevState.open };
    // });
  }

  const deleteNotes = (event) => {
    console.log("deleteNotes:", event);
    setDeleteProcedure(event.presc);
    markForDel(event.presc, event.e);

  }

  const confirmDelete = (val, e, procedure) => {
    //e.preventDefault();
    console.log("procedure conform delete:", procedure);
    console.log("conform delete:", val);
    setDeleteProcedure(procedure);
    setOpenDeleteModal(val);
    e.preventDefault();
  }

  const takePrint = (e, pres) => {
    setPrint(true);
    console.log("pres: ", pres)
    console.log("preescptionjcujc", pres.compositionId);
    // setepriscTemplate(pres.compositionId);
    //set checkbox of selected item to true
    setTimeout(() => {
      let elm = document.getElementById(pres.id);
      console.log(elm);
      elm.checked = true;
    }, 500);

    let count = selecetdCount;
    count++;
    setselecetdCount(count);
    updateProcedureList(pres, true);
    updateEprescription(pres.compositionId, pres.templateId);
  }


  const takeDischargePrint = (e, pres) => {
    setDischargePrint(true);
    console.log("takeDischargePrint", pres.compositionId);
    // setepriscTemplate(pres.compositionId);
    //set checkbox of selected item to true
    setTimeout(() => {
      let elm = document.getElementById(pres.id);
      console.log(elm);
      elm.checked = true;
    }, 500);

    let count = selecetdCount;
    count++;
    setselecetdCount(count);
    updateProcedureList(pres, true);
    updateEprescription(pres.compositionId);

  }


  const cancelPrint = () => {
    patientProcedureList.procedureslist.forEach((item) => {
      item.print = false;
    })
    setPatientProcedureList({ ...{ procedureslist: patientProcedureList.procedureslist } });
    setPrint(false);
    setselecetdCount(0);
  }


  const handleopenDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  }

  const handlemarkCompleteModal = () => {
    setmarkCompleteModal(!markCompleteModal);
  }



  const selectForPrint = (val, pres) => {
    console.log("val obtained:", val, pres);
    pres.print = val;
    let count = selecetdCount;
    console.log("val obtained:", val);
    if (val) {
      count++;
    } else {
      count--;
    }
    console.log("count obtained:", count);
    if (pres.templateId && pres.templateId.includes(("Report"))) {
      setLabReportPrintStatus(true)
    }
    else {
      setLabReportPrintStatus(false)
    }
    setselecetdCount(count);
    updateProcedureList(pres, val);

    console.log(setLabReportPrintStatus)
    // setproceduresToPrint([]);
  }

  const updateProcedureList = (pres, val) => {
    patientProcedureList.procedureslist.forEach((item) => {
      if (item.id == pres.id) {
        item.print = val;
      }
    })
    console.log("procedurelist with actions:", patientProcedureList.procedureslist);
    setPatientProcedureList({ ...{ procedureslist: patientProcedureList.procedureslist } });
    let filterList = patientProcedureList.procedureslist.filter((item) => item.print == true);
    setproceduresToPrint(filterList);
    console.log("proceduresToPrint:", filterList);
  }



  const openPdfModal = (event) => {
    if (print) {
      setpdfscreen(true);
      // setproceduresToPrint([]);
    }
    setTimeout(() => {
      setselecetdCount(0);
      setPrint(false);
      setpdfscreen(false);
      patientProcedureList.procedureslist.forEach((item) => {
        item.print = false;
      })
      //  setPatientProcedureList({...{ procedureslist: patientProcedureList.procedureslist}});
      // setPatientProcedureList({});
    }, 500);

    if (dischargePrint) {
      console.log("patientProcedureList:", dischargeSummary);
      setdischargepdfscreen(true);
    };

  };


  const closePdfModal = (event) => {
    if (print) {
      setpdfscreen(false);
    };
    if (dischargePrint) {
      setdischargepdfscreen(false);
    };

  };


  const closeCompositionModal = (event) => {
    setShowComposition(false);
    console.log("patientProcedureList:", patientProcedureList);
    procedruelist(patientProcedureList.id, patientProcedureList.visit.id)
    if (event) {
      setMessageStrip(event);
    }
  }
  const updateEprescription = (templateId, dbUItemplateid) => {
    console.log("template data: ", dbUItemplateid)
    dispatch(ehrActions.getCompositionByVid({}, templateId, patientEhrid, resCb => {
      if (resCb) {
        console.log("templateId", templateId)
        console.log("API Response getCompositionByVid :", resCb);
        console.log("resc :", resCb.archetype_details);
        console.log("props", props)
        let template_id_value;
        if (dbUItemplateid == resCb.archetype_details.template_id.value) {
          template_id_value = resCb.archetype_details.template_id.value
        } else {
          template_id_value = dbUItemplateid
        }

        let form = jsonSchema.getSchemaFromCanonicalJson(resCb, template_id_value, "editComposition");
        console.log("formrrr", form);
        seteprescription(form)
        if (resCb.archetype_details.template_id.value == "Laboratory Report") {
          setLabReportPrintStatus(true)
        }
        else {
          setLabReportPrintStatus(false)
        }

        console.log("LabReportPrintStatus state", labReportPrintStatus)
        // dispatch(patientActions.setForm(form));
        // setFormschema(form);
        // setSchemajson(resCb);
        // formUiSchema(resCb.archetype_details.template_id.value,true);
        // console.log('formschema1',formschema);
      }
    }))
  }


  const serviceListModal = (presc, event) => {
    setShowService(true);
    presc.packageService.patientId = pData[0].patientId;
    presc.packageService.visitId = pData[0].visitId;
    presc.packageService.state = 'update';
    setServiceDetails({ ...presc });
  }

  const closeServiceModal = (event) => {
    console.log("message obtained:", event);
    setShowService(false);
    procedruelist(patientProcedureList.id, patientProcedureList.visit.id)
    setMessageStrip(event);
  }
  const closeNotesModal = (event) => {
    console.log("message obtained:", event);
    setCaseRecordModal(false);
    procedruelist(patientProcedureList.id, patientProcedureList.visit.id)
    setMessageStrip(event);
  }

  const CloseAddConsultantModal = (e) => {
    setShowConsultant(false);
  }
  const editConsultant = (presc, event) => {
    setconsultantDetails({ ...presc })
    setShowConsultant(true);
  }

  const setMessageStrip = (e) => {
    var ServerResVal = ServerRes;
    if (e.success) {
      ServerResVal.success = e.success;
    }
    else {
      ServerResVal.error = e.error;
    }
    setServerRes({ ...ServerResVal });
    setTimeout(() => {
      closeAlert();
    }, 5000);
  }


  return (

    <>
      {ServerRes.success && (
        <Alert theme="success" className="alert-div"  >{ServerRes.success}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
      )}
      {ServerRes.error && (
        <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons" onClick={(event) => closeAlert(event)} style={{ cursor: 'pointer' }}>close</i></span></Alert>
      )}

      <div className={!props.basic ? 'pres-with-basic h-100' : 'layout-content h-100'} id='patient-pres'>
        <div className='d-flex justify-content-between position-fixed list-actions'>
          <div className="back-icon" id="back-btn" onClick={(event) => backToUserList()}>
            <span class="material-icons">arrow_back</span>
          </div>
          <div className='filter-container px-3'>

            {showFilterOptions ?
              <div className='filter-options position-absolute'>
                <div className='d-flex m-3 flex-wrap'>
                  <div className={appliedFilters['uploads'] ? "btn filter-btn mb-1 mr-2 btn-dark active " : "btn filter-btn mb-1 mr-2"} onClick={(event) => applyFilter('uploads', appliedFilters.uploads)} >Uploads</div>
                  {/* <div className={appliedFilters['discharge']  ? "btn filter-btn mb-1 mr-2 btn-dark active" : "btn filter-btn mb-1 mr-2"} onClick={(event) => applyFilter('discharge',appliedFilters.discharge)} >Discharge</div> */}
                  <div className={appliedFilters['services'] ? "btn filter-btn mr-2 mb-1 btn-dark active" : "btn filter-btn mb-1 mr-2"} onClick={(event) => applyFilter('services', appliedFilters.services)} >Services</div>
                  <div className={appliedFilters['templates'] ? "btn filter-btn mr-2 mb-1 btn-dark active" : "btn filter-btn mb-1 mr-2"} onClick={(event) => applyFilter('templates', appliedFilters.templates)} >EHR</div>
                  <div className={appliedFilters['prescriptions'] ? "btn filter-btn mr-2 mb-1 btn-dark active" : "btn filter-btn mb-1 mr-2"} onClick={(event) => applyFilter('prescriptions', appliedFilters.prescriptions)} >Prescriptions</div>

                  <div className="mr-2">
                    {/* <input type="date" className={appliedFilters['date'] ? "form-control active" : "form-control"} placeholder="select date" aria-label="Recipient's username" aria-describedby="basic-addon2"  onChange={(event) => {handleInputChange(event,appliedFilters.date);}}></input> */}
                    <select name="Select Date" value={selectedDate} className="form-control" onChange={(event) => handleChange(event, appliedFilters.date)}>
                      {/* <option defaultValue={'Select Type'}>Select Type</option> */}
                      <option disabled={true} value="">
                        Select Visit Date
                      </option>
                      {allVisitdates.visitList.map((lastVisited, index) => (
                        <option key={index} value={lastVisited.id}>{getFormateDate(lastVisited.visitDate)}</option>
                      ))}

                      {/* {visitDates.map((date,index) => (
                <option  key={index}  value={date}>{date}</option>
                ))} */}
                    </select>
                  </div>
                </div>
              </div> : ''}

            <span className="mainSpan" ><i title="Filter" class="material-icons m-2 filter-icon" onClick={(event) => toggleFilterOptions()}>filter_list</i></span>
          </div>
        </div>

        <div className='h-100 mt-5' id="procedure-list">

          {patientProcedureList && patientProcedureList.procedureslist && patientProcedureList.procedureslist.map((presc, index) => (
            <>

              {/* <div key={index} className={`${print ? 'set-width' : ''} ${(presc.notes && index+1 < patientProcedureList.procedureslist.length) && patientProcedureList.procedureslist[index].groupid === patientProcedureList.procedureslist[index+1].groupid ? 'hideNotes' : ''}`}> */}

              <div key={index} className={print ? 'set-width' : ''}>

                {(presc && !presc.markForDel && presc.notes) && (
                  <>

                    {(new Date(procedureDate).toDateString() != new Date(presc.cdt).toDateString()) &&
                      <div className='patient-lastvisit my-2 ' id={getFormateDate(presc.cdt)}>
                        <span className='patient-lastvisit-date'>{getFormateDate(presc.cdt)}</span>
                      </div>
                    }
                    <Col className={presc.print ? 'form-group bg-highlight' : 'form-group'}>
                      <div key={index} className="p-0" >
                        <CardBody className="p-0 d-flex align-items-center procedure-msg">
                          {presc.notes && presc.notes != null && presc.groupname != DISCHARGESUMMARY && presc.groupname != CASERECORD && presc.templateId != DFollowupNote && presc.templateId != CFollowupNote ?
                            <>
                              {print &&
                                <div className="checkbox" >
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              {!presc.categoryServiceId && presc.groupname != "Consultant" && presc.templateId != DFollowupNote && presc.templateId != CFollowupNote &&

                                <div className={print ? 'set-width' : ''} >

                                  <div class='markForDelete'>
                                    <Card small className="mb-0 h-100 abc note-card flex-wrap pt-2 presc-summary" key={presc.id} >
                                      <span class='expand'>
                                        <Dropdown
                                          open={presc.action}
                                          toggle={() => toggle(index, presc)}
                                          dropup
                                        >
                                          <DropdownToggle theme="none">
                                            <span class='material-icons expand'>expand_more</span>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                              Delete
                                            </DropdownItem>
                                            <DropdownItem className="drop-down-item" onClick={(event) => takePrint(event, presc)}>
                                              Print
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>


                                      </span>
                                      <span className='bubble-arrow' onClick={(event) => zoomReviewModal(presc, event)}>
                                        <div className='pt-2'>
                                          <label className='mb-0 mt-2'>{presc.notes}</label>
                                          {/* {procedureNotes && procedureNotes.map((note,index)=>(
                                          <>             
                                          <div key={index} className={note.notes}>
                                          
                                            <span className='bubble-arrow' onClick={(event) => zoomReviewModal(note,event)}>
                                              <div className=''>
                                                  <label className='mb-0'>{note.notes}</label>
                                              </div>
                                              <span style={{float: "right",fontSize: '12px',fontWeight:400}} className=''>{note.udt}</span>
                                            </span>
                                              
                                          </div>
                                          </>
                                                      
                                        ))} */}
                                        </div>
                                        <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                      </span>

                                    </Card>

                                  </div>
                                </div>
                              }


                            </>
                            : ''
                          }
                          {presc.notes && presc.notes != null && presc.groupname === CASERECORD ?
                            <>
                              {print &&
                                <div className="checkbox" >
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              {!presc.categoryServiceId &&

                                <div className={print ? 'set-width' : ''} >

                                  <div class='markForDelete'>
                                    <Card small className="mb-0 h-100 abc note-card flex-wrap pt-2 presc-summary" key={presc.id} >
                                      <span class='expand'>
                                        <Dropdown
                                          open={presc.action}
                                          toggle={() => toggle(index, presc)}
                                          dropup
                                        >
                                          <DropdownToggle theme="none">
                                            <span class='material-icons expand'>expand_more</span>
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                              Delete
                                            </DropdownItem>
                                            <DropdownItem className="drop-down-item" onClick={(event) => takePrint(event, presc)}>
                                              Print
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </Dropdown>


                                      </span>
                                      <span className='bubble-arrow' onClick={(event) => caseRecordView(presc)}>
                                        <div className='pt-2'>
                                          <label className='mb-0 mt-2'><b>{presc.claSS}:</b> {presc.notes}</label>
                                        </div>
                                        <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                      </span>

                                    </Card>

                                  </div>
                                </div>
                              }


                            </>
                            : ''
                          }
                          {presc.groupname == 'Consultant' ?
                            <>

                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow'>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'>{presc.shortdesc}</label><br></br>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                  </span>

                                </Card>

                              </div>
                            </>
                            : ''}

                          {presc.claSS != null && presc.claSS === "complaint" ?

                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'><b>{presc.claSS}:</b> {presc.notes}</label>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow'>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'>{presc.complaint}</label><br></br>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                  </span>
                                </Card>

                              </div>
                            </>
                            : ''}

                          {presc.notes && presc.notes != null && presc.groupname === DISCHARGESUMMARY ?
                            <>
                              {(print || dischargePrint) &&
                                <div className="checkbox" >
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={dischargePrint ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap pt-2 presc-notes" key={presc.id} >
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>
                                        <DropdownItem className="drop-down-item" onClick={(event) => takePrint(event, presc)}>
                                          Print
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow' onClick={(event) => dischargemodal(presc, event)}>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'><b>{presc.claSS}:</b> {presc.notes}</label>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                  </span>
                                  <span>

                                  </span>
                                </Card>
                                <div>
                                </div>
                              </div>
                            </>


                            : ''}

                          {presc.uploads.imgurl ?
                            <>
                              {print &&
                                <div className="checkbox" style={{ visibility: 'hidden' }}>
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >
                                {/* <div className='patient-lastvisit my-2' id={getFormattedDate(presc.cdt)}>
                              <span className='patient-lastvisit-date'>{getFormattedDate(presc.cdt)}</span>   
                          </div> */}
                                <Card small className="mb-0 h-100 note-card flex-wrap pt-4 upload-img position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <div class='markForDelete'>
                                    <img src={GET_UPLOAD_PATH + presc.uploads.imgurl} className='pres-img' style={{ cursor: `pointer` }}
                                      onClick={(event) => showImage(index)}></img>

                                  </div>
                                  <span>
                                    <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                  </span>
                                </Card>

                              </div>
                            </>

                            : ''}
                          {presc.categoryServiceId && presc.groupname != "Consultant" ?
                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow' onClick={(event) => serviceListModal(presc, event)}>
                                    <div className='pt-1'>
                                      <label className="mb-0 mt-2">{presc.notes}</label><br></br>
                                      {/* <label className="mb-0 mt-2">{presc.packageService.description}</label><br></br> */}

                                      <span style={{ fontWeight: 400 }}> {presc.packageService.billAmount > 0 ? 'Rs. ' + presc.packageService.billAmount + ' per unit' : 'No charges Applied'}
                                        {presc.packageService.quantity > 1 ? ', ' + presc.packageService.quantity + ' units' : ''}
                                      </span>
                                      <br></br>

                                      <div style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</div>
                                    </div>
                                  </span>

                                </Card>

                              </div>
                            </>

                            : ''}
                          {presc.templateId == DFollowupNote ?
                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow' onClick={(event) => zoomReviewModal(presc, event)}>
                                    <div className='pt-1'>
                                      <label className="mb-0 mt-2"><b>Doctor's Followup : </b>{presc.notes}</label><br></br>
                                      {/* <label className="mb-0 mt-2">{presc.packageService.description}</label><br></br> */}

                                      {/* <span style={{ fontWeight: 400 }}> {presc.packageService.billAmount > 0 ? 'Rs. ' + presc.packageService.billAmount + ' per unit' : 'No charges Applied'}
                                        {presc.packageService.quantity > 1 ? ', ' + presc.packageService.quantity + ' units' : ''}
                                      </span> */}
                                      {/* <br></br> */}

                                      <div style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</div>
                                    </div>
                                  </span>

                                </Card>

                              </div>
                            </>

                            : ''}
                          {presc.templateId == CFollowupNote ?
                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow' onClick={(event) => zoomReviewModal(presc, event)}>
                                    <div className='pt-1'>
                                      <label className="mb-0 mt-2"><b>Counsellor's Followup : </b>{presc.notes}</label><br></br>
                                      {/* <label className="mb-0 mt-2">{presc.packageService.description}</label><br></br> */}

                                      {/* <span style={{ fontWeight: 400 }}> {presc.packageService.billAmount > 0 ? 'Rs. ' + presc.packageService.billAmount + ' per unit' : 'No charges Applied'}
                                          {presc.packageService.quantity > 1 ? ', ' + presc.packageService.quantity + ' units' : ''}
                                        </span> */}
                                      {/* <br></br> */}

                                      <div style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</div>
                                    </div>
                                  </span>

                                </Card>

                              </div>
                            </>

                            : ''}

                          {presc.claSS != null && presc.claSS === "complaint" ?

                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow'>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'>{presc.complaint}</label><br></br>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                  </span>
                                </Card>

                              </div>
                            </>
                            : ''}


                          {presc.claSS != null && presc.claSS === "AssignDoctor" ?

                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow'>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'>{presc.shortdesc}</label><br></br>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                  </span>

                                </Card>

                              </div>
                            </>
                            : ''}

                          {presc.groupname != null && presc.groupname === "Appointment" ?

                            <>
                              {print &&
                                <div className="checkbox">
                                  <input type="checkbox" id={presc.id} onChange={(event) => selectForPrint(event.target.checked, presc)}></input>
                                </div>
                              }
                              <div className={print ? 'set-width' : ''} >

                                <Card small className="mb-0 h-100 note-card flex-wrap service-msg position-relative">
                                  <span class='expand'>
                                    <Dropdown
                                      open={presc.action}
                                      toggle={() => toggle(index, presc)}
                                      dropup
                                    >
                                      <DropdownToggle theme="none">
                                        <span class='material-icons expand'>expand_more</span>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem className="drop-down-item" onClick={(event) => confirmDelete(true, event, presc)}>
                                          Delete
                                        </DropdownItem>

                                      </DropdownMenu>
                                    </Dropdown>
                                  </span>
                                  <span className='bubble-arrow'>
                                    <div className='pt-2'>
                                      <label className='mb-0 mt-2'>{presc.shortdesc}</label><br></br>
                                      <span style={{ float: "right", fontSize: '12px', fontWeight: 400 }} className=''>{formatUdt(presc.cdt)}</span>
                                    </div>
                                  </span>
                                </Card>

                              </div>
                            </>
                            : ''}

                        </CardBody>

                      </div>
                    </Col>


                    <Modal
                      open={openDeleteModal}
                      toggle={() => handleopenDeleteModal()}
                    >
                      <ModalHeader>Delete</ModalHeader>
                      <ModalBody>This cannot be undone. Are you sure you want to delete?</ModalBody>
                      <ModalFooter style={{ justifyContent: `space-between` }}>
                        <Button onClick={handleopenDeleteModal}>Cancel</Button>
                        <Button
                          onClick={(event) => markForDel(presc, event)}
                        >
                          Delete
                        </Button>
                      </ModalFooter>
                    </Modal>

                  </>
                )}
              </div>


            </>
          ))}

        </div>

      </div>



      <div className='pres-footer '>
        {(print || dischargePrint) ?
          <div className='list-layout-footer print-footer'>
            <div className="main-content-container container-fluid p-3 d-flex justify-content-between align-items-center">
              <div className='d-flex'>
                <button onClick={cancelPrint}>
                  <span className='material-icons'>close</span>
                </button>
                <span className='ml-3'>{selecetdCount} Selected</span>
              </div>

              <div className='mr-3'>
                <button onClick={() => openPdfModal(true)}>
                  <span className='material-icons'>picture_as_pdf</span>
                </button>
              </div>

            </div>
          </div>
          :

          <div className={props.basic ? "pres-layout-basic-footer" : "list-layout-footer"}>
            <div className="main-content-container container-fluid">
              <div className="row">
                <div className="mb-12 col-sm-12 col-md-12 hpcol bg menu-head">
                  <div className="blog-comments__item d-flex cpointer  p-2 menu-content">
                    <div style={{ display: `flex` }} className="px-3">
                      <div className="blog-comments__content">
                        <div className="blog-comments__meta text-mutes">

                          <div aria-disabled="false" role="button" tabindex="0" className='attach-btn' data-tab="10" title="Attach" aria-label="Attach" onClick={(event) => showAllOptions()}>
                            <span data-testid="clip" data-icon="clip" >
                              <svg viewBox="0 0 24 24" width="24" height="24">
                                <path fill="#54656f" d="M1.816 15.556v.002c0 1.502.584 2.912 1.646 3.972s2.472 1.647 3.974 1.647a5.58 5.58 0 0 0 3.972-1.645l9.547-9.548c.769-.768 1.147-1.767 1.058-2.817-.079-.968-.548-1.927-1.319-2.698-1.594-1.592-4.068-1.711-5.517-.262l-7.916 7.915c-.881.881-.792 2.25.214 3.261.959.958 2.423 1.053 3.263.215l5.511-5.512c.28-.28.267-.722.053-.936l-.244-.244c-.191-.191-.567-.349-.957.04l-5.506 5.506c-.18.18-.635.127-.976-.214-.098-.097-.576-.613-.213-.973l7.915-7.917c.818-.817 2.267-.699 3.23.262.5.501.802 1.1.849 1.685.051.573-.156 1.111-.589 1.543l-9.547 9.549a3.97 3.97 0 0 1-2.829 1.171 3.975 3.975 0 0 1-2.83-1.173 3.973 3.973 0 0 1-1.172-2.828c0-1.071.415-2.076 1.172-2.83l7.209-7.211c.157-.157.264-.579.028-.814L11.5 4.36a.572.572 0 0 0-.834.018l-7.205 7.207a5.577 5.577 0 0 0-1.645 3.971z"></path>
                              </svg>
                            </span>
                          </div>

                        </div>
                        {showOptions ?

                          <Components.AttachOptions
                            data={patientProcedureList}
                            selectedPatient={props.selectedPatientInfo}
                            patientDetail={patientProcedureList}
                            person={docDetail}
                            closeAttachAction={(event) => setcloseAttachAction(event)}
                            closeAllOptions={(event) => showAllOptions(event)} />
                          : ''}
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        }

      </div>
      {showModal ?
        <Components.PrescriptionLayoutModel
          data={data}
          uploadedImages={patientProcedureList.uploadslist}

          onModalClose={(event) => closeModal(event)} />
        : ''
      }
      {/* {showPatientBasic ?
                  
        <div className='patient-desc'>
         <>
          <Components.PatientEditForm
            selectedPatient = {selectedPatient}
            onPatientBasic={(event) => openPatientBasic(event)}/>
         </>
                  {/* // </Col> 
          </div>
          :''}  */}
      {/* {showReviewModel ?
          <div className='modalmn' id="meeting-notes">

          <Components.ZoomMeetingReviewModel
          selectedPatient = {patientProcedureList}
          procedureNotes = {procedureNotes}
          delete={(event) => deleteNotes(event)} 
          onReviewModalClose={(event) => zoomReviewModalClose(event)}/>
           </div>
        :''} */}
      {showDischargeSummary ?
        <div className='position-fixed discrgsumm-container expanded-views' id="discharge-summary">
          <Components.Discharge
            selectedPatient={props.selectedPatientInfo}
            dischargesummary={dischargesummary}
            mode="edit"
            onModalClose={(event) => closeDischargeSummaryModal(event)} />
        </div>

        : ''}
      {showComposition == true && (
        <div className='position-fixed template-container expanded-views' id="template-preview">
          <Components.TemplatePreview
            fileUploadcontent={pData[0]}
            selectedProcedure={selectedProcedure}
            mode={flag}
            templateId={templateId}
            ontemplatePreviewModalClose={(event) => closeCompositionModal(event)} />
        </div>

      )}
      {/* {dischargepdfscreen ? (
        <div className="">
          <Components.DischargePrint
            doctorInfo={doctorInformation}
            prescriptionList={proceduresToPrint}
            dischargeSummary ={dischargeSummary}
            selectedPatient={selectedPatientInfo}
            onModalClose={(event) => closePdfModal(event)}
          />
        </div>
      ) : (
        ""
      )} */}

      {showpdfscreen && labReportPrintStatus === false ? (
        <div className="">
          <Components.pdfprint
            doctorInfo={doctorInformation}
            prescriptionList={proceduresToPrint}
            presc={eprescription}
            ehrid={patientEhrid}
            // localMedicineName={medicineName}
            selectedPatient={props.selectedPatientInfo}
          //onModalClose={(event) => closePdfModal(event)}
          />
        </div>
      ) : (
        ""
      )}

      {showpdfscreen && labReportPrintStatus === true ? (
        <div className="">
          <Components.LabReportPrint
            doctorInfo={doctorInformation}
            prescriptionList={proceduresToPrint}
            presc={eprescription}
            ehrid={patientEhrid}
            // localMedicineName={medicineName}
            selectedPatient={props.selectedPatientInfo}
          //onModalClose={(event) => closePdfModal(event)}
          />
        </div>
      ) : (
        ""
      )}

      {showService ? (
        <div className='position-fixed packservice-container expanded-views' id="packgservice">
          <Components.PackageService
            patientData={pData[0]}
            mode="edit"
            serviceDetails={serviceDetails}
            onModalClose={(event) => closeServiceModal(event)} />
        </div>
      ) : (
        ""
      )}

      {caseRecordModal ? (
        <div className='position-fixed packservice-container expanded-views' id="packgservice">
          <Components.Notes
            patientData={patientProcedureList}
            procedureNotes={procedureNotes}
            person={docDetail}
            onModalClose={(event) => closeNotesModal(event)}
          />
        </div>
      ) : (
        ""
      )}
      {showConsutant ? (
        <div className='position-fixed consultant-container expanded-views' id="add-consultant">
          <Components.AddConsultant
            patientData={pData[0]}
            details={consultantDetails}
            mode="edit"
            onModalClose={(event) => CloseAddConsultantModal(event)} />
        </div>
      ) : (
        ""
      )}
    </>
  );
}


export default ProcedureListing;

