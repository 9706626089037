import React,{useState, useEffect} from 'react'
import { useBarcode } from 'react-barcodes';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import { helperServices } from "../../shared/helper";
import {Button,Form,Row,Col,ListGroup,ListGroupItem,FormInput, Card,CardBody} from "shards-react";
pdfMake.vfs = pdfFonts.pdfMake.vfs;


function Barcode (props){
    const [patientDetail,setPatientDetails] = useState([])
    const [visitId,setVisitId] = useState([])
    
    // Setting Patient Id to strOne,strTwo,strThree for generating barcode
    const [strOne, setBarCodeOne] = useState('Barcode')
    const [strTwo, setBarCodeTwo] = useState('Barcode')
    const [strThree, setBarCodeThree] = useState('Barcode')
    const [viewBarcode, setviewBarcode] = useState(false)
    const str  = props.patientInfo.fname + props.patientInfo.lname;
    const strlen = str.toString().slice(0,8);
    
    useEffect(() => {
        console.log("Barcode Props", props)
        console.log("patientDetail in Barcode", patientDetail)
        if (props.patientInfo) {
            setPatientDetails(props.patientInfo.patientId)
            setBarCodeOne(strlen + + props.patientInfo.age)
            
            
            // if(props.patient.visitInfo){
            //     props.patient.visitInfo.map((visit,index)=>{
            //         setBarCodeTwo(visit.id)
            //         visit.procedures.map((procedure,idx)=>{
            //             setBarCodeThree(procedure.id)
            //         })
            //     })
            // } 
        }
        setBarCodeTwo(props.patientInfo.age)
        //setBarCodeThree(props.patientInfo.procedureId)
    }, [props])
    
    console.log("#Barcode props",patientDetail,strOne,strTwo,strThree)

    const { inputRef } = useBarcode({
        value: strOne ,
        options: {
          displayValue: true,
          background: '#F5F6F7',
        }
    });

    const downloadBarcode = (event) => {
        event.preventDefault()
        const canvas = document.getElementById("mybarcode");
        console.log("canvas",canvas);
        const dataURI = canvas.toDataURL()
        // console.log(dataURI)
        var arrayURI = []
        for (var i = 0; i < 10; i++) {
        // arrayURI.push({image:dataURI,width:250,height:50,marginBottom:10})  
            arrayURI.push({columns: [
                {image:dataURI,width:250,height:50,marginBottom:10},
                {image:dataURI,width:250,height:50,marginBottom:10}
            ]})
        }
        console.log("URI",arrayURI)
        var docDefinition = {
            pageSize :'A4',
            pageMargins: [40,55,40,55],
            pageOrientation: 'portrait',
            defaultStyle : {
                fontSize  : 12,
                columnGap : 20
            },
            content:[arrayURI],
        }
       
         pdfMake.createPdf(docDefinition).open()
       // pdfMake.createPdf(docDefinition).download()
    };

    const toggleBarcode = () => {
       // setviewBarcode(!viewBarcode);
      };

    return(

        <main className="">
        <h5 className="">BarCode
        {(!helperServices.CheckAccessLevel("InsurancePatients")) ?
        <span className="ml-3" onClick={toggleBarcode} style={{cursor:'pointer'}} >
            <i className="material-icons" title={viewBarcode ? 'Edit' : 'View'}>{viewBarcode ? 'edit' : 'visibility'}</i>
            </span>
        :''}
        </h5>
        { viewBarcode ? 
        <div>
            <Card className="border">
                <CardBody className="p-3">
                    
                </CardBody>
            </Card>
        </div>
        :
        
        <ListGroup flush className="mb-2 card">
            <ListGroupItem className="p-3 bg-none">
                {strOne!== '' && strTwo !== '' && strThree !== '' ? 
                    <Row>
                        <Col>
                            <Form className="px-4 py-3">
                                <Row form >
                                    <Col md="6" className="form-group">
                                        <label htmlFor="#patientid">Patient Id</label>
                                        <FormInput placeholder="Patient Id" value={props && props.patientInfo ? props.patientInfo.id : ''} readOnly/>
                                    </Col>
                                    <Col md="6" className="form-group">
                                        <label htmlFor="#patientname">Patient Name</label>
                                        <FormInput 
                                            placeholder="Patient Name" 
                                            value={props && props.patientInfo ? 
                                                props.patientInfo.fname + " " + props.patientInfo.mname + " " + props.patientInfo.lname
                                            :''} 
                                            readOnly
                                        />
                                    </Col>
                                </Row>
                                <Row form>
                                    <Col md="12" className="mb-2 text-center">
                                        <canvas id="mybarcode" ref={inputRef}/>
                                    </Col>
                                </Row>
          
                            </Form>
                            
                        </Col>
                    </Row>
                : <p>No barcode preview</p>}
            </ListGroupItem>
        </ListGroup>
        }
        </main>
    )
}

// function mapStateToProps(state) {
//     return {
//         patient:state.patientDetailsState.patient,
//         visitId:state.patientDetailsState.visitId,
//         procedureId:state.patientDetailsState.procedureId

//     };
// }
  
// function mapDispatchToProps(dispatch) {
//     return {
        
//     };
// } 
// const BarcodeWithProps = connect(mapStateToProps, mapDispatchToProps)(Barcode);
  
// const BarcodeWithRouter = withRouter(BarcodeWithProps);
  
export default Barcode;
