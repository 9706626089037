import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import doctorActions from "../../../core/actions/doctorActions";
import Components from "../../doctor/index";
import { patientActions } from '../../../core/actions';

import {
  ListGroup,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from "shards-react";

function StaffListGrid(props) {
    let ids;
    const history = useHistory();
    const [pagination, setPagination] = useState(true);
    const [paginationPageSize, setPaginationPageSize] = useState(10);
    const [patientVisit, setPatientVisit] = useState();
    const [showPatientVisitModal, setshowPatientVisitModal] = useState(false);
    const [showPatientRevisitModal, setshowPatientRevisitModal] = useState(false);
    const [selectPatientData, setselectPatientData] = useState();
    const [selectedPatientRowData, setSelectedDoctorRowData] = useState();
    const [ServerRes, setServerRes] = useState({ success: "", error: "" });
    const [selectedPatientSummary, setSummary] = useState();
    const [showPatientSection, setShowPatientSection] = useState(false);
    const [staffForm, setStaffData] = useState(false);
    const [staffRowData, setstaffRowData] = useState({});
  
    const [confirm, setConfirm] = useState(false);
    const [onmodal, setOnModal] = useState(true);


    const [summary, setSummaryData] = useState({
        preparedBy: "",
        approver: "",
        patientId: "",
        visitId: "",
        notes: "",
        groupname: "",
        notes: "",
        subgroup: "",
        type: "",
      });
      const dispatch = useDispatch();
    
      const [staffList, setstaffList] = useState({
        staff: "",
      });
    
     
    
      const [columnDefs] = useState([
        // {
        //   headerName: "Display Title ",
        //   field: "displaytitle",
        //   sortable: true,
        //   filter: true,
        //   flex: 1,
        //   resizable: true,
        //   floatingFilter: true,
        // },
        {
          headerName: "Name",
          field: "first_name",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
        },
        //   {
        //   headerName: "Department",
        //   field: "department",
        //   sortable: true,
        //   filter: true,
        //   flex: 1,
        //   resizable: true,
        //   floatingFilter: true,
        //   onGridReady: "open",
        // },
        {
          headerName: "Type",
          field: "type",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
          onGridReady: "open",
        },
        {
          headerName: "Contact",
          field: "phoneno",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
        },
        {
          headerName: "Email",
          field: "email",
          sortable: true,
          filter: true,
          flex: 1,
          resizable: true,
          floatingFilter: true,
        },
        // {
        //   headerName: "SalaryType",
        //   field: "salarytype",
        //   sortable: true,
        //   filter: true,
        //   flex: 1,
        //   resizable: true,
        //   floatingFilter: true,
        //   onGridReady: "open",
        // },
        { headerName:"Actions",field: "Actions", sortable: true, filter: true, flex: 1,
        resizable: true, width: 150, cellRenderer: "BtnCellRenderer" },
       
      ]);
    
      const [AddPatientmodel, setaddPatientModel] = useState(false);
    
      const patientVisitClick = () => {
        setshowPatientVisitModal(true);
      };
    
      const patientCloseClick = () => {
        setshowPatientRevisitModal(true);
      };
    
      const Addpatient = () => {
        setaddPatientModel(true);
      };
    
      const closeModal = (event) => {
        setStaffData(!staffForm);
    
      };
       const closeAlert = (e) => {
        setServerRes({ ..."" });
      }
    
      const handleRendererCall = (props) => {
        console.log("props", props.visitsList);
        if (props.visitsList.typeFlag == "patientClose") {
          setselectPatientData(props.visitsList);
    
          patientCloseClick(props.visitsList);
        } else {
          setselectPatientData(props.visitsList);
          patientVisitClick(props.visitsList);
        }
        console.log("patient data", selectPatientData, props.visitsList);
      };
    
      const getFormattedDate = (date) => {
        const reqdate = new Date(date);
        let formatteddate = format(reqdate, "MMM dd yyyy' 'HH:mm");
        return formatteddate;
      };
    
      const getFormatedReqtDate = (date) => {
        const reqtdate = new Date(date);
        // const apphours=new Date(date);
        //  const appminites= new Date(date);
        let formatedreqtdate = format(reqtdate, "MMM dd yyyy");
        //  let formatedappdate= format(appdate, "MMM dd yyyy",apphours,"HH",appminites,"MM" );
        return formatedreqtdate;
      };
      const getFormatedApptDate = (date) => {
        const apptdate = new Date(date);
        let formatedapptdate = format(apptdate, "MMM dd yyyy");
        return formatedapptdate;
      };
    
      const visitsList = useSelector(
        (state) => state.patientDetailsState.visitsList
      );
      console.log("visitsList", visitsList);
    
      const stafflist = () => {
        
        dispatch(
          doctorActions.getStaffList( (resCb) => {
            if (resCb) {
              const filteredList = resCb.accresp.personList.filter(person => (
                person.type != "Doctor" || person.type !="Counsellor"));
              setstaffList(resCb);
              staffList.staff = resCb;
              console.log("list staff Response:", staffList);
            }
          })
        );
      };
    
      const getAggriddata = (e) => {
    
      console.log("getAggriddata",e.data);
        console.log("ACTIONS:", props);
    
        //   setDoctorData(!doctorForm)
    
        if(e.colDef.field=="Actions"){
          setConfirm(!confirm);
          console.log("delete rowdata:",e.data);
            setTimeout(() => {
              setstaffRowData(e.data);
        }, 1000);
        }else{
        history.push({
          pathname: '/addstaff',
          rowData:e.data,
          label:"edit"
         }); 
        } 
      };
    
      useEffect(() => {
        console.log("stafflistgrid use effect")
        dispatch(patientActions.setPageHeader("staff"));
    
        let data = {};
        console.log("props are:", props.staffSearchList);
        const filteredList = props.staffSearchList.filter(person => (
        person.type != "doctor" || person.type !="counsellor"));
        let dataArray = [];
         if (props && props.staffSearchList && props.staffSearchList.length > 0) {
           let pslist = props.staffSearchList;
           for (let item of pslist) {
            if(item.type == "Doctor"){
              // dataArray.push(item); 
            } 
            else{
              dataArray.push(item);
            }
         }
         console.log("dataArray::", dataArray);
          console.log("filteredList::", filteredList);
          setPatientVisit(filteredList);
        }
      }, [props]);
    
    
      const rowStyle = { background: "white" };
    
    
      const handleSubmit = (event) => {
        console.log("event", event);
        dispatch(
        doctorActions.deletedoctor(staffRowData, (resCb) => {
          if (resCb) {
            console.log("deletestaff", resCb);
            
            props.onGridAction();
            setConfirm(!confirm);
             var ServerResVal=ServerRes;
           ServerResVal.success = "Staff deleted Successfully";
          setServerRes({...ServerResVal});
          setTimeout(() => {
           closeAlert();
          }, 3000);
    
          }
        }));
      };
      const toggle = (event) => {
        setConfirm(!confirm);
      };
  
  






















 return (
    <>
    {ServerRes.success && (
                      <Alert theme="success">{ServerRes.success}</Alert>
                    )}
                    {ServerRes.error && (
                      <Alert theme="danger">{ServerRes.error}</Alert>
                    )}
      {/* <h5 className="page-title my-2 mx-3">Doctors</h5> */}
      <div>
        <div className="p-0 preview">
          <div className="ag-theme-balham ag-style card rounded table mb-0 tcol table-head"style={{fontSize:'14px', height: 500, width: `98%` }}>
            <AgGridReact
              frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
            //   context={context}
              rowData={patientVisit}
              columnDefs={columnDefs}
              // onRowClicked={(e) => getAggriddata(e)}
              rowStyle={rowStyle}
            //   getRowStyle={getRowStyle}
            onCellClicked={(e) => {
              console.log("event onCellclicked::",e);
              const field = e.colDef.field;
              const colIndex = e.columnApi
                .getAllColumns()
                ?.findIndex((col) => col.getColDef().field === field);
          
              console.log("cell clicked ",field, colIndex);
              getAggriddata(e)
            }}
            ></AgGridReact>
           
          </div>
          {/* {doctorForm && (
      <div >
      <Components.addDoctor onModalClose={(event) => closeModal(event)} doctorData={doctorRowData}/>
      </div>)} */}

{confirm && (
      <div >
      <Modal
        className="d-flex w-95 h-90 flex-column-reverse "
        id="doctor-modal"
        open={onmodal}
      >
        <div>
          <ModalHeader>
            Confirm Operation
            <span className="close-model" onClick={(event) => toggle(event)}>
              X
            </span>
          </ModalHeader>
          <ModalBody>
            <div className="confirmationheader">
              <h6>This operation cannot be undo! Do you want to continue? </h6>
            </div>
              {/* <h6>Do you want to continue?</h6> */}
            <main className="mainsection">
              <div className="buttonsetconfirm">

                <Button className='btn-danger'type="submit" onClick={(event) => toggle(event)}>
                  Cancel
                </Button>
                <Button type="submit" onClick={(event) => handleSubmit(event)}>
                  Delete
                </Button>
              </div>
       
         
            </main>
          </ModalBody>
        </div>
      </Modal>

      </div>)}
         
        </div>
      </div>
    </>
  );
}
export default StaffListGrid;
