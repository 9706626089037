import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from 'moment';
import BtnCellRenderer from "../common/BtnCellRenderer";
import { doctorActions, patientActions } from "../../core/actions";
import Select, { createFilter } from "react-select";
import Components from "./index";
import { format } from "date-fns";
import { DatePicker } from "shards-react";
import { DateTimePicker } from "react-datetime-picker";
import ".";
import {
  ListGroup,
  ListGroupItem,
  Row,
  FormTextarea,
  Col,
  Form,
  FormInput,
  Alert,
  Button,
  Card,
  CardBody,
  ModalBody,
  Modal,
  ModalHeader,
  ModalFooter
} from "shards-react";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import * as ReactDOM from "react-dom";
import { filter } from "lodash";
import { addService } from "../../core/actions/categoryActions";
import { Checkbox } from "@mui/material";
import {PRODUCT_HMS_LOCATION} from "../../shared/constants";
// import SearchPackageServiceList from './';
var showReqAppoinment = "";

function PackageService(props) {
  const dispatch = useDispatch();
  const [packageServiceSelected, setPackageServiceSelected] = useState();
  const [serviceList, setServiceList] = useState();
  const [packageList, setPackageList] = useState();
  const [showForm, setShowForm] = useState(false);
  const [isPackage, setIsPackage] = useState(false);
  const patientCustId = useSelector(
    (state) => state.patientDetailsState.patient
  );
  const patientBasic = useSelector(
    (state) => state.patientDetailsState.patientBasic
  );
  const [voucherList, setVoucherList] = useState([]);
  const [voucherFlag, setVoucherFlag] = useState(false);
  const [defaultDoctor, setDefaultDoctor] = useState();
  const [onmodal, setOnModal] = useState(false);
  const [packageService, setService] = useState([
    {
      productId: "",
      packageid: "",
      packagetype: "",
      description: "",
      amount: "",
      billable: true,
      doctorId: "",
      isDoctor: "",
    },
  ]);

  const [diplayServices, setDisplayServices] = useState();
  const [displayProducts, setDisplayProducts] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const [showVoucherModal, setShowVoucherModal] = useState(false);
  const [procedure, setProcedure] = useState();
  const [isReadonly, setIsReadonly] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');
  const [deptId, setDeptId] = useState("");
  const [options, setOption] = useState("");
  const [search, SetSearch] = useState();
  const [validForm, setValidForm] = useState(true);
  const [redeemedVoucher, setRedeemedVoucher] = useState([]);
  const [voucherData, setVoucherData] = useState([]);
  const [selectedVoucher, setSelelectedVoucher]=([]);
  const [index, setIndex] = useState();
  const [flag, setFlag] = useState(false);
  const [formErrors, setFormErrors] = useState({
    name: "",
    packagetype: "",
    description: "",
    amount: "",
    productId:"",
  });

  

  const validateField = (fieldName, value) => {
    let fieldValidationErrors = formErrors;
    let basicInfo;
    for(let i=0; i<packageService.length; i++){
       basicInfo =packageService[i];
    }
    
    
    console.log("in validate field method: ", fieldName, value)

    // var pattern = new RegExp(/^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/)
    // var currentDate = new Date().getDate;
    switch (fieldName) {
      case "name":
        console.log("name check");
        fieldValidationErrors.name =
          value !== "" ? "" : "Please Enter Service Name";
        basicInfo.name = value !== "" ? value : "";
        // setValidForm(false);
        break;
      case "productId":
        console.log("name check");
        
        // fieldValidationErrors.productId = value !== "" ? "" : "Please Enter Product Name";
        fieldValidationErrors.productId = basicInfo.productName ? "" :"Please Enter Valid Product Name";
        basicInfo.productId = value !== "" ? value : "";
         //setValidForm(false);
        break;
      
      case "packagetype":
        console.log("name check");
        fieldValidationErrors.packagetype =
          value !== "" ? "" : "Please Enter Package Type";
        basicInfo.packagetype = value !== "" ? value : "";
         //setValidForm(false);
        break;
      case "description":
        console.log("name check");
        fieldValidationErrors.description =
          value !== "" ? "" : "Please Enter Description";
        basicInfo.description = value !== "" ? value : "";
        //setValidForm(false);
        break;
      case "amount":
        console.log("name check");
        fieldValidationErrors.amount =
          value !== "" ? "" : "Please Enter Amount";
        basicInfo.amount = value !== "" ? value : "";
       //setValidForm(false);
        break;
      default:
        break;
    }
    setFormErrors({ ...fieldValidationErrors });
  };

  const handleServices = (service) => {
    console.log("service selected :", service);
  };
  let proc = {};
  
  useEffect(() => {
    dispatch(
      patientActions.getRedeemedVouchers(patientCustId.erpCustId, (resCb) => {
        if (resCb) {
          console.log("redemmedvouchers", resCb);
          setRedeemedVoucher(resCb.voucherList);
          //setSelectedOption(resCb.voucherList[0])
          console.log("setSelectedOption",selectedOption)
        }
      })
    );
    getVouchersList();
    setService([
      {
        productId: "",
        packageid: "",
        packagetype: "",
        description: "",
        amount: "",
        billable: true,
        doctorId: "",
        isDoctor: "",
      },
    ]);
    let data = {};
    console.log("props in service", props);
    console.log("props :", props);
    //console.log("props  mode in click", props.mode);
    console.log("count");
    if (
      props &&
      props.patientData &&
      props.patientData.id &&
      props.patientData.visit &&
      props.patientData.visit.id
    ) {
      proc["patientId"] = props.patientData.id;
      proc["visitId"] = props.patientData.visit.id;
      if(props.patientData.visit.type){
      proc["visitType"] = props.patientData.visit.type;
      }
      setProcedure({ ...proc });
      setTimeout(() => {
        console.log("inside if proc :", procedure);
      }, 100);
    }

    if (props.mode === "edit") {
      setService([{ ...props.serviceDetails.packageService }]);
      setShowForm(true);
      setIsReadonly(true);
     
    } else {
      setDefaultDoctor(props.patientData.gvrole.gvId)
      console.log("defaultdoctorid: ", props.patientData.gvrole.gvId)
      console.log("procedure :", procedure, proc);
      dispatch(
        doctorActions.getServiceList(data, (resCb) => {
          if (resCb) {
            console.log("resCb doctor Response:", resCb);

            if (
              resCb &&
              resCb.packageServiceList &&
              resCb.packageServiceList.length > 0
            ) {
              const updatedService = resCb.packageServiceList.map((item) => {
                return { ...item, isPackage: false };
              });
              setServiceList(updatedService);
              // setServiceList(resCb.packageServiceList)
              const updatedPackage = resCb.packageList.map((item) => {
                return { ...item, isPackage: true };
              });
              console.log("updated package is", updatedPackage);
              setPackageList(updatedPackage);
              //setPackageList(resCb.packageList)
              setPackageServiceSelected(resCb.setPackageServiceSelected);
              // setDisplayServices(resCb.packageServiceList)
            }

            console.log("list serviceList Response:", serviceList);
            // setOption(deptOptions)
            // },400)
          }
        })
      );
      console.log("procedure :", procedure, proc);
    }
    getDoctorList();
    
    //getVouchersList();
    
  }, [props]);

  //for search filter
  const handleChange = (selectedOption) => {
    console.log("event", selectedOption);
    setSelectedOption(selectedOption);
    if (selectedOption != null) {
      props.parentCallBack(selectedOption);
      // this.props.parentCallBack({selectedBatch : selectedOption})
    }
  };

  const ModalOpen = () => {
    console.log("move to bill method:");
    setOnModal(true);    
  };

  const toggle = (event) => {
    setOnModal(false);
  };

  


  const handleSubmit = (e) => {
    console.log("PATIENTS_INFO",patientBasic,props.patientData.id,packageService);
    if (
      packageService[0].billable === true &&
      patientBasic.id === props.patientData.id &&
      (
        patientBasic.visit.internalStatus === "MOVED_TO_BILLING" ||
        patientBasic.visit.internalStatus === "INVOICE_GENERATED" ||
        patientBasic.visit.internalStatus === "BILL_CLEARED"
      )
      ) {
      // Return true or perform some action
      ModalOpen();
    } else {
      // Return false or perform another action for the false condition
      console.log("PATIENTS_INFO",patientBasic);
    e.preventDefault();
    console.log("submit:", e);
    console.log("packageService in submit method:", packageService[0]);
    for(let i=0; i<packageService.length; i++){
      Object.keys(packageService[i]).forEach((key) => {
     console.log("key", key);
      console.log("package key", packageService[i]);
      validateField(key, packageService[i][key]);
    });
    }
    // Object.keys(packageService).forEach((key) => {
    //  console.log("key", key);
    //   console.log("package key", packageService[key]);
    //   validateField(key, packageService[key]);
    // });
    // if(formErrors.name && formErrors.name.length === 0){
    //   setValidForm(false);
    // }
    validateForm(e);
    }
  };

  //  console.log("packageService if :",packageService);
  //if(!formValid){
  const validateForm = (e) => {
    let formValid =
      formErrors.name === "" &&
      formErrors.amount === "" &&
      formErrors.packagetype === "" &&
      formErrors.productId=== "" &&
      formErrors.description === "";
    e.preventDefault();
    if (formValid) {
      console.log("packageservice in update case:", packageService);
      if (
        packageService &&
        packageService[0].state &&
        packageService[0].state === "update"
      ) {
        console.log("packageservice in update case:", packageService);
        if (packageService.quantity == 0) {
          packageService.quantity = 1;
        }
        console.log("package service in save case", voucherData, index);
       
        if (voucherList == null || voucherList.length === 0) {
          // voucherList is either null or an empty array
          setVoucherFlag(false)
          console.log("package service in save case", voucherFlag);
        } else  if (voucherData.length == 0 && index == undefined) {
          setVoucherFlag(true);
          console.log("package service in save case", voucherFlag);
          return;
        }
        else {
          handleRedeemClick(voucherData, index);
        }
        dispatch(
          doctorActions.updateService(packageService, (resCb) => {
            if (resCb) {
              // setStatus({ type: 'success' });
              // props.onModalClose()
              let serviceResp = resCb.packageService;
              let procedure = {};
              procedure["packageService"] = [{ ...resCb.packageServiceList }];
              // procedure["packageService"] = {...resCb.packageService};
              console.log("procedure to updarte:", procedure);
              addServiceProcedure(packageService, null);
            }
          })
        );
      } else {
        if (packageService.quantity == 0) {
          packageService.quantity = 1;
        }
        console.log("package service in save case", voucherData, index);
        
        if (voucherList == null || voucherList.length === 0) {
          // voucherList is either null or an empty array
          setVoucherFlag(false)
          console.log("package service in save case", voucherFlag);
        } else if (voucherData.length == 0 && index == undefined) {
          setVoucherFlag(true);
          console.log("package service in save case", voucherFlag);
          return;
        }
        else {
          handleRedeemClick(voucherData, index);
        }
        console.log("package service in save case", voucherFlag);
        console.log("package service in save case", packageService);
        dispatch(
          doctorActions.addService(packageService, (resCb) => {
            if (resCb) {
              // setStatus({ type: 'success' });
              // props.onModalClose()
              console.log("response of service add :", resCb);
              //  let serviceResp = resCb.packageService
              let serviceResp = resCb.packageServiceList;

              addServiceProcedure(serviceResp, null);
            }
          })
        );
      }
    }
    // }
  };

  const AddServiceVoucher = () => {
    dispatch(
      doctorActions.addService(packageService, (resCb) => {
        if (resCb) {
          // setStatus({ type: 'success' });
          // props.onModalClose()
          console.log("response of service add :", resCb);
          //  let serviceResp = resCb.packageService
          let serviceResp = resCb.packageServiceList;

          addServiceProcedure(serviceResp, null);
        }
      })
    );
  };

  const CancelModel = (event) => {
    setVoucherFlag(false);
  };

  const closeModal = () => {
	setOnModal(false);
    props.onModalClose();
  };

  const handleInputChange = (name, value, item) => {
    console.log("In handleinput change", name, value, item);
    formErrors[name]="";
    if (name === "search") {
      SetSearch(value);
      console.log("keyword :", value.length);
      if (value.length > 2) {
        searchService(value);
        setShowForm(false);
        setDisplayProducts(null);
      } else {
        setDisplayServices(null);
      }
    } else {
      var pServiceDetail = packageService;
      // pServiceDetail[name] = value;
      console.log("In handleinput change", name, value);
      console.log("item in console:", item);
      console.log("service detail in console:", pServiceDetail);
      let servicelist = [];
      for (let service of pServiceDetail) {
        if (item.id == service.id) {
          service[name] = value;
          console.log("item if condition console:", item);
          servicelist.push(item);
        } else {
          servicelist.push(service);
        }
      }

      item.billAmount = item.amount
      console.log('billamount', item.billAmount)
      setService(servicelist);
      
      console.log("Service: ", packageService);
      // setService([{...pServiceDetail}])
    }
    if (name === "productId") {
      packageService.productName = value;
      console.log('packageService.productName: ', packageService.productName)
     // setService([{ productName:value}]);
      
      console.log("productsearch:", value);
      if (value.length > 2) {
        console.log("searchlength:", value.length);
        // let searchparam = value;
        // console.log("searchparam", searchparam);
        searchProduct(value);
      } else {
        setDisplayProducts(null);
      }
    }
  };

  const searchProduct = (value) => {
    let productsearch = value;
  //  let location = 0;
    console.log("searchedvalue", productsearch);
    dispatch(
      doctorActions.getProductDetails(productsearch, PRODUCT_HMS_LOCATION, (resCb) => {
        if (resCb && resCb.erpProductList) {
          console.log("Response of searched product", resCb.erpProductList);
        

          // setDisplayProducts([...resCb.products])
          setDisplayProducts([...resCb.erpProductList]);

          console.log("display", displayProducts);
        }
        // }
      })
    );
    //  return displayProducts
  };

  const closeServiceModal = (event) => {
    props.onModalClose(event);
  };
  const handleInputCheckbox = (value,item) => {
    console.log("value:", value);
    console.log("item is:", item);
    //packageService.billable = value;
    item.billable = value
    setService([item]);
  //  setService([packageService]);
    document.getElementById("billable").checked = value;
  };
  const isDoctorCheckbox = (value,item) => {
    let defaultdoctorid = defaultDoctor;
    console.log('defaultdoctorid: ', defaultdoctorid)
    // console.log("defaultdoctorid: ", defaultDoctor, item.doctorId)
    // item.doctorId = defaultDoctor;
    // console.log("defaultdoctorid: ", defaultDoctor, item.doctorId)

    console.log("value:", value);
    console.log("item is:", item);
    item.isDoctor = value;
    console.log("isDoctor: ", item.isDoctor)

    if(value == true){
      console.log('enteredifcase')
      item.doctorId = defaultdoctorid; 
    }
    else {
      console.log('enteredelsecase')
      item.doctorId = '';
    }

    setService([item]);
    console.log("packageService is in checkbox:", packageService);
    document.getElementById("isDoctor").checked = value;
    
    // console.log("defaultdoctorid: ", defaultDoctor, item.doctorId)
    // console.log("packageservice", packageService)
    
  };

  //filter method
  const searchService = (data) => {
    console.log("keyword searchService :", data);
    console.log("serviceList :", serviceList);
    console.log('packagelist: ', packageList)

    //   const filteredData = serviceList.filter((item) => {
    //     return Object.values(item.name).join('').toLowerCase().includes(data.toLowerCase())
    // })

    // const filteredData = serviceList.filter((item) => {
    //   return item.name
    // })

    const filteredData = serviceList.concat(
      packageList.map((item) => ({
        name: item.name,
        id: item.id,
        isPackage: item.isPackage,
      }))
    );
    
    let filteredPackageList = [...filteredData];
    const filtered = filteredPackageList.filter(
      (product) =>
        product.name &&
        product.name.toLowerCase().startsWith(data.toLowerCase())
    );
    console.log("filtered: ", filtered);
    //filtered.billAmount = filtered.billAmount
    setDisplayServices(filtered);
  };

  function sort(doctorList) {
    {
      doctorList.map((post, index) => {
        <div key={index}>
          <p>{post}</p>
        </div>;
      });
    }
  }
  const addNewService = (event) => {
    getVouchersList();
    SetSearch("");
    let data = {
      productId: "",
      packageid: "",
      packagetype: "",
      description: "",
      amount: "",
      billable: true,
      name: "",
      quantity: "",
      isDoctor: "",
    };

    setService([{ ...data }]);
    setShowForm(true);
    setShowVoucherModal(false);
    setTimeout(() => {
      if (document.getElementById("billable")) {
        document.getElementById("billable").checked = true;
      }
    }, 100);
  };
  const addServiceProcedure = (service, index) => {
    console.log("service in add procedure method:", service);
    for (let list of service) {
      console.log("list inside for loop is:", list);
      let data = {};
      if (props.mode == "edit") {
        data["patientId"] = props.patientData.patientId;
        data["visitId"] = props.patientData.visitId;
        if(props.patientData.gv && props.patientData.gv.id){
        data["refGvId"] = props.patientData.gv.id;
        }
      } else {
        data["patientId"] = props.patientData.id;
        data["visitId"] = props.patientData.visit.id;
        if(props.patientData.gv && props.patientData.gv.id){
          data["refGvId"] = props.patientData.gv.id;
          }
      }
      data["categoryServiceId"] = list.id;
      data["categoryId"] = list.packageid;
      if(list && list.quantity && list.quantity > 0){
        data["quantity"] = list.quantity;
      }else {
        data["quantity"] = 1;
      }
      data["productId"] = list.productId;
      data["billAmount"] = list.amount;
      data["doctorId"] = list.doctorId;
      data["notes"] = list.packagename ? list.packagename+"-"+list.name : list.name +' - '+ list.description;
      console.log(data)
      dispatch(
        doctorActions.addProcedure(data, (resCb) => {
          if (resCb) {
            console.log("resCb doctor Response:", resCb);

            //(resCb.packageServiceList)
            // setPackageServiceSelected(resCb.setPackageServiceSelected);
            //props.onModalClose();

            if (resCb.successMessages) {
              if (packageService.state === "update") {
                props.onModalClose({
                  success: "Service details updated successfully",
                });
              } else {
                props.onModalClose({
                  success: "Service details added successfully",
                });
              }
            } else {
              props.onModalClose({ error: "Failed to add Service details " });
            }
          }
        })
      );
    }
  };

  const getVouchersList = () => {
    let redeemedVouchers ;
    dispatch(
      patientActions.getRedeemedVouchers(patientCustId.erpCustId, (resCb) => {
        if (resCb) {
          console.log("redemmedvouchers", resCb);
          setRedeemedVoucher(resCb.voucherList);
          redeemedVouchers = resCb.voucherList
          console.log("redemmedvouchers", redeemedVouchers);
        }
      })
    );
    dispatch(
      patientActions.getVouchers(patientCustId.erpCustId, (resCb) => {
        if (resCb) {
          let redeemedVoucher= redeemedVouchers;
          console.log("response from addPatient api called", redeemedVoucher);
          let vouchers = resCb.voucherList;
          const areObjectsEqual = (obj1, obj2) => {
            return obj1.voucherId === obj2.voucherId;
          };
          if (resCb.voucherList) {
            let vouchers = resCb.voucherList;
            if (vouchers && redeemedVoucher && redeemedVoucher.some) {
              let filteredVoucherList = vouchers.filter((obj1) => {
                return !redeemedVoucher.some((obj2) =>
                  areObjectsEqual(obj1, obj2)
                );
              });
              console.log("filteredList1", filteredVoucherList);
              if (filteredVoucherList && filteredVoucherList.length > 0)
                filteredVoucherList.map((v) => {
                  const dateObj = new Date(v.issueDate);
                  const formattedDate = `${dateObj
                    .getDate()
                    .toString()
                    .padStart(2, "0")}-${(dateObj.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")}-${dateObj.getFullYear()}`;
                  console.log("response from addPatient api", formattedDate);
                  v.issueDate = formattedDate;
                  console.log("dhhfkgk", Date(formattedDate));
                });
              console.log(
                "response from addPatient afreapi",
                filteredVoucherList
              );
              setVoucherList([...filteredVoucherList]);
              setFlag(true);
              console.log("vouchers are : ", voucherList);
              //setShowVoucherModal(true);
            }
          } else {
            setVoucherList("");
            
            console.log("vouchers are not present : ", voucherList);
            // setShowVoucherModal(true);
          }

          //setShowForm(true);
        }
      })
    );
  };
  const handleClick = () => {
    //patientCustId.visit.id
    dispatch(patientActions.getRedeemedVouchers(patientCustId.erpCustId, (resCb) => {
      if (resCb) {
      console.log("redemmedvouchers",resCb);
     // setRedeemedVoucher(resCb.voucherList);
      }
    }));
    dispatch(
      patientActions.getVouchers(patientCustId.erpCustId, (resCb) => {
        if (resCb) {
          console.log("response from addPatient api",redeemedVoucher);
          let vouchers = resCb.voucherList ;
          const areObjectsEqual = (obj1, obj2) => {
            return obj1.voucherId === obj2.voucherId ;
          };
          if (resCb.voucherList) {
            let vouchers = resCb.voucherList;
            if (vouchers) {
              let filteredVoucherList = vouchers.filter((obj1) => {
                return !redeemedVoucher.some((obj2) => areObjectsEqual(obj1, obj2));
              });
              console.log("filteredList1", filteredVoucherList);
              if (filteredVoucherList && filteredVoucherList.length > 0)
                filteredVoucherList.map((v) => {
                  const dateObj = new Date(v.issueDate);
                  const formattedDate = `${dateObj.getDate().toString().padStart(2, '0')}-${(dateObj.getMonth() + 1).toString().padStart(2, '0')}-${dateObj.getFullYear()}`;
                  console.log("response from addPatient api", formattedDate);
                  v.issueDate = formattedDate;
                  console.log("dhhfkgk", Date(formattedDate))
                })
              console.log("response from addPatient afreapi", filteredVoucherList);
              setVoucherList(filteredVoucherList);
              console.log("vouchers are : ", voucherList)
              setShowVoucherModal(true);
            }
          } else {
            setVoucherList('');
            console.log("vouchers are not present : ", voucherList)
            // setShowVoucherModal(true);
          }

          setShowForm(false);
        }
      })
    );
  };

  const showService = (data) => {
    console.log("showData :", data);

    SetSearch("");
    console.log("showdata: ", data);
    data.state = "update";
    
    data.productName = data.productName;
    
    console.log("data.productName: ", data.productName);

    console.log("data state :", data);
    var serviceArray = [];

    if (data && data.isPackage == true) {
      setIsPackage(true);
      getDoctorList();

      
      for (let list of serviceList) {
        
        if (data.id && data.id == list.packageid) {
          list.billAmount = list.amount;

          console.log("list inside for loop is:", list);
          list.state = "update";
          if(list.isDoctor == "true" || list.isDoctor == true){
            console.log('entered if condition')
            list.doctorId = defaultDoctor;
          }
          console.log("list inside for loop after state:", list);
          serviceArray.push(list);
          console.log('service array: ', serviceArray)
        }
        
      }

      // if(data.isDoctor == "true" || data.isDoctor == true){
      //   console.log('entered if condition')
      //   data.doctorId = defaultDoctor;
      // }
      console.log("packageService: ", serviceArray  );
      //setService(serviceArray);
      setService([...serviceArray]);
      console.log("packageService: ", packageService);
    } else {
      setIsPackage(false);
      console.log(defaultDoctor);

      if (data.isDoctor == "true"){
        data.doctorId = defaultDoctor
      }

      //data.billAmount = data.amount
      setService([data]);
      console.log("service is:", data);
    }

    //setService(data)
    console.log("package service :", packageService);
    setShowForm(true);
    //Read only inputbox in edit case
    //setIsReadonly(true);
    //console.log("services:", data.billAmount, data.amount)
    

  };
  
  const handleRedeemClick = (voucher,index)=>{
    console.log("voucherredeemed",props.patientData, redeemedVoucher);
    voucherList[index]["redeemed"] = true;
    let filteredVoucherList = redeemedVoucher.filter((obj1) => {
      if(obj1.visitId == props.patientData.visit.id){
        closeVoucherModal({success:"You have already redeemed voucher for this visit."});
      }
    });
    console.log("voucherredeemednjj", filteredVoucherList, voucher);
    voucher[0].issueDate = moment(voucher[0].issueDate, "DD-MM-YYYY").format(
      "YYYY-MM-DDTHH:mm:ss"
    );
    dispatch(
      patientActions.redeemVoucher(
        props.patientData.visit.id,
        props.patientData.id,
        voucher[0],
        (resCb) => {
          if (resCb) {
            console.log("response from voucherredeemed api",resCb);
              if(resCb.successMessages){
                  // setNonRedeemed(false);
                  // setDisabled(true);
                  closeVoucherModal({success:resCb.successMessages});
              }
              if(resCb.errorMessages){
                closeVoucherModal({success:"Failed to redeemed voucher."});
              }
          }
        })
      );
  }

  const productSelected = (data,index,item) => {
    console.log("data in product", data);
    console.log("item in product", item);
    // packageService["productId"] = data.productId;
    // packageService["productName"] = data.productName;
    // setService([{ ...packageService }]);
    
    item.productId = data.productId;
    item.productName = data.productName;
    console.log("item in product after set", item);
   setService([item]);
    console.log("package service", packageService);

    setDisplayProducts(null);
  };
  
  const getDoctorList = () => {
    let data = {};
    let docList = [];
    dispatch(
      doctorActions.getDoctorList(data, (resCb) => {
        if (resCb) {
          console.log("doctorlist in packageservice", resCb);
          for (let doctorList of resCb) {
            if (doctorList && doctorList.type == "doctor") {
              docList.push(doctorList);
            }
          }
          setDoctorList([...docList]);
          
        }
      })
    );

    console.log("doctorlist", doctorList)
  };
  const closeVoucher = (voucher) => {
    console.log("voucherId-------", voucher);
    setShowVoucherModal(voucher);
  };
 
  const closeVoucherModal = (voucher) => {
    props.onModalClose(voucher);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    console.log("handleSelectChange", event.target.value);
    setSelectedOption(event.target.value);
    setIndex(event.target.value);
    setVoucherData([voucherList[event.target.value]]);
    console.log(
      "handleSelectChange",
      typeof voucherList[event.target.value].issueDate
    );

    console.log("handleSelectChange", voucherList[event.target.value]);
  };

  const handleVoucherSubmit = () => {
    console.log("move to bill method:");
    setOnModal(true);
  };

  // const closeModal = () => {
  // setOnModal(false);
  //   props.onModalClose();
  // };

  return (
    <>
      <div className=" bg-none nav-wrapper h-100 d-flex flex-column service-view">
        <div className='"d-flex'>
          <span
            className="topright d-flex justify-content-end pl-4 pt-3 mr-4"
            onClick={(event) => closeServiceModal(event)}
          >
            X
          </span>
        </div>

        <div className="w-100 service-container" style={{ overflowY: "auto" }}>
          <div className="d-flex m-3 justify-content-between px-4">
            <div className="w-100 flex-wrap flex-column">
              {props.mode === "new" && (
                <Row>
                  <Col sm={12} md={7}>
                    <div className="search-service mb-1">
                      <FormInput
                        // className = 'p-2'
                        value={search}
                        placeholder="Search Service"
                        required
                        autoComplete="off"
                        onChange={(event) => {
                          handleInputChange("search", event.target.value);
                        }}
                      />
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="new-service mb-1">
                      <Button
                        theme="primary"
                        className="add-new-service btn-block"
                        onClick={(event) => addNewService(event)}
                      >
                        Add New Service
                      </Button>
                    </div>
                  </Col>
                  {/* {procedure && procedure.visitType && procedure.visitType == "InPatient" && */}
                  {/* <Col sm={12} md={2}>
                    <div className="new-service mb-1">
                      <Button
                        theme="primary"
                        className="add-new-service btn-block"
                        onClick={handleClick}
                      >
                        Vouchers
                      </Button>
                    </div>
                  </Col> */}
                </Row>
              )}
              {/* {voucherList == "" && (
                <div
                  className="voucherr d-flex justify-content-center"
                  style={{ color: "#848884" }}
                >
                  Sorry you don't have any Vouchers...
              </div>
              )

              }
              {showVoucherModal && (
                // <div style={{marginTop:`21px`}}>
                // <Components.Vouchers
                //   pateintInfo={props.patientData}
                 // closeVoucher={closeVoucher}
                //  redeemVoucher={(event) => closeVoucherModal(event)}
                // />
                // </div>
                
                  <div className="new-service-form h-100">
                     <Col>
                          <Form className="row voucher-header service-form">
                            <div className=" pl-0 col-lg-2 col-xl-2 col-md-2 col-6">
                              <label className="service-lable voucher-label">
                                Voucher Code
                              </label>
                            </div>

                            <div className="pl-0 col-lg-6 col-xl-6 col-md-6 col-6">
                              <label className="service-lable voucher-label">
                                Description
                              </label>
                            </div>
                            <div className="pl-0 col-lg-2 col-xl-2 col-md-2 col-6">
                              <label className="service-lable voucher-label">
                              Issue Date
                              </label>
                            </div>

                            <div className="pl-0 col-lg-2 col-xl-2 col-md-2 col-6">
                            </div>
                          </Form>
                        </Col>
                      {voucherList &&
                      voucherList.map((voucher, index) => (
                       <Col className="voucherr">
                          <Form className="row  voucher-row  service-form">
                            <div className=" py-3 pl-0 col-lg-2 col-xl-2 col-md-2 col-6">
                              <div className="voucher-row">{voucher.voucherId}</div>
                              <div style={{ color: "#EC4147" }}>
                              </div>
                            </div>

                            <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-6">
                            {/* <div className="voucherr">{voucher.narration}</div> */}
                           {/* <div className="voucher-row" > With the advent of technologies in various spheres of our lives, criminal interrogation has also adapted to the same to some extent.</div>
                              <div style={{ color: "#EC4147" }}>
                              </div>
                            </div>
                            <div className="py-3 pl-0 col-lg-2 col-xl-2 col-md-2 col-6">
                              <div className="voucher-row" >
                              {voucher.issueDate}
                              </div>
                            </div>

                            <div className="py-3 pl-0 col-lg-2 col-xl-2 col-md-2 col-6">
                              <div style={{ color: "#EC4147" }}>
                                  <Button theme="primary"  className="redeem" onClick={(event)=>handleRedeemClick(voucher,index)}>
                                  Redeem{" "}
                        </Button>
                              </div>
                            </div>
                          </Form>
                        </Col>
                      ))} */}
                  {/* {voucherList &&
                  voucherList.map((voucher, index) => (
                    <div className="vouchers-doctors" key={index}>
                      {(JSON.parse(localStorage.getItem("role")) === "Nurse" ||
                        JSON.parse(localStorage.getItem("role")) ===
                          "Doctor") && (
                          <Button theme="primary"  className="redeem">
                          Redeem{" "}
                        </Button>
                      )}

                      <Row className="vochersList-doctors">
                        <Col sm={12} md={12}>
                          <label className="service-lable">Voucher Code</label>
                          <span>
                            <span className="mr-2">:</span>
                            {voucher.voucherId}
                          </span>
                        </Col>
                        <Col sm={12} md={12}>
                          <label className="service-lable">Narration</label>{" "}
                          <span>
                            <span className="mr-2">:</span>
                            {voucher.narration}
                          </span>
                        </Col>
                        <div className="vochersList"></div>
                      </Row>
                    </div>
                  ))} */}
                {/* </div>
              )}  */}
             
              {showForm ? (
                
                <div className="new-service-form h-100">
                  {packageService.map((item) => (
                    <>
                      { 
                      isPackage ? (                       
                        <Col>
                          {/* <h6 className="row service-form">
                                {item.packagename}
                          </h6> */}
                          
                          <Form className="row service-form">
                            <div className="py-3 pl-0 col-lg-3 col-xl-3 col-md-3 col-6">
                              <label className="service-lable">
                                Service Name :
                              </label>
                              <FormInput
                                className="p-2"
                                value={item.packagename + ": "+ item.name}
                                placeholder="Service Name"
                                readOnly={isReadonly}
                                required
                                autoComplete="off"
                                onChange={(event) => {
                                  handleInputChange(
                                    "name",
                                    event.target.value,
                                    item
                                  );
                                } }
                                
                                invalid={formErrors.name === "" ? false : true}
                              />
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.name}</span>
                              </div>
                            </div>
                            {/*                     
                    <div className='py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12'>
                    <label className="service-lable">Service type :</label>
                    <FormInput className = 'p-2'
                                value={item.packagetype}
                                placeholder="Service Package type"
                                readOnly={isReadonly}
                                autoComplete="off"
                                onChange={(event) => {handleInputChange("packagetype",event.target.value,item);}}
                                invalid={formErrors.packagetype === "" ? false : true}
                                            />
                                            <div style={{ color: "#EC4147" }}>
                                              <span>{formErrors.packagetype}</span>
                                            </div>
                        
                    </div> */}

                            {/* <div className='py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-6'>
                    <label className="service-lable">Product Name :</label>
                    
                     <FormInput className = 'p-2'
                                            value={item.productName}
                                            placeholder="Search products"
                                            readOnly={item.state === 'update'? true : false}
                                            required
                                            autoComplete="off"
                                            type="text"
                                            onChange={(event) => {handleInputChange("productId",event.target.value,item);}}
                          /> 
                

                <div className=" mt-2 shadow product-list-container position-absolute">
                {displayProducts && displayProducts.length>0 && displayProducts.map((product, index) => (

                                    
                          <div className='border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column' onClick={(event)=>productSelected(product,index)}>
                        
                          <h6 className='mb-0'> {product.displayname}</h6>
                          <h6 className='text-muted'>{product.productId}</h6>
                         
                        </div>
                 ))}
              </div>
        
                    </div> */}
                            {/* <div className='py-2 pl-0  col-lg-3 col-xl-3 col-md-3 col-6'>
                      <label className="service-lable">Description :</label>
                      <FormTextarea className = 'p-2'
                                              value={item.description}
                                              placeholder="Description"
                                              autoComplete="off"
                                              readOnly={isReadonly}
                                              onChange={(event) => {handleInputChange("description",event.target.value,item);}}
                                              invalid={formErrors.description === "" ? false : true}
                                            />
                                            <div style={{ color: "#EC4147" }}>
                                              <span>{formErrors.description}</span>
                                            </div>
                            
                    </div> */}
                            <div className="py-3 pl-0  col-lg-2 col-xl-2 col-md-2 col-6">
                              <label className="service-lable">
                                Quantity :
                              </label>
                              <FormInput
                                className="p-2"
                                value={item.quantity}
                                placeholder="Quantity"
                                defaultValue="1"
                                autoComplete="off"
                                readOnly={isReadonly}
                                onChange={(event) => {
                                  handleInputChange(
                                    "quantity",
                                    event.target.value,
                                    item
                                  );
                                }}
                                // invalid={formErrors.name === "" ? false : true}
                              />
                              {/* <div style={{ color: "#EC4147" }}>
                                              <span>{formErrors.quantity}</span>
                                            </div> */}
                            </div>

                            {/* <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-1 col-xl-1 col-md-1 col-6">
                              <input
                                className="p-2"
                                value={item.billable}
                                checked={item.billable}
                                type="checkbox"
                                id="billable"
                                placeholder="billable"
                                autoComplete="off"
                                //disabled={isReadonly}
                                disabled = {isReadonly}
                                onChange={(event) =>
                                  handleInputCheckbox(
                                    event.target.checked,
                                    item
                                  )
                                }
                              />
                              <span className="ml-2">Billable</span>
                            </div> */}

                            {/* <div className='d-flex justify-content-end col-12'>
                    </div> */}
                            <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-6">
                              <label className="service-lable">
                                Charges/ Fee:
                              </label>
                              <FormInput
                                className="p-2"
                                value={item.amount}
                                placeholder="Doctor charges"
                                autoComplete="off"
                                readOnly={isReadonly}
                                onChange={(event) => {
                                  handleInputChange(
                                    "amount",
                                    event.target.value,
                                    item
                                  );
                                }}
                                invalid={
                                  formErrors.amount === "" ? false : true
                                }
                              />
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.amount}</span>
                              </div>
                            </div>

                            {item.isDoctor == true || item.isDoctor == "true"  ? (
                              <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-6">
                                <label className="service-lable">
                                  Doctor :
                                </label>
                                <select
                                  name="category"
                                  className="form-control filterin Quantity"
                                  //defaultValue={defaultDoctor}
                                  value={item.doctorId}
                                  defaultValue={defaultDoctor}
                                  onChange={(event) => {
                                    handleInputChange(
                                      "doctorId",
                                      event.target.value,
                                      item
                                    );
                                  }}
                                >
                                  {/* {<option defaultValue={"selected Type"}>
                                    ---Select Doctor---
                                  </option> } */}
                                  {doctorList.map((doctor, index) => (
                                    <option key={index} value={doctor.gvId} >
                                      {doctor.displaytitle}.{doctor.first_name}
                                      
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              ""
                            )}
                          </Form>
                        </Col>
                      ) : (
                        // selecting package service
                        <Col>
                          <Form className="row service-form">
                            <div className="py-3 pl-0 col-lg-6 col-xl-6 col-md-6 col-12">
                              <label className="service-lable">
                                Service Name : 
                              </label>
                              <FormInput
                                className="p-2"
                               // value={item.name}
                                value = {
                                  props.mode === "edit" ? 
                                    item.name + (item.description ? ': ' + item.description : '')
                                      : item.name}
                                placeholder="Service Name"
                                readOnly={isReadonly}
                                required
                                autoComplete="off"
                                onChange={(event) => {
                                  handleInputChange(
                                    "name",
                                    event.target.value,
                                    item
                                  );
                                }}
                                invalid={formErrors.name === "" ? false : true}
                              />
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.name}</span>
                              </div>
                            </div>

                            <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                              <label className="service-lable">
                                Service type :
                              </label>
                              <FormInput
                                className="p-2"
                                value={item.packagetype}
                                placeholder="Service Package type"
                                readOnly={isReadonly}
                                autoComplete="off"
                                onChange={(event) => {
                                  handleInputChange(
                                    "packagetype",
                                    event.target.value,
                                    item
                                  );
                                }}
                                invalid={
                                  formErrors.packagetype === "" ? false : true
                                }
                              />
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.packagetype}</span>
                              </div>
                            </div>

                            <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                              <label className="service-lable">
                                Product Name :
                              </label>

                              <FormInput
                                className="p-2"
                                value={item.productName}
				//value = {item.productId}
                                placeholder="Search products"
                                readOnly={
                                  item.state === "update" ? true : false
                                }
                                required
                                autoComplete="off"
                                type="text"
                                onChange={(event) => {
                                  handleInputChange(
                                    "productId",
                                    event.target.value,
                                    item
                                  );
                                }}
                                invalid={
                                  formErrors.productId=== "" ? false : true
                                }
                              />

                              <div className=" mt-2 shadow product-list-container position-absolute">
                                {displayProducts &&
                                  displayProducts.length > 0 &&
                                  displayProducts.map((product, index) => (
                                    <div
                                      className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column"
                                      onClick={(event) =>
                                        productSelected(product, index,item)
                                      }
                                    >
                                      <h6 className="mb-0">
                                        {" "}
                                        {product.productName}
                                      </h6>
                                      <h6 className="text-muted">
                                        {product.productId}
                                      </h6>
                                    </div>
                                  ))}
                              </div>
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.productId}</span>
                              </div>
                              
                            </div>
                            <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                              <label className="service-lable">
                                Description :
                              </label>
                              <FormTextarea
                                className="p-2"
                                value={item.description}
                                placeholder="Description"
                                autoComplete="off"
                                disabled={isReadonly}
                                onChange={(event) => {
                                  handleInputChange(
                                    "description",
                                    event.target.value,
                                    item
                                  );
                                }}
                                invalid={
                                  formErrors.description === "" ? false : true
                                }
                              />
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.description}</span>
                              </div>
                            </div>
                            <div className="py-3 pl-0  col-lg-4 col-xl-4 col-md-4 col-12">
                              <label className="service-lable">
                                Quantity :
                              </label>
                              <FormInput
                                className="p-2"
                                value={item.quantity}
                                placeholder="Quantity"
                                defaultValue="1"
                                autoComplete="off"
                                readOnly={isReadonly}
                                onChange={(event) => {
                                  handleInputChange(
                                    "quantity",
                                    event.target.value,
                                    item
                                  );
                                }}
                                // invalid={formErrors.name === "" ? false : true}
                              />
                              {/* <div style={{ color: "#EC4147" }}>
                                              <span>{formErrors.quantity}</span>
                                            </div> */}
                            </div>

                            <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-2 col-xl-2 col-md-2 col-12">
                              <input
                                className="p-2"
                                value={item.billable}
                                checked={item.billable}
                                type="checkbox"
                                id="billable"
                                placeholder="Quantity"
                                autoComplete="off"
                                disabled  = {item.state == "update" ? true: false}
                                style={{ cursor: isReadonly ? "not-allowed" : "default" }}
                                onChange={(event) =>
                                  handleInputCheckbox(
                                    event.target.checked,
                                    item
                                  )
                                }
                              />
                              <span className="ml-2">Billable</span>
                            </div>

                            <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                              <label className="service-lable">
                                Vouchers List :
                              </label>
                              <select
                                name="category"
                                className="form-control filterin Quantity"
                                disabled={isReadonly}
                                value={selectedOption}
                                onChange={handleSelectChange}
                              >
                                {flag && Array.isArray(voucherList) ? (
                                  <>
                                    <option value="" disabled>
                                    --Select Voucher (VoucherId-Description-IssueDate)--
                                  </option>
                                  {voucherList.map((voucher, index) => (
                                    <option key={index} value={index}>
                                      {voucher.voucherId} {voucher.narration} {voucher.issueDate}
                                    </option>
                                  ))}
                                  </>
                                ) : (
                                  <option value="" disabled selected>
                                    -- No Vouchers Available --
                                  </option>
                                )}
                                {/* <option value="" disabled selected>
                                  --Select Voucher (VoucherId-Discripition-IssueDate)--
                                </option>
                                {voucherList.map((voucher, index) => (
                                    <option key={index} value={index}>
                                      {voucher.voucherId}  {voucher.narration}Discripition  {voucher.issueDate}
                                      
                                    </option>))}  */}
                              </select>
                            </div>

                            <div className="d-flex justify-content-end col-12"></div>
                            <div className="py-3 pl-0  col-lg-6 col-xl-6 col-md-6 col-12">
                              <label className="service-lable">
                                Doctor charges:
                              </label>
                              <FormInput
                                className="p-2"
                                value={props.mode == 'edit' ? item.billAmount : item.amount}
                                placeholder="Doctor charges"
                                autoComplete="off"
                                readOnly={isReadonly}
                                onChange={(event) => {
                                  handleInputChange(
                                    "amount",
                                    event.target.value,
                                    item
                                  );
                                }}
                                invalid={
                                  formErrors.amount === "" ? false : true
                                }
                              />
                              <div style={{ color: "#EC4147" }}>
                                <span>{formErrors.amount}</span>
                              </div>
                            </div>


                            
                            {/* Commented by dheeraj..isDoctor field insert in database manually */}
                            <div className="py-3 pl-0  pt-5 d-flex align-items-center col-lg-3 col-xl-3 col-md-3 col-12">
                              <input
                                className="p-2"
                                value={item.isDoctor}
                                checked={item.isDoctor == 'false' ? item.isDoctor == " " : item.isDoctor }
                                type="checkbox"
                                id="isDoctor"
                                placeholder="isDoctor"
                                autoComplete="off"
                                //disabled={isReadonly}
                                //readOnly = {isReadonly}
                                disabled = {item.state == "update" ? true: false}
                                style={{ cursor: item.state === "update" ? "not-allowed" : "default" }}

                                onChange={(event) =>
                                  isDoctorCheckbox(
                                    event.target.checked,
                                    item
                                  )
                                }
                              />
                              <span className="ml-2">IsDoctor</span>
                            </div>

                            {item.isDoctor == true || item.isDoctor == "true" ?
                            <div className="py-3 pl-0  col-lg-3 col-xl-3 col-md-3 col-12">
                              <label className="service-lable">Doctor:</label>
                              <select
                                name="category"
                                
                                className="form-control filterin Quantity"
                                value={item.gvId!==0 ? item.gvId:item.doctorId}
                                defaultValue={item.gvId}
                                // 
                                disabled={item.gvId!==0 ? true:false}
                                onChange={((event) => {
                                  handleInputChange(
                                    "doctorId",
                                    event.target.value,
                                    item
                                  );
                                } ) }
                              >
                                {/*<option defaultValue={"selected Type"}>
                                  ---Select Doctor---
                                </option> */}

                                {doctorList.map((doctor, index) => (
                                  <option key={index} value={doctor.gvId} >
                                    {doctor.displaytitle}.{doctor.first_name}
                                    
                                  </option>))} 
                                
                                {/* {doctorList.map((doctor, index) => (
                                  <option key={index} value={defaultDoctor} selected = {doctor.gvId === defaultDoctor}>
                                  {doctor.displaytitle}.{doctor.first_name}
                                  </option>
                                ))} */}
                          
                                {/* {doctorList.map((doctor, index) => (
                                  <option key={index} value={doctor.gvId}>
                                    {doctor.displaytitle}.{doctor.first_name}
                                    
                                  </option>
                                ))} */}

                              </select>

                             
                            </div>
                             :'' }
                          </Form>
                        </Col>
                      )}
                    </>
                  ))}

                  <Modal
                   className="d-flex w-95 h-90 flex-column-reverse "
                   id="closemodalopen"
                   open={onmodal}
                   toggle={toggle}
                   >
                  <div>
                  <ModalHeader>
                  Warning!
                  <span className="close-model" onClick={(event) => toggle(event)}>
                    X
                  </span>
                </ModalHeader>
                <ModalBody>
                <span>Patient {props.patientData.fname} already has been sent for Billing.  If you need to add/delete services/charges please do it in the Billing module.</span>
                </ModalBody>
                <ModalFooter>
                <button
                className="btn btn-submit btn-primary btn-style bottomright"
                onClick={(event) => closeModal(event)}
                >
              Close
            </button>
                </ModalFooter>
                 </div>
                 </Modal>

                  <Modal
                    className="d-flex w-95 h-90 flex-column-reverse "
                    id="closemodalopen"
                    open={voucherFlag}
                    toggle={toggle}
                  >
                    <div>
                      <ModalHeader>
                        Warning!
                        <span
                          className="close-model"
                          onClick={(event) => CancelModel(event)}
                        >
                          X
                        </span>
                      </ModalHeader>
                      <ModalBody>
                        <span>No Voucher selected. Do you want to Continue?</span>
                      </ModalBody>
                      <ModalFooter className="justify-content-between">
                        <button
                          className="btn btn-submit btn-primary btn-style bottomright"
                          onClick={(event) => CancelModel(event)}
                        >
                          Cancel
                        </button>
                        <button
                          className="btn btn-submit btn-primary btn-style bottomright"
                          onClick={(event) => AddServiceVoucher(event)}
                        >
                          Continue
                        </button>
                      </ModalFooter>
                    </div>
                  </Modal>

                  {props.mode == "new" && (
                    <div className="d-flex justify-content-end">
                      <Button
                        theme="primary"
                        type="submit"
                        className="px-5"
                        onClick={(event) => handleSubmit(event)}
                      >
                        Save
                      </Button>
                    </div>
                  )}
                </div>
              ) : (
                <div className=" mt-2 shadow service-list-container">
                  {diplayServices &&
                    diplayServices.length > 0 &&
                    diplayServices.map((service, index) => (
                      
                      <div
                        className="border-bottom blog-comments__item d-flex p-2 cpointer flex-wrap border-0 flex-column"
                        onClick={(event) => showService(service, index)}
                      >
                        {/* < ul > <label className="service-lable">Name: {service.name}</label></ul> */}
                        <h5 className="mb-0"> 
                        {service.packagename ? (service.packagename + "-") : ('')} 
                            {service.name} 
                            <span style={{fontSize:15 ,float:'right'}}>
                            {service.packagename ? ' '+'Rs'+' '+(service.amount): ''} 
                            </span>
                        </h5>

                        <h6 className="text-muted">{service.description}</h6>
                        {/* <Button theme="white" className="ml-3 pl-2 btn btn-white"> */}
                        {/* <i className="material-icons" style={{width:`3px`}}>double_arrow_right</i> */}
                        {/* </Button> */}
                      </div>
                    ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default PackageService;
