import * as React from "react";
import PdfPrinter from "pdfmake";
import { useEffect, useState,setState } from "react";
import {HOSPITAL_ADDRESS,EMAIL_ID,PHONE_NO,HOSPITAL_NAME,HOSPITAL_DESC} from "../../shared/constants";
import { MANASWINI_LOGO,MANASWINI_BACKGROUND } from "../../shared/constants/manaswinilogo";
import { ehrActions } from '../../core/actions';
import {useDispatch, useSelector} from "react-redux";
import { jsonSchema } from "../../shared/schema";
var fonts = require("pdfmake/build/vfs_fonts");

function Pdfprint(props) {
  const now = new Date();
  const day = now.getDate();
  const month = now.toLocaleString("default", { month: "short" });
  const year = now.getFullYear();
  const suffix = getNumberWithSuffix(day);
  const formattedDate = `${suffix} ${month} ${year}`;
  const [rowData, setRowData] = useState();
  const qz = require("qz-tray");
  const [rowCheck, setRowCheck] = useState();
  const printerName = process.env.REACT_APP_PRINTER_NAME;
  const EMERGENCY_CONTACT_NO=process.env.REACT_APP_EMERGENCY_NUNBER;
  
 
 
  function getNumberWithSuffix(number) {
    const suffixes = ["th", "st", "nd", "rd"];
    const suffix = number % 100;
    return (
      number + (suffixes[(suffix - 20) % 10] || suffixes[suffix] || suffixes[0])
    );
  }
  const dispatch = useDispatch();
  const [patientInfo, setpatientInfo] = useState(props.selectedPatient);
  const [count, setCount] = useState(0);
  const [doctorDetails, setDoctorDetails] = useState(
    JSON.parse(localStorage.getItem("doctorInformation"))
  );
  const [printerStatus, setPrinterStatus] = useState('Not Connected');

// const[prescriptionList,setPrescriptionList]=useState(props.prescriptionList);

let rows = [[]];
rows[0] = [
    { text: "Medication", rowSpan: 2, fontSize:6, alignment: 'center' },
    { text: "Dosage", rowSpan: 2, fontSize:6, alignment: 'center' },
    { text: "Instructions", colSpan: 4, fontSize:6, alignment: 'center' },
    {text: '' },
    {text: ''},
    {text: ''},
    { text: "Duration",rowSpan:2, fontSize:6, alignment: 'center' }, 
    { text: "Comments",rowSpan:2, fontSize:6, alignment: 'center' } 
];
let fontSize;
rows.push(['', '', 'M', 'A', 'E', 'N', '', ''].map(cell => ({ text: cell, fontSize: 6, alignment: 'center' })));
console.log(rows);
 
  const dd = [];
  useEffect(() => {
    console.log("props of prescription", props, doctorDetails);
    console.log("patientinfo", props.selectedPatient);
    console.log("props:::pdf::",  props.prescriptionList);
    // setPrescriptionList(props.prescriptionList);
    // console.log("propslocalMedicineName",props.localMedicineName);
    // props.prescriptionList = [];
    let currIndex = 0;
    props.prescriptionList && props.prescriptionList.forEach((item,index)=>{
     console.log("indexesss",index);
     console.log("item.compositionId",item.compositionId);
     //  let docPrescArray=[];
     
     dispatch(ehrActions.getCompositionByVid({},item.compositionId,props.ehrid,resCb=>{
      if(resCb){
        let localmedicine = '';
        currIndex++;
        console.log("API Response getCompositionByVid :",currIndex); 
        for (let content of resCb.content) {
          for (let events of content.activities) {
            for (let items of events.description.items) {
              //console.log("localvaluess",items);
               if(items._type == "ELEMENT" ){
               if(items.name.value == "Local medicinal product brand" )
               {
                //console.log("dataLocal medicinal product brand",items.value.value);
                 localmedicine=items.value.value;
               }
             }
            }
          }
        }
       console.log("resc :",resCb.archetype_details);
       let form = jsonSchema.getSchemaFromCanonicalJson(resCb,resCb.archetype_details.template_id.value,"editComposition");
       form["local medicinal brand"] = localmedicine;
       console.log("pdfprintform",form); 
        formatEpres(form);
      //  //props.prescriptionList.spl
      //  if(index == props.prescriptionList.length-1 ){
      //   console.log("index values",index);
      //   prescArray.forEach((row) => {
      //   console.log("adjdfgfhj",row)
      //   rows.push([row.medicineName, row.instructions[0], row.advicetoDispenser[0], row.comment[0]]);
      //   });
      //   addPrescription();
      //  }
  
      }
       if(currIndex == props.prescriptionList.length ){
        console.log("index values",index);
        console.log("prescArray",prescArray)
        prescArray.forEach((row) => {
        console.log("adjdfgfhj",row)
        //console.log("ehjdhfjd", row.medicineName, row.instructions[0], row.advicetoDispenser[0], row.comment[0])
        if(row.rowCheck === true){
          rows.push([row.medicineName, row.dosage[0],row.M[0], row.A[0],row.E[0], row.N[0], row.advicetoDispenser[0], row.comment[0]].map(cell => ({ text: cell, fontSize: 6 })));
        }else{
          rows.push([
            {text: row.medicineName, fontSize:6},
            {text: row.dosage[0], fontSize:6},
            {text: row.instructions[0], colSpan: 4, fontSize: 6},'','','',
            {text: row.advicetoDispenser[0], fontSize:6},
            {text: row.comment[0], fontSize:6}
          ]);
        }
      });
        addPrescription();
        // setPrescriptionList([]);
       }
     }));
  })
  
  
  return () => {
    console.log("Entering disconnect");
    if (printerStatus === 'Connected') {
      // qz.websocket.disconnect().then(() => {
      //   setPrinterStatus('Disconnected');
      //   console.log("Disconnected");
      // });
    }
  };

 }, []);
  
//  console.log("prescriptionList",prescriptionList);

 let prescArray = [];
 const formatEpres = (docPrescArray) => {
    console.log("docPrescArray",docPrescArray);
    console.log("docPrescArrayproperties",docPrescArray.properties);
    // console.log("epresss",docPrescArray);
    let prescObject = docPrescArray.properties;
    let presc = Object.keys(docPrescArray.properties);
    let siNo = 0;

    let medicineName = docPrescArray["local medicinal brand"];
    let dosage = '';
    let instructions = "";
    let advicetoDispenser = "";
    let comment = "";
    let M = ''
    let E = ''
    let A = ''
    let N = ''
    let rowCheck = true;
    presc.map((pre) => {
      console.log("press2",pre);
      siNo = siNo + 1;
      // if (pre === "Local medicinal product brand") {
        // medicineName = prescObject[pre].textarea;
          //  medicineName = props.localMedicineName;
          // medicineName = "Motrin";
      // }
      if (pre === "Dose unit") {
        dosage = prescObject[pre].textarea;
        // console.log("instructionssss",instructions);
      }
      if (pre === "Instructions to patient") {
        const split = splitInstructions(prescObject[pre])
        const rowsCheck = containsOnlyNumericValue(split);
        if(split.length === 4 && rowsCheck === true){
          console.log("rowCheck")
            M= split[0]
            A= split[1]
            E= split[2]
            N= split[3]
            rowCheck = true;
          }
          else if(split.length === 1 && split[0] === ' ' ){
            M = ''
            A = ''
            E = ''
            N = ''
            rowCheck = true;
          }
          
          else{
            instructions = prescObject[pre].textarea;
            rowCheck = false;
          }
        }

        if (pre === "Advice to dispenser") {
        advicetoDispenser = prescObject[pre].textarea;
        // console.log("Advice to dispenserss",advicetoDispenser);
      }
      if (pre === "Comment") {
        comment = prescObject[pre].textarea;
        // console.log("commentss",comment)
      }
    });

    prescArray.push({
      medicineName: medicineName,
      dosage: dosage,
      instructions: instructions,
        M: M,
        A: A,
        E: E,
        N: N,
      advicetoDispenser: advicetoDispenser,
      comment:comment,
      rowCheck: rowCheck
    });
    //  setPrescriptions(prescArray);
    console.log("prescArrayprescArray", prescArray);
    // prescArray.forEach((row) => {
    //   console.log("adjdfgfhj",row)
    //   rows.push([row.medicineName, row.instructions[0], row.advicetoDispenser[0], row.comment[0]]);
    // });
    // console.log("prescriptionrowsibsdh", rows);
    // addPrescription(); 

  };
  
  function splitInstructions(instruction){
    console.log("instruction: ", instruction)
    const split = instruction.default.split("-");
    
    console.log("Instructions: ", split, rows)
    return split

  }

  function containsOnlyNumericValue(arr) {
    return arr.every(element => !isNaN(element));
}
  


  function addPrescription() {
    console.log("prescriptionrowsibsdh in addPrescription", rows);
    console.log(dd);
    var head = {};
    head["alignment"] = "justify";
    head["columns"] = [];

    var headerColumn = {};
    headerColumn["text"] = [
      {
        text: "PRESCRIPTION",
        bold: "true",
        alignment: "center",
        // margin: [20, 10,10, 5], 
      },
    ];
    
    var subhead = {};
    subhead["columns"] = [];
    var PnoColumn = {}
    var PdateColumn = {};
    PdateColumn["text"] = [
      // {
      //   text: "Prescription Date:",
      //   // alignment: "left",
      //   // float: "right",
      //   // color: "#176fad",
      //   alignment: "left",
      //   float: "right",
      //   fontSize: 8,
      //   lineHeight: 1.25
      // },
      // { text: `\n${formattedDate}`, alignment: "left", float: "right", fontSize:10 },
    ];

    var subhead2 = {};
    subhead2["columns"] = [];
    var PatientInformation = {};
    PatientInformation["text"] = [
      { text: "\nPatient Information:", color: "#176fad", fontSize: 8, lineHeight: 1.25, bold: "true"},
      { text: "\nMAIN: ", fontSize: 7, lineHeight: 1.25},
      {
        text: `${
          patientInfo.id !== null && patientInfo.id !== "" && patientInfo.id !== undefined
            ? patientInfo.id
            : "- -"
        }\n`, fontSize: 7,
      },
      { text: "Name: ", fontSize: 7 },
      {
        text: `${
          patientInfo.fullName !== null && patientInfo.fullName !== "" && patientInfo.fullName !== undefined
            ? patientInfo.fullName
            : "- -"
        }`, fontSize: 7, 
      },
      { text: "\nPhone number:", fontSize: 7, lineHeight: 1.25 },
      {
        text: `${
          patientInfo.phno !== null && patientInfo.phno !== "" && patientInfo.phno !== undefined
            ? patientInfo.phno
            : "- -"
        }\n`, fontSize: 7
      },
      { text: "Address: " , fontSize: 7 , lineHeight: 1.25 },
      {
        text: `\n${
          patientInfo.address !== null && patientInfo.address !== "" && patientInfo.address !== undefined
            ? patientInfo.address
            : "- -"
        }`, fontSize: 7
      },
    ];

    var PatientInformation2 = {};
    PatientInformation2["text"] = [

      {
        text: "Prescription Date:",
        // alignment: "left",
        // float: "right",
        // color: "#176fad",
        alignment: "left",
        float: "right",
        fontSize: 7,
        lineHeight: 1.25
      },
      { text: `\n${formattedDate}`, alignment: "left", float: "right", fontSize: 7 },
      { text: "\n\nAge: " , fontSize: 7, lineHeight: 1.25},
      {
        text: `${
          patientInfo.age !== null && patientInfo.age !== "" && patientInfo.age !== undefined
            ? patientInfo.age
            : "- -"
        }\n`, fontSize: 7
      },
      { text: "Gender: ", fontSize: 7, lineHeight: 1.25},
      {
        text: `${
          patientInfo.gender !== null && patientInfo.gender !== "" && patientInfo.gender !== undefined
            ? patientInfo.gender
            : "- -"
        }\n`, fontSize: 7
      },
      {text: 'Diagnosis Code: ',
      fontSize: 7,
      lineHeight: 1.25
     },
     // {text: '${diagnosisCode && diagnosisCode != null && diagnosiscode != '' ? diagnosisCode : "--"}\n', fontSize: 7,
     //lineHeight: 1.25},

      // { text: "\nAdhar:\n" },
      // {
      //   text: `${
      //     patientInfo.adhar !== null && patientInfo.adhar !== "" && patientInfo.adhar !== undefined
      //       ? patientInfo.adhar
      //       : "- -"
      //   }\n`,
      // },
    ];

    // var diagnosisCode = {}
    // var diagnosisColumn = {}
    // diagnosisColumn["text"] = [
    //   {text: 'Diagnosis Code: ',
    //    alignment: 'right',
    //    fontSize: 7,
    //    lineHeight: 1.25
    //   },
    //   // {text: '${diagnosisCode && diagnosisCode != null && diagnosiscode != '' ? diagnosisCode : "--"}\n', fontSize: 7,
    //   //lineHeight: 1.25},
    // ]
    var reportDate = {};
    subhead["columns"] = [];
    //var PnoColumn = {}
    var reportDateColumn = {};
    reportDateColumn["text"] = [
      {
        text: "Report Generated on: ",
        alignment: "left",
        float: "right",
        fontSize: 7,
        lineHeight: 1.25
        //color: "#000000",
      },
      { text: `${formattedDate}`, alignment: "left", float: "right", fontSize: 7, lineHeight: 1.25},
    ]

    var PM = {};
    PM.columns = [];
    var Pmhead = {};
    Pmhead.text = [
  { text: "\nList of Prescribed Medications:\n", color: "#176fad", fontSize: 8,  bold: "true" },
  { text: "Rx\n", color: "#176fad", fontSize: 8, bold: "true" },
];


    
    var PrescribedMedicationTable = {};
    PrescribedMedicationTable["columns"] = [];
    var PmTableData = {};
    PmTableData = [
      {
        table: {
          headerRows: 2,
          // width: 100," * ",100",
          // widths: [150, 150,100, 100, 100],
           widths: [100,50,20,20,20,20, 100,100],
          alignment: "center",
          body: rows,
          // body: [
            // ['width=100', 'star-sized', 'width=200', 'star-sized'],
            // ['fixed-width cells have exactly the specified width', {text: 'nothing  here', width: 1000, italics: true, color: 'gray'}, {text: 'nothing interesting here', italics: true, color: 'gray'}, {text: 'nothing interesting here', italics: true, color: 'gray'}]
          // ]
          
        },
      },
    ];

    var followUpOnInfo = {};
    followUpOnInfo["columns"] = [];
    var followUpOn = {};
    followUpOn["text"] = [
      { text: "\nFollow Up On: ____________\n", lineHeight: 1.25, fontSize: 7, bold: '  ' },
    ]
    var DoctorInfo = {};
    DoctorInfo["columns"] = [];
    var doctInfo = {};
    doctInfo["text"] = [
      { text: "Doctor Name:\n", fontSize:7, bold: "true" },
      {
        text:
          `${props.selectedPatient.person.displaytitle ? props.selectedPatient.person.displaytitle : ''}.` +
          `${props.selectedPatient.person.first_name ? props.selectedPatient.person.first_name : ''}` +
          `${props.selectedPatient.person.last_name ? props.selectedPatient.person.last_name : ''}\n`,
        fontSize: 7, bold: "true"
      },
      { text: "\nSignature:" , fontSize: 7,  bold: "true"},
    ];

    var doctInfo2 = {};
    doctInfo2["text"] = [
      // { text: "Phone Number:\n" },
      { text: "Helpline Number: ", fontSize: 7, bold: "true"},
      // { text: `${doctorDetails.phoneno ? PHONE_NO : '- -'}\n`, fontSize: 7,  bold: "true"},
      { text: `${props.selectedPatient.person.phoneno ? EMERGENCY_CONTACT_NO : '- -'}\n`, fontSize: 7, bold: "true" },
      { text: "Email: ", fontSize: 7, bold: "true" },
      { text: ` ${props.selectedPatient.person.email ? EMAIL_ID : '- -'}`, fontSize: 7, bold: "true" },
    ];


    head.columns.push(headerColumn);
    //subhead.columns.push(PnoColumn, "\t", PdateColumn);
    subhead2.columns.push(PatientInformation, "\t", PatientInformation2);
    PM.columns.push(Pmhead);
    PrescribedMedicationTable.columns.push(PmTableData);
    followUpOnInfo.columns.push(followUpOn);
    DoctorInfo.columns.push(doctInfo, "\t", doctInfo2);
    // DoctorInfo.columns.push(doctInfo);
    dd.content = [];
    
    dd.background = [ {
      image: MANASWINI_BACKGROUND,
      // width: 350,
      // height: 800,
      fit: [350, 900],
      alignment: 'center',
      margin:[150,0,0,50],
      color: '#ff0000'
    }];
    dd.header = [
      {
        columns: [
          {
            image: MANASWINI_LOGO,
            fit: [60, 60], // set the width and height of the image
            // alignment: "left",
            margin: [40, 0, 0, 40],
            width: 90, // set the top and bottom margin of the image
          },
          [
            {
              text: HOSPITAL_NAME,
              style: "header",
              width: "auto",
              // alignment: "center",
              margin: [20, 10, 0, 0], //Add margin to separate header from content
            },
            {
              stack: [
                {
                  text: HOSPITAL_DESC,
                  style: "subheader",
                  width: "auto",
                  margin: [20 , 0, 0, 20],
                  border: [false, false, false, true],
                },
              ],
              //margin: [0, 0, 10, 20], // Add margin to separate header from content
            },
          ],
        ],
      },
    ];
    dd.content.push(
      head,
      subhead2,
      // diagnosisColumn,
      PM,
      PrescribedMedicationTable,
      followUpOnInfo,
      DoctorInfo
    );
    dd.styles = {
      header: {
        fontSize: 20,
        bold: true,
        color: "#176fad",
      },
      subheader: {
        fontSize: 14,
        bold: true,
        color: "#176fad",
      },
    };
    dd.pageSize = "A5";
    dd.pageOrientation = "landscape";
    dd.pageMargins = [40, 60, 40, 60 ];
    dd.footer = function (currentPage, pageCount) {
      return [
        {
          text: HOSPITAL_NAME,
          alignment: "left",
          fontSize: 8,
          color: "#176fad",
          bold: true,
          margin: [40, 7, 0, 0],
        },
        {
          text: HOSPITAL_DESC,
          alignment: "left",
          fontSize: 8,
          color: "#176fad",
          bold: true,
          margin: [40, 0, 0, 0],
        },
        {
          text: HOSPITAL_ADDRESS,
          alignment: "left",
          fontSize: 8,
          margin: [40, 0, 0, 0],
        },
        {
          // text: "Mob:" + PHONE_NO + "| Email:" + EMAIL_ID,
          text: "Mob:" + EMERGENCY_CONTACT_NO + "| Email:" + EMAIL_ID,
          alignment: "left",
          fontSize: 8,
          margin: [40, 0, 0, 5],
        },
        {
          text: currentPage.toString() + " of " + pageCount.toString(),
          alignment: "center",
          fontSize: 8,
        },
      ];
    };
    // PdfPrinter.createPdf(dd, null, null, fonts.pdfMake.vfs).open();
  printPriscription(dd);
  }

  function  printPriscription(dd){
    PdfPrinter.createPdf(dd, null, null, fonts.pdfMake.vfs).open();
    let pdf;
    const pdfDocGenerator = PdfPrinter.createPdf(dd);
    pdfDocGenerator.getBase64((data) => {
      pdf = data;
    });

    console.log("pdfdocument is" ,pdfDocGenerator)
    /*if (printerStatus === 'Connected') {
      console.log("already connected, going to print");
      qz.printers.find().then((printers) => {
        console.log("The Printers are ", printers);
        
    
        // for(i=0;i<printers.filter.nam)
        //let a= printers.filter((el) => el.includes('IMPACT by Honeywell IH-2 (203 dpi) - ZPL'));
    
        printers.filter(el => {
          if(el.includes('IMPACT by Honeywell IH-2 (ZPL)')) {
            let config = qz.configs.create(el);
            // config.setPaperSize('A5');
            console.log("config is" , config);
            return qz.print(config, [{
                type: 'pixel',
                format: 'pdf',
                pageSize: 'A5',
                flavor: 'base64',
                 data:pdf,
               // data: '<h1>Hello JavaScript!</h1>'
            }]);
          }
    
      });
    }).catch((err) => {
      // console.error(err);
      // process.exit(1);
  });
  } else {*/
    console.log("printer status prop check else case connecting");
    qz.websocket.connect().then(() => {
      console.log("connecting");
      //setPrinterStatus('Connected');
      console.log("connected");
      return qz.printers.find();
  }).then((printers) => {
      console.log("The Printers are ", printers);
      console.log("The Printers are ", printerName);
      console.log("The Printers are ",process.env.REACT_APP_ACCOUNTS_URL);
  
      printers.filter(el => { 
        if(el.includes( printerName)) {
        // if(el.includes('HP DeskJet 1200 series')) {
        // if(el.includes('Canon LBP6030/6040/6018L')) {

          let config = qz.configs.create(el, {
            size: {width: 148, height: 210}, units: 'mm',
                scaleContent: false,
                });
          // config.setPaperSize('A5');
          console.log("config is" , config);
          return qz.print(config, [{
              type: 'pixel',
              format: 'pdf',
              // pageSize: 'A5',
              // flavor: 'base64',
              flavor: 'file',
               data:pdf,
             // data: '<h1>Hello JavaScript!</h1>'
          }]).then(() => {
            console.log("print complete");
            return qz.websocket.disconnect();
            //Promise.resolve('Printing successful');
            // return qz.websocket.disconnect();
        })
        }
  
    })



  }).then(() => {
    qz.websocket.disconnect();
    console.log("Disconnected");
     
  }).then(() => {
   
  }).catch((err) => {

  });
    
  }
  return <></>;
}
export default Pdfprint;

