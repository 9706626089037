import React, { useState, useEffect } from 'react';
import {
  Form,
  FormInput,
  Button, Card, CardBody
} from "shards-react";
import { zoomActions } from '../../core/actions';
import {useDispatch, useSelector} from "react-redux";
import Components from '..';

function ZoomNotes(props) {
    const[showAddCardModel, setShowAddCardModel] = useState({});
    const[showEditCardModel, setShowEditCardModel] = useState(false);
    const[cardInfo, setCardInfo] = useState([])
    const[selectedCardInfo, setSelectedCardInfo] = useState({})
    const[newCard, setNewcard] = useState({notes:''})
    const[cardLoad, setCardLoad] = useState(false)
    useEffect(() => {
        listZoomNotes()
        console.log('use effect in zoom meeting',props.meetingInfo)
    },[])
    const dispatch = useDispatch();

    const addCard = () =>{
        setShowAddCardModel(!showAddCardModel);
    }

    const handleInputChange = (name,value) => {
        var currentCardInfo = newCard;
        currentCardInfo[name] = value;
        setNewcard({...currentCardInfo});
        console.log("In handleinput change",value);
        //event.preventDefault();
      //  setNewcard({data})
       /*  console.log("In handleinput change",data);
        event.preventDefault();
        setNewcard({data}) */
        
    }
    const zoomNotesList = useSelector(state => state.zoomDetailsState.zoomNotesList)
    console.log("zoomNotesList:",zoomNotesList)
    const listZoomNotes = () => {
        let notes  = {}
        notes['notes'] = newCard.notes
        notes['patientId'] = props.meetingInfo.patientId
        notes['visitId'] = props.meetingInfo.visitId
        notes['groupid'] = props.meetingInfo.groupId
        //event.preventDefault()
        console.log("listZoomNotes:")
        dispatch(zoomActions.listZoomNotes(notes,resCb=>{
            if(resCb){
                console.log("listZoomNotes Response:",resCb)
            }
        })) 
    }

    const saveCard = (event,note) =>{
        let newCardData = cardInfo
        newCardData.push(note)
        console.log('newCardData cards info : ',newCard)
      //  setCardInfo(newCardData)
      //  setCardLoad(!cardLoad)
      
     // setTimeout(() => {
          setNewcard({notes:''});
     //   },300)
        //var currentCardInfo = newCard;
        //currentCardInfo.notes = '';
        event.preventDefault()
        let data  = {}
       // listZoomNotes()
       data['notes'] = newCard.notes
       data['patientId'] = props.meetingInfo.patientId
       data['visitId'] = props.meetingInfo.visitId
       data['groupid'] = props.meetingInfo.groupId
       dispatch(zoomActions.saveZoomNotes(data,resCb=>{
           if(resCb){
               //listZoomNotes()
                
                //event.preventDefault()
                console.log("saveZoomNotes Response:",resCb)
              }
        })) 
        
        console.log('added cards info : ',data)
    }

    const editNote = (note) => {
        setShowEditCardModel(!showEditCardModel)
        setSelectedCardInfo(note)

    }

    return (
         <>
        <span className='p-15'>{props.meetingInfo.name}</span>
        <Card small className="mb-3 h-100 "  key={cardLoad}>
            <CardBody className="notes-card-body">
            {/*  <Form className="add-new-card">
                    <span onClick={(event) => addCard()}>
                    <i class="fas fa-plus"></i> Add a card
                    </span>
                
                </Form> */}
            {cardInfo && cardInfo.length > 0 &&
                    cardInfo.map((note, idx) => (
                        <Card small className="mb-3 h-100 note-card" key={idx} onClick={(event) => editNote(note)}>
                            <span>{note.notes}</span>
                        </Card>
                    ))
            }
            
            </CardBody>
        </Card>  
        <div className="d-flex">
        <Form className="add-new-card d-flex justify-content-start">
            <FormInput className="mb-3" placeholder="Your Text" 
            value={newCard.notes}
            onChange={(event) => {
                handleInputChange('notes',event.target.value);
            }}/>
             <Button className="save-card" type="submit" style={{display:`none`}}
              onClick={(event) => saveCard(event,newCard)}>
                SAVE
            </Button> 
         {/*    {showEditCardModel ?
            <div className="modalmn" id="zoom-notes-edit">
                        <CardBody className="notes-card-body">
                            <Card small className="mb-3 h-100 note-card edit-zoomonote" >
                                <span>{selectedCardInfo.notes}</span>
                            </Card>
                        </CardBody>

            
        :''} */}
        
        </Form> 
        </div>
       
        </>

    );

}


export default ZoomNotes;
