import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import BtnCellRenderer from "../../common/BtnCellRenderer";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";

import Components from "../insurance/index";
import {
  ListGroup,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button,
  ModalBody,
  Modal,
  ModalHeader,
} from "shards-react";
// import { roomMasterActions } from '../../../core/actions';
import { patientActions } from "../../../core/actions";

import { roomMasterActions } from '../../../core/actions';

function RoomMasterGrid(props) {
  let ids;
  const history = useHistory();
  const [ServerRes, setServerRes] = useState(
    {success:'',error:'',}
    );

  const [roomForm, setRoomData] = useState(false);
  const [roomRowData, setroomRowData] = useState({});
  const [roomDetails, setRoomDetails] = useState()
  const [roomRowlist, setRowData] = useState();
  const [pagination, setPagination] = useState(true);
  const [paginationPageSize, setPaginationPageSize] = useState(10);
  const dispatch = useDispatch();

  const [confirm, setConfirm] = useState(false);

  const [columnDefs] = useState([
    {
      headerName: "Room Name ",
      field: "roomName",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Room Code",
      field: "roomCode",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
      onGridReady: "open",
    },
    {
      headerName: "Type",
      field: "type",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Floor Id",
      field: "floorId",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },
    {
      headerName: "Section.",
      field: "section",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      floatingFilter: true,
    },

    {
      headerName: "Actions",
      field: "Actions",
      sortable: true,
      filter: true,
      flex: 1,
      resizable: true,
      width: 150,
      cellRenderer: "BtnCellRenderer",
    },
  ]);

  const closeModal = (event) => {
    setRoomData(!roomForm);
  };


  const visitsList = useSelector(
    (state) => state.patientDetailsState.visitsList
  );
  console.log("visitsList", visitsList);

  
  const [onmodal, setOnModal] = useState(true);
  const closemodalopen = () => {
    setOnModal(true);
  };
  const getAggriddata = (e) => {
    console.log("props", props);
    console.log("getAggriddata", e.data);

    if (e.colDef.field == "Actions") {
      setConfirm(!confirm);
      console.log("delete rowdata:", e.data);
      setroomRowData(e.data);
      handleSubmit(e.data);
     
    } else {
      history.push("/roommaster", {
        rowData: e.data,
      });
    }

  
  };

  useEffect(() => {
    dispatch(patientActions.setPageHeader("roomList"));

    let data = {};
    console.log("props are:", props.roommaster);

    if (props && props.roommaster && props.roommaster.length > 0) {
      let pslist = props.roommaster;
      for (let item of pslist) {
        setRowData(pslist);
      }
    }
  }, [props]);

  const handleSubmit = (event) => {

    dispatch(
      roomMasterActions.roomDelete(event, (resCb) => {
        if (resCb) {
          console.log("roomDelete", resCb);
          if(resCb.messageStrip=="This room is already booked !! cannot be made inactive"){
            ServerRes.error="";
            ServerRes.error=resCb.messageStrip;
            ServerRes.success="";

          }else if(resCb.messageStrip=="This room can be made inactive "){
            ServerRes.error="";
            ServerRes.success="Room status has been successfully changed";
          }else{
            ServerRes.error="";
            ServerRes.success="";

          }
          props.onGridAction();
          setConfirm(!confirm);
        }
      })
    );
  };
  
  const toggle = (event) => {
    setConfirm(!confirm);
  };
  const closeAlert = (e) => {
    setServerRes({...""});
}
  const rowStyle = { background: "white" };

  return (
    <>
      {/* <h5 className="page-title my-2 mx-3">Doctors</h5> */}
      {ServerRes.error && (
                    <Alert theme="danger" className="alert-div">{ServerRes.error}<span><i className="material-icons"  onClick={(event) => closeAlert(event)} style={{cursor:'pointer'}}>close</i></span></Alert>
                  )}

   {ServerRes.success && (
   <Alert theme="success" className="alert-div">{ServerRes.success}<span><i className="material-icons"  onClick={(event) => closeAlert(event)} style={{cursor:'pointer'}}>close</i></span></Alert>
    )}
      <div>
        <div className="p-0 preview">
          <div
            className="ag-theme-balham ag-style card rounded table mb-0 tcol"
            style={{ height: 500, width: `98%` }}
          >
            <AgGridReact
              frameworkComponents={{ BtnCellRenderer: BtnCellRenderer }}
              pagination={pagination}
              paginationPageSize={paginationPageSize}
              //   context={context}
              rowData={roomRowlist}
              columnDefs={columnDefs}
              // onRowClicked={(e) => getAggriddata(e)}
              rowStyle={rowStyle}
              //   getRowStyle={getRowStyle}
              onCellClicked={(e) => {
                console.log("event onCellclicked::", e);
                const field = e.colDef.field;
                const colIndex = e.columnApi
                  .getAllColumns()
                  ?.findIndex((col) => col.getColDef().field === field);

                console.log("cell clicked ", field, colIndex);
                getAggriddata(e);
              }}
            ></AgGridReact>
          </div>

          
        </div>
      </div>
    </>
  );
}
export default RoomMasterGrid;
