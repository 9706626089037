import React, { useState, useEffect , useCallback } from 'react';
import { connect } from 'react-redux';
import PageTitle from "../common/PageTitle";
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormInput,
  Alert,
  Button, Card, CardBody,ModalBody, Modal, ModalHeader, ModalFooter
} from "shards-react";
import Components from '..';
import {useDispatch, useSelector} from "react-redux";
import { patientActions,doctorActions } from '../../core/actions';
import { helperServices } from '../../shared/helper';
import {useDropzone} from 'react-dropzone'
import { RESET,DISCHARGESUMMARY, PATIENT_DETAILS } from '../../shared/constants';

  function AttachOptions(props) {
    //const[patientObject,setPatientObject] = useState();
    const[uploadPreview, setuploadPreview] = useState(false);
    const[fileUploadcontent, setfileUploadcontent] = useState();
    const[upload, setUpload] = useState({});
    const dispatch = useDispatch();
    const [status, setStatus] = useState(undefined);
    const [patientDetail, setPatientDetail] = useState();
    const[templatePreview, setTemplatePreview] = useState(false);
    const[selectPatientData, setselectPatientData ] = useState();
    const[selectedPatientSummary, setPatientSummary ] = useState();
    const[selectedDischargeSummary, setDischargeSummary ] = useState();
    const [reloadModal,setReloadModal] = useState(false);
    const[pateintVisitAssignment, setPateintVisitAssignment ] = useState([]);
    const[doctorInfo, setdocInfo ] = useState({});
    const patientVisitList = useSelector(state => state.patientDetailsState.patientVisitLists);  
    const[summary, setSummary] = useState({
      preparedBy:'',
      approver:'',
      patientId:'',
      visitId:'',
      notes:'',
      groupname:'',
      notes:'',
      subgroup:'',
      type:'',
      id:''
     
    })
    // visit.appointment
    const[pDetail, setPDetail] = useState({
      patientId:"",
      visitId: "",
     
    })
    const [showPackageService,setShowPackageService] = useState(false);
    const [calendar,setCalendar] = useState(false);
    const [notes,setNotes] = useState(false);
    
    
    const selectedID = useSelector(state => state.patientDetailsState.selectedPatientId)
    let pid;
    useEffect(() => {
      console.log("patientVisitList in attach options:",patientDetail,selectPatientData );
      console.log("selectedID:",selectedID);
      if(props.selectedPatient.id != selectedID){
        dispatch(patientActions.setSelectedPatientId(props.selectedPatient.id));
        setTemplatePreview(false);
        setDischargeSummary(false);
        setShowPackageService(false);
        setNotes(false);
        setCalendar(false);
        setuploadPreview(false);
        setOnModal(false);
        setPatientSummary(false);
      }
      
      getVisitList(props.selectedPatient.visit.patientId, props.selectedPatient.visit.id)
      
      console.log("props selectedPatient:",props.selectedPatient);
      setPatientDetail(props.selectedPatient)
      setselectPatientData(props.selectedPatient)
      console.log("props123:",props);
      setPatientDetail(props.selectedPatient)
      console.log("attach options patient id : ", props.selectedPatient)
      pid = props.selectedPatient;
      //setPatientObject(props.data)
      setselectPatientData(props.selectedPatient)
  //  setSummary(props.data);
      pDetail.patientId = props.selectedPatient.visit.patientId;
      pDetail.visitId = props.selectedPatient.visit.id;
      //pDetail.id=props.data.procedure.id;
      // if(props.data && props.data.procedure && props.data.procedure.visitId){
      // pDetail.visitId = props.data.procedure.visitId;
      // }else{
      //   pDetail.visitId = null;
      // }
      console.log("patient id : ", pDetail);
      listTemplates();
      dispatch(patientActions.setUploadedFiles(RESET))
    },[props.selectedPatient])


    const getVisitList = (patId, vId) => {
      let data = {};
      data["visitId"] = vId;
      data["patientId"] = patId;
      let type = "showAllVisits";
      dispatch(
        patientActions.getVisitListById(vId, type, (resCb) => {
          if (resCb) {
           
            let docinfo = {
              counselor: "",
              email: "",
              type: "edit",
              gvId: "",
            };
            if (resCb.patsearchreslt[0].person) {
              docinfo.counselor = resCb.patsearchreslt[0].person.personId;
              docinfo.email = resCb.patsearchreslt[0].person.email;
              docinfo.gvId = resCb.patsearchreslt[0].person.gvId;
            }
            setdocInfo({ ...docinfo });
  
            let counsInfo = {
              counselor: "",
              email: "",
              type: "edit",
              gvId: "",
            };
            if (resCb.patsearchreslt[0].counsellor) {
              counsInfo.counselor = resCb.patsearchreslt[0].counsellor.personId;
              counsInfo.email = resCb.patsearchreslt[0].counsellor.email;
              counsInfo.gvId = resCb.patsearchreslt[0].counsellor.gvId;
            }
            setPateintVisitAssignment(resCb.patsearchreslt);
          }
        })
      );
    };
    var uploadedImages=[];
    const onDrop = useCallback((acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
  
        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
        // Do whatever you want with the file contents
          const binaryStr = reader.result
          console.log(binaryStr)
        }
        reader.readAsArrayBuffer(file)
        console.log("file :", file);
        console.log("acceptedFiles :", acceptedFiles);
        console.log("reader :", reader);
        setuploadPreview(true);
        uploadedImages.push(file);
        // dispatchsetUploadedFiles
      })
      console.log("uploadedImages 1:",uploadedImages);
      dispatch(patientActions.setUploadedFiles(uploadedImages))
    }, [])
    console.log("uploadedImages :",uploadedImages);
    const {getRootProps, getInputProps} = useDropzone({onDrop})
    console.log("getRootProps :", getRootProps,"getInputProps :",getInputProps);
    
    const uploadContentButtonSelected = () => {

      // setTimeout(() => {
        setuploadPreview(true);
        console.log("uploadedImages : setTimeout setuploadPreview");
      // }, 500);

    }
    const setshowAttachAction = () => {
      // props.showAttachAction()
      setshowAttachAction(!setshowAttachAction)
  }
   
      const closeModal = (event) => {
        // setTemplatePreview(!setTemplatePreview)
        setuploadPreview(!setuploadPreview)
      console.log(" upload prevew closed ")
 //dispatch illige
        //  props.setState({flieUploadErrorMsg:''});
        //  props.setState({flieUploadMsg:''});
  }

  const TemplatePreviewModal = (event) => {
    props.closeAllOptions(event);
  setTemplatePreview(false);
  }

  const listTemplates = (data) => {
    // let data = {}
    dispatch(doctorActions.listTemplates(data,resCb=>{
      if(resCb){
        console.log("listTemplates API Response:",resCb)          
        }
    }))
  }

  const modalopen = (event) => {
    setTemplatePreview(true);
    // props.closeAttachAction()
  }
  const [onmodal, setOnModal] =useState(false);
  const closemodalopen = () => {
    setOnModal(true);
  }

  const closePatient = () => {  
      dispatch(patientActions.updateVAStatus(pDetail,(resCb) => {
        if(resCb){
            setStatus({ type: 'success' });
            // props.onModalClose()
        }
      }));
    }

    
  const toggle =(event)=>{
   setOnModal(false);
  }

  const openAttachment = (event) => {
    dispatch(patientActions.setUploadedFiles(RESET));
    console.log(" props value 143 :",props);
    // if (condition) {
      // props.closeAttachAction(event);
      // }
      if(event){
        props.closeAllOptions(event);
      }
      setuploadPreview(!setuploadPreview);
    // props.closeAttachAction(false)

  }

  const patientSummaryClick = (selectedPatient) =>{
    console.log("patientSummaryClick data",selectedPatient)
    summary.patientId = selectedPatient.patientId
    summary.visitId = selectedPatient.visitId
    summary.groupname = DISCHARGESUMMARY
    summary.markForDel=false
        dispatch(patientActions.getDischargeSummary(summary, resCb => {
            if(resCb){
                    if(resCb.procedureList != null && resCb.procedureList.length > 0){
                       for(let dischsummary of resCb.procedureList){
                           console.log('summary : ',dischsummary);
                           summary.preparedBy = dischsummary.preparedBy;
                           summary.groupname = dischsummary.groupname;
                           summary.notes = dischsummary.notes;
                           summary.subgroup = dischsummary.subgroup;
                           summary.approver = dischsummary.approver;
                           summary.preparedBy = dischsummary.preparedBy;
                           summary.id = dischsummary.id;
                           summary.markForDel =false
                           summary.type = 'edit';
                           
                          //  summary.id = dischsummary.id;
                           console.log("inside discharge resCb  type 'edit' for ",summary);
                       }
                      //  setSummary(summary)
                       console.log("inside discharge resCb  type 'edit' for ", summary);
                    }else{
                        summary.preparedBy = '';
                        summary.groupname = '';
                        summary.notes = '';
                        summary.patientId = '';
                        summary.subgroup = '';
                        summary.approver = '';
                        summary.preparedBy = '';
                        summary.markForDel =false
                        console.log('summary ::: ',);
                        summary.type = 'save'
                        console.log("inside discharge resCb  summary.type = 'save' ", summary);
                    }
                    console.log("inside discharge resCb ", summary);
                       setPatientSummary(true);
                       
            }
        }))
    
    
}
// dishchargemodal

const dishchargemodal =(event)=>{
  let url = "/DischargeSummary/" + patientDetail.id + "/" + props.patientDetail.visit.id;
  window.open(url, '_blank');
  console.log("patientDetail :",patientDetail);
  //console.log("patientObject :",patientObject);
 
  console.log("selected patient data",selectPatientData)
  // setPatientSummary(true);
    // patientSummaryClick(pDetail)
 // patientSummaryClick(pDetail)
  console.log("selected patient data1",selectPatientData)
  
  // setTimeout(() => {  
  //   setSummary(true)
  // }, 200);
 }
 const closeDishargeModal =(event)=>{
  props.closeAllOptions(event);
  setPatientSummary(false)
 }
 const openServiceListModal =(event)=>{
  setShowPackageService(true)
 }
 const appointmentmodal = ()=>{
  setCalendar(true);
 }
 const openNotes = ()=>{
  setNotes(true);
 }
 
 const closeServiceListModal =(event)=>{
   props.closeAllOptions(event);
    setShowPackageService(false);
 }
 
 const closeCalendarModal =(event)=>{
  props.closeAllOptions(event);
  setCalendar(false);
}
const closeNotesModal =(event)=>{
  props.closeAllOptions(event);
  setNotes(false);
}

 const CloseAddConsultantModal = (event) => {
  props.closeAllOptions(event);
  setOnModal(false);
 }
  /* attach icon styles referred - https://codepen.io/j471n/pen/QWvErOO and */
  /* svg's referred - whatsapp */
    return (  
    <>
        <div style={{display:`flex`}} className="px-3 attch-options collapse position-relative" >
            <div className='attchlist'>
                <ul className="px-0 ul-list mb-0"style={{listStyleType:`none`}}>
             
                        <li className="mr-2" id= "" title="Photos" onClick={() => uploadContentButtonSelected()}>
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {
                          <div className="icon_container gallery">
                                  <svg viewBox="6 7 100 100" width="90" height="90">
                                        <g fill="#F5F5F5">
                                          <path id="svg-image" d="M18.318 18.25h16.364c.863 0 1.727.827 1.811 1.696l.007.137v12.834c0 .871-.82 1.741-1.682 1.826l-.136.007H18.318a1.83 1.83 0 0 1-1.812-1.684l-.006-.149V20.083c0-.87.82-1.741 1.682-1.826l.136-.007h16.364Zm5.081 8.22-3.781 5.044c-.269.355-.052.736.39.736h12.955c.442-.011.701-.402.421-.758l-2.682-3.449a.54.54 0 0 0-.841-.011l-2.262 2.727-3.339-4.3a.54.54 0 0 0-.861.011Zm8.351-5.22a1.75 1.75 0 1 0 .001 3.501 1.75 1.75 0 0 0-.001-3.501Z"></path>
                                        </g>
                                    </svg>  
                            </div>
                                     } 
                              
                          </div>
                        </li>
                     
                        <li className="mr-2" title="Counsellor" onClick={() => closemodalopen(true)}>
                          <div className="icon_container contact">
                            <svg viewBox="6 7 100 100" width="90" height="90" >
                              <g fill="#F5F5F5">
                                <path id="svg-contact" d="M26.5 26.5A4.5 4.5 0 0 0 31 22a4.5 4.5 0 0 0-4.5-4.5A4.5 4.5 0 0 0 22 22a4.5 4.5 0 0 0 4.5 4.5Zm0 2.25c-3.004 0-9 1.508-9 4.5v1.125c0 .619.506 1.125 1.125 1.125h15.75c.619 0 1.125-.506 1.125-1.125V33.25c0-2.992-5.996-4.5-9-4.5Z"></path>
                              </g>
                            </svg>
                          </div>
                        </li>

                        <li className="mr-2" title="EHR" onClick={() => modalopen(true)}>
                          <div className="icon_container doc">
                            <svg viewBox="6 7 100 100" width="90" height="90" >
                              <g fill="#F5F5F5">
                                <path id="svg-document" d="M29.09 17.09c-.38-.38-.89-.59-1.42-.59H20.5c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H32.5c1.1 0 2-.9 2-2V23.33c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM27.5 22.5V18l5.5 5.5h-4.5c-.55 0-1-.45-1-1z"></path>
                                </g>
                            </svg>
                          </div>
                        </li>
                        
                        <li className="mr-2" title="Discharge" onClick={(event) => dishchargemodal(event)}>
                          <div className="icon_container gallery">
                            <svg viewBox="6 7 100 100" width="90" height="90" >
                              <g fill="#F5F5F5">
                                <path id="svg-contact" d="M26.5 26.5A4.5 4.5 0 0 0 31 22a4.5 4.5 0 0 0-4.5-4.5A4.5 4.5 0 0 0 22 22a4.5 4.5 0 0 0 4.5 4.5Zm0 2.25c-3.004 0-9 1.508-9 4.5v1.125c0 .619.506 1.125 1.125 1.125h15.75c.619 0 1.125-.506 1.125-1.125V33.25c0-2.992-5.996-4.5-9-4.5Z"></path>
                              </g>
                            </svg>
                          </div>
                        </li>
                      
                        <li className="mr-2" title="Services" onClick={(event) => openServiceListModal(event)}>
                          <div className="icon_container doc">
                          {/*   <svg viewBox="0 0 100 100" width="90" height="90" >
                              <g fill="#F5F5F5">
                                <path id="svg-contact" d="M29.09 17.09c-.38-.38-.89-.59-1.42-.59H20.5c-1.1 0-2 .9-2 2v16c0 1.1.89 2 1.99 2H32.5c1.1 0 2-.9 2-2V23.33c0-.53-.21-1.04-.59-1.41l-4.82-4.83zM27.5 22.5V18l5.5 5.5h-4.5c-.55 0-1-.45-1-1z"></path>
                              </g>
                            </svg> */}
                               <svg  viewBox="0 0 60 75" width="25" height="25">
                                <g fill="#F5F5F5">
                                  <path class="icon-path" d="M63 12.6H46.3V-.7H19.7v13.2H2c-.9 0-2 .8-2 1.7v48.5c0 .9 1.1 1.3 2 1.3h61c.9 0 1.6-.4 1.6-1.3V14.3c.1-.9-.7-1.7-1.6-1.7zM22.9 3H43v9.6H22.9V3zm23.6 41.9h-9.1V54H27.3v-9.1h-9.1V34.8h9.1v-9.1h10.1v9.1h9.1v10.1z"></path>
                                </g>
                              </svg>
                          </div>
                        </li>

                        <li className="mr-2" title="Calendar" onClick={(event) => appointmentmodal(event)}>
                          <div className="icon_container gallery">
                          <svg  width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                          <g fill="#F5F5F5">
                            <path d="M0 464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V192H0v272zm320-196c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM192 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12h-40c-6.6 0-12-5.4-12-12v-40zM64 268c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zm0 128c0-6.6 5.4-12 12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H76c-6.6 0-12-5.4-12-12v-40zM400 64h-48V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H160V16c0-8.8-7.2-16-16-16h-32c-8.8 0-16 7.2-16 16v48H48C21.5 64 0 85.5 0 112v48h448v-48c0-26.5-21.5-48-48-48z"/>
                            </g>
                             </svg>
                         
                          </div>
                        </li>
                        <li className="mr-2" title="Notes" onClick={(event) => openNotes(event)}>
                          <div className="icon_container gallery">
                          <svg xmlns="http://www.w3.org/2000/svg"  fill="currentColor" version="1.1" id="Capa_1" width="20px" height="20px" viewBox="0 0 494.936 494.936">

<g fill="#F5F5F5">
<path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157    c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21    s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741    c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"/>
<path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069    c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963    c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692    C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107    l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005    c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"/>
</g>
</svg>
                         
                          </div>
                        </li>
                </ul>
            </div>           
        </div>

        
        { onmodal ?
          <div className='position-fixed consultant-container expanded-views' id="add-consultant">
          <Components.AddConsultant
          patientData = {patientDetail}
          mode="new"
          onModalClose={(event) =>  CloseAddConsultantModal(event)}/>
          </div>
            :''
          
        }

        {uploadPreview ?
        <div className='p-3 position-fixed file-upload-container expanded-views' id='file-upload'>
          
             <Components.Attachments
              openAttachment={(event) => openAttachment(event)}
              patientDetail = {patientDetail}
              // closeModal = {(event) => closeModal(event)}
         />

        </div>
          // <Components.FileUploadPreview
          // fileUploadcontent = {patientDetail}
          // uploadPreview={(event) => closeModal(event)}/> 
            :''
        }
        {templatePreview ?
          <div className='position-fixed template-container expanded-views' id="template-preview">
          <Components.TemplatePreview
            fileUploadcontent={patientDetail}
            patientInfo={props.selectedPatient}
            mode="new"
            ontemplatePreviewModalClose={(event) => TemplatePreviewModal(event)} />
        </div>
        : ''

      }
      {selectedPatientSummary ?
        <div className='position-fixed discrgsumm-container expanded-views' id="discharge-summary">
          <Components.Discharge
            // patientData={selectPatientData}
            // selectedPatient={props.selectedPatient}
            patientData={props.patientDetail}
            selectedPatient={props.patientDetail}
            dischargesummary={summary}
            mode="new"
            onModalClose={(event) => closeDishargeModal(event)} />
        </div>
        : ''}

           
        {showPackageService ?
           <div className='position-fixed packservice-container expanded-views' id="packgservice"> 
            <Components.PackageService
             patientData={selectPatientData}
             mode="new"
             onModalClose={(event) => closeServiceListModal(event)} />
          </div>
        : ''}

    {calendar ?
           <div className='position-fixed packservice-container expanded-views' id="packgservice"> 
            <Components.AppointmentScheduleModal
             patientData={pateintVisitAssignment[0]}
             doctorInfo={doctorInfo}
             type="Doctor"
             attachOption= {true}
             onModalClose={(event) => closeCalendarModal(event)} />
          </div>
        : ''}

      {notes ?
        <div className='position-fixed packservice-container expanded-views' id="packgservice">
          <Components.Notes
            // patientData={selectPatientData}
            patientData={props.patientDetail}
            person ={props.person}
            onModalClose={(event) => closeNotesModal(event)}
          />
        </div>
        : ''}
    </>
    );
}


export default AttachOptions;
